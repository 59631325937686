import { useEffect, useRef } from "react";

// eslint-disable-next-line import/no-anonymous-default-export
// eslint-disable-next-line @typescript-eslint/ban-types
export default function <T extends HTMLElement>(callback: Function) {
  const ref = useRef<T>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        event.button === 0 &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return ref;
}
