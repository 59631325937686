import clsx from "clsx";
import CutleryCounterMobileProps from "./CutleryCounterMobile.props";
import styles from "./CutleryCounterMobile.module.css";
import CrockeryIcon from "~/assets/icons/crockery.svg";
import PlusIcon from "~/assets/icons/plus.svg";
import { ButtonMobile, CounterMobile } from '~/components';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';


export const CutleryCounterMobile = ({
  className,
  basket,
  ...props
}: CutleryCounterMobileProps): JSX.Element => {
  const { setCutlery } = useBasketContext();

  const handlePlus = async () => {
    try {
      await setCutlery(basket.cutleryCount + 1);
    } catch (e) {
      console.log("Ошибка добавления приборов", e);
    }
  };

  const handleMinus = async () => {
    try {
      await setCutlery(basket.cutleryCount - 1);
    } catch (e) {
      console.log("Ошибка добавления приборов", e);
    }
  };

  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <div className={styles.text}>
        <CrockeryIcon className={styles.crockeryIcon} />
        Приборы
      </div>
      {basket.cutleryCount > 0 ? (
        <CounterMobile
          onPlus={handlePlus}
          onMinus={handleMinus}
          value={basket.cutleryCount}
        />
      ) : (
        <ButtonMobile
          size='m'
          pressed={false}
          appearance='gray'
          className={clsx(styles.button, "buttonAddToCart")}
          onClick={handlePlus}
        >
          <PlusIcon className={styles.plusIcon} />
          <span>
            Добавить
          </span>
        </ButtonMobile>
      )}
    </div>
  );
};
