import { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import clsx from "clsx";

import { ButtonDesktop } from '~/components';
import { ResendCode } from "../ResendCode/ResendCode";
import AuthCodeProps from "./AuthCode.props";
import styles from "./AuthCode.module.css";
import EditPencilIcon from "~/assets/icons/edit-pencil.svg";
import { useUserContext } from "~/hooks/user/useUser.hook";
import {
  confirmCode,
  sendCode
} from "~/plugins/api";
import { FieldState } from '~/models/account';
import jwtDecode from 'jwt-decode';
import useEnterElement from '~/hooks/useEnterElement';
import { useAddressContext } from '~/hooks/address/useAddress.hook';

export const AuthCode = ({
  onSuccessAuth = () => null,
  setActiveStage = (stage: number) => stage,
  phone = "",
  isOrderingScreen = false,
  className,
  ...props
}: AuthCodeProps): JSX.Element => {
  const { login } = useUserContext();
  const { handleGetUserAddresses } = useAddressContext();
  const [code, setCode] = useState("");
  const [codeRef, setCodeRef] = useState<HTMLInputElement | null>(null);
  const [codeState, setCodeState] = useState<FieldState>({
    isLoading: false,
    errors: [],
  });

  const onTakeBackStage = () => {
    setActiveStage(0);
  };

  const handleSendCode = async () => {
    const phoneRaw = phone.replace(/[^0-9]/g, "");
    try {
      await sendCode(phoneRaw);
    } catch (e) {
      console.log("Ошибка отправки кода");
      console.log(e);
    }
  };

  const handleConfirmCode = async () => {
    setCodeState({ ...codeState, isLoading: true });
    const codeRaw = code.replace(/[^0-9]/g, "");
    const phoneRaw = phone.replace(/[^0-9]/g, "");

    try {
      const token = await confirmCode({
        phoneNumber: phoneRaw,
        code: codeRaw,
      });

      login(token);
      handleGetUserAddresses();

      const isHaveNickName = !!(
        jwtDecode<any>(token.accessToken)["unique_name"] as string
      ).trim().length;

      setCodeState((state) => {
        return {
          ...state,
          isLoading: false,
        };
      });

      if (isHaveNickName) {
        onSuccessAuth();
      } else {
        setActiveStage(2);
      }
    } catch (e) {
      setCodeState({ ...codeState, errors: ["Вы ввели неправильный код"] });

      if (codeRef) {
        codeRef.focus();
      }
      console.error(e);
    }
  };

  useEnterElement({
    isRegisterListener: code.replace(/[^0-9]/g, "").length === 6,
    elementSelector: "#portal[data-portal]",
    callBack: handleConfirmCode
  });

  useEffect(() => {
    setCodeState({ ...codeState, errors: [] });
    const codeRaw = code.replace(/[^0-9]/g, "");

    if (codeRaw.length === 6 && isOrderingScreen) {
      handleConfirmCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const promtsWithModal = (
    <div className={styles.titleWrapper}>
      <div className={styles.prompt}>
        Введите код подтверждения отправленный в СМС на номер
        <span className={styles.phoneNubmer} onClick={onTakeBackStage}>{phone}</span>
      </div>
    </div>
  );

  const promtsWithOrdering = (
    <div>
      <div className={clsx(styles.prompt, styles.promtOrdering)}>
        <div className={styles.promtText}>Код отправлен на номер</div>
        <div className={styles.phoneNubmer}>
          <div onClick={onTakeBackStage}>{phone}</div>
          <EditPencilIcon className={styles.editIcon} />
        </div>
      </div>

      <ResendCode isOrderingScreen={isOrderingScreen} sendCode={handleSendCode} />
    </div>
  );

  return (
    <div className={clsx({ [styles.authCode]: isOrderingScreen })}>
      {!isOrderingScreen ? promtsWithModal : null}

      <div
        className={clsx(styles.inputWrapper, {
          [styles.inputWrapperOrdering]: isOrderingScreen,
          [styles.inputWrapperInvalid]: codeState.errors.length,
        })}
      >
        {isOrderingScreen && <label
          className={clsx(styles.label, {
            [styles.hasValue]: code?.length,
          })}
          htmlFor="name"
        >
          Код из смс
        </label>}
        <IMaskInput
          id="name"
          mask="0 0 0 0 0 0"
          inputRef={setCodeRef}
          onAccept={(value) => {
            if (value) return setCode(value.trim());
            return setCode("");
          }}
          disabled={codeState.isLoading}
          type="text"
          autoFocus
          inputMode="numeric"
          pattern="[0-9]*"
          className={clsx(styles.input, {
            [styles.inputOrdering]: isOrderingScreen
          })}
          value={code}
          autoComplete="one-time-code"
          placeholder={"__ __ __ __ __ __"}
        />
        <div className={styles.alertPrompt}>
          {codeState.errors[0]}
        </div>
      </div>

      {!isOrderingScreen ? <ResendCode isOrderingScreen={isOrderingScreen} sendCode={handleSendCode} className={styles.resendCode} /> : null}

      {isOrderingScreen ? promtsWithOrdering : null}

      {!isOrderingScreen ? (
        <ButtonDesktop
          onClick={handleConfirmCode}
          disabled={codeState.isLoading || code.replace(/[^0-9]/g, "").length < 6}
          loading={codeState.isLoading}
          className={styles.confirmBtn}
        >
          Подтвердить
        </ButtonDesktop>) : null}
    </div>
  );
};
