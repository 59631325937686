import Image from "next/legacy/image";
import clsx from "clsx";

import { AddToCartMobile } from '../AddToCartMobile';
import ProductCardProps from "./ProductCard.props";
import styles from "./ProductCard.module.css";

import DefaultImage from "~/assets/svg/product-placeholder.webp";
import { CardCover } from './CardCover';

export const ProductCardMobile = ({
  className,
  product,
  adaptiveWidth = false,
  ...props
}: ProductCardProps): JSX.Element => {
  return (
    <div
      className={clsx(styles.card, className, {
        [styles.adaptiveCard]: adaptiveWidth,
      })}
      {...props}
    >
      <CardCover product={product} />

      <div className={styles.imageWrapper}>
        <Image
          priority
          alt={product.name}
          layout="fill"
          objectFit="cover"
          width={180}
          height={114}
          src={product.image.length ? product.image : DefaultImage}
        />
        <div
          className={clsx(styles.persentPlate, {
            [styles.visiblePersentPate]: product.salePercent,
          })}
        >
          – {product.salePercent}%
        </div>
      </div>
      <div
        className={styles.productInformation}
      >
        <div className={styles.price}>
          <div>
            <div className={clsx(styles.oldCost, {
              [styles.hidden]: product?.oldCost === 0
            })}>
              <span>{product?.oldCost}</span>
              <span>₽</span>
            </div>
            <div className={clsx(styles.cost, {
              [styles.redPrice]: product?.oldCost !== 0
            })}>
              <span>{product.cost}₽</span>
            </div>
          </div>
          {product?.portionSize && <span className={styles.characteristic}>{product?.portionSize}</span>}
        </div>

        <div title={product.name} className={clsx(styles.name, "clamp-3")}>
          {product.name}
        </div>

        <AddToCartMobile product={product} />
      </div>
    </div>
  );
};
