import { TagLinkMobile } from "~/components";

import CatalogFiltersBottomSheetProps from "./CatalogFiltersBottomSheet.props";
import styles from "./CatalogFiltersBottomSheet.module.css";
import Link from 'next/link';

export const CatalogFiltersBottomSheet = ({
  tags,
  categoryId,
  handleSelectTag = () => null,
  ...props
}: CatalogFiltersBottomSheetProps): JSX.Element => (
  <div className={styles.popupWrapper} {...props}>
    <div className={styles.filtersList}>
      {tags.map((tag) => (
        <Link
          href={`/?categoryId=${categoryId}&filter=${tag?.name}`}
          passHref
          key={tag?.id}
        >
          <TagLinkMobile
            className={'tag'}
            onClick={() => handleSelectTag(tag)}
          >
            {tag?.name}
          </TagLinkMobile>
        </Link>
      ))}
    </div>
  </div>

);

