
import HeaderProps from "./Header.props";
import { Content } from './Content';
import { Wrapper } from './Wrapper';

export const Header = ({ className, ...props }: HeaderProps): JSX.Element => (
  <Wrapper className={className} {...props}>
    <Content />
  </Wrapper>
);
