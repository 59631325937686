import { useEffect, useState } from "react";
import useCloseESCModal from "./useCloseESCModal";

interface IModal {
  isOpen: boolean;
  hideModal: () => void;
  onExit?: () => void;
}

export default function useChangeVisibleModal({
  isOpen = false,
  hideModal = () => null,
  onExit = () => null,
}: IModal) {
  //Для того чтобы не отменять стили "фиксирования страница", когда закрывается наложенная модалка
  const [isOpenSomeModals, setIsOpenSomeModals] = useState(false);
  useEffect(() => {
    if (document.querySelectorAll("#portal[data-portal]").length > 1)
      setIsOpenSomeModals(true);
    else setIsOpenSomeModals(false);
  }, [hideModal, onExit, isOpen]);

  useEffect(() => {
    const html = document.documentElement;
    if (html && isOpen) {
      html.style.overflow = "hidden";
      // html.style.paddingRight = "10px";
    }
    if (html && !isOpen && !isOpenSomeModals) {
      html.style.overflow = "";
      html.style.paddingRight = "";
    }

    if (!isOpen) setTimeout(onExit, 300);
  }, [isOpen, isOpenSomeModals]);

  const isClose = useCloseESCModal();

  useEffect(() => {
    if (isClose) hideModal();
  }, [isClose]);

  return null;
}
