import {
  createContext,
  ReactNode,
  useContext,
} from "react";

import { Product } from "~/models/basket";
import { Token } from "~/plugins/api";
import useToken from './useToken';
import useSelectPay, { IPaymentType } from './useSelectPay';
import useSelectBanknotes, { IBanknote } from './useSelectBanknotes';

export interface IUser {
  id: string;
  lastDeliveryCityId: string;
  lastMarketCategoryId: number;
  name: string;
  phone: string;
}

export interface IBasketMethodsArgs {
  product: Product;
  partnerId: string;
  marketCategoryId: number;
  quantity: number;
}

interface IContextProps {
  user: IUser | null;
  token: Token | null;
  loggedIn: boolean;
  userName: string;
  login: (token: Token, callback?: () => void) => void;
  logout: () => void;
  setIsUpdateUserData: (bool: boolean) => void;
  // payments: IPaymentType[],
  // cardsList: IPaymentType[],
  // activePayment: IPaymentType | null,
  // handleSetPaymentType: (payment: IPaymentType) => void,
  // banknotes: IBanknote[],
  // changeFromBanknote: IBanknote | null,
  // handleChangeBanknote: (banknote: IBanknote | null) => void,
}

const UserContext = createContext<IContextProps>({
  token: null,
  user: null,
  loggedIn: false,
  userName: '',
  login: () => null,
  logout: () => null,
  setIsUpdateUserData: () => null,
  // payments: [],
  // cardsList: [],
  // activePayment: null,
  // handleSetPaymentType: () => null,
  // banknotes: [],
  // changeFromBanknote: null,
  // handleChangeBanknote: () => null,
});

export function UserProvider({ children }: { children: ReactNode }) {

  const user = useToken();

  return (
    <UserContext.Provider
      value={{
        ...user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}