import { useModal } from "react-modal-hook";
import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import dynamic from 'next/dynamic';
import clsx from "clsx";

import { ButtonMobile } from "~/components";
import { TitleMobile } from '../TitleMobile';
import CartBarLayoutProps from "./CartBarLayout.props";
import styles from "./CartBarLayout.module.css";
import CartIcon from "~/assets/icons/cart.svg";
import DeliveryIcon from "~/assets/icons/delivery.svg";
import ArrowIcon from "~/assets/icons/arrow-down-2.svg";

import { useUserContext } from "~/hooks/user/useUser.hook";
import { namedComponent } from '~/helpers/dynamicComponent';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { usePartnerInfo } from '~/hooks/partner';

const Components = {
  BottomSheet: dynamic(() =>
    namedComponent(import('~/components/modals/_Components/BottomSheet'), 'BottomSheet')
  ),
  AuthBottomSheet: dynamic(() =>
    namedComponent(import('~/components/modals/AuthBottomSheet'), 'AuthBottomSheet')
  ),
  PayBottomSheet: dynamic(() =>
    namedComponent(import('~/components/modals/PayBottomSheet'), 'PayBottomSheet')
  ),
};

const partnerPath = [
  "/retail/[id]",
  "/cart",
  "/partner/[id]"
];

export const CartBarLayout = ({
  className,
  ...props
}: CartBarLayoutProps): JSX.Element => {
  const { loggedIn } = useUserContext();
  const { basket, loadingBasket } = useBasketContext();
  const { data: partner } = usePartnerInfo(basket?.partnerId ?? "");
  const router = useRouter();
  const pathname = router.pathname;
  const queryPartnerId = router.query?.id ?? undefined;
  const payCheck = router.query?.pay as string ?? undefined;

  const availablePaymentMethods= partner?.paymentMethods;

  useEffect(() => {
    if (payCheck === "check") showPayModal();
  }, [payCheck]);

  const [showPayModal, hidePayModal] = useModal(({ in: open, onExited }) => {
    return <Components.PayBottomSheet isOpen={open} onExit={onExited} hideModal={hidePayModal} />
  });

  const isVisibleCartBarDeliveryInfo =
    pathname === "/partner/[id]" ||
    pathname === "/cart" ||
    pathname === "/retail/[id]";

  const amount = useMemo(() => {
    if (!basket) return 0;

    return Math.ceil(basket.amount - basket.discount);
  }, [basket]);

  const deliveryCost = basket?.deliveryCost;
  const getFreeSumm = (amountFree: number | undefined, amountCurrent: number) => {
    if (!amountFree) return 0;
    if (amountFree > amountCurrent) {
      if ((amountFree / amountCurrent - 1) > 0.35) return 0
      return amountFree - amountCurrent
    }
    return 0;
  };

  const [deliveryFreeSumm, setDeliveryFreeSumm] = useState<number | null>(null);
  const [minDeliverySumm, setMinDeliverySumm] = useState<number>(0);

  useEffect(() => {
    if (partner) {
      const deliveryFrame = partner?.deliveryFrames[0].deliveryCosts;
      const minDeliverySumm = deliveryFrame[0].amountFrom > amount ? deliveryFrame[0].amountFrom - amount : 0;
      const isFree = deliveryFrame.find(({ deliveryCost }) => deliveryCost === 0);
      const amountFrom = isFree?.amountFrom
      const summ = getFreeSumm(amountFrom, amount);

      setDeliveryFreeSumm(summ);
      setMinDeliverySumm(minDeliverySumm);
    }
  }, [partner, basket?.content]);

  const [showModal, hideModal] = useModal(({ in: open, onExited }) => {
    return <Components.AuthBottomSheet onExit={onExited} isOpen={open} hideModal={hideModal} onSuccessAuth={showPayModal} />;
  });

  const handleOrderPlateClick = () => {
    if (pathname === "/cart") {
      if (!loggedIn) return showModal();
      if (payCheck === "check") return showPayModal();
      return router.push(
        `/cart?pay=check`,
        undefined,
        { shallow: true }
      );
    }

    if (!basket?.partnerIsStructure) {
      if (
        pathname === "/partner/[id]" &&
        basket?.partnerId === queryPartnerId
      ) {
        return router.push(`/cart`);
      }
      return router.push(`/partner/${basket?.partnerId}?name=${basket?.partnerName}`);

    } else {
      if (pathname === "/retail/[id]" && basket?.partnerId === queryPartnerId) {
        return router.push(`/cart`);
      }
      return router.push(
        `/retail/${basket?.partnerId}?name=${basket?.partnerName}&category=Товары по акции&categoryId=promo`
      );
    }
  };

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!basket) return;
    if (basket && basket?.amount > 0) {
      setVisible(true);
    }
    if (!basket?.amount) {
      setTimeout(() => {
        setVisible(false);
      }, 400);
    }
  }, [basket]);

  const handleDeliveryPlateClick = () => {
    if (partnerPath.includes(pathname)) return showAboutDelivery()
  };

  const isPartnerDelivery = partner?.deliveryFrames[0].deliveryTypeId === 1;

  const [showAboutDelivery, hideAboutDelivery] = useModal(
    ({ in: open, onExited }) => {
      return (
        <Components.BottomSheet
          onExit={onExited}
          isOpen={open}
          hideModal={hideAboutDelivery}
        >
          <div className={styles.aboutDelivery}>
            <TitleMobile headerType='h4' margin={false}>Информация о доставке</TitleMobile>
            <div>
              {isPartnerDelivery ? (
                <div>Доставку выполнит курьер от ресторана. Вся ответственность за заказ лежит на партнере!</div>
              ) : (
                <div>Доставку выполнит курьер CronMarket</div>
              )}
            </div>
            <div className={styles.pricesItem}>
              <div className={styles.modalItemTitle}>Минимальный заказ:</div>
              <div>от {partner?.minAmountOrder} руб.</div>
            </div>
            <div className={styles.prices}>
              <div className={styles.modalItemTitle}>Стоимость доставки:</div>
              {partner?.deliveryFrames[0]?.deliveryCosts.map((cost, index) => (
                <div key={index} className={styles.pricesItem}>
                  <div>
                    {cost.amountFrom === 0
                      ? `При заказе до ${cost.amountTo} руб.`
                      : cost.amountTo === 0
                        ? `При заказе от ${cost.amountFrom} руб.`
                        : `При заказе от ${cost.amountFrom} до ${cost.amountTo} руб.`}
                  </div>
                  <div>{cost.deliveryCost} руб.</div>
                </div>
              ))}
            </div>
            {!isPartnerDelivery ? (
              <div>
                <div className={styles.modalItemTitle}>Лимиты:</div>
                <div className={styles.pricesItem}>
                  <div>максимальный вес заказа</div>
                  <div>30 кг.</div>
                </div>
              </div>
            ) : null}
          </div>
        </Components.BottomSheet>
      );
    },
    [partner]
  );

  return (
    <div className={styles.wrapper}>
      {visible ? (
        <div
          className={clsx(styles.window, className, {
            [styles.visible]: basket && basket?.amount > 0,
            [styles.hidden]: !basket?.amount,
          })}
          {...props}
        >
          <div className={clsx(styles.cartBarLayout, { [styles.partnerBar]: isVisibleCartBarDeliveryInfo })}>
            {isVisibleCartBarDeliveryInfo ? (
              <div onClick={handleDeliveryPlateClick} className={styles.deliveryMetaInfo}>
                <DeliveryIcon className={styles.deliveryIcon} />
                <div className={styles.info}>
                  {minDeliverySumm > 0 ? (
                    <div className={styles.supText}>До минимального заказа ещё {minDeliverySumm}₽</div>
                  ) : null}

                  {pathname !== "/cart" ? (
                    <div
                      className={clsx(styles.text, {
                        [styles.textSuccess]: deliveryCost == 0,
                        [styles.textSmall]: !!deliveryFreeSumm
                      })}
                    >
                      {deliveryCost != 0 ? `Доставка ${deliveryCost}₽` : "Доставим бесплатно"}
                    </div>
                  ) : (
                    <div
                      className={clsx(styles.text, {
                        [styles.textSmall]: !!deliveryFreeSumm
                      })}
                    >
                      {partner?.deliveryFrames[0].deliveryTypeId === 2 ? `Доставит курьер CronMarket` : "Доставит курьер партнера"}
                    </div>
                  )}

                  {deliveryFreeSumm && deliveryFreeSumm > 0 ? (
                    <div className={styles.subText}>До бесплатной еще {deliveryFreeSumm}₽</div>
                  ) : null}
                </div>
                <ArrowIcon className={styles.arrowIcon} />
              </div>
            ) : null}

            <ButtonMobile
              size='h'
              pressed={false}
              onClick={handleOrderPlateClick}
              className={styles.orderPlateButton}
              loading={loadingBasket}
              disabled={!!minDeliverySumm && partnerPath.includes(pathname)}
            >
              {pathname === "/cart" ? (
                <div className={styles.checkoutText}>
                  К Оформлению заказа
                </div>
              ) : (
                <>
                  <div className={styles.leftBlock}>
                    <CartIcon className={styles.cartIcon} />
                    <span className={styles.amount}>
                      {amount}₽
                    </span>
                  </div>
                  <div className={styles.rightBlock}>
                    <span className={clsx(styles.cartName, "clamp-2")}>
                      {partnerPath.includes(pathname) && queryPartnerId === basket?.partnerId
                        ? "Корзина"
                        : basket?.partnerName}
                    </span>
                  </div>
                </>
              )}
            </ButtonMobile>
          </div>
        </div>
      ) : null}
    </div>
  );
};
