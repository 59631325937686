import { useRouter } from "next/router";
import { useEffect, useState } from "react";
// import { LoadingIndicator } from '../LoadingIndicator';
import styles from "./Loader.module.css";

export function Loading(): JSX.Element {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // const body = document.body;
    // const root = document.getElementById("__next");
    // const pagePosition = window.scrollY;
    // const bodyPosition = body.dataset.position ? +body.dataset.position : 0;

    const handleStart = () => {
      // if (root) {
      //   body.style.overflow = "hidden";
      //   body.style.position = "fixed";
      //   body.style.top = "0";
      //   body.dataset.position = `${pagePosition}`;
      //   body.style.top = -pagePosition + "px";
      //   body.style.width = "100vw";
      //   root.style.position = "inherit";
      // }
      setLoading(true)
    };

    const handleComplete = () => {
      // if (root) {
      //   body.style.top = "auto";
      //   body.style.overflow = "";
      //   body.style.position = "";
      //   body.style.top = "0";
      //   window.scroll({ top: bodyPosition });
      //   body.removeAttribute("data-position");
      //   body.style.width = "";
      //   root.style.position = "";
      // }
      setLoading(false)
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  });

  return <>{loading ? <div className={styles.loader} /> : null}</>;
}
{/* <LoadingIndicator isScreen color='primary' />  */ }