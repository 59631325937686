import { useModal } from 'react-modal-hook';
import { ButtonDesktop, Modal } from "~/components";
import PartnerRating from './PartnerRating';
import RaitingIcon from "~/assets/icons/star-fill.svg";
import styles from "./PartnerRating.module.css";
import { useRouter } from 'next/router';
import { usePartnerInfo } from '~/hooks/partner';

export const PartnerRatingModal = (): JSX.Element => {
  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Modal
        align='left'
        headline='Отзывы'
        width={546}
        isOpen={open}
        onExit={onExited}
        hideModal={hideModal}
      >
        <PartnerRating />
      </Modal>
    )
  );

  const router = useRouter();
  const partnerId = router.query.id as string;
  const { data: partner } = usePartnerInfo(partnerId);

  return (
    <ButtonDesktop
      appearance='text'
      className={styles.metaPlate}
      onClick={showModal}
    >
      <div className={styles.metaPlateIcon}>
        <RaitingIcon className={styles.starSvg} />
        {partner?.rating}
      </div>

      <div className={styles.metaPlateInfo}>
        <div className={styles.metaSubTitle}>
          {partner?.feedbackCount} отзывов
        </div>
        <div className={styles.metaSubText}>Читать отзывы</div>
      </div>
    </ButtonDesktop>
  )
};
