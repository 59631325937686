import { useEffect, useState } from 'react';
import clsx from "clsx";

import { BottomSheet, ButtonMobile, CitySelectMobile, Input, Textarea, TitleMobile } from "~/components";
import DeliveryAddressBottomSheetProps from "./DeliveryAddressBottomSheet.props";
import styles from "./DeliveryAddressBottomSheet.module.css";

import { IOrderInfo } from '~/pages/ordercheckout';
import { useAddressContext } from '~/hooks/address/useAddress.hook';

export const DeliveryAddressBottomSheet = ({
  isOpen,
  hideModal,
  onExit,
  className,
  ...props
}: DeliveryAddressBottomSheetProps): JSX.Element => {
  const { location, editAddress, handleSetLocation } = useAddressContext();
  const [loading, setLoading] = useState(false);
  const [orderInfo, setOrderInfo] = useState<IOrderInfo>({
    intercom: location?.intercom,
    entrance: location?.entrance,
    floor: location?.floor,
    flat: location?.flat,
    addressComment: location?.comment,
    longitude: location?.longitude ?? "",
    latitude: location?.latitude ?? "",
  });

  useEffect(() => {
    if (location?.longitude && location?.latitude)
      setOrderInfo({
        intercom: location?.intercom,
        entrance: location?.entrance,
        floor: location?.floor,
        flat: location?.flat,
        addressComment: location?.comment,
        longitude: location?.longitude,
        latitude: location?.latitude,
      });
  }, [location]);

  const changeOrderInfo = (
    field: "intercom" | "entrance" | "floor" | "flat" | "addressComment",
    value: string
  ) => setOrderInfo((info) => ({ ...info, [field]: value }));

  const handleEditAddress = async () => {
    if (location) {
      setLoading(true);
      const newAddress = {
        id: location.id,
        cityId: location.cityId,
        cityName: location.cityName,
        address: location.address,
        fullAddress: location.fullAddress ?? "",
        entrance: orderInfo.entrance ?? location.entrance,
        floor: orderInfo.floor ?? location.floor,
        flat: orderInfo.flat ?? location.flat,
        intercom: orderInfo.intercom ?? location.intercom,
        comment: orderInfo.addressComment ?? location.comment,
        latitude: location.latitude,
        longitude: location.longitude,
      };

      editAddress(newAddress);

      handleSetLocation({
        ...newAddress,
        fullAddress: location?.fullAddress,
      });
      setLoading(false);
      hideModal();
    }
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onExit={onExit}
      hideModal={hideModal}
    >
      <div className={clsx(styles.wrapper, className)} {...props}>
        <TitleMobile headerType='h4'>Адрес доставки</TitleMobile>
        <div className={styles.addressSelect}>
          <CitySelectMobile />
        </div>
        <form className={styles.form}>
          <Input
            onChange={({ currentTarget }) =>
              changeOrderInfo("intercom", currentTarget.value)
            }
            placeholder="Домофон"
            value={orderInfo?.intercom}
            className={styles.oneColumn}
          />
          <Input
            onChange={({ currentTarget }) =>
              changeOrderInfo("entrance", currentTarget.value)
            }
            placeholder="Подъезд"
            value={orderInfo?.entrance}
            className={styles.oneColumn}
          />
          <Input
            onChange={({ currentTarget }) =>
              changeOrderInfo("flat", currentTarget.value)
            }
            placeholder="Квартира"
            value={orderInfo?.flat}
            className={styles.oneColumn}
          />
          <Input
            onChange={({ currentTarget }) =>
              changeOrderInfo("floor", currentTarget.value)
            }
            placeholder="Этаж"
            value={orderInfo?.floor}
            className={styles.oneColumn}
          />
          <Textarea
            onChange={({ currentTarget }) =>
              changeOrderInfo("addressComment", currentTarget.value)
            }
            placeholder="Комментарий к адресу"
            value={orderInfo.addressComment}
            noResize
            className={styles.twoColumns}
          />
        </form>
        <ButtonMobile
          size='l'
          loading={loading}
          disabled={loading}
          className={styles.saveAddress}
          onClick={handleEditAddress}
        >
          Сохранить
        </ButtonMobile>
      </div>
    </BottomSheet>
  );
};

