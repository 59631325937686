import { useState } from "react";
import clsx from "clsx";

import OrderFeedbackViewProps from "./OrderFeedbackView.props";
import styles from "./OrderFeedbackView.module.css";
import { Mark } from '../Mark';
import { Textarea } from '../Textarea';
import { addOrderFeedback, IOrderFeedback } from '~/plugins/api/order';
import { ButtonMobile } from '../_MobileUI/ButtonMobile';
import useHtmlAttributes from '~/hooks/useHtmlAttributes';
import { ButtonDesktop } from '../_DesktopUI/ButtonDesktop';
import Link from 'next/link';
import { useGetSupportPhoneNumber } from '~/hooks/systemInfo';
import maskPhoneNumber from '~/helpers/maskPhoneNumber';

export const OrderFeedbackView = ({
	data,
	className,
	orderId,
	isModal = false,
	handleSuccessFeedBack,
	...props
}: OrderFeedbackViewProps): JSX.Element => {
	const { devices } = useHtmlAttributes();
	const [feedBackInfo, setFeedBackInfo] = useState<IOrderFeedback>({
		positiveText: data?.positiveText ?? "",
		negativeText: data?.negativeText ?? "",
		mark: data?.mark ?? 0,
		orderId: orderId,
	});

	const isValidateForm = !!((feedBackInfo?.positiveText.length || feedBackInfo?.negativeText.length) && (feedBackInfo.mark > 0));

	const changeOrderFeedBack = (
		field: "positiveText" | "negativeText",
		value: string
	) => setFeedBackInfo((info) => ({ ...info, [field]: value }));

	const [loading, setLoading] = useState(false);

	const [isSuccess, setIsSuccess] = useState(false);

	const handleAddFeedback = async () => {
		setLoading(true);
		await addOrderFeedback(feedBackInfo);
		setIsSuccess(true);
		setLoading(false);
	};

	const { data: supportPhoneNumber } = useGetSupportPhoneNumber();

	return (
		<div className={styles.content} {...props}>
			{!isSuccess ? (
				<>
					<form className={styles.form}>
						<div className={styles.feedback}>
							<span>Ваша оценка</span>
							<div className={styles.stars}>
								<Mark
									mark={feedBackInfo.mark}
									isEditable={!data}
									setMark={(mark) => setFeedBackInfo((info) => ({ ...info, mark }))}
								/>
							</div>
						</div>

						<div className={clsx(styles.positiveReview, {
							[styles.noPositiveFeedback]: !data?.positiveText && data?.mark
						})}>
							<label className={styles.addressLabel}>Расскажите, что вам понравилось?</label>
							<Textarea
								onChange={({ currentTarget }) => {
									changeOrderFeedBack("positiveText", currentTarget.value);
								}}
								placeholder="Оставьте отзыв"
								value={feedBackInfo?.positiveText}
								className={clsx(styles.reviewText, {
									[styles.blockedText]: data,
								})}
								disabled={!!data}
							/>
						</div>

						<div className={clsx(styles.negativeReview, {
							[styles.noNegativeFeedback]: !data?.negativeText && data?.mark
						})}>
							<label className={styles.addressLabel}>Расскажите, что вам не понравилось?</label>
							<Textarea
								onChange={({ currentTarget }) =>
									changeOrderFeedBack("negativeText", currentTarget.value)
								}
								placeholder="Оставьте отзыв"
								value={feedBackInfo?.negativeText}
								className={clsx(styles.reviewText, {
									[styles.blockedText]: data,
								})}
								disabled={!!data}
							/>
						</div>
					</form>
					{data ? (
						<p className={styles.modalFooterText}>
							Хотите удалить или изменить отзыв?<br />
							Обратитесь в техподдержку по номеру
							<Link className={styles.socLink} href={`tel:${supportPhoneNumber?.callCenterPhoneNumber}`}>
								<span className={styles.socLinkText}>{maskPhoneNumber(supportPhoneNumber?.callCenterPhoneNumber)}</span>
							</Link>
						</p>
					) : (
						<div className={clsx({
							[styles.bottomBarDesktop]: devices?.isDesktop,
							[styles.bottomBarMobile]: !devices?.isDesktop
						})}>
							{devices?.isDesktop ? (
								<ButtonDesktop
									className={styles.sendButton}
									onClick={handleAddFeedback}
									loading={loading}
									disabled={!isValidateForm}
								>
									оставить отзыв
								</ButtonDesktop>
							) : (
								<ButtonMobile
									size='s'
									className={styles.sendButton}
									onClick={handleAddFeedback}
									loading={loading}
									disabled={!isValidateForm}
								>
									оставить отзыв
								</ButtonMobile>
							)}
						</div>
					)}
				</>
			) : (
				<div className={styles.successFeedback}>
					<h3 className={styles.title}>Спасибо за ваш отзыв!</h3>
					<p className={styles.successDesc}>
						Мы очень ценим вас, наших клиентов,
						и благодаря вашей обратной связи стараемся сделать сервис лучше, чтобы каждый остался доволен.
						Ваш отзыв появится на сайте после модерации.
					</p>
					{devices?.isDesktop ? (
						<ButtonDesktop
							size='s'
							onClick={handleSuccessFeedBack}
							className={styles.buttonSuccess}
						>
							ОК
						</ButtonDesktop>
					) : (
						<ButtonMobile
							size='s'
							onClick={handleSuccessFeedBack}
							className={styles.buttonSuccess}
						>
							ОК
						</ButtonMobile>
					)}
				</div>
			)
			}
		</div >
	);
};
