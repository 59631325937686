import { useState } from "react";

import { Input, Textarea, ButtonDesktop } from "~/components";
import { AddressDetailsProps } from "./AddressDetailsModal.props";
import styles from "./AddressDetailsModal.module.css";

import { useAddressContext } from '~/hooks/address/useAddress.hook';

interface IOrderInfo {
  intercom?: string;
  entrance?: string;
  floor?: string;
  flat?: string;
  comment?: string;
}

export const AddressDetails = ({
  hideModal,
  onSuccesAddress = () => null,
}: AddressDetailsProps): JSX.Element => {
  const { location, editAddress } = useAddressContext();

  const [orderInfo, setOrderInfo] = useState<IOrderInfo>({
    intercom: location?.intercom ?? "",
    entrance: location?.entrance ?? "",
    floor: location?.floor ?? "",
    flat: location?.flat ?? "",
    comment: location?.comment ?? "",
  });

  const changeOrderInfo = (
    field: "intercom" | "entrance" | "floor" | "flat" | "comment",
    value: string
  ) => {
    setOrderInfo((info) => ({ ...info, [field]: value }));
  };

  const handleEditAddress = async () => {
    if (!location) return;
    const newAddress = {
      ...location,
      entrance: orderInfo.entrance,
      floor: orderInfo.floor,
      flat: orderInfo.flat,
      intercom: orderInfo.intercom,
      comment: orderInfo.comment,
    };

    editAddress(newAddress);

    onSuccesAddress();
    hideModal();
  };

  return (
    <div className={styles.desktopContainerMap}>
      <div className={styles.addressDetails}>
        <div className={styles.subDetails}>
          <Input
            type="number"
            onChange={({ currentTarget }) =>
              changeOrderInfo("flat", currentTarget.value)
            }
            label="Квартира"
            value={orderInfo.flat ?? ""}
            min={1}
            maxLength={4}
          />
          <Input
            onChange={({ currentTarget }) =>
              changeOrderInfo("intercom", currentTarget.value)
            }
            label="Домофон"
            value={orderInfo.intercom ?? ""}
            min={1}
            maxLength={12}
          />
          <Input
            type="number"
            onChange={({ currentTarget }) =>
              changeOrderInfo("entrance", currentTarget.value)
            }
            label="Подъезд"
            value={orderInfo.entrance ?? ""}
            min={1}
            maxLength={3}
          />
          <Input
            type="number"
            onChange={({ currentTarget }) =>
              changeOrderInfo("floor", currentTarget.value)
            }
            label="Этаж"
            value={orderInfo.floor}
            min={1}
            maxLength={3}
          />
        </div>
        <Textarea
          onChange={({ currentTarget }) =>
            changeOrderInfo("comment", currentTarget.value)
          }
          placeholder="Комментарий к адресу"
          value={orderInfo.comment ?? ""}
          className={styles.preference}
          maxLength={300}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <ButtonDesktop
          appearance='gost'
          className={styles.button}
          onClick={hideModal}
        >
          Отмена
        </ButtonDesktop>
        <ButtonDesktop
          className={styles.button}
          onClick={handleEditAddress}
        >
          Сохранить
        </ButtonDesktop>
      </div>
    </div>
  );
};
