import { useRouter } from "next/router";
import dynamic from 'next/dynamic';
import { deafultComponent } from '~/helpers/dynamicComponent';

const Components = {
  MainContentHeader: dynamic(() =>
    deafultComponent(import('./MainContentHeader'))
  ),
  SearchContentHeader: dynamic(() =>
    deafultComponent(import('./SearchContentHeader'))
  ),
};

export const Content = (): JSX.Element => {
  const router = useRouter();
  const pathName = router.pathname;
  const isMainView =
    pathName === "/partner/[id]" || pathName === "/retail/[id]" || pathName === "/retail/category/[id]";

  return isMainView ? <Components.MainContentHeader /> : <Components.SearchContentHeader />;
};
