import { DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState } from 'react';
import {
  ButtonMobile,
  // Switcher, 
  // TitleMobile,
  Input,
  TitleMobile,
} from "~/components";
import styles from "./MobileProfileModal.module.css";
import TrashIcon from "~/assets/icons/trash.svg";

import useSaveUserName from '~/hooks/useSaveUserName';
import { useUserContext } from '~/hooks/user/useUser.hook';
import Image from 'next/legacy/image';
import { useDeleteUserCard } from '~/hooks/user/useDeleteUserCard';
import { DeleteCardProps } from '~/plugins/api/payment';
import useConfirmBottomSheet from '~/hooks/useConfirmBottomSheet';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

interface MobileProfileModalProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  hideModal: () => void;
}

interface ErrorResponse {
  message: string;
  status: number;
  statusText: string;
}

export const Profile = ({
  hideModal = () => null,
  ...props
}: MobileProfileModalProps): JSX.Element => {
  const { userName } = useUserContext();
  const { cardsList } = useBasketContext();

  const {
    setName,
    handleSaveName,
    name,
  } = useSaveUserName(hideModal, userName);

  const field = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (field.current) field.current?.focus()
  }, []);

  const { mutate, error, isError } = useDeleteUserCard();
  const [deleteId, setDeleteId] = useState<DeleteCardProps>({
    cardId: '',
    rebillId: ''
  })
  const handleDeleteCard = async (cardId: number, rebillId: string) => {
    setDeleteId({cardId, rebillId})
    showConfirmBottomSheet()
  };

  const { showConfirmBottomSheet, hideConfirmBottomSheet } = useConfirmBottomSheet({
		onConfirm: () => mutate(deleteId),
		onCancel: () => hideConfirmBottomSheet(),
		message: "Вы уверены что хотите удалить карту",
		cancelText: "Нет",
		confirmText: "Да",
		dependencies: [deleteId]
	})

  const erroeResp = (error as ErrorResponse)?.message;

  const { showConfirmBottomSheet: showBottomSheetModal, hideConfirmBottomSheet: hideBottomSheetModal } = useConfirmBottomSheet({
		onConfirm: () => null,
		onCancel: () => hideBottomSheetModal(),
    title: "Внимание !", 
		message: erroeResp || "Не удалось удалить карту из-за проблем на сервере.",
		cancelText: "Закрыть",
		confirmText: "",
		dependencies: [erroeResp]
	})

  useEffect(() => {
    if(isError && erroeResp) {
      showBottomSheetModal();
    }
  }, [erroeResp, isError])

  return (
    <div {...props}>
      <div className={styles.setings}>
        <Input
          innerRef={field}
          placeholder="Ваше имя"
          value={name}
          className={styles.name}
          onChange={({ currentTarget }) => setName(currentTarget?.value)}
        />

        <ButtonMobile
          className={styles.buttonSaveName}
          disabled={userName.trim() == name.trim()}
          onClick={handleSaveName}
        >
          Внести изменения
        </ButtonMobile>
      </div>

      {cardsList?.length ? <div className={styles.cards}>
        <TitleMobile headerType='h4'>Привязанные карты</TitleMobile>
        <ul className={styles.cardsList}>
          {cardsList.map(({ id, key, name, icon }) => (
            <li key={id} onClick={() => handleDeleteCard(id, key)} className={styles.cardItem}>
              {icon && <span
                className={styles.cardItemIcon}
              >
                <Image
                  width={45}
                  height={30}
                  alt={key}
                  src={icon}
                />
              </span>}
              <span className={styles.cardItemText}>
                {name}
              </span>
              <span>
                <TrashIcon className={styles.checkIcon} />
              </span>
            </li>
          ))}
        </ul>
      </div> : null}
      {/* <div className={styles.setings}>
        <TitleMobile headerType='h5'>Настройки</TitleMobile>
        <Switcher label='Уведомления' />
      </div> */}
    </div>
  );
};
