import { useModal } from 'react-modal-hook';
import clsx from "clsx";

import { BanknoteTypesBottomSheet, ButtonMobile } from "~/components";
import SelectChangeBanknoteMobileProps from "./SelectChangeBanknoteMobile.props";
import styles from "./SelectChangeBanknoteMobile.module.css";
import ArrowIcon from "~/assets/icons/arrow-right-black.svg";
import PanhandlerIcon from "~/assets/icons/panhandler-icon.svg";
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

export const SelectChangeBanknoteMobile = ({
  className,
  ...props
}: SelectChangeBanknoteMobileProps): JSX.Element => {
  const { activePayment, changeFromBanknote } = useBasketContext();

  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => {
      return <BanknoteTypesBottomSheet isOpen={open} hideModal={hideModal} onExit={onExited} />
    }
  );

  if (activePayment?.id !== 1) return <></>

  return (
    <ButtonMobile
      pressed={false}
      id='payment'
      appearance='text'
      size='l'
      type='button'
      className={clsx(styles.buttonSelectPayment, className)}
      onClick={showModal}
      {...props}
    >
      <span
        className={styles.paymentIcon}
      >
        <PanhandlerIcon />
      </span>
      <span className={styles.paymentText}>
        {changeFromBanknote ? changeFromBanknote?.type + " ₽" : "Сумма, с которой нужна сдача"}
      </span>

      <ArrowIcon className={styles.arrowIcon} />
    </ButtonMobile>
  );
};
