import Image from "next/legacy/image";
import clsx from "clsx";

import { AddToCart } from "../AddToCart";
import ProductCardDesktopProps from "./ProductCardDesktop.props";
import styles from "./ProductCardDesktop.module.css";
import DefaultImage from "~/assets/svg/product-placeholder.webp";
import { CardCover } from './CardCover';

export const ProductCardDesktop = ({
	className,
	product,
	...props
}: ProductCardDesktopProps): JSX.Element => (
	<div
		className={clsx(styles.card, className)}
		{...props}
	>
		<CardCover product={product} />

		<div className={styles.imageWrapper}>
			<Image
				priority
				alt={product.name}
				layout="fill"
				objectFit="cover"
				width={200}
				height={168}
				src={product.image.length ? product.image : DefaultImage}
				sizes="(min-width: 1080px) 200px"
			/>
			<div
				className={clsx(styles.persentPlate, {
					[styles.visiblePersentPate]: product.salePercent,
				})}
			>
				– {product.salePercent}%
			</div>
		</div>
		<div className={styles.productInformation}>
			<div className={styles.price}>
				<div>
					<div className={clsx(styles.oldCost, {
						[styles.hidden]: product?.oldCost === 0
					})}>
						<span className={styles.costNumber}>{product?.oldCost}</span>
						<span className={styles.costCurrency}>₽</span>
					</div>
					<div className={clsx(styles.cost, {
						[styles.redPrice]: product?.oldCost !== 0
					})}>
						<span className={styles.costNumber}>{product.cost}₽</span>
					</div>
				</div>
				{product?.portionSize && <span className={styles.characteristic}>{product?.portionSize}</span>}
			</div>

			<div title={product.name} className={clsx(styles.name, "clamp-3")}>
				{product.name}
			</div>

			<AddToCart product={product} />
		</div>
	</div>
);
