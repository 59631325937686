import { useEffect, useState } from "react";

interface IDevices {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isIos: boolean;
  isAndroid: boolean;
}

export default function useHtmlAttributes() {
  const [devices, setDevices] = useState<IDevices | null>(null);

  useEffect(() => {
    const html = document.getElementsByTagName("html");
    let attributes = null;
    if (html && html[0]?.attributes) {
      const attributesHtml = html[0]?.attributes;

      if (attributesHtml)
        attributes = Object.keys(attributesHtml)
          .map((key: any) => ({
            [attributesHtml[key].name]: !!attributesHtml[key].value,
          }))
          .slice(1);
    }
    if (attributes instanceof Array) {
      setDevices({
        isIos: !!attributes.find((it: any) => it["data-device-ios"]),
        isAndroid: !!attributes.find((it: any) => it["data-device-android"]),
        isMobile: !!attributes.find((it: any) => it["data-device-mobile"]),
        isTablet: !!attributes.find((it: any) => it["data-device-tablet"]),
        isDesktop: !!attributes.find((it: any) => it["data-device-desktop"]),
      });
    }
  }, []);

  return { devices };
}

// const [attributes, setAttributes] = useState<IAttributes[] | null>(null);
// const [devices, setDevices] = useState<IDevices | null>(null);
// useEffect(() => {
//   const html = document.getElementsByTagName("html");
//   if (html && html[0]?.attributes) {
//     const attributesHtml = html[0]?.attributes;

//     if (attributesHtml)
//       setAttributes(() => {
//         return Object.keys(attributesHtml)
//           .map((key: any) => ({
//             [attributesHtml[key].name]: !!attributesHtml[key].value,
//           }))
//           .slice(1);
//       });
//   }
// }, []);

// useEffect(() => {
//   if (!(attributes instanceof Array)) return;

//   setDevices({
//     isMobile: !!attributes.find((it: any) => it["data-device-mobile"]),
//     isTablet: !!attributes.find((it: any) => it["data-device-tablet"]),
//     isDesktop: !!attributes.find((it: any) => it["data-device-desktop"]),
//   });
// }, [attributes]);
