// import { useEffect, useMemo, useState } from "react";

import { useQuery, useInfiniteQuery } from "react-query";
import {
  getPartnerInfo,
  getPartnerRating,
  getPartners,
  IGetPartnersParams,
} from "~/plugins/api/partner";
// import { Pagination, Partner } from "~/models/partners";
// import { useAddressContext } from "../address/useAddress.hook";

export function usePartnerInfo(id: string) {
  return useQuery([`partner_${id}`, id], () => getPartnerInfo(id), {
    enabled: !!id,
    staleTime: 60 * 3 * 1000,
    cacheTime: 60 * 3 * 1000, // 3 minets
  });
}

export function useGetPartners(params: IGetPartnersParams) {
  return useQuery(
    [`partners`, params.marketCategoryId],
    () => getPartners(params),
    {
      enabled: !!params.marketCategoryId,
    }
  );
}

export function usePartnerRating(partnerId: string) {
  return useQuery(
    [`partnerRating`, partnerId],
    () => getPartnerRating({ partnerId }),
    {
      staleTime: Infinity,
      cacheTime: 60 * 10 * 1000, // 10 minets
      enabled: !!partnerId,
    }
  );
}

// interface IPartners {
//   partners: Partner[];
//   pagination: Pagination;
// }

// interface IParams {
//   dataInit: IPartners;
//   categoryId: string;
//   filter?: string;
//   sort?: string;
//   limit?: number;
//   hasMore: boolean;
// }

// interface IState {
//   page: number;
//   total: number;
//   lastItemKey: string;
// }

// export default function useInfiniteScrollingPartners({
//   dataInit,
//   categoryId,
//   filter,
//   sort,
//   limit = 40,
//   hasMore = false,
// }: IParams) {
//   const { location } = useAddressContext();
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(false);
//   const [partners, setPartners] = useState<IPartners>(dataInit);
//   const [page, setPage] = useState(1);
//   const [total, setTotal] = useState(0);

//   useEffect(() => {
//     setLoading(false);
//     setError(false);
//     setPage(1);
//     setTotal(dataInit.pagination.total);
//     setPartners(dataInit);
//   }, [dataInit]);

//   const fetch = async () => {
//     try {
//       setLoading(true);
//       setError(false);

//       const result = await getPartners({
//         marketCategoryId: categoryId,
//         tagId: filter,
//         latitude: location?.latitude,
//         longitude: location?.longitude,
//         limit,
//         offset: page * limit,
//       });

//       setPage((prev) => prev + 1);
//       setPartners((prev) => {
//         if (prev) {
//           return {
//             ...result,
//             partners: [...prev?.partners, ...result.partners],
//           };
//         }
//         return result;
//       });
//       setTotal(result.pagination.total);
//       setLoading(false);
//     } catch (e) {
//       setLoading(false);
//       setError(true);
//     }
//   };

//   const [state, setState] = useState<IState>({
//     page: 1,
//     total: 0,
//     lastItemKey: "",
//   });

//   setLoading(false);
//   setError(false);
//   setPage(1);
//   setTotal(dataInit.pagination.total);
//   setPartners(dataInit);

//   // const { data: sss, refetch } = useInfiniteQuery(
//   //   [`partnersByMarket_${categoryId}`, categoryId],
//   //   () =>
//   //     getPartners({
//   //       marketCategoryId: categoryId,
//   //       tagId: filter,
//   //       latitude: location?.latitude,
//   //       longitude: location?.longitude,
//   //       limit,
//   //       offset: page * limit,
//   //     }),
//   //   {
//   //     onSuccess: (data) => {
//   //       return setState((prevState) => ({
//   //         page: 1,
//   //         total: 0,
//   //         lastItemKey: data.partners.at(-1)?.id ?? "",
//   //       }));
//   //     },
//   //     enabled: !!categoryId,
//   //   }
//   // );

//   const fetchPartners = async (params: any) => {
//     console.log(params);
//     const { pageParam = 0 } = params;
//     const res = await getPartners({
//       marketCategoryId: categoryId,
//       tagId: filter,
//       latitude: location?.latitude,
//       longitude: location?.longitude,
//       limit: pageParam,
//       offset: page * limit,
//     });
//     return res;
//   };

//   const { fetchNextPage } = useInfiniteQuery({
//     queryKey: [`partnersByMarket_${categoryId}`, categoryId],
//     queryFn: fetchPartners,
//     getNextPageParam: (lastPage, pages) => lastPage.partners,
//   });

//   useEffect(() => {
//     if ((total !== partners?.partners.length || !partners) && hasMore) {
//       fetchNextPage({ pageParam: limit });
//     }
//   }, [hasMore]);

//   const data = useMemo(() => partners.partners, [partners]);

//   const lastElemKey = data.at(-1)?.id ?? "";

//   return { data, lastElemKey, loading, error };
// }
