import Image from "next/legacy/image";
import clsx from "clsx";

import StubMainPageProps from "./StubMainPage.props";
import styles from "./StubMainPage.module.css";

import EmptySvg from "~/assets/svg/jdun.png";

export const StubMainPage = ({
  className,
  ...props
}: StubMainPageProps): JSX.Element => {
  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <div className={styles.imgWrapper}>
        <Image
          className={styles.img}
          unoptimized
          // layout="fill"
          objectFit="cover"
          alt="ждун"
          src={EmptySvg}
        />
      </div>
      <div className={styles.textBlock}>
        <p className={styles.jdunToptext}>Нас тут еще нет :(</p>
        <p className={styles.jdunBottomText}>
          К сожалению, мы пока не доставляем по этому адресу.
          {/* \Оставляя заявку вы
          помогаете нам расширить зону доставки. */}
        </p>
      </div>
    </div>
  );
};
