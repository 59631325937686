import { useState } from "react";
import Image from "next/legacy/image";
import clsx from "clsx";

import { Counter } from "~/components";
import styles from "./CartItem.module.css";
import CartItemProps from "./CartItem.props";
import ProductIcon from "~/assets/svg/product-icon.svg";

import { useBasketContext } from '~/hooks/basket/useBasket.hook';

declare const ym: any;

export const CartItem = ({
	className,
	product,
	marketCategoryId = NaN,
	partnerId = "",
	withoutItem = false,
	...props
}: CartItemProps): JSX.Element => {
	const { addToBasket, removeFromBasket, tokenForMetrics } = useBasketContext();

	const additiveItemCost: number = product ? product.additives
		.map(({ cost }) => Number(cost))
		.reduceRight((sum, item) => {
			return (sum += item);
		}, 0) : 0;

	const [loading, setLoading] = useState(false);

	const handlePlus = async () => {
		try {
			setLoading(true);
			await addToBasket({
				product,
				marketCategoryId,
				partnerId,
				quantity: product.quantity + 1,
			});
			tokenForMetrics && ym ? ym(88121717, "reachGoal", "add_to_cart") : null;
		} catch (e) {
			console.log("Ошибка добавления в корзину");

			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	const handleMinus = async () => {
		try {
			setLoading(true);

			await removeFromBasket({
				product,
				marketCategoryId,
				partnerId,
				quantity: product.quantity,
			});
			tokenForMetrics && ym ? ym(88121717, "reachGoal", "remove_from_cart") : null;
		} catch (e) {
			console.log("Ошибка удаления из корзины");

			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={clsx(styles.product, className)} {...props}>
			<div className={styles.wrapper}>
				<div className={styles.img}>
					{product.photoUri ? (
						<Image
							className={styles.image}
							unoptimized
							alt={product.name}
							objectFit="cover"
							width={85}
							height={85}
							src={product.photoUri}
						/>
					) : (
						<ProductIcon className={styles.svg} />
					)}
				</div>
				<div className={styles.itemOptions}>
					<div className={styles.itemName}>
						<div className={styles.description}>
							{product.name}
						</div>
					</div>

					{!withoutItem && <div className={styles.portion}>
						{product.cost + additiveItemCost} ₽
					</div>}

					<div className={styles.bottom}>
						{!withoutItem ? (
							<Counter
								loading={loading}
								onPlus={handlePlus}
								onMinus={handleMinus}
								value={product.quantity}
								className={styles.counter}
							/>
						) : null}

						<div className={styles.price}>
							{product?.oldCost && product?.oldCost !== null ? <div className={clsx(styles.oldCost, {
								[styles.hiddenPrice]: product?.oldCost === 0
							})}>
								<span className={styles.costNumber}>{product?.oldCost * product.quantity}</span>
								<span className={styles.costCurrency}> ₽</span>
							</div> : null}

							<div className={clsx(styles.cost, {
								[styles.redPrice]: product?.oldCost !== 0
							})}>
								<span className={styles.costNumber}>{(additiveItemCost + product?.cost) * product.quantity}</span>
								<span className={styles.costCurrency}> ₽</span>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className={styles.additivesWrapper}>
				{product.additives.map(({ id, name, cost }) => (
					<div key={id} className={styles.additiveItem}>
						<span>{name}</span>
						{cost ? <span>- {cost}&#8381;</span> : null}
					</div>
				))}
			</div>
		</div>
	);
};
