import { Modal } from "~/components";
import DesktopProfileModalProps from "./DesktopProfileModal.props";
import { Profile } from './Profile';


export const DesktopProfileModal = ({
  isOpen,
  onExit = () => null,
  hideModal = () => null,
  className,
  ...props
}: DesktopProfileModalProps): JSX.Element => {

  return (
    <Modal width={600} headline='Мои данные' align='left' isOpen={isOpen} onExit={onExit} hideModal={hideModal}>
      <Profile hideModal={hideModal} className={className} {...props} />
    </Modal>
  );
};
