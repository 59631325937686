import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { CategoryLinkMobile } from './CategoryLinkMobile';
import styles from "./MarketCategoriesTagsMobile.module.css";
import MarketCategoriesTagsMobileProps from "./MarketCategoriesTagsMobile.props";

export const MarketCategoriesTagsMobile = ({
  categories
}: MarketCategoriesTagsMobileProps): JSX.Element => {
  const categoryId = +(useRouter().query.categoryId as string);
  const activeCategory = categories.filter(({ id }) => id === categoryId)[0];
  const [pressedTag, setPressedTag] = useState<number | null>(null);

  useEffect(() => {
    const arrTagsWidth: number[] = [];
    const tags = document.querySelectorAll('.tagCategory');
    tags.forEach(element => {
      return arrTagsWidth.push(Math.ceil(element.getBoundingClientRect().width));
    });

    tags.forEach((element, index) => {
      if (element.textContent === activeCategory?.name) {
        const activeElement = element.getBoundingClientRect();
        const viewWidth = document.body.getBoundingClientRect().width;
        const scrollContainer = document.getElementsByClassName("categories")[0];
        // const elementUnVisible = activeElement.right > viewWidth;
        // if (elementUnVisible) {
        //   const width1 = activeElement.right - (viewWidth + activeElement.width) / 2 - 8;
        //   const width2 = activeElement.left - (viewWidth - activeElement.width - 8) / 2;
        //   const width = width1 < viewWidth ? width2 : width1;
        //   scrollContainer.scrollTo({ left: width, behavior: "smooth" });
        // }
        if (activeElement.left < 0 || activeElement.right > viewWidth) {
          const scroll = arrTagsWidth.slice(0, index + 1).reduce((acc, num) => acc + num, 0);
          scrollContainer.scrollTo({ left: scroll - viewWidth / 2 - activeElement.width / 2 - 8, behavior: "smooth" });
        }

      }
    });
  }, [categories, activeCategory]);

  return (
    <div
      className={clsx("categories", styles.tags)}
    >
      <div
        className={styles.gridTag}
      >
        {categories.map((category) => {
          const { image, name, id } = category;
          return (
            <div key={id} className={clsx('tagCategory', styles.tagItem)}>
              <Link
                href={`/?categoryId=${id}`}
                passHref

              >
                <CategoryLinkMobile
                  id={`${"buttonCategory" + id}`}
                  className={clsx(styles.beforeElement, {
                    [styles.pressedTag]: id === pressedTag && id !== categoryId,
                    [styles[`pressedLink${id}`]]: id === pressedTag,
                    [styles[`activeLink${id}`]]: id === categoryId,
                  })}
                  image={image}
                  onTouchStart={() => setPressedTag(id)}
                  onTouchEnd={() => setTimeout(() => setPressedTag(null), 150)}
                >
                  {name}
                </CategoryLinkMobile>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
