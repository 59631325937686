import { httpv1, httpv2 } from "../http";
import {
  OrderFullInfo,
  OrderWithList,
  OrderFeedback,
  OrderRepeat,
  OrderPoints,
} from "~/models/order";
import { customFetch } from "~/plugins/services/fetch";

export const repeatOrder = async (id: string) => {
  const response = await httpv1.post<OrderRepeat>(`Order/repeat?Id=${id}`);

  return response.data;
};

export interface CreateOrderArgs {
  basketId: string;
  phoneNumber: string;
  deliveryCityId?: string;
  address: string;
  changeSum?: string | number;
  paymentMethodId: 1 | 2; // 1 cash / 2 online payment
  deviceId?: 1 | 2 | 3; // 1 website / 2 ios / 3 android
  discount?: number;
  comment?: string;
  addressComment?: string;
  entrance?: string;
  floor?: string;
  flat?: string;
  intercom?: string;
  latitude: string;
  longitude: string;
}

type CashOrder = CreateOrderArgs & {
  paymentMethodId: 1; // 1 cash / 2 online payment
  linkNewCard?: unknown;
  rebillId?: unknown;
};

type OnlineOrder = CreateOrderArgs & {
  paymentMethodId: 2; // 1 cash / 2 online payment
  linkNewCard: boolean;
  rebillId?: string | null;
};

export type CreateOrderProps = CashOrder | OnlineOrder;

export interface ResponseCreateOrder {
  paymentUrl: string;
  orderId: string;
}

export const createOrder = async ({
  basketId,
  phoneNumber,
  deliveryCityId,
  address,
  discount = 0,
  paymentMethodId = 1,
  deviceId = 1,
  comment,
  changeSum,
  addressComment,
  entrance,
  floor,
  flat,
  intercom,
  latitude,
  longitude,
  linkNewCard,
  rebillId = null,
}: CreateOrderProps) => {
  const response = await httpv2.post<ResponseCreateOrder>("Order", {
    basketId,
    phoneNumber,
    deliveryCityId,
    address,
    discount,
    deviceId,
    addressComment,
    changeSum: String(changeSum),
    paymentMethodId,
    comment,
    entrance,
    floor,
    flat,
    intercom,
    latitude,
    longitude,
    linkNewCard,
    rebillId,
  });

  return response.data;
};

export const cancelOrder = async (id: string) => {
  return customFetch(async () => await httpv1.post(`Order/cancel?Id=${id}`));
};

interface OrderResponseItem {
  order: OrderFullInfo;
  orderFeedback: OrderFeedback | null;
}

export const getOrder = async (id: string, token?: string) => {
  let headers = {};

  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await httpv1.get<OrderResponseItem>("Order", {
    headers,
    params: {
      id,
    },
  });

  return {
    ...response.data.order,
    orderFeedback: response.data.orderFeedback,
  } as OrderFullInfo;
};

interface OrderListResponse {
  order: OrderWithList;
  feedback: OrderFeedback | null;
}

export const getAllOrders = async (token?: string, offset = 0, limit = 0) => {
  let headers = {};

  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await httpv1.get<OrderListResponse[]>("Order/all", {
    headers,
    params: {
      offset,
      limit,
    },
  });

  return response.data.map(({ order, feedback }) => ({
    ...order,
    feedback,
  })) as OrderWithList[];
};

export interface IOrderFeedback {
  orderId: string;
  mark: number;
  positiveText: string;
  negativeText: string;
}

export const addOrderFeedback = async ({
  orderId,
  mark,
  positiveText,
  negativeText,
}: IOrderFeedback) => {
  const response = await httpv1.post<OrderFeedback>("Order/feedback", {
    orderId,
    mark,
    positiveText,
    negativeText,
  });

  return response.data;
};

export const getOrderPoints = async (id: string, token?: string) => {
  let headers = {};

  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const response = await httpv1.get<OrderPoints>("Order/points", {
    headers,
    params: {
      id,
    },
  });

  return response.data;
};
