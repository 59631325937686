import { useCallback, useEffect, useState } from "react";

import MapProps from "./MapViewDesktop.props";
import styles from "./MapViewDesktop.module.css";
import useDebounce from '~/hooks/useDebounce';
import clsx from 'clsx';
import { ISuggestItem } from './MapViewDesktop.props';

declare const ymaps: any;

export const MapViewDesktop = ({
  initAddress,
  suggestAddress,
  getUserGeoData,
  getSuggestList,
}: MapProps): JSX.Element => {
  // const [geoCodeCoords, setGeoCodeCoords] = useState<number[] | string[] | null>(null);
  const [yandexMap, setYandexMap] = useState<any | null>(null);
  const [searchAddress, setSearchAddress] = useState("");
  const [suggestList, setSuggestList] = useState<ISuggestItem[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [mapActionCoords, setMapActionCoords] = useState<number[] | []>([]);

  const mapCenterCoords = useDebounce(mapActionCoords, 2000);

  useEffect(() => {
    if (mapCenterCoords.length) {
      getAddress(mapCenterCoords);
    }
  }, [mapCenterCoords[0], mapCenterCoords[1]]);

  useEffect(() => {
    if (searchAddress.trim().toLocaleLowerCase() == suggestList[0]?.name.trim().toLocaleLowerCase())
      return getSuggestList([]);
    else return getSuggestList(suggestList);
  }, [suggestList]);

  function init(latitude: number | null, longitude: number | null) {
    const defCoords = latitude && longitude ? [latitude, longitude] : [42.9747296, 47.5089114];
    const initCoords = initAddress?.latitude && initAddress.longitude ? [initAddress?.latitude, initAddress?.longitude] : defCoords;

    const map = new ymaps.Map(
      "map",
      {
        center: initCoords,
        zoom: 17,
      },
      {
        searchControlProvider: "yandex#search",
      }
    );

    map && setYandexMap(map);

    map.setCenter(initCoords, map.getZoom());

    map.controls.remove("geolocationControl"); // удаляем геолокацию
    map.controls.remove("searchControl"); // удаляем поиск
    map.controls.remove("trafficControl"); // удаляем контроль трафика
    map.controls.remove("typeSelector"); // удаляем тип
    map.controls.remove("fullscreenControl"); // удаляем кнопку перехода в полноэкранный режим
    map.controls.remove("zoomControl"); // удаляем контрол зуммирования
    map.controls.remove("rulerControl"); // удаляем контрол правил
    // map.behaviors.disable(['scrollZoom']); // отключаем скролл карты (опционально)

    const suggestOptions = {
      results: 10,
      offset: [0, 10],
      boundedBy: initCoords,
      // strictBounds: true,
      // noSuggestPanel: false,
    };

    const suggestDesktop = new ymaps.SuggestView(
      'suggestDesktop',
      {
        ...suggestOptions,
        provider: {
          suggest: (function (request: string, options: any) {
            return ymaps.suggest("Россия, Дагестан, " + request, suggestOptions).then(function (items: any) {
              let newItems: any[] = [];
              for (let i = 0; i < items.length; i++) {
                const item = {
                  reverceName: items[i].displayName.replace(/, Республика Дагестан, Россия/g, ''),
                  name: items[i].value.replace(/Россия, Республика Дагестан, /g, '')
                };
                newItems = [...newItems, item];
              }

              suggestDesktop.events.fire('requestsuccess', {
                target: false,
              });

              setSuggestList(newItems);
              return [];
            });
          })
        },
      }
    );

    const geolocationControl = new ymaps.control.GeolocationControl({
      options: {
        layout: ymaps.templateLayoutFactory.createClass(
          "<div class='geoConrol desktopGeoConrol' title='{{ data.title }}'>"
          + "{{ data.content }}" +
          "</div>"
        ),
        noPlacemark: true,
      }
    });

    geolocationControl.events.add("locationchange", function (event: any) {
      const position = event.get("position");
      const locationPlacemark = new ymaps.Placemark(position);

      map.geoObjects.options.set("preset", "islands#blueCircleIcon");
      map.geoObjects.add(locationPlacemark);
      // Установим новый центр карты в текущее местоположение пользователя.
      map.panTo(position);
    });

    map.controls.add(geolocationControl, {
      float: "none",
      position: { right: "8px", top: "20px" },
      floatIndex: 100,
    });


    map.events.add("actionbegin", function () {
      setIsSearching(true);
    });

    map.events.add("actionend", function () {
      const coords: number[] = map.getCenter();
      const fixedCoords = [+coords[0].toFixed(7), +coords[1].toFixed(7)];
      if (fixedCoords[0] !== mapActionCoords[0] || fixedCoords[1] !== mapActionCoords[1]) {
        setMapActionCoords(fixedCoords);
      }
    });
  }

  const success = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    ymaps.ready(init(latitude, longitude));
  };

  const error = () => {
    console.warn('User denied access to Geolocation');
    ymaps.ready(init);
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      ymaps.ready(init);
    }
  }, []);

  const getCityName = (address: string) => {
    return address.split(", ")[1].replace(/городской округ /g, '');
  };

  const getAddress = (data: string | number[] | string[]) => {
    ymaps.geocode(data).then(function (res: any) {
      const geoObject = res.geoObjects.get(0);
      const coords = data instanceof Array ? data : geoObject.geometry._coordinates;
      const fixedCoords = [+coords[0].toFixed(7), +coords[1].toFixed(7)];
      const address = geoObject.getAddressLine().replace(/Россия, Республика Дагестан, /g, '');
      const isVillage = !!address.match(/село/);
      const isTownship = !!address.match(/посёлок/) || !!address.match(/поселок/);
      const isCityDistrict = !!address.match(/городской округ/);

      const city = isCityDistrict ? getCityName(address) : geoObject.getLocalities()[0];
      const localities = geoObject.getLocalities().length > 1 ? geoObject.getLocalities()[1] : geoObject.getLocalities()[0];
      const street = geoObject.getThoroughfare() ?? "";
      const house = geoObject.getPremiseNumber() ?? "";

      if (fixedCoords && address.length && house.length) {
        setSearchAddress(address);
        getUserGeoData({
          fullAddress: address,
          address: isTownship ? `${localities}, ${street}, ${house}` : `${street}, ${house}`,
          cityName: city,
          addressObj: { localities, street, house },
          latitude: String(fixedCoords[0]),
          longitude: String(fixedCoords[1])
        });
        // getSuggestList([])
        // if (isNewMapCenter) 
        yandexMap.setCenter(fixedCoords, yandexMap.getZoom());
      }

      // if (address.length && !house) {
      //   getUserGeoData(null);
      // }
      yandexMap.setCenter(fixedCoords, yandexMap.getZoom());
      setIsSearching(false);
    });
  };

  useEffect(() => {
    if (suggestAddress?.name) getAddress(suggestAddress?.name);
  }, [suggestAddress]);

  const handlePlus = useCallback(() => {
    yandexMap.setZoom(yandexMap.getZoom() + 1, { checkZoomRange: true, duration: 250 });
    setIsSearching(false);
  }, [yandexMap]);

  const handleMinus = useCallback(() => {
    yandexMap.setZoom(yandexMap.getZoom() - 1, { checkZoomRange: true, duration: 250 });
    setIsSearching(false);
  }, [yandexMap]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>

        <div className={clsx(styles.infoPlate, {
          [styles.hiddenPlate]: searchAddress && !isSearching
        })}>
          <div className={styles.addressTitle}>
            {isSearching ? "Ищем адрес..." : searchAddress ? null : "Выберите адрес с номером дома"}
          </div>
        </div>

        <div
          id="marker"
          className={styles.markerContainer}
        >
          <div className={styles.marker} />
        </div>

        <div
          className={styles.zoomControl}
        >
          <div id="zoomIn" className={styles.zoomButton} onClick={handlePlus}>+</div>
          <div id="zoomOut" className={styles.zoomButton} onClick={handleMinus}>–</div>
        </div>
      </div>
      <div className={styles.map} id="map"></div>
    </div>
  );
};

// function geoCode(address: string | number[]) {
//   ymaps.geocode(address).then(
//     function (res: any) {
//       const obj = res.geoObjects.get(0);
//       const house = obj.getPremiseNumber() ?? null;
//       console.log(obj.geometry._coordinates);
//       if (obj.geometry._coordinates.length && house)
//         yandexMap.setCenter(obj.geometry._coordinates, 16);
//     },
//     function (e: any) {
//       console.log(e);
//     }
//   );
// }