import clsx from "clsx";
import CheckboxProps from "./Checkbox.props";
import styles from "./Checkbox.module.css";

export const Checkbox = ({
  className,
  checked,
  value,
  ...props
}: CheckboxProps): JSX.Element => {
  return (
    <div className={styles.wrapper} {...props}>
      <input
        onChange={() => null}
        value={value}
        checked={checked}
        className={styles.input}
        type="checkbox"
      />
      <div
        className={clsx(className, styles.checkbox, {
          [styles.checkbox__checked]: checked,
        })}
      />
    </div>
  );
};
