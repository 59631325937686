import { PropsWithChildren } from "react";
import clsx from "clsx";

import { ButtonDesktop } from "~/components";
import ConfirmModalProps from "./ConfirmModal.props";
import styles from "./ConfirmModal.module.css";

const DEFAULT_CONFIRM_MESSAGE = "Сообщение окна подтверждения";

export const ConfirmModal = ({
  hideModal,
  onConfirm,
  onCancel,
  cancelText = "НЕТ",
  confirmText = "ДА",
  message = "",
  icon,
  className,
  ...props
}: PropsWithChildren<ConfirmModalProps>): JSX.Element => {

  const handleConfirm = () => {
    onConfirm();
    setTimeout(hideModal, 350);
  };

  return (
    <div
      className={clsx(styles.wrapper, className)}
      {...props}
    >
      {icon && (
        <div className={styles.iconContainer}>{icon}</div>
      )}
      {message.length ? <p className={styles.message}>{message}</p> : null}
      <div className={styles.btnWrapper}>
        {cancelText.length ? (
          <ButtonDesktop
            appearance='gray'
            className={styles.button}
            onClick={onCancel}
            isWrap
          >
            {cancelText}
          </ButtonDesktop>
        ) : null}
        {confirmText.length ? (
          <ButtonDesktop
            className={styles.button}
            onClick={handleConfirm}
            isWrap
          >
            {confirmText}
          </ButtonDesktop>
        ) : null}
      </div>
    </div>
  );
};
