import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Image from "next/legacy/image";
import clsx from "clsx";

import { ButtonMobile, CounterMobile, MobileModal, TitleMobile } from '~/components';
import MobileProductCardModalProps from "./MobileProductCardModal.props";
import styles from "./MobileProductCardModal.module.css";
import { Product } from '~/models/basket';
import DefaultImage from "~/assets/svg/product-placeholder.webp";
import ClosePlateIcon from "~/assets/icons/shopping-store-signage.svg";

import { partnerIsNotOpenYet, partnerIsWorking } from '~/helpers';
import useConfirmBottomSheet from '~/hooks/useConfirmBottomSheet';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { usePartnerInfo } from '~/hooks/partner';

export const MobileProductCardModal = ({
	isOpen,
	hideModal,
	onExit,
	className,
	product,
	...props
}: PropsWithChildren<MobileProductCardModalProps>): JSX.Element => {
	const router = useRouter();
	const { data: partner } = usePartnerInfo(router.query.id as string ?? "");
	const { addToBasket, basket } = useBasketContext();

	const productCart: Product = {
		productId: product.id,
		name: product.name,
		quantity: 1,
		cost: product.cost,
		photoUri: product.image,
		additives: []
	};

	const isHaveProductsWithBasket = useMemo(() => basket?.partnerId === partner?.id && basket?.content.length, [basket, partner]);

	const [quantity, setQuantity] = useState(1);
	const [defQuantity, setDefQuantity] = useState(0);

	const content = basket?.content;

	useEffect(() => {
		if (!content?.length) return;
		const currentProductIndex = content.findIndex((item) => item.productId === product.id);
		if (currentProductIndex >= 0) {
			setDefQuantity(content[currentProductIndex].quantity);
		}
	}, [content, product.id]);

	const [loading, setLoading] = useState(false);

	const handleSucess = async () => {
		if (!partnerIsWorking(partner?.schedule) && !isHaveProductsWithBasket) {
			return showConfirmBottomSheet();
		}
		return await handleAddToBasket()
	};

	const handleAddToBasket = async () => {
		if (!partner) return
		try {
			// Has in basket like this
			setLoading(true);
			partner && await addToBasket({
				partnerId: partner?.id,
				marketCategoryId: partner?.marketCategoryId,
				quantity: quantity + defQuantity,
				product: productCart,
			});

			hideModal();
		} catch (e) {
			console.log("Ошибка добавления товара: ", e);
		} finally {
			setLoading(false);
		}
	};

	const beginTime = partner?.schedule.begin.slice(0, 5)
	const confirmMessage = partnerIsNotOpenYet(partner?.schedule) ?
		`сегодня с ${beginTime}` :
		`завтра c ${beginTime}`;

	const { showConfirmBottomSheet } = useConfirmBottomSheet({
		onConfirm: handleAddToBasket,
		onCancel: () => router.push(`/?categoryId=${partner?.marketCategoryId}`),
		title: "Доступен предзаказ",
		message: `Мы можем доставить только когда ${partner?.marketCategoryId == 1 ? "ресторан" : "магазин"} откроется - ${confirmMessage}`,
		cancelText: "Посмотреть другие места",
		confirmText: "Оформить предзаказ",
		icon: <ClosePlateIcon />,
		dependencies: [basket, partner, quantity]
	});

	return (
		<MobileModal fullScreen hiddenHeader isOpen={isOpen} hideModal={hideModal} onExit={onExit}>
			<div
				className={clsx(styles.wrapper, className)}
				{...props}
			>
				<div className={styles.header}>
					<Image
						className={styles.img}
						unoptimized
						layout="fill"
						objectFit="cover"
						alt={product.name}
						src={product.image.length ? product.image : DefaultImage}
					/>
				</div>

				<div className={styles.content}>
					<TitleMobile headerType='h3'>{product.name}</TitleMobile>

					<div className={styles.infoItem}>
						{product.portionSize ?? ""}
					</div>

					<div className={styles.infoItem}>
						{product.description ?? ""}
					</div>
				</div>

				<div className={styles.bottomBar}>
					<CounterMobile
						loading={loading}
						onMinus={() => setQuantity((q) => Math.max(q - 1, 1))}
						onPlus={() => setQuantity((q) => Math.min(q + 1, 99))}
						value={quantity}
					/>
					<div className={styles.totalWrp}>
						{quantity > 1 && <span className={styles.forOnePrice}>
							{product.cost} ₽ за 1 шт.
						</span>}
						<div className={styles.total}>
							Сумма:{" "}
							<span className={styles.totalPrice}>
								{product.cost * quantity} ₽
							</span>
						</div>
					</div>
					<ButtonMobile
						onClick={handleSucess}
						type="submit"
						className={styles.buttonAddToCart}
					>
						ДОБАВИТЬ
					</ButtonMobile>
				</div>
			</div>
		</MobileModal>
	);
};