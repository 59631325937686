const convertTime = (date: string | Date | undefined) => {
  if (!date) return "";
  const hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();
  // const seconds = new Date(date).getSeconds();

  const convertTime = `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`; //:${seconds < 10 ? `0${seconds}` : seconds}

  return convertTime;
};

export default convertTime;
