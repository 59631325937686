import Link from "next/link";
import clsx from "clsx";

import { ProductCardDesktop, Slider, RenderIfVisible } from "~/components";
import ProductCardRowProps from "./ProductCardRow.props";
import styles from "./ProductCardRow.module.css";
import { CardsHeight } from '~/constants';

import "swiper/css";

const cardHeight = CardsHeight.desktopProductCard;

export const ProductCardRow = ({
  className,
  products,
  path = "",
  withLinkCard = true,
  ...props
}: ProductCardRowProps): JSX.Element => {
  return (
    <div className={clsx(className)} {...props}>
      <Slider
        slidesPerView={"auto"}
        sliderWidth={198}
        spaceBetween={16}
        colorHide="#f8f8f8"
      >
        {products.map((product) => {
          return withLinkCard ? (
            <div key={product.id} className={styles.desktopCardLinkWrapper}>
              <RenderIfVisible
                key={product.id}
                defaultHeight={cardHeight}
                visibleOffset={0}
                placeholderCardClass="desktopCard"
              >
                <ProductCardDesktop
                  key={product.id}
                  product={product}
                />
              </RenderIfVisible>
              <Link href={path} target={"_blank"} className={styles.desktopCardLink}>
              </Link>
            </div>
          ) : (
            <RenderIfVisible
              key={product.id}
              defaultHeight={cardHeight}
              visibleOffset={0}
              placeholderCardClass="desktopCard"
            >
              <ProductCardDesktop
                key={product.id}
                product={product}
              />
            </RenderIfVisible>
          );
        })}
      </Slider>
    </div>
  );
};
