import { useRouter } from 'next/router';
import { TitleDesktop } from '~/components/_DesktopUI/TitleDesktop';
import { usePartnerInfo } from '~/hooks/partner';
import styles from "./AboutDelivery.module.css";

const AboutDelivery = () => {
  const router = useRouter();
  const partnerId = router.query.id as string;
  const { isError, isLoading, data: partner } = usePartnerInfo(partnerId);

  if (isError || isLoading) return <></>;

  const isPartnerDelivery = partner?.deliveryFrames[0].deliveryTypeId === 1;
  const deliveryCosts = partner?.deliveryFrames[0]?.deliveryCosts ?? [];

  return (
    <div className={styles.aboutDelivery}>
      <TitleDesktop headerType='h4' margin={false}>Информация о доставке</TitleDesktop>
      <div>
        Доставку выполнит курьер{" "}
        {isPartnerDelivery ? "от ресторана" : "CronMarket"}
      </div>
      <div className={styles.deliveryItemInfo}>
        <div className={styles.modalItemTitle}>Минимальный заказ:</div>
        <div>от {partner?.minAmountOrder} руб.</div>
      </div>
      <div className={styles.deliveryAmount}>
        <div className={styles.modalItemTitle}>Стоимость доставки:</div>
        {deliveryCosts.map((cost, index) => (
          <div key={index} className={styles.deliveryItemInfo}>
            <div>
              {cost.amountFrom === 0
                ? `При заказе до ${cost.amountTo} руб.`
                : cost.amountTo === 0
                  ? `При заказе от ${cost.amountFrom} руб.`
                  : `При заказе от ${cost.amountFrom} до ${cost.amountTo} руб.`}
            </div>
            <div>{cost.deliveryCost} руб.</div>
          </div>
        ))}
      </div>
      {!isPartnerDelivery ? (
        <div>
          <div className={styles.modalItemTitle}>Лимиты:</div>
          <div className={styles.deliveryItemInfo}>
            <div>максимальный вес заказа</div>
            <div>30 кг.</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default AboutDelivery;