import cookie from "js-cookie";

interface ISetCookie {
  name: string;
  value: any;
}

const setCookieDate = () => {
  const Dy = new Date();
  Dy.setFullYear(Dy.getFullYear() + 2);
  return Dy;
};

const setCookie = ({ name, value }: ISetCookie) => {
  cookie.set(name, JSON.stringify(value), {
    expires: setCookieDate(),
  });
};

const getCookie = (name: string) => cookie.get(name);

const removeCookie = (name: string) => cookie.remove(name);

export { setCookie, getCookie, removeCookie };
