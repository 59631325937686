import { PropsWithChildren } from "react";
import clsx from "clsx";

import RepeatIcon from "~/assets/icons/repeat-arrow.svg";
import ButtonRepeatOrderDesktopProps from "./ButtonRepeatOrderDesktop.props";
import styles from "./ButtonRepeatOrderDesktop.module.css";

import { ButtonDesktop } from '../ButtonDesktop';
import { useRepeatOrder } from '~/hooks/order';

export const ButtonRepeatOrderDesktop = ({
  orderId,
  className,
  ...props
}: PropsWithChildren<ButtonRepeatOrderDesktopProps>): JSX.Element => {

  const { isLoading, handleRepeatengOrder } = useRepeatOrder();

  return (
    <ButtonDesktop
      size='m'
      loading={isLoading}
      spinnerColor="primary"
      appearance="gray"
      onClick={() => handleRepeatengOrder(orderId)}
      className={clsx(styles.button, className)}
      {...props}
    >
      <RepeatIcon
        className={styles.repeatIcon}
      />
      <span>
        Повторить заказ
      </span>
    </ButtonDesktop>
  );
};
