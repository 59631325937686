import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { NIL as NIL_UUID } from "uuid";
import clsx from "clsx";

import { Input, Textarea, MapViewDesktop, ButtonDesktop } from "~/components";
import { UserGeoData } from "~/models/user";
import { City, UserAddressToken } from '~/models';
import DesktopMapModalProps from "./DesktopMapModal.props";
import styles from "./DesktopMapModal.module.css";

import { getDeliveryCities } from "~/plugins/api";
import { updatePage } from "~/helpers/upadatePage";
import { useUserContext } from "~/hooks/user/useUser.hook";
import useConfirmModal from '~/hooks/useConfirmModal';
import { ISuggestItem } from '~/components/_DesktopUI/MapViewDesktop/MapViewDesktop.props';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { useAddressContext } from '~/hooks/address/useAddress.hook';
import { usePartnerInfo } from '~/hooks/partner';

declare const ym: any;

export const DesktopMapModal = ({
  isEditAddress = null,
  hideModal,
  onSuccesAddress = () => null,
  className,
  ...props
}: PropsWithChildren<DesktopMapModalProps>): JSX.Element => {
  const router = useRouter();
  const { token } = useUserContext();
  const { location, addAddress, editAddress } = useAddressContext();
  const { basket, clearBasket, tokenForMetrics } = useBasketContext();

  const partnerid = basket?.content.length ? basket.partnerId : router.query.id as string;

  const { data: partner } = usePartnerInfo(partnerid);

  const addressField = useRef<HTMLInputElement>(null);
  const subDetailsField = useRef<HTMLInputElement>(null);

  const [address, setAddress] = useState("");
  const [userGeoData, setUserGeoData] = useState<UserGeoData | null>(null);
  const [isChangedDetails, setIsChangedDetails] = useState(false);
  const [orderInfo, setOrderInfo] = useState<Record<string, any>>({
    intercom: isEditAddress?.intercom ?? location?.intercom,
    entrance: isEditAddress?.entrance ?? location?.entrance,
    floor: isEditAddress?.floor ?? location?.floor,
    flat: isEditAddress?.flat ?? location?.flat,
    comment: isEditAddress?.comment ?? location?.comment,
  });

  const changeOrderInfo = (
    field: "intercom" | "entrance" | "floor" | "flat" | "comment",
    value: string
  ) => {
    setOrderInfo((info) => ({ ...info, [field]: value }));
    setIsChangedDetails(true);
  };

  useEffect(() => {
    if (token && isEditAddress) {
      setAddress(isEditAddress.address)
    }
    if (!token && location) {
      setAddress(location.fullAddress);
      setUserLocation(location);
    }
  }, [isEditAddress?.fullAddress, token?.accessToken]);

  const [cities, setCities] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const arr = await getDeliveryCities()
      return setCities(arr);
    })();
  }, []);

  const [userLocation, setUserLocation] = useState<UserAddressToken | null>(null);

  useEffect(() => {
    if (userGeoData) {
      setAddress(userGeoData.fullAddress);
      const geoStreet = userGeoData.fullAddress.split(", ").reverse()[1];
      const geoHouse = userGeoData.fullAddress.split(", ").reverse()[0];
      const locationStreet =
        (location && location.address.split(", ").reverse()[1]) ?? "";
      const locationHouse =
        (location && location.address.split(", ").reverse()[0]) ?? "";

      if (
        geoStreet.toLowerCase() !== locationStreet.toLowerCase() ||
        geoHouse.toLowerCase() !== locationHouse.toLowerCase()
      ) {
        setOrderInfo({
          intercom: "",
          entrance: "",
          floor: "",
          flat: "",
          comment: "",
        });
      }

      (async () => {
        if (!userGeoData.addressObj || !userGeoData.addressObj.house) {
          setAddress(userGeoData.fullAddress);
          return addressField.current?.focus();
        }
        const addressCity = userGeoData?.cityName;
        const city = cities.find(
          (item) =>
            addressCity && item.name.toLowerCase() === addressCity?.toLowerCase()
        );

        if (!city) {
          setAddress("");
          setUserGeoData(null);
          return showNoneCityModal();
        }

        const locationParams = {
          id: NIL_UUID,
          cityId: city.id,
          cityName: city.name,
          address: userGeoData.address,
          fullAddress: userGeoData.fullAddress,
          addressObj: userGeoData.addressObj,
          entrance: orderInfo.entrance,
          floor: orderInfo.floor,
          flat: orderInfo.flat,
          intercom: orderInfo.intercom,
          comment: orderInfo.comment,
          latitude: userGeoData.latitude,
          longitude: userGeoData.longitude,
        };

        setUserLocation(locationParams);
      })()
    }
  }, [userGeoData?.fullAddress]);

  const handleConfirmButton = () => {
    hideConfirmModal();
    setTimeout(() => {
      hideModal();

      if (userLocation) {
        addAddress(userLocation);
      }
      if (basket?.content.length) {
        clearBasket();
        tokenForMetrics && ym ? ym(88121717, 'reachGoal', 'clear_basket') : null;
      }

      if (router.pathname === "/") return router.replace(router.asPath);
      return router.push(`/?categoryId=${partner?.marketCategoryId}`);
    }, 350);
  };

  const handleCancelButton = () => {
    hideConfirmModal();
    setTimeout(hideModal, 350);
  };

  const isHaveProducts = !!basket?.content.length;

  const [ showConfirmModal, hideConfirmModal ] = useConfirmModal({
    onConfirm: handleConfirmButton,
    onCancel: handleCancelButton,
    width: 500,
    title: "Прочти пожалуйста!",
    message: `Выбранное заведение не доставляет по указанному адресу`,
    cancelText: "Оставить предыдущий",
    confirmText: `Изменить ${isHaveProducts ? "и удалить корзину" : ""}`,
    dependencies: [userLocation, isHaveProducts]
  });

  const handleSelectOtherAddress = () => {
    hideNoneCityModal();
    return addressField.current?.focus();
  };

  const [ showNoneCityModal , hideNoneCityModal  ] = useConfirmModal({
    onConfirm: () => null,
    onCancel: handleSelectOtherAddress,
    width: 500,
    title: "Печалька :(",
    message: "К сожалению, мы пока не доставляем по этому адресу.",
    cancelText: "Указать другой адрес",
    confirmText: '',
  });

  const handleSuccessAddress = (newAddress: UserAddressToken | null) => {
    if (newAddress) {
      addAddress(newAddress);
    }
    onSuccesAddress();
    hideModal();
  };

  const handleSaveAddress = async () => {
    if (!userGeoData) return;
    const params = {
      basketId: basket?.id as string,
      isEmptyBasket: !basket?.content.length,
      partnerId: partner?.id,
      categoryId: partner?.marketCategoryId,
      latitude: userGeoData?.latitude,
      longitude: userGeoData?.longitude,
    };

    addressField.current?.blur();

    updatePage({
      router,
      params,
      callbackFailure: showConfirmModal,
      callBackSuccess: () => handleSuccessAddress(userLocation),
    });

  };

  const handleEditAddress = async () => {
    if (!isEditAddress) return;
    const data = userGeoData ?? isEditAddress

    let city: City | undefined = undefined;

    if (userGeoData?.cityName !== isEditAddress.cityName) {
      const addressCity = data?.cityName;
      const cities = await getDeliveryCities();
      city = cities.find(
        (item) =>
          addressCity && item.name.toLowerCase() === addressCity?.toLowerCase()
      );
    }

    const newAddress = {
      id: isEditAddress.id,
      cityId: city?.id ?? isEditAddress.cityId,
      cityName: city?.name ?? isEditAddress.cityName,
      address: data.address,
      entrance: orderInfo.entrance ?? isEditAddress.entrance,
      floor: orderInfo.floor ?? isEditAddress.floor,
      flat: orderInfo.flat ?? isEditAddress.flat,
      intercom: orderInfo.intercom ?? isEditAddress.intercom,
      comment: orderInfo.comment ?? isEditAddress.comment,
      fullAddress: userGeoData?.fullAddress ?? "",
      latitude: data.latitude,
      longitude: data.longitude,
    };

    editAddress(newAddress);

    onSuccesAddress();

    // if (address.toLowerCase() === location?.address.toLowerCase()) {
    //   handleSetLocation({
    //     ...newAddress,
    //     fullAddress: userGeoData?.fullAddress ?? "",
    //     addressObj: userGeoData?.addressObj ?? location.addressObj
    //   })
    // }

    addressField.current?.blur();
    hideModal();
  };

  const [suggestList, setSuggestList] = useState<ISuggestItem[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<any | null>(null);
  const [selectedListItemIndex, setSelectedListItemIndex] = useState(0);

  const handleKeyNavigationList = (
    e: React.KeyboardEvent<HTMLInputElement>,
    addressList: any[],
  ) => {
    if (!addressList.length) return;

    if (e.key === "Enter") {
      setSelectedAddress(addressList[selectedListItemIndex]);
      setAddress(addressList[selectedListItemIndex].name)
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedListItemIndex((prevState) => {
        if (prevState + 1 < addressList.length) {
          return prevState + 1;
        }
        if (prevState + 1 === addressList.length) {
          return 0;
        }
        return prevState;
      });
    }
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedListItemIndex((prevState) => {
        if (prevState + 1 > addressList.length) {
          return prevState - 1;
        }
        if (prevState === 0) {
          return addressList.length - 1;
        }
        if (prevState + 1 <= addressList.length) {
          return prevState - 1;
        }
        return prevState;
      });
    }
  };

  const handleListItemClick = (item: ISuggestItem) => {
    setSelectedAddress(item);
    setAddress(item.name);
    addressField.current?.focus();
  };

  const handleClear = () => {
    setUserGeoData(null);
    setAddress("");
    setSuggestList([]);
    setSelectedAddress(null);
    setSelectedListItemIndex(0);
    setOrderInfo({
      intercom: "",
      entrance: "",
      floor: "",
      flat: "",
      comment: "",
    });
  };

  const [focus, setFocus] = useState(false);

  return (
    <div className={clsx(styles.desktopContainerMap, { [styles.editableContainerMap]: isEditAddress }, className)} {...props}>
      <div className={styles.desktopAddressBlock}>
        <div className={styles.addressTopBar}>
          <div
            className={styles.addressSearchBar}
          >
            <Input
              id="suggestDesktop"
              placeholder="Укажите адрес доставки"
              clear
              value={address ?? ""}
              onChange={({ currentTarget }) => setAddress(currentTarget?.value)}
              onKeyDown={(e) => handleKeyNavigationList(e, suggestList)}
              onClear={handleClear}
              innerRef={addressField}
              onFocus={() => setFocus(true)}

            />
            {focus && suggestList.length ? (
              <div className={styles.suggestPopup}>
                <ul className={styles.suggestList}>
                  {suggestList.map((item, index) => (
                    <li
                      key={item.name}
                      className={clsx(styles.listItem, {
                        [styles.selectedlistItem]: index === selectedListItemIndex,
                      })}
                      onClick={() => handleListItemClick(item)}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>

              </div>
            ) : null}
          </div>
          <ButtonDesktop
            className={styles.button}
            onClick={isEditAddress ? handleEditAddress : handleSaveAddress}
            disabled={!userGeoData && !isChangedDetails}
          >
            СОХРАНИТЬ
          </ButtonDesktop>
        </div>

        {isEditAddress ? (
          <div className={styles.addressDetails}>
            <h4 className={styles.title}>
              Уточните адрес{" "}
              <span className={styles.titleNote}>(не обязательно)</span>
            </h4>
            <div className={styles.subDetails}>
              <Input
                type="number"
                onChange={({ currentTarget }) =>
                  changeOrderInfo("flat", currentTarget.value)
                }
                label="Квартира"
                value={orderInfo.flat ?? ""}
                innerRef={subDetailsField}
                min={1}
                maxLength={4}
              />
              <Input
                onChange={({ currentTarget }) =>
                  changeOrderInfo("intercom", currentTarget.value)
                }
                label="Домофон"
                value={orderInfo.intercom ?? ""}
                min={1}
                maxLength={12}
              />
              <Input
                type="number"
                onChange={({ currentTarget }) =>
                  changeOrderInfo("entrance", currentTarget.value)
                }
                label="Подъезд"
                value={orderInfo.entrance ?? ""}
                min={1}
                maxLength={3}
              />
              <Input
                type="number"
                onChange={({ currentTarget }) =>
                  changeOrderInfo("floor", currentTarget.value)
                }
                label="Этаж"
                value={orderInfo.floor}
                min={1}
                maxLength={3}
              />
            </div>
            <Textarea
              onChange={({ currentTarget }) =>
                changeOrderInfo("comment", currentTarget.value)
              }
              placeholder="Комментарий к адресу"
              value={orderInfo.comment ?? ""}
              className={styles.preference}
              maxLength={300}
            />
          </div>
        ) : null}
      </div>

      <div className={styles.mapContainer}>
        <MapViewDesktop
          initAddress={isEditAddress}
          suggestAddress={selectedAddress}
          getUserGeoData={setUserGeoData}
          getSuggestList={setSuggestList}
        />
      </div>
    </div >
  );
};
