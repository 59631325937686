import { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useRouter } from "next/router";
import getConfig from "next/config";
import * as signalR from "@microsoft/signalr";

import { orderStatuses } from "~/constants";
import { OrderFullInfo, OrderRepeat, OrderWithList } from "~/models/order";
import {
  cancelOrder,
  getAllOrders,
  getOrder,
  getOrderPoints,
  repeatOrder,
} from "~/plugins/api/order";
import { customAuthFetch } from "~/plugins/services/fetch";
import { useBasketContext } from "../basket/useBasket.hook";

export function useGetOrder(id: string) {
  return useQuery(
    ["order", id],
    // () => customAuthFetch<OrderFullInfo>(() => getOrder(id)),
    () => getOrder(id),
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: !!id,
    }
  );
}

interface IPoints {
  id: string;
  accessToken?: string;
}

export function useGetOrderPoints({ id, accessToken }: IPoints) {
  return useQuery(["orderPoints", id], () => getOrderPoints(id, accessToken), {
    cacheTime: 0,
    staleTime: 0,
    enabled: !!id,
  });
}

export function useRepeatOrder(isMobile = false) {
  const router = useRouter();
  const { handleSetBasket } = useBasketContext();
  const queryClient = useQueryClient();

  const [repeatId, setRepeatOrderId] = useState("");
  const handleRepeatengOrder = (id: string) => setRepeatOrderId(id);

  return {
    ...useQuery(
      ["orderRepeat", repeatId],
      // () => customAuthFetch<OrderRepeat>(() => repeatOrder(repeatId)),
      () => repeatOrder(repeatId),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(["orderList"]);
          handleSetBasket(data);
          return router.push(isMobile ? "/cart" : "/ordercheckout");
        },
        enabled: !!repeatId,
      }
    ),
    handleRepeatengOrder,
  };
}

export function useCancelOrder() {
  const queryClient = useQueryClient();

  const [cancelId, setCancelOrderId] = useState("");
  const handleCanceledOrder = (id: string) => setCancelOrderId(id);

  const fetch = async (orderId: string) => {
    try {
      await cancelOrder(orderId);
    } catch (error: any) {
      return error.response;
    }
  };

  return {
    ...useQuery(["orderCancel", cancelId], () => fetch(cancelId), {
      onSuccess: () => {
        queryClient.invalidateQueries(["order", cancelId]);
        // queryClient.invalidateQueries(["orderList"]);
      },
      enabled: !!cancelId,
      retry: 1,
    }),
    handleCanceledOrder,
  };
}

interface IOrdersLists {
  activeOrders: OrderWithList[];
  completedOrders: OrderWithList[];
}

export function useGetOrderList() {
  const queryClient = useQueryClient();
  return useQuery(
    ["orderList"],
    // () => customAuthFetch<OrderWithList[]>(() => getAllOrders()),
    () => getAllOrders(),
    {
      initialData: () => {
        return queryClient.getQueryData(["orderList"]);
      },
      select: (data): IOrdersLists => {
        return {
          activeOrders:
            data?.filter(
              ({ statusName }) =>
                statusName.toLocaleLowerCase() !==
                  orderStatuses.delivered.name.toLocaleLowerCase() &&
                statusName.toLocaleLowerCase() !==
                  orderStatuses.cancelled.name.toLocaleLowerCase()
            ) ?? [],
          completedOrders:
            data?.filter(
              ({ statusName }) =>
                statusName.toLocaleLowerCase() ===
                  orderStatuses.delivered.name.toLocaleLowerCase() ||
                statusName.toLocaleLowerCase() ===
                  orderStatuses.cancelled.name.toLocaleLowerCase()
            ) ?? [],
        };
      },
    }
  );
}

interface IOrderTracking {
  accessToken: string | undefined;
  orderId: string;
  initCoords: number[] | string[] | null;
}

interface ICourierOrder {
  courierId: string;
  customerId: string;
  latitude: string;
  longitude: string;
  orderId: string;
  orderStatusId: string;
  coordinates: number[] | string[] | null;
}
const { publicRuntimeConfig } = getConfig();

export function useOrderTracking({
  accessToken,
  orderId,
  initCoords,
}: IOrderTracking) {
  const connectionHub = `${publicRuntimeConfig.NEXT_PUBLIC_HUB_URL}/customerHub?access_token=${accessToken}&orderId=${orderId}`;
  const transport =
    signalR.HttpTransportType.WebSockets |
    signalR.HttpTransportType.LongPolling;
  const options = {
    transport,
  };

  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  const [courierState, setCourierState] = useState<ICourierOrder | null>(null);

  const coordinates = courierState?.coordinates ?? initCoords;

  const courierStatus = courierState
    ? courierState?.orderStatusId
    : initCoords
    ? 3
    : 2;

  useEffect(() => {
    if (accessToken) {
      (async () => {
        try {
          const connection = new signalR.HubConnectionBuilder()
            .withUrl(connectionHub, options)
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 20000])
            .build();

          connection.on("GetLocation", setCourierState);

          connection.onclose(() => {
            setConnection(null);
          });

          await connection.start().then(function () {
            connection.invoke("GetConnectionId");
          });

          setConnection(connection);
        } catch (err) {
          console.log("SignalR Disconnected:");
        }
      })();
    }

    return () => {
      (async () => {
        try {
          await connection?.stop();
          console.log("SignalR Disconnected.");
        } catch (err) {
          console.log("SignalR failed to disconnect:", err);
        }
      })();
    };
  }, [accessToken]);

  return { coordinates, courierStatus };
}
