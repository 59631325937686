import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import styles from "./HeaderDesktopSearch.module.css";
import useWindowScrollValue from '~/hooks/useWindowScrollValue';

interface WrapperProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLHeadElement>, HTMLHeadElement> {
  children: ReactNode;
}

export const Wrapper = ({
  className,
  children,
  ...props
}: WrapperProps): JSX.Element => {
  const scroll = useWindowScrollValue();

  return (
    <header
      className={clsx(styles.header, className, {
        [styles.isScrollHeader]: scroll > 30,
      })}
      {...props}
    >
      {children}
    </header>
  );
};
