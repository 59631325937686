import { useState } from "react";
import { IMaskInput } from "react-imask";
import clsx from "clsx";

import { ButtonMobile, TitleMobile } from '~/components';
import { ResendCode } from "../ResendCode/ResendCode";
import AuthCodeProps from "./AuthCode.props";
import styles from "./AuthCode.module.css";
import EditPencilIcon from "~/assets/icons/edit-pencil.svg";

import { useUserContext } from "~/hooks/user/useUser.hook";
import {
  confirmCode,
  sendCode
} from "~/plugins/api";
import { FieldState } from '~/models/account';
import jwtDecode from 'jwt-decode';
import { useAddressContext } from '~/hooks/address/useAddress.hook';

export const AuthCode = ({
  onSuccessAuth = () => null,
  setActiveStage = (stage: number) => stage,
  phone = "",
  isOrderingScreen = false,
}: AuthCodeProps): JSX.Element => {
  const { login } = useUserContext();
  const { handleGetUserAddresses } = useAddressContext();

  const [code, setCode] = useState("");
  const [codeRef, setCodeRef] = useState<HTMLInputElement | null>(null);
  const [codeState, setCodeState] = useState<FieldState>({
    isLoading: false,
    errors: [],
  });

  const onTakeBackStage = () => {
    setActiveStage(0);
  };

  const handleSendCode = async () => {
    const phoneRaw = phone.replace(/[^0-9]/g, "");
    try {
      await sendCode(phoneRaw);
    } catch (e) {
      console.log("Ошибка отправки кода");
      console.log(e);
    }
  };

  const handleConfirmCode = async () => {
    setCodeState({ ...codeState, isLoading: true });
    const codeRaw = code.replace(/[^0-9]/g, "");
    const phoneRaw = phone.replace(/[^0-9]/g, "");

    try {
      const token = await confirmCode({
        phoneNumber: phoneRaw,
        code: codeRaw,
      });

      login(token);
      handleGetUserAddresses();

      const isHaveNickName = !!(
        jwtDecode<any>(token.accessToken)["unique_name"] as string
      ).trim().length;

      setCodeState((state) => {
        return {
          ...state,
          isLoading: false,
        };
      });

      if (isHaveNickName) {
        onSuccessAuth();
      } else {
        setActiveStage(2);
      }

    } catch (e) {
      setCodeState({ ...codeState, errors: ["Вы ввели неправильный код"] });

      if (codeRef) {
        codeRef.focus();
      }
      console.error(e);
    }
  };

  return (
    <>
      <TitleMobile headerType='h4' margin={false}>Авторизация</TitleMobile>
      <div className={styles.content}>
        <div className={styles.prompt}>
          <div>
            Введите код подтверждения отправленный в СМС на номер
          </div>
          <div className={styles.phoneNubmer} onClick={onTakeBackStage}>
            {phone}
            <EditPencilIcon className={styles.editIcon} />
          </div>
        </div>

        <div
          className={clsx(styles.inputWrapper, {
            [styles.inputWrapperInvalid]: codeState.errors.length,
          })}
        >
          {isOrderingScreen && <label
            htmlFor="name"
          >
            Код из смс
          </label>}
          <IMaskInput
            id="name"
            mask="0 0 0 0 0 0"
            inputRef={setCodeRef}
            onAccept={(value) => {
              if (value) return setCode(value.trim());
              return setCode("");
            }}
            disabled={codeState.isLoading}
            type="text"
            autoFocus
            inputMode="numeric"
            pattern="[0-9]*"
            className={styles.input}
            value={code}
            autoComplete="one-time-code"
            placeholder={"__ __ __ __ __ __"}
          />
          <div className={styles.alertPrompt}>
            {codeState.errors[0]}
          </div>
        </div>

        <ButtonMobile
          onClick={handleConfirmCode}
          disabled={codeState.isLoading || code.replace(/[^0-9]/g, "").length < 6}
          className={styles.confirmBtn}
        >
          Подтвердить
        </ButtonMobile>
        <ResendCode sendCode={handleSendCode} />
      </div>
    </>
  );
};
