import { useModal } from 'react-modal-hook';
import { ButtonMobile, MobileModal } from "~/components";
import PartnerRating from './PartnerRating';
import RaitingIcon from "~/assets/icons/star-fill.svg";
import styles from "./PartnerRating.module.css";
import { useRouter } from 'next/router';
import { usePartnerInfo } from '~/hooks/partner';

export const PartnerRatingButton = (): JSX.Element => {
  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <MobileModal
        headline='Отзывы'
        isOpen={open}
        onExit={onExited}
        hideModal={hideModal}
      >
        <PartnerRating />
      </MobileModal>
    )
  );

  const router = useRouter();
  const partnerId = router.query.id as string;
  const { data: partner } = usePartnerInfo(partnerId);

  return (
    <ButtonMobile
      appearance='gost'
      className={styles.buttonDelivery}
      onClick={showModal}
    >
      <RaitingIcon className={styles.icon} />

      <div className={styles.buttonText}>
        <div className={styles.supText}>
          {partner?.rating}{" "}
          <span className={styles.feedback}>
            {partner?.feedbackCount} отзывов
          </span>
        </div>
        <div className={styles.subText}>Читать отзывы</div>
      </div>
    </ButtonMobile>
  );
};
