const getTimeWithTimezone = (deliverAtDatetime: Date | null) => {
  if (!deliverAtDatetime) return null;
  const offset = new Date().getTimezoneOffset() / 60;
  const gmt = `${
    Math.abs(offset) < 10 ? `0${Math.abs(offset)}` : Math.abs(offset)
  }`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTime =
    deliverAtDatetime &&
    deliverAtDatetime.toLocaleString("ru-Ru", {
      timeZone: timezone,
    });
  const date = dateTime?.split(", ")[0].split(".").reverse().join("-");
  const time = dateTime?.split(", ")[1];
  return `${date}T${time}+${gmt}:00`;
};

export default getTimeWithTimezone;
