import CheckboxChoiseProps from "./CheckboxChoise.props";
import styles from "./CheckboxChoise.module.css";
import clsx from "clsx";
import { Checkbox } from "~/components";
import { useState } from "react";

export const CheckboxChoise = ({
  className,
  additive,
  onSelect = () => null,
  onRemove = () => null,
  ...props
}: CheckboxChoiseProps): JSX.Element => {
  const [checked, setChecked] = useState(false);

  const handleChkecked = () => {
    const newValue = !checked;
    setChecked(newValue);

    if (newValue) {
      onSelect();
    } else {
      onRemove();
    }
  };

  return (
    <div
      onClick={handleChkecked}
      className={clsx(styles.wrapper, className)}
      {...props}
    >
      <Checkbox value={additive.id} key={props.key} checked={checked} />
      <div className={styles.title}>{additive.name}</div>
      <span className={styles.price}>+ {additive.cost} ₽</span>
    </div>
  );
};
