import clsx from "clsx";

// import { CatalogPageSortPopup, Popup } from "~/components";
import styles from "./CatalogFilters.module.css";
import CatalogFiltersProps from "./CatalogFilters.props";
// import SettingsIcon from "~/assets/icons/settings.svg";
import { FiltersList } from './FiltersList';

export const CatalogFiltersDesktop = ({
	className,
	tags,
	...props
}: CatalogFiltersProps): JSX.Element => {

	// const [showSortPopup, hideSortPopup] = useModal(
	// 	({ in: open, onExited }) => {
	// 		return (
	// 			<Popup
	// 				onExit={onExited}
	// 				isOpen={open}
	// 				hidePopup={hideSortPopup}
	// 				elementId="sortFilters"
	// 			>
	// 				<CatalogPageSortPopup
	// 					handleSelectTag={hideSortPopup}
	// 				/>
	// 			</Popup>
	// 		);
	// 	}
	// );

	return (
		<div className={clsx(styles.catalog, className)} {...props}>
			<FiltersList tags={tags} />
			{/* <TagDesktop
				id="sortFilters"
				className={clsx(styles.tag, styles.sortButton, {
					[styles.tagActive]: !!sort,
				})}
				onClick={showSortPopup}
			>
				<SettingsIcon className={styles.settingsSvg} /> Сортировка
			</TagDesktop> */}
		</div >
	);
};
