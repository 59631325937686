import { useEffect, useState } from "react";
import clsx from "clsx";

import { BottomSheet } from '../_Components/BottomSheet';
import { TitleMobile } from '~/components/_MobileUI/TitleMobile';
import { ButtonMobile } from '~/components/_MobileUI/ButtonMobile';
import CheckIcon from "~/assets/icons/check.svg";
import styles from "./TimePickerBottomSheet.module.css";
import TimePickerBottomSheetProps from "./TimePickerBottomSheet.props";

import convertTime from "~/helpers/convertTime";
import { partnerIsNotOpenYet, partnerIsWorking } from '~/helpers';
import { deliverIsTommorow } from '~/helpers/deliveryDateTime';
import useHtmlAttributes from '~/hooks/useHtmlAttributes';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

const RANGE_TIME = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const getOrderDeliveredAtDateTime = (
  safari: boolean,
  isTommorow: boolean,
  orderTime: string
) => {
  if (orderTime === "Сейчас") return null;
  const times = String(orderTime).split(":");
  const count = !safari ? 32 : 33;
  const newDate = new Date();
  const currentDayInMonth = newDate.getDate();
  const daysInCurrentMoth =
    count -
    new Date(newDate.getFullYear(), newDate.getMonth(), count).getDate();

  if (!isTommorow && times) {
    return new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      currentDayInMonth,
      Number(times[0]),
      Number(times[1])
    );
  }
  if (isTommorow && times) {
    if (currentDayInMonth < daysInCurrentMoth && times) {
      return new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        currentDayInMonth + 1,
        Number(times[0]),
        Number(times[1])
      );
    } else {
      return new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        1,
        Number(times[0]),
        Number(times[1])
      );
    }
  }
  return null;
};

export const TimePickerBottomSheet = ({
  isOpen,
  onExit,
  hideModal,
  handlerSaveTime,
  schedule,
  isRoundTheClock,
  className,
  ...props
}: TimePickerBottomSheetProps): JSX.Element => {
  const { basket, addDeliveredAtDateTime } = useBasketContext();

  const [workRangeTime, setWorkRangeTime] = useState<string[]>([]);
  const [tommorowWorkRangeTime, setTommorowWorkRangeTime] = useState<string[]>(
    []
  );
  const [isTommorow, setIsTommorow] = useState(false);
  const [activeTime, setActiveTime] = useState<string>("");
  const [activeItemTommorow, setActiveItemTommorow] = useState<string>("");

  const partnerIsWork = partnerIsWorking(schedule);

  const begin = schedule.begin.slice(0, 5);
  const end = schedule.end.slice(0, 5);

  useEffect(() => {
    if (basket?.deliverAtDatetime) {
      if (deliverIsTommorow(basket?.deliverAtDatetime)) {
        setActiveItemTommorow(convertTime(basket?.deliverAtDatetime));
        setIsTommorow(() => deliverIsTommorow(basket?.deliverAtDatetime));
      }
      if (!deliverIsTommorow(basket?.deliverAtDatetime)) {
        setActiveTime(convertTime(basket?.deliverAtDatetime));
        setIsTommorow(() => deliverIsTommorow(basket?.deliverAtDatetime));
      }
    }
    if (basket?.deliverAtDatetime === null) {
      setActiveTime("Сейчас");
    }
  }, []);

  useEffect(() => {
    const hours = new Date().getHours();
    const minute = new Date().getMinutes();
    const curentTime = `${hours + 1 < 10 ? 0 : ""}${hours + 1}:${minute < 30 ? "00" : "30"
      }`;
    const srartCurentIndex = RANGE_TIME.indexOf(curentTime);
    const beginTime = RANGE_TIME.indexOf(begin);
    const endTime = RANGE_TIME.indexOf(end);

    if (begin && end) {
      if (isRoundTheClock) {
        if (!isTommorow) {
          return setWorkRangeTime([
            "Сейчас",
            ...RANGE_TIME.slice(srartCurentIndex),
          ]);
        } return setTommorowWorkRangeTime(RANGE_TIME);
      } else {
        if (!isTommorow) {
          //партнер сегодня работает
          if (partnerIsWork) {
            return setWorkRangeTime([
              "Сейчас",
              ...RANGE_TIME.slice(srartCurentIndex, endTime + 1),
            ]);
          }
          //партнер сегодня еще не открылся
          if (partnerIsNotOpenYet(schedule)) {
            return setWorkRangeTime(RANGE_TIME.slice(beginTime, endTime + 1));
          }
          //партнер сегодня закрылся
          return setWorkRangeTime([]);
        } return setTommorowWorkRangeTime(
          RANGE_TIME.slice(beginTime, endTime + 1)
        );
      }
    }
  }, [isTommorow, isRoundTheClock]);

  const { devices } = useHtmlAttributes();

  const onItemClick = async (time: string) => {
    if (!devices) return
    const times = getOrderDeliveredAtDateTime(devices?.isIos, isTommorow, time);
    if (basket) {
      await addDeliveredAtDateTime(basket?.id, times);
      setActiveTime(time);
      setTimeout(handlerSaveTime, 300);
    }
  };

  return (
    <BottomSheet
      onExit={onExit}
      isOpen={isOpen}
      hideModal={hideModal}
    >
      <div className={clsx(styles.wrapper, className)} {...props}>
        <div>
          <TitleMobile headerType='h4'>Выберите время</TitleMobile>
          <div className={styles.rangeFlex}>
            <ButtonMobile
              appearance={!isTommorow ? "primary" : "text"}
              onClick={() => setIsTommorow(false)}
              className={styles.buttonRangeType}
            >
              Сегодня
            </ButtonMobile>
            <ButtonMobile
              appearance={isTommorow ? "primary" : "text"}
              onClick={() => setIsTommorow(true)}
              className={styles.buttonRangeType}
            >
              Завтра
            </ButtonMobile>
          </div>
        </div>

        <div className={styles.timeList}>
          {!isTommorow ? (
            workRangeTime.length ? (
              workRangeTime.map((it, indx) => {
                return (
                  <div
                    key={indx}
                    className={clsx(styles.itemTimeList, {
                      [styles.selectedItemList]: activeTime === it,
                    })}
                    onClick={() => onItemClick(it)}
                  >
                    {it}
                    {activeTime === it && (
                      <CheckIcon />
                    )}
                  </div>
                );
              })
            ) : (
              <div className={styles.alertText}>Патнер сегодня не доставляет, попробуйте сделать предзаказ на завтра!</div>
            )) : null
          }
          {isTommorow &&
            tommorowWorkRangeTime.map((it, indx) => {
              return (
                <div
                  key={indx}
                  className={clsx(styles.itemTimeList, {
                    [styles.selectedItemList]: activeItemTommorow === it,
                  })}
                  onClick={() => onItemClick(it)}
                >
                  {it}
                  {activeItemTommorow === it && (
                    <CheckIcon />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </BottomSheet>
  );
};