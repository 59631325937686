
import AuthBottomSheetProps from "./AuthBottomSheet.props";
import { BottomSheet } from '../_Components/BottomSheet';
import { AuthViewMobile } from '~/components/_MobileUI/AuthViewMobile';

export const AuthBottomSheet = ({
  isOpen,
  hideModal,
  onExit,
  onSuccessAuth = () => null,
  className,
  ...props
}: AuthBottomSheetProps): JSX.Element => {
  const handleSuccess = () => {
    hideModal();
    setTimeout(onSuccessAuth, 300);
  };

  return (
    <BottomSheet isOpen={isOpen} onExit={onExit} hideModal={hideModal}>
      <AuthViewMobile onSuccessAuth={handleSuccess} className={className} {...props} />
    </BottomSheet>
  )
};

