import { PropsWithChildren } from "react";

import {
	BottomSheet,
	PromocodeViewMobile
} from '~/components';
import PromocodeBottomSheetProps from "./PromocodeBottomSheet.props";

export const PromocodeBottomSheet = ({
	isOpen,
	hideModal,
	onExit,
	className,
	...props
}: PropsWithChildren<PromocodeBottomSheetProps>): JSX.Element => {

	return (
		<BottomSheet isOpen={isOpen} hideModal={hideModal} onExit={onExit}>
			<PromocodeViewMobile onSuccess={hideModal} className={className}{...props} />
		</BottomSheet>
	);
};