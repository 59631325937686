import { useState } from 'react';
import TagMobileProps from "./TagMobile.props";
import styles from "./TagMobile.module.css";
import clsx from "clsx";

export const TagMobile = ({
  className,
  children,
  active = false,
  disabled = false,
  color = "gray",
  ...props
}: TagMobileProps): JSX.Element => {
  const [pressedTag, setPressedTag] = useState(false);
  return (
    <button
      className={clsx(styles.tag, {
        [styles.active]: active,
        [styles.buttonPressed]: pressedTag && !active,
        [styles.colorGray]: color === "gray",
        [styles.colorLight]: color === "light",
      }, className)}
      {...props}
      disabled={disabled}
      onTouchStart={() => setPressedTag(true)}
      onTouchEnd={() => setTimeout(() => setPressedTag(false), 150)}
    >
      {children}
    </button>
  );
};
