import { useState } from "react";
import { useModal } from "react-modal-hook";
import { useRouter } from 'next/router';
import Link from "next/link";
import clsx from "clsx";
import dynamic from 'next/dynamic';
import { useQueryClient } from "react-query";

import { ButtonDesktop } from "~/components";
import UserAuthDesktopProps from "./UserAuthDesktop.props";
import styles from "./UserAuthDesktop.module.css";

import LoginIcon from "~/assets/icons/desktop-login.svg";
import UserAuthPopupIcon from "~/assets/icons/user-circle-solid.svg";
import UserAuthLocation from "~/assets/icons/user-location.svg";
import UserAuthOrdersIcon from "~/assets/icons/user-orders.svg";
import UserAuthLogout from "~/assets/icons/user-logout.svg";

import { useUserContext } from "~/hooks/user/useUser.hook";
import useClickOutside from "~/hooks/useOutsideClick.hook";
import useConfirmModal from '~/hooks/useConfirmModal';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { ACCESS_LOGIN_PAGES } from '~/constants';
import { namedComponent } from '~/helpers/dynamicComponent';

const Components = {
  AuthModal: dynamic(() =>
    namedComponent(import('~/components'), 'AuthModal')
  ),
  DesktopProfileModal: dynamic(() =>
    namedComponent(import('~/components'), 'DesktopProfileModal')
  )
};

export const UserAuthDesktop = ({ className, ...props }: UserAuthDesktopProps): JSX.Element => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { loggedIn, userName, logout } = useUserContext();
  const { clearBasket } = useBasketContext();
  const [showModal, hideModal] = useModal(({ in: open, onExited }) => {
    return <Components.AuthModal onExit={onExited} isOpen={open} hideModal={hideModal} onSuccessAuth={hideModal} />;
  });

  const handelLogoutUser = () => {
    if (ACCESS_LOGIN_PAGES.includes(router.pathname)) {
      router.push("/")
    }
    clearBasket();
    logout();
    queryClient.clear();
    handlerHideConfirmModal();
  };

  const [showProfileModal, hideProfileModal] = useModal(({ in: open, onExited }) => {
    return <Components.DesktopProfileModal isOpen={open} onExit={onExited} hideModal={hideProfileModal} />
  });

  const handlerHideConfirmModal = () => hideConfirmModal();

  const [ showConfirmModal, hideConfirmModal ] = useConfirmModal({
    onConfirm: handelLogoutUser,
    onCancel: handlerHideConfirmModal,
    width: 500,
    title: "Вы действительно хотите выйти из профиля?",
    message: "",
  })

  const [userPopup, setUserPopup] = useState(false);

  const popupUlRef = useClickOutside<HTMLUListElement>(() => setUserPopup(false));

  const handleLogin = () => {
    if (!loggedIn) {
      return showModal();
    } else {
      if (loggedIn && userName.length < 2) {
        return showModal();
      }
      return setUserPopup(true);
    }
  };

  return (
    <div className={styles.container}>
      <ButtonDesktop
        onClick={handleLogin}
        appearance={userName.trim().length ? "text" : "gray"}
        className={className}
        {...props}
      >
        {userName ? (
          <span className={styles.userCircle}>{userName.charAt(0)}</span>
        ) : (
          <>
            <LoginIcon className={styles.unloggedIcon} />
            <span>Войти</span>
          </>
        )}
      </ButtonDesktop>

      {userPopup ? (
        <>
          <div className={styles.overlay}></div>
          <div className={clsx(styles.popup, "text")}>
            <div className={styles.nameHolder}>{userName}</div>
            <ul className={styles.navList} onClick={() => setUserPopup(false)} ref={popupUlRef}>
              <li
                className={styles.listItem}
                onClick={showProfileModal}
              >
                <div>
                  <UserAuthPopupIcon />
                  <span className={styles.itemName}>Мои данные</span>
                </div>
              </li>
              {/*   <li className={styles.listItem}>
                <Link href={`/favorite`}>
                    <UserAuthPopupHeart />
                    <span>Избранное</span>
                    <ArrowRightBlack />
                </Link>
              </li> */}
              <li className={styles.listItem}>
                <Link href={`/myaddresses`}>
                  <UserAuthLocation />
                  <span className={styles.itemName}>Мои адреса</span>
                </Link>
              </li>
              <li className={styles.listItem}>
                <Link href={`/myorders`}>
                  <UserAuthOrdersIcon />
                  <span className={styles.itemName}>История заказов</span>
                </Link>
              </li>
              <li
                onClick={showConfirmModal}
                className={styles.listItem}
              >
                <div>
                  <UserAuthLogout />
                  <span className={styles.itemName}>Выйти</span>
                </div>
              </li>
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
};
