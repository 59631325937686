import ModalProps from "./Modal.props";
import clsx from "clsx";

import styles from "./Modal.module.css";
import useChangeVisibleModal from '~/hooks/useChangeVisibleModal';
import { TitleDesktop } from '~/components/_DesktopUI/TitleDesktop';

export const Modal = ({
  isOpen,
  hideModal,
  onExit,
  headline,
  width = 'auto',
  control = "right",
  align = 'center',
  hiddenHeader = false,
  children,
  className,
  ...props
}: ModalProps): JSX.Element => {
  useChangeVisibleModal({ isOpen, hideModal, onExit });
  return (
    <div
      id='portal'
      data-portal
      className={clsx(styles.wrapper, {
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
      })}
    >
      <div className={styles.overlay} onClick={hideModal} />

      <div
        className={clsx(styles.window, className)}
        style={{ maxWidth: `${width}px` }}
        {...props}
      >
        {!hiddenHeader ? (
          <div className={styles.header}>
            {control === "left" ? (
              <div className={clsx(styles.controlContainer, styles.leftControlContainer)}>
                <button className={styles.closeButton} onClick={hideModal} />
              </div>
            ) : null}

            <div className={clsx(styles.headerTitle, {
              [styles.mlTitle]: control === "right" && align != "left",
              [styles.mrTitle]: control === "left" && align != "right"
            })}>
              {headline ? (
                <TitleDesktop align={align} headerType='h3' margin={false}>{headline}</TitleDesktop>
              ) : null}
            </div>

            {control === "right" ? (
              <div className={clsx(styles.controlContainer, styles.rightControlContainer)}>
                <button className={styles.closeButton} onClick={hideModal} />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div >
  );
};
