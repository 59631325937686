import { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./HeaderAuthorizedDesktop.module.css";

interface WrapperProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLHeadElement>, HTMLHeadElement> {
  children: ReactNode;
}

export const Wrapper = ({
  className,
  children,
  ...props
}: WrapperProps): JSX.Element => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY);

    window.addEventListener("scroll", handleScroll, false);
    return () => window.removeEventListener("scroll", handleScroll, false);
  }, []);

  return (
    <header
      className={clsx(styles.header, className, {
        [styles.isScrollHeader]: scroll > 30,
      })}
      {...props}
    >
      {children}
    </header>
  );
};
