import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import clsx from 'clsx';

import BreadCrumbsProps from "./BreadCrumbs.props";
import styles from "./BreadCrumbs.module.css";

import {
  getPartnerBreadcrumbs,
  IPartnerBreadcrumbs,
} from "~/plugins/api/partner";

export const BreadCrumbs = ({ isDesktop = false }: BreadCrumbsProps) => {
  const router = useRouter();
  const partnerId = router.query.id as string;
  const activeCategoryId = router.query.categoryId as string;
  const currentCategoryName = router.query.category as string;
  const partnerName = router.query.name as string;

  const basePath = `${isDesktop ? "shop" : "retail"}`;

  const [breadcrumbs, setBreadcrumbs] = useState<IPartnerBreadcrumbs[] | []>([]);

  useEffect(() => {
    if (activeCategoryId !== "promo") {
      const fetchBreadcrumbs = async (categoryId: string) => {
        const result = await getPartnerBreadcrumbs(categoryId);
        setBreadcrumbs(result);
      };

      fetchBreadcrumbs(activeCategoryId);
    }
  }, [activeCategoryId]);

  return (
    <div className={styles.breadcrumbs}>
      <div className={styles.breadcrumbsItem}>
        {breadcrumbs.length ? <Link
          href={`/${basePath}/${partnerId}?name=${partnerName}&category=Товары по акции&categoryId=promo`}
          passHref
        >
          {partnerName}
        </Link> : partnerName}
        <span className={styles.delimeter}>⁄</span>
      </div>

      {
        breadcrumbs.map((it) => (
          <div className={styles.breadcrumbsItem} key={it.id}>
            <Link
              href={`/${basePath}/${partnerId}?name=${partnerName}&category=${it.name}&categoryId=${it.id}`}
              passHref
            >
              {it.name}
            </Link>
            <span className={styles.delimeter}>⁄</span>
          </div>
        ))
      }

      <div className={clsx(styles.breadcrumbsItem, styles.currentItem)}>
        {currentCategoryName}
      </div>
    </div >
  );
};
