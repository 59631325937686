import { useEffect } from "react";

interface IEnterElement {
  isRegisterListener: boolean;
  elementSelector: string;
  callBack: () => void;
}

export default function useEnterElement({
  isRegisterListener = false,
  elementSelector,
  callBack = () => null,
}: IEnterElement) {
  useEffect(() => {
    if (isRegisterListener) {
      const element = document.querySelector(elementSelector);

      const handleEnterPress = (e: any) => {
        if (e?.key === "Enter") {
          return callBack();
        }
      };

      if (element) {
        element.addEventListener("keypress", handleEnterPress, false);

        return () => {
          element.removeEventListener("keypress", handleEnterPress, false);
        };
      }
    }
  }, [isRegisterListener]);

  return null;
}
