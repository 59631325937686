
import AuthModalProps from "./AuthModal.props";
import { Modal } from '../_Components/Modal';
import { AuthViewDesktop } from '~/components/_DesktopUI/AuthViewDesktop';

export const AuthModal = ({
  isOpen,
  hideModal,
  onExit,
  onSuccessAuth = () => null,
  className,
  ...props
}: AuthModalProps): JSX.Element => {
  const handleSuccess = () => {
    hideModal();
    setTimeout(onSuccessAuth, 300);
  };

  return (
    <Modal width={600} headline="Авторизация" align='left' isOpen={isOpen} hideModal={hideModal} onExit={onExit}>
      <AuthViewDesktop onSuccessAuth={handleSuccess} className={className} {...props} />
    </Modal>
  );
};
