import { useRouter } from 'next/router';
import { useModal } from 'react-modal-hook';

import { BottomSheet, ButtonMobile } from "~/components";
import AboutDelivery from './AboutDelivery';
import DeliveryIcon from "~/assets/icons/delivery.svg";
import styles from "./AboutDelivery.module.css";

import { usePartnerInfo } from '~/hooks/partner';

export const AboutDeliveryButton = (): JSX.Element => {
  const router = useRouter();
  const partnerId = router.query.id as string;
  const { data: partner } = usePartnerInfo(partnerId);

  const [showBottomSheet, hideBottomSheet] = useModal(
    ({ in: open, onExited }) => {
      return (
        <BottomSheet
          onExit={onExited}
          isOpen={open}
          hideModal={hideBottomSheet}
        >
          <AboutDelivery />
        </BottomSheet>
      );
    }
  );

  return (
    <ButtonMobile
      appearance='gost'
      className={styles.buttonDelivery}
      onClick={showBottomSheet}
    >
      <DeliveryIcon className={styles.icon} />
      <div className={styles.buttonText}>
        <div className={styles.supText}>
          от {partner?.minAmountOrder}₽
        </div>
        <div className={styles.subText}>о доставке</div>
      </div>
    </ButtonMobile>
  )
};
