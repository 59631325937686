import { useState } from "react";
import Image from "next/legacy/image";
import Link from "next/link";
import clsx from "clsx";

import PartnerCardMobileProps from "./PartnerCardMobile.props";
import styles from "./PartnerCardMobile.module.css";
import RaitingIcon from "~/assets/icons/star-fill.svg";
import ShoppingBagIcon from "~/assets/icons/shopping-bag-alt.svg";
import DeliveryManIcon from "~/assets/icons/delivery-man.svg";
import ClockIcon from "~/assets/icons/clock.svg";

import parseSchedule from "~/helpers/parseSchedule";
import { isPartnerWorkingTime } from "~/helpers";

export const PartnerCardMobile = ({
  partner,
  marketCategory,
  className,
  ...props
}: PartnerCardMobileProps): JSX.Element => {
  const [partnerIsWork] = useState<boolean>(() => isPartnerWorkingTime(partner));

  const [link] = useState(() => {
    if (partner?.isStructure) {
      return `/retail/${partner?.id}?name=${partner?.name}&category=Товары со скидкой&categoryId=promo`;
    }
    return `/partner/${partner?.id}?name=${partner?.name}`;
  })

  const [pressedCard, setPressedCard] = useState(false);

  return (
    <div
      className={clsx(styles.wrapper, {
        // [styles.closed]: !partnerIsWork,
        [styles.pressed]: pressedCard
      }, className)}
      onTouchStart={() => setPressedCard(true)}
      onTouchEnd={() => setTimeout(() => setPressedCard(false), 150)}
      {...props}
    >
      <Link href={link}>
        <div className={styles.imgWrapper}>
          {partner.mainWinImg ? (
            <>
              <Image
                className={clsx({
                  [styles.imgPartnerClose]: !partnerIsWork,
                })}
                // unoptimized
                priority
                alt={partner.name}
                src={partner.mainWinImg ?? undefined}
                width={326}
                height={160}
                layout="fill"
                objectFit="cover"
              // sizes="(max-width: 768px) 50vw, (max-width: 1240px) 25vw, 13vw"
              />
              <div
                className={clsx(styles.closingPlaceNone, {
                  [styles.closingPlace]: !partnerIsWork,
                })}
              >
                Откроется в {partner.schedule.begin.slice(0, 5)}
              </div>

              <div
                className={clsx(styles.avgDeliveryTimePlace, {
                  [styles.avgTimeIsNone]: partner?.avgDeliveryTime?.end <= 10,
                })}
              >
                <DeliveryManIcon className={styles.manSvg} /> {partner.avgDeliveryTime?.begin}-{partner.avgDeliveryTime?.end} мин.
              </div>
            </>
          ) : null}
        </div>
        <div className={styles.info}>
          <div className={clsx("h4", styles.name)}>
            {partner.name?.trim() ? partner.name : <span>&nbsp;</span>}
          </div>
          <div
            title={partner.shortDescription || undefined}
            className={styles.subTitle}
          >
            {partner.shortDescription?.trim() ? (
              partner.shortDescription
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
          <div className={styles.meta}>
            <div className={styles.raiting}>
              <RaitingIcon className={styles.starSvg} />
              <span className={styles.metaSubTextSpan}>{partner.rating}</span>
            </div>
            <div className={styles.cost}>
              <ShoppingBagIcon className={styles.bagSvg} />
              <span className={styles.metaSubTextSpan}>
                от {partner.minAmountOrder}₽
              </span>
            </div>
            <div className={styles.deliveryTime}>
              <ClockIcon className={styles.clockSvg} />
              <span className={styles.metaSubTextSpan}>
                {partner.isRoundTheClock ? "Круглосуточно" : parseSchedule(partner.schedule)}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
