import { useEffect, useMemo, useState } from "react";
import CashIcon from "~/assets/svg/Cash-pay.png";
import LinkCardIcon from "~/assets/svg/Link-card.png";
import MasterCardIcon from "~/assets/svg/Master-card.png";
import VisaCardIcon from "~/assets/svg/Visa-card.png";
import MirCardIcon from "~/assets/svg/Mir-card.png";
import DefaultCardIcon from "~/assets/svg/Default-card.png";
import { LinkNewOnlineCard, PaymentMethodsType } from '~/constants';
import { useGetUserCards } from './useGetUserCards';

export interface IPaymentType {
  id: number;
  key: string;
  name: string;
  paymentMethodId: PaymentMethodsType;
  icon?: StaticImageData;
}

type PaymentMethods = {
  id: number
}

const payments: IPaymentType[] = [
  {
    id: 1,
    key: "cash",
    name: "Наличными",
    paymentMethodId: 1,
    icon: CashIcon,
  },
  {
    id: 2,
    key: LinkNewOnlineCard.key,
    name: "Привязать\n новую карту",
    paymentMethodId: 2,
    icon: LinkCardIcon,
  }
];

const CardsIcon: Record<string, StaticImageData> = {
  '2': MirCardIcon,
  '4': VisaCardIcon,
  '5': MasterCardIcon,
}

export default function useSelectPay(token?: string, paymentMethods?: PaymentMethods[]) {
  const { data = [] } = useGetUserCards(!!token);

  const cardsList = useMemo(() => [
    ...data.map(it => ({ ...it, name: it.name.slice(-9), icon: CardsIcon[it.name[0]] ?? DefaultCardIcon }))
  ], [data])

  const newList = useMemo(() => {
    if(paymentMethods?.length === 1) {
      console.log('Enter');
      if(paymentMethods[0].id === 1) {
        return ([
          payments[0]
        ])
      }
      if(paymentMethods[0].id === 2) {
        console.log('2');
        return ([
          ...cardsList,
          payments[1]
        ])
      }
    } else {
      return ([
        payments[0],
        ...cardsList,
        payments[1]
      ])
    }
  }, [cardsList, paymentMethods]);
  // const userToken = cookie.get("cron-user") ? JSON.parse(cookie.get("cron-user") as string) : undefined;
  // const defaultPayment = userToken ? payments.find(({ key }) => key.toLocaleLowerCase() === userToken?.payment.toLocaleLowerCase()) : payments[0];

  const [activePayment, setActivePayment] = useState<IPaymentType | null>(() => newList? newList[0] : null);

  useEffect(()=>{
    setActivePayment(newList ? newList[0] : null)
  }, [newList])

  const handleSetPaymentType = useMemo(() => {
    let prevpaymentType: IPaymentType | null;

    return (paymentType: IPaymentType | null) => {
      // if (!paymentType) return null;
      if (prevpaymentType?.id === paymentType?.id) {
        return;
      }
      prevpaymentType = paymentType;
      setActivePayment(paymentType)
    }
  }, []);

  return { payments: newList, cardsList, activePayment, handleSetPaymentType };
}
