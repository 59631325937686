import { useMemo, useState } from "react";

export interface IBanknote {
  id: number;
  type: number;
}

export default function useSelectBanknotes() {
  const banknotes: IBanknote[] = useMemo(() => [
    {
      id: 1,
      type: 500
    },
    {
      id: 2,
      type: 1000
    },
    {
      id: 3,
      type: 2000
    },
    {
      id: 4,
      type: 5000
    },
  ], []);

  const [changeFromBanknote, setChangeFromBanknote] = useState<IBanknote | null>(null);

  const handleChangeBanknote = useMemo(() => {
    let prevBanknote: IBanknote | null;

    return (banknote: IBanknote | null) => {
      if (!banknote) return setChangeFromBanknote(banknote);

      if (prevBanknote?.id === banknote?.id) {
        return setChangeFromBanknote(null);
      }

      prevBanknote = banknote;
      setChangeFromBanknote(banknote);
    };
  }, []);

  return { banknotes, changeFromBanknote, handleChangeBanknote };
}
