import clsx from "clsx";
import RadioButtonProps from "./RadioButton.props";
import styles from "./RadioButton.module.css";

export const RadioButton = ({
  className,
  name,
  value,
  checked,
  ...props
}: RadioButtonProps): JSX.Element => {
  return (
    <div className={styles.wrapper} {...props}>
      <input
        name={name}
        onChange={() => null}
        value={value}
        checked={checked}
        className={styles.input}
        type="radio"
      />
      <div
        className={clsx(className, styles.checkbox, {
          [styles.checkbox__checked]: checked,
        })}
      />
    </div>
  );
};
