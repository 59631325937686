import HeaderAuthorizedMobileProps from "./HeaderAuthorizedMobile.props";
import { Wrapper } from './Wrapper';
import { Content } from './Content';

export const HeaderAuthorizedMobile = ({
  className,
  headLine,
  ...props
}: HeaderAuthorizedMobileProps): JSX.Element => {
  return (
    <Wrapper className={className} {...props}>
      <Content headLine={headLine} />
    </Wrapper>
  );
};
