import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";

import SearchProps from "./Search.props";
import styles from "./Search.module.css";
import SearchIcon from "~/assets/icons/search.svg";
import ClearIcon from "~/assets/icons/close.svg";

import useDebounce from "~/hooks/useDebounce";
import { ButtonDesktop } from '../_DesktopUI/ButtonDesktop';

export const Search = ({ className, ...props }: SearchProps): JSX.Element => {
  const router = useRouter();
  const pathName = router.pathname;
  const searchQuery = router.query.query;
  const type = router.query.type ?? "Рестораны";
  const typeId = router.query.typeid ?? 1;
  const basePath = `/search?type=${type}&typeid=${typeId}&query=`;

  const field = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    searchQuery && setSearch(searchQuery as string);
  }, [searchQuery]);

  const debouncedSearch = useDebounce(search, 340);

  useEffect(() => {
    if (!debouncedSearch) return;
    if (pathName !== "/search") return;
    if (debouncedSearch.length >= 1) {
      router.push(
        `${basePath}${debouncedSearch}`,
        `${basePath}${debouncedSearch}`,
        { shallow: true }
      );
    }
    if (search?.length === 0) {
      router.push(`${basePath}`, `${basePath}`, {
        shallow: true,
      });
    }
  }, [debouncedSearch, search]);

  useEffect(() => {
    field.current?.focus();
  }, []);

  const handleEnterDownEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && search && pathName !== "/search") {
      router.push(`${basePath}${search}`, `${basePath}${search}`, {
        shallow: true,
      });
    }
  };

  return (
    <div
      className={clsx(styles.wrapper, className, {
        [styles.withButtonSearch]:
          search && pathName === "/",
      })}
      {...props}
    >
      <button className={styles.button}>
        <SearchIcon className={styles.searchIcon} />
      </button>
      <input
        value={search ?? ""}
        placeholder="Найти ресторан, магазин, блюдо, товар..."
        className={clsx("text", styles.field)}
        type="text"
        name="search"
        autoComplete="off"
        onKeyDown={handleEnterDownEvent}
        onChange={({ currentTarget }) => setSearch(currentTarget.value)}
        ref={field}
      />
      {search && (
        <button
          className={styles.clear}
          onClick={() => {
            setSearch("");
            return field.current?.focus();
          }}
        >
          <ClearIcon className={styles.clearIcon} />
        </button>
      )}
      {search && pathName !== "/search" && (
        <ButtonDesktop
          size='s'
          className={styles.searchButton}
          onClick={() =>
            router.push(`${basePath}${search}`, `${basePath}${search}`, {
              shallow: true,
            })
          }
        >
          <span>Найти</span>
        </ButtonDesktop>
      )}
    </div>
  );
};
