import { useEffect } from "react";
import { partnerIsNotOpenYet, partnerIsWorking } from "~/helpers";
import { Partner } from "~/models/partners";
import { useBasketContext } from "./basket/useBasket.hook";

export default function useSetDeliveryAtDateTime(partnerInfo?: Partner | null) {
  const { basket, addDeliveredAtDateTime } = useBasketContext();
  const basketId = basket?.id;
  const schedule = partnerInfo?.schedule;
  const basketPartnerId = basket?.partnerId;
  const deliverAtDatetime = basket?.deliverAtDatetime;
  const isWorkingTime = partnerIsWorking(schedule);

  useEffect(() => {
    if (!basketPartnerId || !schedule || !basketId) return;
    // партнер работает/параметр "доставки к" не указан
    if (isWorkingTime && deliverAtDatetime === null) return;
    // партнер не работает/параметр "доставки к" не указан - устанавливаю параметр "доставки к" равный времени открытия партнера
    if (!isWorkingTime && deliverAtDatetime === null) {
      const today = new Date();
      if (partnerIsNotOpenYet(schedule)) {
        // устанавливаем время заказа на открытие, если партнер еще не открылся
        const partnerTimeBeginHours = schedule.begin.slice(0, 2);
        const partnerTimeBeginMinutes = schedule.begin.slice(3, 5);
        const defaultDeliverTime = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          Number(partnerTimeBeginHours),
          Number(partnerTimeBeginMinutes)
        );
        addDeliveredAtDateTime(basketId, defaultDeliverTime);
      } else {
        // устанавливаем время заказа на открытие завтра, если партнер уже закрылся
        const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        const partnerTimeBeginHours = schedule.begin.slice(0, 2);
        const partnerTimeBeginMinutes = schedule.begin.slice(3, 5);
        const defaultDeliverTime = new Date(
          tomorrow.getFullYear(),
          tomorrow.getMonth(),
          tomorrow.getDate(),
          Number(partnerTimeBeginHours),
          Number(partnerTimeBeginMinutes)
        );
        addDeliveredAtDateTime(basketId, defaultDeliverTime);
      }
    }
  }, [isWorkingTime, basketPartnerId, schedule, deliverAtDatetime]);

  return {};
}
