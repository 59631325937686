import { useState } from 'react';
import { useRouter } from 'next/router';
import clsx from "clsx";

import CatalogPageSortPopupProps from "./CatalogPageSortPopup.props";
import styles from "./CatalogPageSortPopup.module.css";
import useHtmlAttributes from "~/hooks/useHtmlAttributes";
import Link from 'next/link';

export const CatalogPageSortPopup = ({
  handleSelectTag = () => null,
  ...props
}: CatalogPageSortPopupProps): JSX.Element => {
  const sortList = [
    {
      id: 'recommend',
      name: 'Рекомендуемые',
    },
    {
      id: 'highRating',
      name: 'С высоким рейтингом',
    },
    {
      id: 'fastDelivery',
      name: 'Быстрые',
    },
    {
      id: 'expensiveFirst',
      name: 'Дорогие',
    },
    {
      id: 'cheapFirst',
      name: 'Недорогие',
    },
    {
      id: 'aroundClockDelivery',
      name: 'Круглосуточная доставка',
    },
  ];

  const { devices } = useHtmlAttributes();
  const query = useRouter().query;
  const querySort = (query.sort as string) ?? "";
  const filter = query.filter;

  const [activeItem, setActiveItem] = useState<string>(querySort);

  if (!devices) return <div></div>;

  return (
    <>
      <div className={clsx(styles.popupWrapper, {
        [styles.desktopPopupWrapper]: devices.isDesktop
      })} {...props}>
        {sortList.map((item) => (
          <Link
            href={`/?categoryId=${query.categoryId}&filter=${filter ? filter : "Все"}&sort=${item.id}`}
            passHref
            key={item.id}
          >
            <div
              key={item.id}
              className={styles.addressItem}
              onClick={() => {
                handleSelectTag();
                setActiveItem(item.id);
              }}
            >
              <span className={styles.address}>
                <span className={clsx(styles.circle, {
                  [styles.selectCircle]: item.id.toLowerCase() === activeItem.toLowerCase()
                })}></span>
                <span className={styles.addressStreet}>{item.name}</span>
              </span>
            </div>
          </Link>
        ))}

      </div>
    </>
  );
};
