import { useRef, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";

import { BannerDesktop, RenderIfVisible } from "~/components";
import PromotionsSliderDesktopProps from "./PromotionsSliderDesktop.props";
import { Promotion } from "~/models/promotions";
import styles from "./PromotionsSliderDesktop.module.css";
import Arrow from "~/assets/icons/nav-arrow-right.svg";

import { useResizeObserver } from '~/hooks/useResizeObserver';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";

const paginationOther = {
    bulletClass: styles.bulletClass,
    bulletActiveClass: styles.bulletActiveClass,
    clickable: true,
};

export const PromotionsSliderDesktop = ({ promotions = [], categoryClass }: PromotionsSliderDesktopProps): JSX.Element => {
    const router = useRouter();
    const path: boolean = router.pathname === '/';

    const [pagination, setPagination] = useState<HTMLElement | null>(null);
    const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
    const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

    const prevSlides = (node: HTMLElement | null) => setPrevEl(node);
    const nextSlides = (node: HTMLElement | null) => setNextEl(node);

    const handlePagination = (node: HTMLElement | null) => setPagination(node);

    const [slidesCount, setSlidesCount] = useState<number>(promotions?.length);
    const ref = useRef<HTMLDivElement>(null);
    const resizeCallback = ({ width: containerWidth }: DOMRectReadOnly) => {
        if (ref && ref.current) {
            const countMaxWidth = 208 + 26;
            const countMinWidth = 176 + 26;
            const countCurrentWidth = Math.floor(containerWidth / slidesCount);
            if (countCurrentWidth <= countMaxWidth && countCurrentWidth >= countMinWidth) { // ширина баннера в допустимом диапазоне
                setSlidesCount(() => Math.round(slidesCount));
            }
            if (countCurrentWidth < countMinWidth) { // ширина баннера меньше минимально допустимой ширины
                setSlidesCount(() => Math.floor(containerWidth / countMinWidth));
            }
            if (countCurrentWidth > countMaxWidth) { // ширина баннера больше максимально допустимой ширины
                setSlidesCount(() => Math.ceil(containerWidth / countMaxWidth));
            }

        }
    };

    useResizeObserver(ref, resizeCallback);

    // Swiper options
    const paginationOptions = { ...paginationOther, el: pagination };
    const navigationOptions = { prevEl, nextEl };
    // const isNotResizeSlide = promotions.length === slidesCount;

    // useEffect(() => {
    //     const slides = document.querySelectorAll(`.swiper-slide`);
    //     if (isNotResizeSlide) {
    //         slides.forEach(element => {
    //             return element.classList.add("swiper-slide__not-resize");
    //         });
    //     } else {
    //         slides.forEach(element => {
    //             return element.classList.remove("swiper-slide__not-resize");
    //         });
    //     }
    // }, [isNotResizeSlide]);

    // useEffect(() => {
    //     const slides = document.querySelectorAll(`.Banner-slider`);
    //     slides && slides.forEach((slide: any) => {
    //         slide.style.width = `176px`
    //         slide.style.height = `176px`
    //     });
    // }, [promotions]);
    if (!promotions) return <div></div>;

    return (
        <>
            <div ref={ref} className={clsx(styles.wrapper, categoryClass, "fix-blink", {
                [styles.marginBottom]: promotions.length <= slidesCount,
            })}
            >
                <button
                    className={clsx(styles.sliderBtnPrev, {
                        [styles.sliderBtnPrevPartner]: !path,
                        [styles.visibleSliderBtn]: promotions.length > slidesCount
                    })}
                    ref={prevSlides}
                >
                    <Arrow />
                </button>
                <Swiper
                    spaceBetween={16}
                    slidesPerView={slidesCount}
                    slidesPerGroup={slidesCount}
                    navigation={navigationOptions}
                    modules={[Pagination, Navigation]}
                    pagination={paginationOptions}
                    className={styles.sliderWrapper}
                    autoHeight={false}
                >
                    {promotions.map((Banner: Promotion) => {
                        return (
                            <SwiperSlide
                                id={`slide-${Banner.id}`}
                                key={Banner.id}
                                tabIndex={-1}
                                aria-hidden="true"
                            // style={{ flex: "1 1 auto" }}
                            >
                                <RenderIfVisible key={Banner.id} visibleOffset={0}>
                                    <BannerDesktop data={Banner} notResize={false} />
                                </RenderIfVisible>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <button
                    className={clsx(styles.sliderBtnNext, {
                        [styles.sliderBtnNextPartner]: !path,
                        [styles.visibleSliderBtn]: promotions.length > slidesCount
                    })}
                    ref={nextSlides}
                >
                    <Arrow />
                </button>
            </div>
            <div
                ref={handlePagination}
                className={clsx(styles.pagination, {
                    [styles.visiblePagination]: promotions.length > slidesCount
                })}
            >
            </div>
        </>
    );
};
