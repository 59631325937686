
import { useRouter } from 'next/router';
import { TitleMobile } from '~/components';
import { usePartnerInfo } from '~/hooks/partner';
import styles from "./AboutPartner.module.css";

const AboutPartner = () => {
  const router = useRouter();
  const partnerId = router.query.id as string;
  const { isError, isLoading, data: partner } = usePartnerInfo(partnerId);

  if (isError || isLoading) return <></>;

  const partnerTimeBeginHours = partner?.schedule.begin.slice(0, 2);
  const partnerTimeBeginMinutes = partner?.schedule.begin.slice(3, 5);
  const partnerTimeEndHours = partner?.schedule.end.slice(0, 2);
  const partnerTimeEndMinutes = partner?.schedule.end.slice(3, 5);

  return (
    <>
      <TitleMobile headerType='h4'>{partner?.name}</TitleMobile>
      <p>{partner?.fullDescription}</p>
      <div className={styles.deliveryInfo}>
        <div>Режим работы:</div>
        <div>
          {partner?.isRoundTheClock ? "круглосуточно" :
            `  с ${partnerTimeBeginHours}:${partnerTimeBeginMinutes} до${" "}
                ${partnerTimeEndHours}:${partnerTimeEndMinutes}`
          }
        </div>
      </div>
    </>
  );
};
export default AboutPartner;