import { useModal } from "react-modal-hook";
import { AdditivesModalDesktop } from '~/components/modals/AdditivesModalDesktop';
import { AdditivesModalMobile } from '~/components/modals/AdditivesModalMobile';
import { PartnerProduct } from "~/models/partners";

export default function useAdditivesModal(
  product: PartnerProduct,
  isMobileModal = false
) {
  const [showModal, hideModal] = useModal(({ in: open, onExited }) => {
    return isMobileModal ? (
      <AdditivesModalMobile
        product={product}
        onExit={onExited}
        isOpen={open}
        hideModal={hideModal}
      />
    ) : (
      <AdditivesModalDesktop
        product={product}
        onExit={onExited}
        isOpen={open}
        hideModal={hideModal}
      />
    )
  });

  return {
    showModal,
    hideModal,
  };
}
