import clsx from "clsx";

import { ButtonDesktop, Counter } from '~/components';
import CutleryCounterProps from "./CutleryCounter.props";
import styles from "./CutleryCounter.module.css";
import CrockeryIcon from "~/assets/icons/crockery.svg";
import PlusIcon from "~/assets/icons/plus.svg";
import { useBasketContext } from '~/hooks/basket/useBasket.hook';


export const CutleryCounter = ({
  className,
  basket,
  ...props
}: CutleryCounterProps): JSX.Element => {
  const { setCutlery } = useBasketContext();

  const handlePlus = async () => {
    try {
      await setCutlery(basket.cutleryCount + 1);
    } catch (e) {
      console.log("Ошибка добавления приборов", e);
    }
  };

  const handleMinus = async () => {
    try {
      await setCutlery(basket.cutleryCount - 1);
    } catch (e) {
      console.log("Ошибка добавления приборов", e);
    }
  };

  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <div className={styles.text}>
        <CrockeryIcon className={styles.crockeryIcon} />
        Приборы
      </div>
      {basket.cutleryCount > 0 ? (
        <Counter
          className={styles.cutleryCounter}
          onPlus={handlePlus}
          onMinus={handleMinus}
          value={basket.cutleryCount}
        />
      ) : (
        <ButtonDesktop
          size='s'
          appearance='gray'
          className={clsx(styles.button, "buttonAddToCart")}
          onClick={handlePlus}
        >
          <PlusIcon className={styles.plusIcon} />
          <span>
            Добавить
          </span>
        </ButtonDesktop>
      )}
    </div>
  );
};
