import { PropsWithChildren } from "react";
import clsx from "clsx";

import DeleteCircledIcon from "~/assets/icons/delete-circled-outline.svg";
import ButtonCancelOrderDesktopProps from "./ButtonCancelOrderDesktop.props";
import styles from "./ButtonCancelOrderDesktop.module.css";

import { ButtonDesktop } from '../ButtonDesktop';
import { useCancelOrder } from '~/hooks/order';
import useConfirmModal from '~/hooks/useConfirmModal';
import useTimer from '~/hooks/useTimer';

export const ButtonCancelOrderDesktop = ({
  orderId,
  dateTime,
  className,
  ...props
}: PropsWithChildren<ButtonCancelOrderDesktopProps>): JSX.Element => {

  const { handleCanceledOrder } = useCancelOrder();

  const { isStopTimer } = useTimer(295, dateTime);

  const [ showConfirmModal, hideConfirmModal ] = useConfirmModal({
    onConfirm: () => handleCanceledOrder(orderId),
    onCancel: () => hideConfirmModal(),
    width: 300,
    title: "Отменить заказ?",
    message: '',
    dependencies: [orderId]
  });

  if (isStopTimer) return <></>;

  return (
    <ButtonDesktop
      size='m'
      type='button'
      appearance='gray'
      className={clsx(styles.button, className)}
      onClick={showConfirmModal}
      {...props}
    >
      <DeleteCircledIcon className={styles.buttonIcon} />
      ОТМЕНИТЬ ЗАКАЗ
    </ButtonDesktop>
  );
};
