import clsx from "clsx";

import { Popup } from "~/components";
import BanknoteTypesPopupProps from "./BanknoteTypesPopup.props";
import styles from "./BanknoteTypesPopup.module.css";
import CheckIcon from "~/assets/icons/check.svg";

import { IBanknote } from '~/hooks/user/useSelectBanknotes';
import { useBasketContext } from "~/hooks/basket/useBasket.hook";

export const BanknoteTypesPopup = ({
  isOpen,
  hidePopup,
  onExit,
  elementId,
  className,
  ...props
}: BanknoteTypesPopupProps): JSX.Element => {
  const { banknotes, changeFromBanknote, handleChangeBanknote } = useBasketContext();

  const handelChangePayment = (item: IBanknote) => {
    handleChangeBanknote(item);
    hidePopup();
  };

  return (
    <Popup
      isOpen={isOpen}
      onExit={onExit}
      hidePopup={hidePopup}
      elementId={elementId}
    >
      <div className={clsx(styles.wrapper, className)} {...props}>
        <div className={styles.paymentsList}>
          {banknotes.map((item) => {
            const { id, type } = item;

            return (
              <div
                key={id}
                className={clsx(styles.payment, {
                  [styles.active]: id === changeFromBanknote?.id
                })}
                onClick={() => handelChangePayment(item)}
              >
                <span className={styles.paymentText}>
                  {type} ₽
                </span>
                <span>
                  <CheckIcon className={styles.checkIcon} />
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </Popup>
  );
};
