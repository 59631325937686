import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from "react";
import { Navigation, Pagination, Autoplay } from "swiper";
import ArrowRight from "~/assets/icons/nav-arrow-right.svg";
import ArrowLeft from "~/assets/icons/nav-arrow-right.svg";
import SliderProps from "./Slider.props";
import styles from "./Slider.module.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import "swiper/css";
import useHtmlAttributes from "~/hooks/useHtmlAttributes";

export const Slider = ({
  children,
  wrapperClass,
  spaceBetween = 24,
  sliderWidth = 0,
  colorHide = "#fff",
  ...props
}: SliderProps): JSX.Element | null => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  const { devices } = useHtmlAttributes();

  return children ? (
    <div
      className={clsx(styles.slider, wrapperClass, {
        [styles.sliderDesktop]: devices?.isDesktop,
        [styles.sliderWhite]: colorHide === "#fff",
        [styles.sliderLite]: colorHide === "#f8f8f8",
      })}
    >
      <button
        className={clsx(styles.prevButton, {
          [styles.hiddenButton]: !devices?.isDesktop,
        })}
        ref={(node) => setPrevEl(node)}
      >
        <ArrowLeft />
      </button>
      <Swiper
        navigation={{
          prevEl,
          nextEl,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        className={styles.safeArea}
        spaceBetween={spaceBetween}
        {...props}
      >
        {Array.isArray(children) ? (
          children.map((slide, index) => {
            return (
              <SwiperSlide
                tabIndex={-1}
                aria-hidden="true"
                key={index}
                style={{ width: !sliderWidth ? "auto" : `${sliderWidth}px` }}
              >
                {slide}
              </SwiperSlide>
            );
          })
        ) : (
          <SwiperSlide
            tabIndex={-1}
            aria-hidden="true"
            style={{ width: !sliderWidth ? "auto" : `${sliderWidth}px` }}
          >
            {children}
          </SwiperSlide>
        )}
      </Swiper>
      <button
        className={clsx(styles.nextButton, {
          [styles.hiddenButton]: !devices?.isDesktop,
        })}
        ref={(node) => setNextEl(node)}
      >
        <ArrowRight />
      </button>
    </div>
  ) : null;
};
