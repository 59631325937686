import {
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";
import { NIL as NIL_UUID, v4 as uuid } from "uuid";
import { isEqual } from "lodash";
import getConfig from "next/config";

import { confirm } from "~/components/modals/ConfirmHocModal";
import { confirmBottomSheet } from "~/components/modals/ConfirmHocBottomSheet";
import { BasketWithProducts, Product } from "~/models/basket";

import {
  addProduct,
  decreaseProduct,
  removeProduct,
  removeBasket,
  addCutlery,
  addDeliverAtDatetime,
} from "~/plugins/api/basket";
import { getCookie, removeCookie, setCookie } from "~/helpers/cookieUtilites";
import useShowChildren from '../useShowChildren';
import { getDeviceType } from '~/helpers/getDeviceType';
import { usePartnerInfo } from "../partner";
import useSelectPay, { IPaymentType } from "../user/useSelectPay";
import useToken from "../user/useToken";
import useSelectBanknotes, { IBanknote } from "../user/useSelectBanknotes";

interface IBasketMethodsArgs {
  product: Product;
  partnerId: string;
  marketCategoryId: number;
  quantity: number;
}

interface IContextProps {
  tokenForMetrics: string | undefined;
  basket: BasketWithProducts | null;
  loadingBasket: boolean,
  addToBasket: (args: IBasketMethodsArgs) => Promise<BasketWithProducts> | unknown;
  removeFromBasket: (
    args: IBasketMethodsArgs
  ) => Promise<BasketWithProducts> | unknown;
  clearBasket: () => void;
  addDeliveredAtDateTime: (
    basketId: string,
    deliverAtDatetime: Date | null
  ) => void;
  setCutlery: (quantity: string | number) => void;
  handleSetBasket: (basket: BasketWithProducts | null) => void;
  payments: IPaymentType[] | undefined;
  cardsList: IPaymentType[];
  activePayment: IPaymentType | null;
  handleSetPaymentType: (payment: IPaymentType) => void;
  banknotes: IBanknote[];
  changeFromBanknote: IBanknote | null;
  handleChangeBanknote: (banknote: IBanknote | null) => void;
}

const BasketContext = createContext<IContextProps>({
  tokenForMetrics: undefined,
  basket: null,
  loadingBasket: false,
  handleSetBasket: () => null,
  addToBasket: () => null,
  removeFromBasket: () => null,
  clearBasket: () => null,
  setCutlery: () => null,
  addDeliveredAtDateTime: () => null,
  cardsList: [],
  payments: [],
  activePayment: null,
  handleSetPaymentType: () => null,
  banknotes: [],
  changeFromBanknote: null,
  handleChangeBanknote: () => null,
});

const { publicRuntimeConfig } = getConfig();

const tokenForMetrics: string | undefined =
  publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER;

export function BasketProvider({ children }: { children: ReactNode }) {
  const [loadingBasket, setLoadingBasket] = useState(false);
  const [basket, setBasket] = useState<BasketWithProducts | null>(() => {
    setLoadingBasket(true);
    const basketToken = getCookie("cron-user-basket") as string ?? null;
    try {
      const parsedBasket = JSON.parse(basketToken);
      setLoadingBasket(false);
      return parsedBasket;
    } catch (e) {
      console.warn("Ошибка парсинга корзины !", e);
      removeCookie("cron-user-basket");
      setLoadingBasket(false);
      return null;
    }
  });

  // useIsomorphLayoutEffect(() => {
  //   const basketToken = getCookie("cron-user-basket") as string ?? null;
  //   if (!basketToken) return;
  //   try {
  //     const parsedBasket = JSON.parse(basketToken);
  //     setBasket(parsedBasket)
  //   } catch (e) {
  //     console.warn("Ошибка парсинга корзины !", e);
  //     removeCookie("cron-user-basket");
  //     setBasket(null)
  //   }
  // }, []);

  const addToBasket = async ({
    product,
    quantity,
    partnerId,
    marketCategoryId,
  }: IBasketMethodsArgs) => {
    setLoadingBasket(true);
    const canAddToBasket = partnerId === basket?.partnerId || basket === null;

    let needCreateNewBasket = false;
    if (!canAddToBasket && basket?.content?.length) {
      const confirmWindow = getDeviceType(window.navigator.userAgent).isDesktop ?
        confirm : confirmBottomSheet;
      setLoadingBasket(false);
      await confirmWindow({
        confirmation:
          "В корзине есть продукты из другого партнёра. Очистить корзину ?",
      });
      needCreateNewBasket = true;
    }

    const allProuctsById =
      basket?.content.filter((p) => p.productId === product.productId) || [];

    const findedProduct = allProuctsById.find((p) => {
      const productAdditivesIds = p.additives.map((a) => a.id);
      const dateProductAdditivesIds = product.additives.map((a) => a.id);

      return isEqual(productAdditivesIds, dateProductAdditivesIds);
    });

    const additivesIds = product?.additives.map((it) => it?.id);

    const params = {
      basketId: needCreateNewBasket
        ? NIL_UUID
        : (basket && basket.id) || NIL_UUID,
      partnerId,
      marketCategoryId,
      id: findedProduct?.id,
      productId: product?.productId,
      additives: additivesIds,
    };

    const responseBasket = await addProduct(
      quantity
        ? {
          ...params,
          quantity: quantity,
        }
        : {
          ...params,
          quantity: 1,
        }
    );

    if (tokenForMetrics) {
      if (!responseBasket.content.length) return handleSetBasket(null);
      handleSetBasket(responseBasket);

      const result = responseBasket.content.map((item) => ({
        id: item.id,
        name: item.name,
        price: item.cost,
        brand: responseBasket.partnerName,
        category: responseBasket.marketCategoryId,
        quantity: item.quantity,
      }));

      dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          add: {
            products: result,
          },
        },
      });
    } else {
      handleSetBasket(responseBasket);
    }
    setLoadingBasket(false);
  };

  const removeFromBasket = async ({
    product,
    quantity,
    partnerId,
    marketCategoryId,
  }: IBasketMethodsArgs) => {
    setLoadingBasket(true);
    const additivesIds = product.additives.map((it) => it?.id);
    const actionProduct = quantity === 1 ? removeProduct : decreaseProduct;
    const responseBasket = await actionProduct({
      basketId: (basket && basket.id) || NIL_UUID,
      partnerId,
      marketCategoryId,
      id: product?.id as string,
      productId: product.productId,
      additives: additivesIds,
      quantity: quantity - 1,
    });

    if (tokenForMetrics) {
      if (!responseBasket.content.length) {
        dataLayer.push({
          ecommerce: {
            currencyCode: "RUB",
            remove: basket?.content,
          },
        });
        setLoadingBasket(false);
        return handleSetBasket(responseBasket);
      }

      const prevBasket = basket?.content;
      const currentBasket = responseBasket.content;

      let basketDeletedFromEccomerce: any = null;

      if (prevBasket && prevBasket.length) {
        basketDeletedFromEccomerce = prevBasket.filter((service) =>
          currentBasket.every((item) => item.id !== service.id)
        )[0];
      } else {
        basketDeletedFromEccomerce = currentBasket[0];
      }

      basketDeletedFromEccomerce &&
        dataLayer.push({
          ecommerce: {
            currencyCode: "RUB",
            remove: basketDeletedFromEccomerce,
          },
        });
      setLoadingBasket(false);
      return handleSetBasket(responseBasket);
    }
    setLoadingBasket(false);
    return handleSetBasket(responseBasket);
  };

  const handleSetBasket = (newBasket: BasketWithProducts | null) => {
    if (newBasket) {
      setBasket(newBasket);
      return setCookie({ name: "cron-user-basket", value: newBasket });
    } else {
      removeCookie("cron-user-basket");
      return setBasket(newBasket);
    }
  };

  const clearBasket = async () => {
    if (!basket?.content) return;
    try {
      setLoadingBasket(true);
      await removeBasket(basket?.id);
      await addDeliveredAtDateTime(basket.id, null);
    } catch(e) {
      console.log("Не удалось выполнить запрос на очистку корзины!", e)
      await addDeliveredAtDateTime(basket.id, null);
      handleSetBasket(null);
    } finally {
      setLoadingBasket(false);
    }
  };

  const setCutlery = async (quantity: string | number) => {
    const responseBasket = await addCutlery(quantity, basket?.id || NIL_UUID);
    handleSetBasket(responseBasket);
  };

  const addDeliveredAtDateTime = async (
    basketId: string,
    deliverAtDatetime: Date | null
  ) => {
    if(!basket) return;
    try {
      const responseBasket = await addDeliverAtDatetime(
        basketId,
        deliverAtDatetime
      );
      handleSetBasket(responseBasket);
    } catch(e) {
      console.log("Не удалось назначить время доставки!", e)
      handleSetBasket({
        ...basket,
        deliverAtDatetime: null
      });
    }
  };

  const show = useShowChildren();

  const { data: partner } = usePartnerInfo(basket?.partnerId ?? "");
  const user = useToken();

  const pay = useSelectPay(user.token?.accessToken, partner?.paymentMethods);
  const banknotes = useSelectBanknotes();

  return (
    <BasketContext.Provider
      value={{
        tokenForMetrics,
        basket,
        loadingBasket,
        handleSetBasket,
        addToBasket,
        removeFromBasket,
        clearBasket,
        setCutlery,
        addDeliveredAtDateTime,
        ...banknotes,
        ...pay,
      }}
    >
      {show ? children : <></>}
    </BasketContext.Provider>
  );
}

export function useBasketContext() {
  return useContext(BasketContext);
}