import { useEffect, useState } from "react";
import clsx from "clsx";

import PartnerClosePlateProps from "./PartnerClosePlate.props";
import styles from "./PartnerClosePlate.module.css";
import ClosePlateIcon from "~/assets/icons/shopping-store-signage.svg";
import useHtmlAttributes from '~/hooks/useHtmlAttributes';
import { partnerIsNotOpenYet, partnerIsWorking } from '~/helpers';
import { useRouter } from 'next/router';
import { usePartnerInfo } from '~/hooks/partner';

export const PartnerClosePlate = ({
  isPreeOrder,
  className,
  ...props
}: PartnerClosePlateProps): JSX.Element => {
  const { devices } = useHtmlAttributes()
  const router = useRouter()
  const { data: partner } = usePartnerInfo(router.query.id as string ?? "");

  const type = partner?.isStructure ? "Магазин" : "Ресторан";

  const [partnerIsWork, setPartnerIsWork] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    partner && setPartnerIsWork(() => partnerIsWorking(partner?.schedule));
  }, [partner]);

  if (partnerIsWork || !partner) return <></>

  return (
    <div
      className={clsx(styles.plate, {
        [styles.plateMobile]: !devices?.isDesktop || partner?.isStructure
      }, className)}
      {...props}
    >
      <ClosePlateIcon className={styles.icon} />

      <div className={clsx(styles.text, "clamp-2")}>
        {type} закрыт. Можно оформить предзаказ на {partnerIsNotOpenYet(partner?.schedule) ? "сегодня" : "завтра"}, с {partner?.schedule.begin.slice(0, 5)}
      </div>
      {/* {isPreeOrder ? (
      ) : (
        <div>{type} закрыт.</div>
      )} */}
    </div>
  );
};
