import { useModal } from "react-modal-hook";
import { DesktopMapModal, Modal } from '~/components';
import { UserAddressWithList } from '~/models';


interface IDesktopMapModal {
  isEditAddress?: UserAddressWithList | null;
  succesCallBack?: () => void;
  dependencies?: any[]
}

export default function useDesktopMapModal({
  isEditAddress = null,
  succesCallBack = () => null,
  dependencies = []
}: IDesktopMapModal = {}) {
  const [showDesktopMapModal, hideDesktopMapModal] = useModal(({ in: open, onExited }) => {
    return (
      <Modal width={950} headline='Адрес доставки' align='left' isOpen={open} hideModal={hideDesktopMapModal} onExit={onExited}>
        <DesktopMapModal
          isEditAddress={isEditAddress}
          hideModal={hideDesktopMapModal}
          onSuccesAddress={succesCallBack}
        />
      </Modal>
    );
  }, [...dependencies, isEditAddress]);

  return {
    showDesktopMapModal,
    hideDesktopMapModal,
  };
}
