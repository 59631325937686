import { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import clsx from "clsx";

import AuthViewDesktopProps from "./AuthViewDesktop.props";
import styles from "./AuthViewDesktop.module.css";
import { AuthPhone } from './AuthPhone';
import { AuthCode } from './AuthCode';
import { AuthNickName } from './AuthNickName';
import { useUserContext } from '~/hooks/user/useUser.hook';

export const AuthViewDesktop = ({
  onSuccessAuth = () => null,
  isOrderingScreen = false,
  className,
  ...props
}: AuthViewDesktopProps): JSX.Element => {
  const { user, userName, loggedIn } = useUserContext();
  const [phone, setPhone] = useState("");
  const [activeStage, setActiveStage] = useState(() => userName.length < 2 && loggedIn ? 2 : 0);

  useEffect(() => {
    if (user?.phone.length) {
      setPhone(user.phone);
    }
  }, [user?.phone]);

  return (
    <div
      className={clsx(styles.wrapper, className)}
      {...props}
    >
      <SwitchTransition mode="out-in">
        <CSSTransition
          unmountOnExit
          key={activeStage}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames={{ ...styles }}
        >
          <>
            {activeStage === 0 ? (
              <div className={clsx({ [styles.authStage]: !isOrderingScreen })}>
                <AuthPhone
                  isOrderingScreen={isOrderingScreen}
                  disabled={!!user?.phone.length}
                  initPhone={phone} getPhone={setPhone}
                  setActiveStage={setActiveStage}
                />
              </div>
            ) : activeStage === 1 ? (
              <div className={clsx({ [styles.authStage]: !isOrderingScreen })}>
                <AuthCode
                  phone={phone}
                  isOrderingScreen={isOrderingScreen}
                  onSuccessAuth={onSuccessAuth}
                  setActiveStage={setActiveStage}
                />
              </div>
            ) : (
              <div className={clsx({ [styles.authStage]: !isOrderingScreen })}>
                <AuthNickName onSuccessAuth={() => isOrderingScreen ? setActiveStage(0) : onSuccessAuth()} isOrderingScreen={isOrderingScreen} />
              </div>
            )}
          </>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
