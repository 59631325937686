import Link from "next/link";
import clsx from "clsx";

import { CitySelectMobile, UserAuthMobile } from "~/components";
import styles from "./HeaderMobile.module.css";
import SearchIcon from "~/assets/icons/search.svg";

export const Content = (): JSX.Element => {
  const searchMobile = (
    <div className={clsx(styles.search, styles.searchMobile)}>
      <Link
        href={{
          pathname: "/search",
          query: { query: "", type: "Рестораны" },
        }}
        passHref
      >
        <SearchIcon className={styles.searchIconLink} />
      </Link>
    </div>
  );

  const searchTablet = (
    <div className={clsx(styles.search, styles.searchTablet)}>
      <Link
        href={{
          pathname: "/search",
          query: { query: "", type: "Рестораны" },
        }}
        passHref
      >
        <div className={styles.wrapperSearch}>
          <SearchIcon className={styles.searchIcon} />
          <div
            className={clsx("text", styles.field)}
          >
            Найти ресторан, магазин, блюдо, товар...
          </div>
        </div>
      </Link>
    </div>
  );

  return (
    <>
      <div className={styles.logoWrapper} />
      {searchMobile}
      {searchTablet}
      <CitySelectMobile className={styles.citySelectWrapper} />
      <UserAuthMobile />
    </>
  );
};
