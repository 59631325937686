const getAddressInfo = (address: string | any, cityName: string) => {
  if (!address) return null;

  const arr = address.split(", ");
  if (arr.length > 2) {
    const city = cityName;
    const localities = arr[0];
    const street = arr[1];
    const house = arr[2];

    return {
      city,
      localities,
      street,
      house,
    };
  }

  const city = cityName;
  const localities = "";
  const street = arr[0];
  const house = arr[1];

  return {
    city,
    localities,
    street,
    house,
  };
};

export default getAddressInfo;
