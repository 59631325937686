import { useModal } from 'react-modal-hook';
import clsx from "clsx";
import Image from 'next/legacy/image';

import { ButtonMobile, PaymentMethodBottomSheet } from "~/components";
import SelectPaymentMethodMobileProps from "./SelectPaymentMethodMobile.props";
import styles from "./SelectPaymentMethodMobile.module.css";
import ArrowIcon from "~/assets/icons/arrow-right-black.svg";
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

export const SelectPaymentMethodMobile = ({
  className,
  onlyCash,
  onlyCard,
  ...props
}: SelectPaymentMethodMobileProps): JSX.Element => {
  const { activePayment, payments } = useBasketContext();
  const tooltip = onlyCash ? 'наличными' : onlyCard ? 'онлайн оплату' : null;
  const onlyOnePaymentMethods = onlyCard || onlyCash;

  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => {
      return <PaymentMethodBottomSheet isOpen={open} hideModal={hideModal} onExit={onExited} />
    }
  );

  return (
    <ButtonMobile
      pressed={false}
      id='payment'
      appearance='text'
      size='l'
      type='button'
      className={clsx(styles.buttonSelectPayment, className)}
      onClick={showModal}
      disabled={onlyCash}
      {...props}
    >
      <span
        className={styles.paymentIcon}
      >
        <Image
          width={45}
          height={30}
          alt={activePayment?.key}
          src={activePayment?.icon ?? ""}
        />
      </span>
      <span className={styles.paymentText}>
        {activePayment?.name}
      </span>
      {onlyOnePaymentMethods &&
        <span className={styles.tooltip}>
          Партнер принимает
          только {tooltip}
        </span>
      }
      {
        payments && payments.length > 1 &&
          <>
            <span>
              <ArrowIcon className={styles.arrowIcon} />
            </span>
          </>
      }
    </ButtonMobile>
  );
};
