import { useModal } from "react-modal-hook";
import clsx from "clsx";

import { ButtonDesktop } from '../ButtonDesktop';
import { TimePickerPopup } from '~/components/modals/TimePickerPopup';
import ButtonTimeSelectDesktopProps from "./ButtonTimeSelectDesktop.props";
import styles from "./ButtonTimeSelectDesktop.module.css";

import convertTime from "~/helpers/convertTime";
import { deliverIsTommorow } from '~/helpers/deliveryDateTime';
import useSetDeliveryAtDateTime from '~/hooks/useSetDeliveryAtDateTime';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { usePartnerInfo } from '~/hooks/partner';

export const ButtonTimeSelectDesktop = ({
  addoneBefore,
  addoneAfter,
  appearance = 'text',
  elementId = "picker",
  className,
  ...props
}: ButtonTimeSelectDesktopProps): JSX.Element => {
  const { basket } = useBasketContext();
  const { data: partner } = usePartnerInfo(basket?.partnerId ?? "");

  useSetDeliveryAtDateTime(partner);

  const [showDeliveryAtTime, hideDeliveryAtTime] = useModal(
    ({ in: open, onExited }) => {
      return partner ? (
        <TimePickerPopup
          onExit={onExited}
          isOpen={open}
          hideModal={hideDeliveryAtTime}
          elementId={elementId}
          schedule={partner?.schedule}
          handlerSaveTime={hideDeliveryAtTime}
          isRoundTheClock={partner.isRoundTheClock}
        />
      ) : <></>
    },
    [partner]
  );

  return (
    <ButtonDesktop
      id={elementId}
      size='s'
      appearance={appearance}
      className={clsx(styles.buttonTimePicker, className)}
      onClick={showDeliveryAtTime}
      {...props}
    >
      {addoneBefore ? <span className={clsx(styles.addone, styles.addoneBefore)}>{addoneBefore}</span> : null}
      <span className={styles.time}>
        {basket?.deliverAtDatetime !== null ? (
          `
          ${deliverIsTommorow(
            basket?.deliverAtDatetime
          )
            ? "завтра "
            : "сегодня "}
          ${convertTime(basket?.deliverAtDatetime)}
          `
        ) : (
          `${partner?.avgDeliveryTime?.begin} ~ ${partner?.avgDeliveryTime?.end} мин.`
        )}
      </span>
      {addoneAfter ? <div className={clsx(styles.addone, styles.addoneAfter)}>{addoneAfter}</div> : null}
    </ButtonDesktop>
  );
};
