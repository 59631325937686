import { useRouter } from 'next/router';
import clsx from 'clsx';

import RatingStar from "~/assets/icons/ratingStar.svg";
import RatingSum from "~/assets/icons/ratingSum.svg";
import RatingBad from "~/assets/icons/ratingBad.svg";
import RatingGood from "~/assets/icons/ratingGood.svg";
import RatingFeedbackStar from "~/assets/icons/ratingFeedbackStar.svg";
import styles from "./PartnerRating.module.css";

import convertDate from '~/helpers/convertDate';
import convertTime from '~/helpers/convertTime';
import { usePartnerInfo, usePartnerRating } from '~/hooks/partner';
import { LoadingIndicator } from '~/components/LoadingIndicator';

const PartnerRating = () => {
  const router = useRouter();
  const partnerId = router.query.id as string;
  const { data: partner } = usePartnerInfo(partnerId);
  const { isLoading, data: rating } = usePartnerRating(partnerId);

  return (
    <div >
      <div className={styles.ratingHeader}>
        <div className={styles.ratingHeaderItems}>
          <div className={styles.ratingHeaderItem}>
            <div className={styles.ratingHeaderItemTitle}>
              Рейтинг заведения
            </div>
            <div className={styles.ratingHeaderSubItem}>
              <RatingStar className={styles.ratingIconTitle} />
              <div className={styles.ratingHeaderValue}>
                {partner?.rating}
              </div>
            </div>
          </div>
          <div className={styles.ratingHeaderItem}>
            <div className={styles.ratingHeaderItemTitle}>
              Количество отзывов
            </div>
            <div className={styles.ratingHeaderSubItem}>
              <RatingSum className={styles.ratingIconTitle} />
              <div className={styles.ratingHeaderValue}>
                {partner?.feedbackCount}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.ratingScroll}>
        {!rating || isLoading ? <LoadingIndicator /> : (
          <ul className={styles.ratingBody}>
            {rating?.feedbacks.map((feedback) =>
              feedback.negativeText || feedback.positiveText ? (
                <li className={styles.ratingFeedback} key={feedback.id}>
                  <div className={styles.ratingFeedbackTop}>
                    <div className={styles.ratingFeedbackDate}>
                      <span>{convertDate(feedback?.createDateTime)}</span>
                      <span>{convertTime(feedback?.createDateTime)}</span>
                    </div>
                    <div className={styles.ratingStars}>
                      <RatingFeedbackStar
                        className={clsx({
                          [styles.ratingFeedbackFilled]: feedback.mark >= 1,
                        })}
                      />
                      <RatingFeedbackStar
                        className={clsx({
                          [styles.ratingFeedbackFilled]: feedback.mark >= 2,
                        })}
                      />
                      <RatingFeedbackStar
                        className={clsx({
                          [styles.ratingFeedbackFilled]: feedback.mark >= 3,
                        })}
                      />
                      <RatingFeedbackStar
                        className={clsx({
                          [styles.ratingFeedbackFilled]: feedback.mark >= 4,
                        })}
                      />
                      <RatingFeedbackStar
                        className={clsx({
                          [styles.ratingFeedbackFilled]: feedback.mark >= 5,
                        })}
                      />
                    </div>
                  </div>
                  <div className={styles.ratingFeedbackItems}>
                    {feedback.positiveText ? (
                      <div className={styles.ratingFeedbackItem}>
                        <RatingGood className={styles.ratingIcon} />
                        <div className={styles.ratingFeedbackText}>
                          {feedback.positiveText}
                        </div>
                      </div>
                    ) : null}
                    {feedback.negativeText ? (
                      <div className={styles.ratingFeedbackItem}>
                        <RatingBad className={styles.ratingIcon} />
                        <div className={styles.ratingFeedbackText}>
                          {feedback.negativeText}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </li>
              ) : null
            )}
          </ul>
        )}

      </div>
    </div>
  );
};
export default PartnerRating;