import { useState, useEffect, useCallback } from 'react';
import styles from './BannerMobile.module.css';
import Image from 'next/legacy/image';
import BannerMobileProps from './BannerMobile.props';
import Link from "next/link";
import { useRouter } from 'next/router';
import { getPromotionInformation } from "~/plugins/api";
import { Partner } from "~/models/partners";
import clsx from 'clsx';

interface PromotionInfo {
    id: string;
    promoCode: string | null;
    promotionName: string;
    description: string | null;
}

export const BannerMobile = ({ data }: BannerMobileProps): JSX.Element => {
    const router = useRouter();
    const isHomePage = router.pathname === '/';

    const { image, description, promotionId, promoCode, partnerId, partnerName, partnerIsStructure } = data;
    const [promotion, setPromotion] = useState<PromotionInfo | undefined>(undefined);
    const [partners, setPartners] = useState<Partner[] | undefined>(undefined);
    const { promotionName } = promotion || {};

    const link = () => {
        if (partnerId) {
            if (partnerIsStructure) {
                return `/retail/${partnerId}?name=${partnerName}&category=Товары со скидкой&categoryId=promo`;
            }
            return `/partner/${partnerId}?name=${partnerName}`;
        }
        return `/promotions/${promotionId}`;
    };

    useEffect(() => {
        let isIgnoreResponse = false;
        if (!promotionId) return;
        const fetchPromotionInfo = async () => {
            const res = await getPromotionInformation({ promotionId });
            if (!isIgnoreResponse) {
                setPromotion(res.promotion);
                setPartners(res.partners);
            }
        };

        fetchPromotionInfo();

        return () => {
            isIgnoreResponse = true;
        };
    }, [promotionId]);

    const [pressedTag, setPressedTag] = useState(false);

    return (
        <>
            {isHomePage && (promotionId || partnerId) ? (
                <Link href={link()}>
                    <div
                        className={clsx(styles.mobileBanner, {
                            [styles.pressed]: pressedTag
                        })}
                        onTouchStart={() => setPressedTag(true)}
                        onTouchEnd={() => setTimeout(() => setPressedTag(false), 150)}
                    >
                        <div className={styles.backgroundImg}>
                            <Image
                                priority
                                width={133}
                                height={133}
                                alt={image}
                                src={image}
                                layout="fill"
                                objectFit="cover"
                            />
                        </div>
                        {partners && partners?.length === 1 ? (
                            partners?.map((partner: Partner) => {
                                return (
                                    <div key={`logo${partner.id}`} className={styles.mobileLogo}>
                                        <Image
                                            unoptimized
                                            key={partner.id}
                                            className={styles.mobileLogo}
                                            width={75} height={29}
                                            alt={partner.name || undefined}
                                            layout="fill"
                                            objectFit="cover"
                                            src={String(partner.logo)}
                                        />
                                    </div>
                                );
                            })
                        ) : null}
                        {promotionName && <div className={styles.mobileTitle}>{promotionName}</div>}
                        {promoCode && partners && partners?.length > 1 ? <div className={styles.promoCode}>{promoCode}</div> : null}
                    </div>
                </Link>
            ) : (
                <>
                    <div
                        className={styles.mobileBanner}
                    >
                        <div className={styles.backgroundImg}>
                            <Image
                                priority
                                alt={image}
                                width={133}
                                height={133}
                                layout="fill"
                                objectFit="cover"
                                src={image}
                            />
                        </div>
                        {promotionName && <div className={styles.mobileTitle}>{promotionName}</div>}
                        {promoCode && <div className={styles.promoCode}>{promoCode}</div>}
                    </div>
                </>
            )}
        </>
    );
};
