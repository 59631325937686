import clsx from "clsx";
import Logo from "~/assets/svg/logo.svg";
import WhatsAppIcon from "~/assets/icons/whatsapp.svg";
import VKontakte from "~/assets/icons/vk.svg";
import AppStoreIcon from "~/assets/svg/app-store.svg";
import InstagramIcon from "~/assets/icons/instagram.svg";
import GooglePlayIcon from "~/assets/svg/google-play.svg";
import FooterProps from "./Footer.props";
import styles from "./Footer.module.css";
import Link from "next/link";
import useHtmlAttributes from '~/hooks/useHtmlAttributes';
import PhoneFieldIcon from "~/assets/icons/phone-field.svg";
import { useGetSupportPhoneNumber } from '~/hooks/systemInfo';

export const Footer = ({ className, ...props }: FooterProps): JSX.Element => {
  const { devices } = useHtmlAttributes();
  const { data: supportPhoneNumber } = useGetSupportPhoneNumber();

  return (
    <footer className={clsx(styles.footer, className)} {...props}>
      <div className={clsx(styles.content, {
        [styles.desktopContent]: devices?.isDesktop || devices?.isTablet
      })}>
        <div className={styles.leftCol}>
          <Link href={"/"}>
            <Logo className={styles.logo} />
          </Link>
          <div className={styles.colLinks}>
            {/* <Link href="/becomePartner">
              <a className={styles.infoLink}>Стать партнером</a>
            </Link> */}
            {/* <Link href="/courier">
              <a className={styles.infoLink}>Стать курьером</a>
            </Link> */}
            {/* <a href="#" className={styles.infoLink}>
              О нас
            </a> */}
          </div>
          {/* <a href="#" className={styles.infoLink}>
            Политика обработки персональных данных
          </a> */}
        </div>

        <div className={styles.centerCol}>
          <h2 className="h5">Мы в соц. сетях</h2>
          <div className={styles.socLinks}>
            <a className={styles.socLink} href="https://wa.clck.bar/79894970664">
              <WhatsAppIcon />
              <span className={styles.socLinkText}>написать в whatsapp</span>
            </a>
            <a
              className={styles.socLink}
              target="_blank"
              href="https://vk.com/cronmarket"
              rel="noreferrer"
            >
              <VKontakte />
              <span className={styles.socLinkText}>cronmarket</span>
            </a>
            <a
              className={styles.socLink}
              target="_blank"
              href="https://www.instagram.com/cronmarket.ru/?hl=ru"
              rel="noreferrer"
            >
              <InstagramIcon />
              <span className={styles.socLinkText}>@cronmarket.ru</span>
            </a>
            <a
              className={styles.socLink}
              href={`tel:${supportPhoneNumber?.callCenterPhoneNumber}`}
              rel="noreferrer"
            >
              <PhoneFieldIcon />
              <span className={styles.socLinkText}>Техподдержка</span>
            </a>
          </div>
        </div>

        <div className={styles.appsCol}>
          <h2 className="h5">Заказывай со смартфона</h2>
          <div className={styles.appsLinksWrapper}>
            <a
              href="https://apps.apple.com/ru/app/cronmarket/id1513807520"
              target="_blank"
              rel="noreferrer"
            >
              <AppStoreIcon />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=ooo.cron.delivery"
              target="_blank"
              rel="noreferrer"
            >
              <GooglePlayIcon />
            </a>
          </div>
        </div>
      </div>
    </footer >
  );
};
