import axios from "axios";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
export const httpv1 = axios.create({
  baseURL: `${publicRuntimeConfig.NEXT_PUBLIC_BASE_URL}v1/`,
});

export const httpv2 = axios.create({
  baseURL: `${publicRuntimeConfig.NEXT_PUBLIC_BASE_URL}v2/`,
});
