import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";

import styles from "./Search.module.css";
import SearchProps from "./Search.props";
import SearchIcon from "~/assets/icons/search.svg";
import ClearIcon from "~/assets/icons/close.svg";

import useDebounce from "~/hooks/useDebounce";

export const SearchMobile = ({
  className,
  ...props
}: SearchProps): JSX.Element => {
  const router = useRouter();
  const pathName = router.pathname;
  const searchQuery = router.query.search;
  const type = router.query.type ?? "Рестораны";
  const typeId = router.query.typeid ?? 1;

  const basePath = `/search?type=${type}&typeid=${typeId}&query=`;

  const field = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    searchQuery && setSearch(searchQuery as string);
  }, [searchQuery]);

  const debouncedSearch = useDebounce(search, 340);

  useEffect(() => {
    if (!debouncedSearch) return;
    if (pathName !== "/search") return;
    if (debouncedSearch.length >= 1) {
      router.push(
        `${basePath}${debouncedSearch}`,
        `${basePath}${debouncedSearch}`,
        { shallow: true }
      );
    }
    if (search?.length === 0) {
      router.push(`${basePath}`, `${basePath}`, { shallow: true });
    }
  }, [debouncedSearch, search]);

  useEffect(() => {
    field.current?.focus();
  }, []);


  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <button className={styles.button}>
        <SearchIcon className={styles.searchIcon} />
      </button>
      <input
        value={search ?? ""}
        placeholder="Введите текст для поиска"
        className={clsx("text", styles.field)}
        type="text"
        name="search"
        autoComplete="off"
        onChange={({ currentTarget }) => setSearch(currentTarget.value)}
        ref={field}
      />
      {search && (
        <button
          className={styles.clear}
          onClick={() => {
            setSearch("");
            router.push(`${basePath}`, `${basePath}`, { shallow: true });
          }}
        >
          <ClearIcon className={styles.clearIcon} />
        </button>
      )}
    </div>
  );
};
