import { MobileModal } from "~/components";
import { Profile } from './Profile';
import MobileProfileModalProps from "./MobileProfileModal.props";

export const MobileProfileModal = ({
  isOpen,
  onExit = () => null,
  hideModal = () => null,
  className,
  ...props
}: MobileProfileModalProps): JSX.Element => {

  return (
    <MobileModal fullScreen headline='Мои данные' isOpen={isOpen} onExit={onExit} hideModal={hideModal}>
      <Profile hideModal={hideModal} className={className} {...props} />
    </MobileModal>
  );
};
