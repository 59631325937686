import { useEffect } from "react";

interface IPopup {
  isHideWhenScrolling: boolean;
  isOpen: boolean;
  hideModal: () => void;
  onExit: () => void;
}

export default function useChangeVisiblePopup({
  isHideWhenScrolling,
  isOpen,
  hideModal = () => null,
  onExit = () => null,
}: IPopup) {
  useEffect(() => {
    if (!isHideWhenScrolling) return;
    const handleHiddenPopup = () => {
      hideModal();
      window.removeEventListener("scroll", handleHiddenPopup, false);
      window.removeEventListener("resize", handleHiddenPopup, false);
    };

    if (isOpen) {
      window.addEventListener("scroll", handleHiddenPopup, { once: true });
      window.addEventListener("resize", handleHiddenPopup, { once: true });
    }
  }, [isHideWhenScrolling]);

  useEffect(() => {
    if (!isOpen) setTimeout(onExit, 300);
  }, [isOpen]);

  return null;
}
