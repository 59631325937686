const maskPhoneNumber = (phone?: string | number | null) => {
  if (!phone) return;
  if (String(phone).length !== 11) return;
  const str = String(phone);
  return `+7(${str.slice(1, 4)}) ${str.slice(4, 7)} - ${str.slice(
    7,
    9
  )} - ${str.slice(9)}`;
};

export default maskPhoneNumber;
