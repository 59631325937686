export const getDeviceType = (userAgent: NavigatorID["userAgent"]) => {
  const isSSR = Boolean(userAgent.match(/SSR/i));
  let isTablet = Boolean(
    userAgent.match(
      /ipad|Android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i
    )
  );
  let isMobile = Boolean(
    userAgent.match(
      /iphone|ipod|Android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i
    )
  );

  if (isTablet && isMobile) {
    isMobile = userAgent.match(/Mobile/i) ? true : false;
    isTablet = userAgent.match(/Mobile/i) ? false : true;
  }

  const isAndroid = userAgent.match(/Android|android 3.0|android/i);
  const isIos = userAgent.match(/iphone|ipad|ipod|iPhone|iPad|iPod/i);
  const mobile = isMobile || isTablet;
  const isDesktop = !(isSSR || mobile);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isAndroid,
    isIos,
  };
};
