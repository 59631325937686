import { FC, ReactNode } from 'react';
import Head from "next/head";
import { AppProps } from "next/dist/shared/lib/router/router";
import Script from "next/script";
import getConfig from "next/config";

import "~/styles/globals.css";
import { Loading } from "~/components";
import { UserProvider } from "~/hooks/user/useUser.hook";
import { ScrollRestorer } from '~/hooks/useScrollRestoration';
import { TransitionGroup } from "react-transition-group";
import { ModalProvider } from "react-modal-hook";
import { QueryClient, QueryClientProvider } from 'react-query';
import { BasketProvider } from '~/hooks/basket/useBasket.hook';
import { AddressProvider } from '~/hooks/address/useAddress.hook';
import favicon from "../../public/favicon.ico";

declare global {
  // eslint-disable-next-line no-var
  var dataLayer: any;
}

export default function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <Container>
      <Component {...pageProps} />
    </Container>
  );
}


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const { publicRuntimeConfig } = getConfig();
const token: string | undefined = publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER;

const Container: FC<{ children: ReactNode }> = ({ children }): JSX.Element => {
  return (
    <>
      <ScrollRestorer />
      <Loading />
      <Head>
        <title>Доставка еды и продуктов от 30 минут в Махачкале</title>
        <link rel="shortcut icon" type="image/x-icon" href={favicon.src} />
        <meta
          name="description"
          content="Заказать еду из ресторанов и магазинов в Махачкале - с быстрой доставкой от 30 минут. Сделать заказ можно на сайте и в приложении. Оплатить - наличными или банковской картой."
        />
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1" />
        <meta name="yandex-verification" content="8c30f7cd880cfae4" />
      </Head>

      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <AddressProvider>
            <BasketProvider>
              <ModalProvider rootComponent={TransitionGroup}>
                {children}
              </ModalProvider>
            </BasketProvider>
          </AddressProvider>
        </UserProvider>
      </QueryClientProvider>
      <Script
        defer
        strategy="afterInteractive"
        src={`https://api-maps.yandex.ru/2.1/?apikey=44b71e01-ea6d-4d38-8753-c98d1130d317&suggest_apikey=d1b5bfa6-01fe-48d3-8112-9284c176649d&lang=ru_RU`}
        type="text/javascript"
      ></Script>

      {token && (
        <>
          <Script defer strategy="afterInteractive" id="g-tagManager-script">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${token}');
            `}
          </Script>
          <Script defer strategy="afterInteractive" id="g-tagManager-script-frame">
            <iframe
              loading="lazy"
              src={`https://www.googletagmanager.com/ns.html?id=${token}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            />
          </Script>
        </>
      )}
    </>
  );
};
