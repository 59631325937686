import { useEffect, useRef, useState } from "react";
import { useModal } from 'react-modal-hook';
import { useRouter } from 'next/router';
import clsx from "clsx";

import { TagDesktop, CatalogFiltersPopup, TagLinkDesktop, Popup } from "~/components";
import styles from "./CatalogFilters.module.css";
import CatalogFiltersProps from "./CatalogFilters.props";
import { Tag as ITag } from "~/models/category";
import ArrowIcon from "~/assets/icons/arrow-down-2.svg";

import { useResizeObserver } from '~/hooks/useResizeObserver';

interface IState {
	visibleTags: ITag[];
	hiddenTags: ITag[];
}

export const FiltersList = ({
	className,
	tags,
	...props
}: CatalogFiltersProps): JSX.Element => {
	const tagsInit = [
		{
			id: "0",
			name: "Все"
		},
		...tags
	];

	const router = useRouter();
	const categoryId = router.query.categoryId as string;
	const filter = router.query.filter as string;
	// const sort = router.query.sort as string ?? null;

	const ref = useRef<HTMLUListElement>(null);
	const [categories, setCategories] = useState<IState>({
		visibleTags: [],
		hiddenTags: [],
	});

	useEffect(() => {
		setCategories({
			visibleTags: tagsInit,
			hiddenTags: [],
		});
	}, []);

	const [arrTagsWidth, setArrTagsWidth] = useState<number[]>([]);

	useEffect(() => {
		if (arrTagsWidth.length) return;
		const arr: number[] = [];
		document.querySelectorAll('.tag').forEach(element => {
			return arr.push(Math.ceil(element.getBoundingClientRect().width) + 2);
		});
		setArrTagsWidth(arr);
	}, [tagsInit]);

	const resizeCallback = ({ width }: DOMRectReadOnly) => {
		if (ref && ref.current && tagsInit && arrTagsWidth.length) {
			let count = 0;
			let summWidth = 0;
			for (let i = 0; i < arrTagsWidth.length; i++) {
				if (arrTagsWidth[i] + summWidth <= width) {
					count++;
					summWidth += arrTagsWidth[i];
				} else break;
			}

			setCategories({
				visibleTags: tagsInit.slice(0, count),
				hiddenTags: tagsInit.slice(count),
			});
		}
	};

	useResizeObserver(ref, resizeCallback, [arrTagsWidth.length]);

	const [selectHiddenTags, setSelectHiddenTags] = useState<ITag | null>(null);

	useEffect(() => {
		if (categories.hiddenTags?.length && filter) {
			categories.hiddenTags?.length && setSelectHiddenTags(categories.hiddenTags.find(({ name }) => name.toLocaleLowerCase() === filter?.toLocaleLowerCase()) ?? null);
		}
	}, [filter, categories.hiddenTags.length]);

	const handleSelectTag = (item: ITag | null, isHidden?: boolean) => {
		if (isHidden) {
			setSelectHiddenTags(item);
		} else {
			setSelectHiddenTags(null);
		}
	};

	const [showFiltersPopup, hideFiltersPopup] = useModal(
		({ in: open, onExited }) => {
			return (
				<Popup
					onExit={onExited}
					isOpen={open}
					hidePopup={hideFiltersPopup}
					elementId="buttonHiddenFilters"
				>
					<CatalogFiltersPopup
						tags={categories.hiddenTags}
						categoryId={categoryId}
						handleSelectTag={(item) => {
							handleSelectTag(item, true);
							hideFiltersPopup();
						}}
					/>
				</Popup>
			);
		}, [categories.hiddenTags.length]
	);

	return (
		<div
			className={clsx("filtersContainer", styles.filters, className)}
			{...props}
		>
			<ul ref={ref} className={styles.filtersList}>
				{categories.visibleTags.map((tag) => {
					return (
						<li key={tag.id} className={clsx('tag', styles.itemList)}>
							<div className={styles.itemListContent}>
								<TagLinkDesktop
									color='gost'
									path={`/?categoryId=${categoryId}&filter=${tag.id ? tag.name : "Все"}`}
									active={filter === tag.name}
									onClick={() => handleSelectTag(tag)}
								>
									{tag.name}
								</TagLinkDesktop>
							</div>
						</li>
					);
				})}
			</ul>

			{categories.hiddenTags.length ? (
				<div className={styles.tagButton}>
					<TagDesktop
						color='gost'
						id="buttonHiddenFilters"
						active={selectHiddenTags ? filter === selectHiddenTags?.name : false}
						onClick={showFiltersPopup}
					>
						{selectHiddenTags ? selectHiddenTags.name : "Еще"} <ArrowIcon className={styles.arrowIcon} />
					</TagDesktop>
				</div>
			) : null}

		</div>
	);
};
