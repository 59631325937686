import { useRouter } from 'next/router';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

export const FooterSpacer = (): JSX.Element => {
  const { basket } = useBasketContext();
  const router = useRouter();
  const isPartnerPage =
    router.pathname === "/partner/[id]" ||
    router.pathname === "/cart" ||
    router.pathname === "/retail/[id]";

  return basket?.content.length ? <div style={{ height: isPartnerPage ? '114px' : '70px' }} /> : <></>
};


