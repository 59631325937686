import HeaderAuthorizedDesktopProps from "./HeaderAuthorizedDesktop.props";
import { Wrapper } from './Wrapper';
import { Content } from './Content';

export const HeaderAuthorizedDesktop = ({
  className,
  ...props
}: HeaderAuthorizedDesktopProps): JSX.Element => {
  return (
    <Wrapper className={className} {...props}>
      <Content />
    </Wrapper>
  );
};
