import { useEffect, useState } from "react";
import { FieldState } from "~/models/account";
import { sendUserName } from "~/plugins/api";
import { useUserContext } from "./user/useUser.hook";

export default function useSaveUserName(
  successCallBack: () => void = () => null,
  defaultName = ""
) {
  const { setIsUpdateUserData } = useUserContext();

  const [name, setName] = useState(defaultName);
  const [nameState, setNameState] = useState<FieldState>({
    isLoading: false,
    errors: [],
  });

  const handleSaveName = async () => {
    const namePattern = /^[а-яА-Яa-zA-Z ]+$/u;
    if (
      namePattern.test(name) &&
      name.trim().length <= 30 &&
      name.trim().length >= 2
    ) {
      if (name.length) {
        try {
          const successSendName = await sendUserName(name);

          if (successSendName === "Имя добавлено успешно.") {
            setTimeout(() => {
              setIsUpdateUserData(true);
            }, 500);
          }

          return successCallBack();
        } catch (error: any) {
          console.log("Ошибка изменения имени", error);

          return setNameState((state) => ({
            ...state,
            errors: ["Имя должно содержать символы кирилицы"],
          }));
        }
      }
      return setNameState((state) => ({
        ...state,
        errors: ["Имя должно содержать символы"],
      }));
    } else {
      const errorText = () => {
        if (name.trim().length < 2)
          return ["Имя должно быть не меньше 2 символов"];
        if (name.trim().length > 30)
          return ["Имя должно быть не более 30 символов"];
        return ["Имя должно содержать только русские и латинские буквы"];
      };
      setNameState((state) => ({
        ...state,
        errors: errorText(),
      }));
    }
  };

  useEffect(() => {
    setNameState({
      isLoading: false,
      errors: [],
    });
    setName(defaultName);
  }, [defaultName]);

  return {
    setName,
    handleSaveName,
    name,
    isLoading: nameState.isLoading,
    errors: nameState.errors,
  };
}
