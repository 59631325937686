import { useState } from "react";
import TagLinkMobileProps from "./TagLinkMobile.props";
import styles from "./TagLinkMobile.module.css";
import clsx from "clsx";

export const TagLinkMobile = (({
  className,
  children,
  active = false,
  color = "gray",
  ...props
}: TagLinkMobileProps): JSX.Element => {
  const [pressedTag, setPressedTag] = useState(false);
  return (
    <div
      className={clsx(styles.tag, {
        [styles.active]: active,
        [styles.pressed]: pressedTag && !active,
        [styles.colorGray]: color === "gray",
        [styles.colorLight]: color === "light",
      }, className)}
      {...props}
      onTouchStart={() => setPressedTag(true)}
      onTouchEnd={() => setTimeout(() => setPressedTag(false), 150)}
      {...props}
    >
      {children}
    </div>
  );
});