import { MarkProps } from './Mark.props';
import styles from './Mark.module.css';
import RatingFeedbackStar from "~/assets/icons/ratingFeedbackStar.svg";
import { useEffect, useState, KeyboardEvent } from 'react';
import clsx from 'clsx';

export const Mark = ({ isEditable = false, mark = 0, setMark = () => null, ...props }: MarkProps): JSX.Element => {
    const [markArray, setMarkArray] = useState<JSX.Element[]>(new Array(5).fill(<></>));

    const [countMark, setCountMark] = useState<number>(mark);

    useEffect(() => {
        constructMark(countMark);
        setMark(countMark);
    }, [countMark]);

    const constructMark = (currentMark: number) => {
        const updatedArray = markArray.map((r: JSX.Element, i: number) => {
            return (
                <span key={i}
                    className={clsx(styles.star, {
                        [styles.filled]: i < currentMark,
                        [styles.editable]: isEditable
                    })}
                    onMouseEnter={() => changeDispay(i + 1)}
                    onMouseLeave={() => changeDispay(countMark)}
                    onClick={() => onClick(i + 1)}
                >
                    <RatingFeedbackStar
                        tabIndex={isEditable ? 0 : -1}
                        onKeyDown={(e: KeyboardEvent<SVGElement>) => isEditable && handleSpace(i + 1, e)}
                    />
                </span>

            );
        });
        setMarkArray(updatedArray);
    };

    const changeDispay = (i: number) => {
        if (!isEditable) {
            return;
        }
        setCountMark(i);
    };

    const onClick = (i: number) => {
        if (!isEditable) {
            return;
        }
        setCountMark(i);
    };

    const handleSpace = (i: number, e: KeyboardEvent<SVGElement>) => {
        if (e.code != 'Space') {
            return;
        }
        setCountMark(i);
    };

    return (
        <div {...props}>
            {markArray.map((r, i) => (<span key={i}>{r}</span>))}
        </div>
    );
};