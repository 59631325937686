import clsx from "clsx";

import {
	ButtonMobile,
	DeliveryAddressBottomSheet,
} from '~/components';
import AddressSelectButtonMobileProps from "./AddressSelectButtonMobile.props";
import styles from "./AddressSelectButtonMobile.module.css";
import ArrowIcon from "~/assets/icons/arrow-right-black.svg";
import LocationIcon from "~/assets/icons/location-fill.svg";
import { useModal } from 'react-modal-hook';
import getAddressInfo from '~/helpers/getAddressInfo';
import { useAddressContext } from '~/hooks/address/useAddress.hook';

export const AddressSelectButtonMobile = ({
	className,
	...props
}: AddressSelectButtonMobileProps): JSX.Element => {
	const { location } = useAddressContext();

	const [showModal, hideModal] = useModal(
		({ in: open, onExited }) => <DeliveryAddressBottomSheet isOpen={open} hideModal={hideModal} onExit={onExited} />
	);
	const parsedAddress = getAddressInfo(location?.address, location?.cityName ?? '');

	return (
		<ButtonMobile
			size='l'
			appearance='text'
			type='button'
			pressed={false}
			onClick={showModal}
			className={clsx(styles.addressSelectButton, className)}
			{...props}
		>

			<LocationIcon className={styles.locationIcon} />
			<div className={styles.addressWrapper}>
				{/* <span className={styles.addressCaption}>{parsedAddress?.localities.length ? parsedAddress.localities : parsedAddress?.city}</span> */}
				<div className={styles.address}>
					<span className={styles.addressStreet}>
						{parsedAddress?.street.length ? `${parsedAddress?.street} ` : " "}
					</span>
					<span className={styles.addressHouse}> ,{parsedAddress?.house}</span>
				</div>

			</div>
			<ArrowIcon className={styles.arrowIcon} />

		</ButtonMobile>
	);
};