import clsx from "clsx";
import { useState, useRef, useEffect } from "react";
import RenderIfVisibleProps from "./RenderIfVisible.props";
import styles from "./RenderIfVisible.module.css";

const isServer = typeof window === undefined;

export const RenderIfVisible = ({
  defaultHeight = 300,
  visibleOffset = 1000,
  root = null,
  children,
  placeholderColor = "#fff",
  placeholderCardClass,
  ...props
}: RenderIfVisibleProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(isServer);
  const placeholderHeight = useRef<number>(defaultHeight);
  const intersectionRef = useRef<HTMLDivElement>(null);

  // Set visibility with intersection observer
  useEffect(() => {
    if (!intersectionRef.current) return;
    const ref = intersectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (typeof window !== undefined && window.requestIdleCallback) {
          window.requestIdleCallback(
            () => setIsVisible(entries[0].isIntersecting),
            {
              timeout: 600,
            }
          );
        } else {
          setIsVisible(entries[0].isIntersecting);
        }
      },
      {
        root,
        rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px`,
      }
    );
    observer.observe(ref);

    return () => {
      if (ref) {
        observer.unobserve(ref);
        setIsVisible(false);
      }
    };
  }, [intersectionRef, root, visibleOffset]);

  // Set height after render
  useEffect(() => {
    if (intersectionRef.current && isVisible) {
      placeholderHeight.current = intersectionRef.current.offsetHeight;
    }
  }, [isVisible, intersectionRef]);

  return (
    <div ref={intersectionRef} {...props}>
      {isVisible ? (
        children
      ) : (
        <div
          className={clsx({
            [styles.mobilePlaceholderCard]:
              placeholderCardClass === "mobileCard",
            [styles.desktopPlaceholderCard]:
              placeholderCardClass === "desktopCard",
            [styles.partnerCardPlaceholder]:
              placeholderCardClass === "partnerCard",
          })}
          style={{
            height: placeholderHeight?.current,
            backgroundColor: placeholderColor,
            color: placeholderColor,
          }}
        ></div>
      )}
    </div>
  );
};
