import { useLayoutEffect, useState } from "react";
import clsx from "clsx";

import {
	ButtonDesktop,
	Input
} from '~/components';
import PromocodeViewDesktopProps from "./PromocodeViewDesktop.props";
import styles from "./PromocodeViewDesktop.module.css";
import { setPromocode, removePromocode } from "~/plugins/api/basket";
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

type validateType = "success" | "error" | undefined;

declare const ym: any;

export const PromocodeViewDesktop = ({
	onSuccess = () => null,
	className,
	...props
}: PromocodeViewDesktopProps): JSX.Element => {
	const { basket, handleSetBasket, tokenForMetrics } = useBasketContext();
	const [loading, setLoading] = useState(false);
	const [userPromocode, setUserPromocode] = useState("");
	const [feedBackUser, setFeedBackUser] = useState("");
	const [validateType, setValidateType] = useState<validateType>();

	const onClearPromocodeSate = () => {
		setFeedBackUser("");
		setUserPromocode("");
		setValidateType(undefined);
	};

	useLayoutEffect(() => {
		if (basket?.promocode?.length) {
			setUserPromocode(basket?.promocode);
			setValidateType("success");
			setFeedBackUser("Промокод применён");
		} else {
			setUserPromocode("");
		}
	}, [basket?.promocode]);

	const handleSetPromocode = async () => {
		if (basket?.id && !!userPromocode.length) {
			try {
				setLoading(true);
				const result = await setPromocode(basket?.id, userPromocode.trim());
				// console.log(result)
				handleSetBasket(result);
				setFeedBackUser("Промокод применён");
				setValidateType("success");
				onSuccess();
				setLoading(false);
				tokenForMetrics && ym ? ym(88121717, 'reachGoal', 'use_promocode') : null;
			} catch (e) {
				setLoading(false);
				console.log(e);
				setFeedBackUser("Промокод не найден или истёк");
				setValidateType("error");
			}
		} else {
			setFeedBackUser("Вы не ввели промокод");
			setValidateType("error");
		}
	};

	const handleEnterPromocode = async (
		e: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (e.key === "Enter" && basket?.id && e.currentTarget.value && !!userPromocode.length) {
			handleSetPromocode();
		}
	};

	const handleClearPromocode = async () => {
		if (basket?.id && !!userPromocode.length) {
			const result = await removePromocode(basket?.id);
			handleSetBasket(result);
			onClearPromocodeSate();
		} else {
			onClearPromocodeSate();
		}
		onSuccess();
	};

	return (
		<div className={clsx(styles.wrapper, className)} {...props}>
			<Input
				onChange={({ currentTarget }) =>
					setUserPromocode(currentTarget.value)
				}
				onKeyDown={handleEnterPromocode}
				placeholder="Введите промокод"
				className={styles.promocode}
				feedBack={feedBackUser}
				validationType={validateType}
				value={userPromocode}
			/>

			<div className={styles.buttonsWrapper}>
				<ButtonDesktop
					appearance='gray'
					className={styles.promocodeButton}
					onClick={handleClearPromocode}
				>
					Удалить
				</ButtonDesktop>

				<ButtonDesktop
					loading={loading}
					disabled={loading || userPromocode.trim().toLocaleLowerCase() === basket?.promocode?.trim().toLocaleLowerCase()}
					className={styles.promocodeButton}
					onClick={handleSetPromocode}
				>
					Применить
				</ButtonDesktop>
			</div>
		</div>
	);
};