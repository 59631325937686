import { useModal } from "react-modal-hook";

import { ButtonDesktop } from '../ButtonDesktop';
import styles from "./ButtonAddressDetailsDesktop.module.css";
import { AddressDetailsModal } from '~/components/modals/AddressDetailsModal';

export const ButtonAddressDetailsDesktop = (): JSX.Element => {
  const [showDeliveryAtTime, hideDeliveryAtTime] = useModal(
    ({ in: open, onExited }) => (
      <AddressDetailsModal isOpen={open} onExit={onExited} hideModal={hideDeliveryAtTime} />
    ));

  return (
    <ButtonDesktop
      appearance="gost"
      className={styles.buttonTimePicker}
      onClick={showDeliveryAtTime}
    >
      Уточнить
    </ButtonDesktop>
  );
};
