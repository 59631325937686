import { useEffect, useState } from "react";

interface IModal {
  isOpen: boolean;
  onExit: () => void;
}

export default function useChangeVisibleMobileModal({
  isOpen = false,
  onExit = () => null,
}: IModal) {
  //Для того чтобы не отменять стили "фиксирования страница", когда закрывается наложенная модалка
  const [isOpenSomeModals, setIsOpenSomeModals] = useState(false);
  useEffect(() => {
    if (document.querySelectorAll("#portal[data-portal]").length > 1)
      setIsOpenSomeModals(true);
    else setIsOpenSomeModals(false);
  }, [onExit, isOpen]);

  // useEffect(() => {
  //   if (isOpen) {
  //     document.documentElement.scrollTo({ top: 300, behavior: "smooth" });
  //     document.body.scrollTo({ top: 300, behavior: "smooth" });
  //     document
  //       .getElementById("__next")
  //       ?.scrollTo({ top: 300, behavior: "smooth" });
  //     const root = document.getElementById("__next");
  //     document.documentElement.style.top = "300px";
  //     document.body.style.top = "300px";
  //     root ? (root.style.top = "300px") : null;
  //   }
  // }, [isOpen]);

  useEffect(() => {
    const body = document.body;
    const root = document.getElementById("__next");
    const pagePosition = window.scrollY;
    const bodyPosition = body.dataset.position ? +body.dataset.position : 0;

    if (root && isOpen) {
      body.style.overflow = "hidden";
      body.style.position = "fixed";
      body.style.top = "0";
      body.dataset.position = `${pagePosition}`;
      body.style.top = -pagePosition + "px";
      body.style.width = "100vw";
      root.style.position = "inherit";
    }

    if (root && !isOpen && !isOpenSomeModals) {
      body.style.top = "auto";
      body.style.overflow = "";
      body.style.position = "";
      body.style.top = "0";
      window.scroll({ top: bodyPosition });
      body.removeAttribute("data-position");
      body.style.width = "";
      root.style.position = "";
    }
    if (!isOpen) setTimeout(onExit, 400);
  }, [isOpen, isOpenSomeModals]);

  return null;
}
