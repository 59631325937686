import HeaderDesktopSearchProps from "./HeaderDesktopSearch.props";
import { Wrapper } from './Wrapper';
import { Content } from './Content';

export const HeaderDesktopSearch = ({
  className,
  ...props
}: HeaderDesktopSearchProps): JSX.Element => (
  <Wrapper className={className} {...props}>
    <Content />
  </Wrapper>
);
