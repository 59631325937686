import { useState } from 'react';
import clsx from "clsx";
import Image from "next/legacy/image";

import { CounterMobile, TitleMobile } from '~/components';
import styles from "./CartItemMobile.module.css";
import CartItemMobileProps from "./CartItemMobile.props";
import ProductIcon from "~/assets/svg/product-icon.svg";
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

declare const ym: any;

export const CartItemMobile = ({
	product,
	marketCategoryId = NaN,
	partnerId = "",
	className,
	...props
}: CartItemMobileProps): JSX.Element => {
	const { addToBasket, removeFromBasket, tokenForMetrics } = useBasketContext();

	const additiveItemCost: number = product ? product.additives
		.map(({ cost }) => Number(cost))
		.reduceRight((sum, item) => {
			return (sum += item);
		}, 0) : 0;

	const [loading, setLoading] = useState(false);

	const handlePlus = async () => {
		try {
			setLoading(true);
			await addToBasket({
				product,
				marketCategoryId,
				partnerId,
				quantity: product.quantity + 1,
			});
			tokenForMetrics && ym ? ym(88121717, "reachGoal", "add_to_cart") : null;
		} catch (e) {
			console.log("Ошибка добавления в корзину");

			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	const handleMinus = async () => {
		try {
			setLoading(true);

			await removeFromBasket({
				product,
				marketCategoryId,
				partnerId,
				quantity: product.quantity,
			});
			tokenForMetrics && ym ? ym(88121717, "reachGoal", "remove_from_cart") : null;
		} catch (e) {
			console.log("Ошибка удаления из корзины");

			console.log(e);
		} finally {
			setLoading(false);
		}
	};


	return (
		<div className={clsx(styles.product, className)} {...props}>
			<div className={styles.imageContainer}>
				{product.photoUri ? (
					<Image
						unoptimized
						alt={product.name}
						objectFit="cover"
						width={68}
						height={68}
						src={product.photoUri}
					/>
				) : (
					<ProductIcon className={styles.placeholderIcon} />
				)}
			</div>
			<div className={styles.productOptions}>
				<div className={styles.title}>
					<TitleMobile headerType='h6' margin={false}>
						{product.name}
					</TitleMobile>
					<div className={styles.productCost}>{product?.cost ?? product?.oldCost} ₽</div>
				</div>
				{product.additives.length ? (
					<div className={styles.additivesWrapper}>
						{product.additives.map(({ id, name, cost }) => (
							<div key={id} className={styles.additiveItem}>
								<span>{name}</span>
								{cost ? <span>- {cost}&#8381;</span> : null}
							</div>
						))}
					</div>
				) : null}

				<div className={styles.price}>
					<div className={styles.productQuantity}>
						<CounterMobile
							loading={loading}
							onPlus={handlePlus}
							onMinus={handleMinus}
							value={product.quantity}
						/>
					</div>
					<div>
						{product?.oldCost && product?.oldCost !== null ? (
							<div
								className={styles.oldCost}
							>
								{product?.oldCost * product.quantity} ₽
							</div>
						) : null}
						<div className={clsx({
							[styles.redColor]: product?.oldCost !== 0
						})}>
							{(additiveItemCost + product?.cost) * product.quantity} ₽
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
