const convertDate = (date: string | Date | undefined) => {
  if (!date) return "";
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth() + 1;
  const year = new Date(date).getFullYear();
  const year1 = String(year).split("");

  return `${day}.${month}.${year1[2]}${year1[3]}г`;
};

export default convertDate;
