import { useModal } from "react-modal-hook";
import dynamic from 'next/dynamic';
import clsx from "clsx";

import CitySelectDesktopProps from "./CitySelectDesktop.props";
import styles from "./CitySelectDesktop.module.css";
import ArrowIcon from "~/assets/icons/arrow-down-2.svg";
import LocationIcon from "~/assets/icons/location-fill.svg";

import getAddressInfo from '~/helpers/getAddressInfo';
import useDesktopMapModal from '~/hooks/useDesktopMapModal';
import { namedComponent } from '~/helpers/dynamicComponent';
import { useUserContext } from "~/hooks/user/useUser.hook";
import { useAddressContext } from '~/hooks/address/useAddress.hook';
import { ButtonDesktop } from '../ButtonDesktop';

const Components = {
  Popup: dynamic(() =>
    namedComponent(import('~/components/modals/_Components/Popup'), 'Popup')
  ),
  AddressPopupList: dynamic(() =>
    namedComponent(import('~/components/modals/AddressListPopup'), 'AddressListPopup')
  ),
}

export const CitySelectDesktop = ({
  className,
  ...props
}: CitySelectDesktopProps): JSX.Element => {
  const { token } = useUserContext();
  const { location, userAddresses } = useAddressContext();

  const { showDesktopMapModal } = useDesktopMapModal();

  const [showAddressPopup, hideAddressPopup] = useModal(
    ({ in: open, onExited }) => {
      return (
        <Components.Popup
          onExit={onExited}
          isOpen={open}
          hidePopup={hideAddressPopup}
          elementId="addressButton"
        >
          <Components.AddressPopupList
            hidePopup={hideAddressPopup}
            onSuccessAddress={hideAddressPopup}
            hidePopupWithOpenMapModal={handlePopupClose}
          />
        </Components.Popup>
      );
    }
  );

  const handlePopupClose = () => {
    hideAddressPopup();
    setTimeout(showDesktopMapModal, 200);
  };

  const handlerOpenLocationModal = () => {
    if (token && userAddresses.length) {
      return showAddressPopup();
    }
    return showDesktopMapModal()
  };

  const parsedAddress = getAddressInfo(location?.address, location?.cityName ?? '');

  return (
    <ButtonDesktop
      id="addressButton"
      appearance='gray'
      className={clsx(styles.addressButton, {
        // [styles.emptyAddress]: !location?.address.length,
      }, className)}
      onClick={handlerOpenLocationModal}
      {...props}
    >
      {location?.address.length ? (
        <div className={styles.addressContainer}>
          <LocationIcon className={styles.locationIcon} />
          <div className={styles.addressWrapper}>
            <span className={styles.addressCaption}>{parsedAddress?.localities.length ? parsedAddress.localities : parsedAddress?.city}</span>
            <div className={styles.addressText}>
              <span className={styles.addressStreet}>
                {parsedAddress?.street.length ? `${parsedAddress?.street}, ` : " "}
              </span>
              <span className={styles.addressHouse}> {parsedAddress?.house}</span>
            </div>
          </div>
          <ArrowIcon className={styles.arrowIcon} />
        </div>
      ) : (
        <>
          <LocationIcon className={styles.locationIcon} />
          <span className={styles.emptyText}>Укажите адрес доставки</span>
        </>
      )}
    </ButtonDesktop>
  );
};
