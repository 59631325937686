import { useState } from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';
import clsx from "clsx";

import PopularPartnersMobileProps from "./PopularPartnersMobile.props";
import styles from "./PopularPartnersMobile.module.css";
import { TitleMobile } from '../TitleMobile';

export const PopularPartnersMobile = ({ partners }: PopularPartnersMobileProps): JSX.Element => {
    const getLink = (isStructure: boolean, id: string, name: string) => {
        if (isStructure) {
            return `/retail/${id}?name=${name}&category=Товары%20со%20скидкой&categoryId=promo`
        }
        return `/partner/${id}?name=${name}`
    };

    const [pressedTag, setPressedTag] = useState<string | null>(null);

    return (
        <div className={clsx(styles.wrapper)}>
            <TitleMobile headerType='h2'>Популярные</TitleMobile>
            <div
                className={styles.container}
            >
                <div
                    className={styles.gridContainer}
                >
                    {partners.map(({ id, name, isStructure, logo }) => {
                        return (
                            <div key={id} className={styles.itemContainer}>
                                <Link
                                    as={getLink(isStructure, id, name)}
                                    href={getLink(isStructure, id, name)}
                                    key={id}
                                >
                                    <div
                                        className={clsx(styles.partnerItem, {
                                            [styles.pressed]: id === pressedTag
                                        })}
                                        onTouchStart={() => setPressedTag(id)}
                                        onTouchEnd={() => setTimeout(() => setPressedTag(null), 150)}
                                    >
                                        {logo && <div className={styles.backgroundImg}>
                                            <Image
                                                unoptimized
                                                alt={name}
                                                objectFit="cover"
                                                layout='fill'
                                                width={48}
                                                height={48}
                                                src={logo}
                                            />
                                        </div>}
                                        <div className={clsx(styles.partnerName, 'clamp-2')}>{name}</div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
