import { AxiosResponse } from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { getCookie, setCookie } from "~/helpers/cookieUtilites";
import { getRefreshToken } from "../api";
import { httpv1, httpv2 } from "../api/http";

export const isAccessTokenExpired = () => {
  const token = getCookie("cron-token");
  if (token) {
    const parsedToken: string = JSON.parse(token).accessToken;
    const decodedToken: number = jwtDecode<JwtPayload>(parsedToken).exp ?? 0;
    const currentTime = Math.floor(new Date().getTime() / 1000);
    return decodedToken <= currentTime;
  }
};

const refreshTokens = async () => {
  const token = getCookie("cron-token");
  const tokenParse = token && JSON.parse(token);
  if (tokenParse) {
    const response = await getRefreshToken(tokenParse);
    httpv1.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.accessToken}`;
    httpv2.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.accessToken}`;
    setCookie({ name: "cron-token", value: response });
  }
};

export const customAuthFetch = async <T = any>(
  request: () => Promise<T>
): Promise<T> => {
  try {
    if (isAccessTokenExpired()) {
      await refreshTokens();
    }
    return await request();
  } catch (error: any) {
    if (error.response.status == "401") {
      await refreshTokens();
      return await request();
    }
    return await request();
  }
};

export const customFetch = async <T = any>(
  request: () => Promise<AxiosResponse<T, any>>
): Promise<AxiosResponse<T, any>> => {
  if (isAccessTokenExpired()) {
    try {
      await refreshTokens();
      return await request();
    } catch (error: any) {
      if (error.response.status == "401") {
        await refreshTokens();
        return await request();
      }
      return await request();
    }
  } else {
    return await request();
  }
};

// interface IDispatch<T> {
//   request: () => Promise<T>;
//   getDataCallback: (value: T) => void;
// }

// export const customFetch = async <T>({
//   request,
//   getDataCallback,
// }: IDispatch<T>) => {
//   const response = await request();
//   return getDataCallback(response);
// };

// try {
//   console.log("request1");
//   return await request();
// } catch (error: any) {
//   try {
//     if (isAccessTokenExpired()) {
//       console.log("refreshTokens");
//       await refreshTokens();
//     }
//     console.log("request2");
//     return await request();
//   } catch (e: any) {
//     console.log("error refreshfetch");
//     if (e?.response.status == "400") {
//       console.log("request3");
//       return await request();
//     }
//   }
//   console.log("request4", error);
//   return await request();
// }

// if (isAccessTokenExpired()) {
//   console.log("refreshTokens", error);
//   await refreshTokens();
//   console.log("request2", error);
//   return await request();
// }

// try {
//   return await request();
// } catch (error: any) {
//   if (error?.response.status == "401") {
//     console.log("refreshfetch");
//     try {
//       await refreshTokens();
//       return await request();
//     } catch (e: any) {
//       console.log("error refreshfetch");
//       if (e?.response.status == "400") {
//         console.log("request");
//         return await request();
//       }
//     }
//     // console.log("request");
//     // return await request();
//   }
//   throw error;
// }

// export const useFetch = <T>(fetch: () => Promise<T>) => {
//   const [data, setData] = useState<T | null>(null);
//   const [error, setError] = useState<any | null>(null);

//   useEffect(() => {
//     fetcher<T>(fetch)
//       .then((data) => setData(data))
//       .catch((error) => setError(error));
//   }, []);

//   return { data, error };
// };
