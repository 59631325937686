import { useRouter } from "next/router";

import { UserAuthDesktop, ButtonBackDesktop } from "~/components";
import { Search } from "../../Search";
import styles from "./HeaderDesktopSearch.module.css";

export const Content = (): JSX.Element => {
  const router = useRouter();

  return (
    <div className={styles.headerContainer}>
      <ButtonBackDesktop onClick={() => router.push("/")}>
        Назад
      </ButtonBackDesktop>
      <Search className={styles.search} />
      <UserAuthDesktop />
    </div>
  );
};
