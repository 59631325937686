import clsx from "clsx";

import { AsideCart, Popup } from "~/components";
import CartPopupProps from "./CartPopup.props";
import styles from "./CartPopup.module.css";

export const CartPopup = ({
  isOpen,
  hidePopup,
  onExit,
  elementId,
  className,
  ...props
}: CartPopupProps): JSX.Element => {
  return (
    <Popup
      isOpen={isOpen}
      onExit={onExit}
      hidePopup={hidePopup}
      elementId={elementId}
      isHideWhenScrolling={false}
    >
      <div className={clsx(styles.wrapper, className)} {...props}>
        <AsideCart isCartPopup className={styles.cartPopup} onHide={hidePopup} />
      </div>
    </Popup>
  );
};
