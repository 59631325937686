import { useEffect, useState } from 'react';
import clsx from "clsx";

import { LoadingIndicator } from '~/components';
import ButtonDesktopProps from "./ButtonDesktop.props";
import styles from "./ButtonDesktop.module.css";

export const ButtonDesktop = ({
  children,
  className,
  size = "d",
  loading = false,
  isWrap = false,
  disabled,
  spinnerColor,
  appearance = "primary",
  type = "button",
  ...props
}: ButtonDesktopProps): JSX.Element => {
  const [isLoading, setIsloading] = useState(false);
  const [isVisibleLoadingIndicator, setIsVisibleLoadingIndicator] = useState(false);

  useEffect(() => {
    if (loading) {
      setIsloading(true);
      setIsVisibleLoadingIndicator(true);
    } else {
      if (isVisibleLoadingIndicator) {
        setTimeout(() => setIsloading(false), 400);
        setTimeout(() => setIsVisibleLoadingIndicator(false), 800);
      }
    }
  }, [loading]);

  return (
    <button
      className={clsx(
        styles.button,
        `${isWrap ? "clamp-2" : ""}`,
        {
          [styles.primary]: appearance === "primary",
          [styles.gray]: appearance === "gray",
          [styles.gost]: appearance === "gost",
          [styles.text]: appearance === "text",
          [styles.small]: size === "s",
          [styles.medium]: size === "m",
          [styles.default]: size === "d",
          [styles.large]: size === "l",
          [styles.isLoading]: !disabled && isVisibleLoadingIndicator,
        },
        className
      )}
      disabled={disabled || isVisibleLoadingIndicator}
      type={type}
      {...props}
    >
      <div className={styles.wrapper}>
        {isVisibleLoadingIndicator &&
          <div className={clsx(styles.loading, {
            [styles.loadingVisible]: isLoading,
            [styles.loadingHide]: !isLoading,
          })}>
            <LoadingIndicator color={!spinnerColor ? appearance === 'primary' ? "white" : "primary" : spinnerColor} />
          </div>
        }

        <div className={clsx(styles.content, {
          [styles.contentHide]: isVisibleLoadingIndicator && isLoading,
          [styles.contentVisible]: isVisibleLoadingIndicator && !isLoading,
        })}>
          {children}
        </div>
      </div>
    </button>
  );
};
