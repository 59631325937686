import CategoryLinkDesktopProps from "./CategoryLinkDesktop.props";
import styles from "./CategoryLinkDesktop.module.css";
import clsx from "clsx";
import Image from "next/legacy/image";

export const CategoryLinkDesktop = (
  (
    { className, children, image, ...props }: CategoryLinkDesktopProps,

  ): JSX.Element => {
    return (
      <div
        className={clsx(styles.tag, className)}
        {...props}
      >
        <div className={styles.imageWrapper}>
          <Image
            unoptimized
            alt={children?.toString()}
            width={72}
            height={72}
            src={image}
            objectFit="cover"
            layout="fill"
          />
        </div>

        <div className={styles.title}>{children}</div>
      </div>
    );
  }
);
