import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { deleteUserCard } from "~/plugins/api/payment";
import { IPaymentType } from "./useSelectPay";

export function useDeleteUserCard() {
  const queryClient = useQueryClient();

  return (
    useMutation(async(props) => {
      try {
        return await deleteUserCard(props)
      } catch(e) {
        const error = e as AxiosError;
        throw new Error(error.response?.data) as AxiosError
      }
    },
    {
      onMutate: (deleteCard: any) => {
        const prevCardsList: IPaymentType[] | undefined = queryClient.getQueryData(['user_card_list']);   
        return prevCardsList?.length ? prevCardsList.filter((it) => it.id !== deleteCard.cardId): [];
      },
      onSuccess: (deleteCard: any) => {
        queryClient.setQueryData(['user_card_list'], (oldCardsList?: IPaymentType[]) => {
          if(oldCardsList?.length) {
            return oldCardsList.filter((it) => it.id !== deleteCard.cardId)
          } return [];
        });
      }
    }
  ));
}
