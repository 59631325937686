import CurrentOrderLinkProps from "./CurrentOrderLink.props";
import styles from "./CategoryLink.module.css";
import clsx from "clsx";
import Basket from "~/assets/icons/cart.svg";
import { forwardRef } from "react";

const CurrentOrderLink = forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<CurrentOrderLinkProps>
>(
  (
    { className, orderCost, ...props }: CurrentOrderLinkProps,
    ref
  ): JSX.Element => {
    return (
      <a ref={ref} className={clsx(styles.tag, className)} {...props}>
        <div>
          <Basket />
        </div>

        <div className={styles.title}>{orderCost}₽</div>
      </a>
    );
  }
);

CurrentOrderLink.displayName = "CurrentOrderLink";

export { CurrentOrderLink };
