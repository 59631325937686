import { useState } from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import clsx from "clsx";

import { ProductCardRow } from "~/components";
import SearchPlaceCarouselProps from "./SearchPlaceCarouselDesktop.props";
import styles from "./SearchPlaceCarouselDesktop.module.css";
import RaitingIcon from "~/assets/icons/star-fill.svg";
import ShoppingBagIcon from "~/assets/icons/shopping-bag-alt.svg";
import ClockIcon from "~/assets/icons/clock.svg";
import DefaultImage from "~/assets/svg/product-placeholder.webp";

import parseSchedule from "~/helpers/parseSchedule";

import "swiper/css";

export const SearchPlaceCarouselDesktop = ({
  className,
  partner,
  size = "l",
  products,
  ...props
}: SearchPlaceCarouselProps): JSX.Element => {
  const [path] = useState<string>(() => {
    if (partner?.isStructure) {
      return `/shop/${partner.id}?name=${partner?.name}&category=Товары по акции&categoryId=promo`;
    }
    return `/partner/${partner.id}?name=${partner?.name}`;
  });

  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <Link key={partner.id} href={path} target={"_blank"}>
        <div className={styles.partnerPlate}>
          <div className={styles.imageWrapper}>
            <Image
              unoptimized
              alt={partner.name}
              width={size === "l" ? 80 : 42}
              height={size === "l" ? 80 : 42}
              layout="fill"
              objectFit="cover"
              src={partner.logo?.length ? partner.logo : DefaultImage}
            />
          </div>
          <div className={styles.partnerPlateInfo}>
            <div className={clsx("h4", styles.name)}>
              {partner.name?.trim() ? partner.name : <span>&nbsp;</span>}
            </div>
            <div
              title={partner.shortDescription || undefined}
              className={styles.subTitle}
            >
              {partner.shortDescription?.trim() ? (
                partner.shortDescription
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
            <div className={styles.meta}>
              <div className={styles.raiting}>
                <RaitingIcon className={styles.starSvg} />
                <span className={styles.metaSubTextSpan}>
                  {partner.rating}
                </span>
              </div>
              <div className={styles.cost}>
                <ShoppingBagIcon className={styles.bagSvg} />
                <span className={styles.metaSubTextSpan}>
                  от {partner.minAmountOrder}₽
                </span>
              </div>
              <div className={styles.deliveryTime}>
                <ClockIcon className={styles.clockSvg} />
                <span className={styles.metaSubTextSpan}>
                  {parseSchedule(partner.schedule)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div className={styles.carouselContainer}>
        <ProductCardRow path={path} products={products} />
      </div>
    </div>
  );
};
