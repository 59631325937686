import clsx from "clsx";

import {
	ButtonDesktop,
	PromocodeModal,
} from '~/components';
import PromocodeButtonDesktopProps from "./PromocodeButtonDesktop.props";
import styles from "./PromocodeButtonDesktop.module.css";
import { useModal } from 'react-modal-hook';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

export const PromocodeButtonDesktop = ({
	className,
	...props
}: PromocodeButtonDesktopProps): JSX.Element => {
	const { basket } = useBasketContext();

	const [showModal, hideModal] = useModal(
		({ in: open, onExited }) => <PromocodeModal isOpen={open} hideModal={hideModal} onExit={onExited} />
	);

	return (
		<ButtonDesktop
			appearance='gray'
			size='m'
			onClick={showModal}
			className={clsx(styles.buttonAddPromocode, {
				[styles.success]: basket?.promocode
			}, className)}
			{...props}
		>
			<span className={styles.buttonText}>
				{basket?.promocode ? basket?.promocode : 'Применить промокод'}
			</span>
		</ButtonDesktop>
	);
};