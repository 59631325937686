import { useModal } from "react-modal-hook";
import dynamic from 'next/dynamic';
import clsx from "clsx";

import CitySelectMobileProps from "./CitySelectMobile.props";
import styles from "./CitySelectMobile.module.css";
import ArrowIcon from "~/assets/icons/arrow-down-2.svg";
import LocationIcon from "~/assets/icons/location-fill.svg";

import { useUserContext } from "~/hooks/user/useUser.hook";
import getAddressInfo from '~/helpers/getAddressInfo';
import useMobileMapModal from '~/hooks/useMobileMapModal';
import { useAddressContext } from '~/hooks/address/useAddress.hook';
import { ButtonMobile } from '../ButtonMobile';
import { namedComponent } from '~/helpers/dynamicComponent';

const Components = {
  BottomSheet: dynamic(() =>
    namedComponent(import('~/components/modals/_Components/BottomSheet'), 'BottomSheet')
  ),
  AddressListBottomSheet: dynamic(() =>
    namedComponent(import('~/components/modals/AddressListBottomSheet'), 'AddressListBottomSheet')
  ),
};

export const CitySelectMobile = ({
  className,
  ...props
}: CitySelectMobileProps): JSX.Element => {
  const { token } = useUserContext();
  const { location, userAddresses } = useAddressContext();

  const { showMobileMapModal } = useMobileMapModal();

  const [showAddressBottomSheet, hideAddressBottomSheet] = useModal(
    ({ in: open, onExited }) => {
      return (
        <Components.BottomSheet
          onExit={onExited}
          isOpen={open}
          hideModal={hideAddressBottomSheet}
        >
          <Components.AddressListBottomSheet
            hidePopup={hideAddressBottomSheet}
            onSuccessAddress={hideAddressBottomSheet}
            hidePopupWithOpenMapModal={handleBottomSheetClose}
          />
        </Components.BottomSheet>
      );
    }
  );

  const handleBottomSheetClose = () => {
    setTimeout(handelOpenMapModal, 300);
    hideAddressBottomSheet();
  };

  const handelOpenMapModal = () => showMobileMapModal();

  const handlerOpenLocationModal = () => {
    if (token && userAddresses.length) {
      return showAddressBottomSheet();
    }
    return handelOpenMapModal();
  };

  const parsedAddress = getAddressInfo(location?.address, location?.cityName ?? '');

  return (
    <ButtonMobile
      id="addressButton"
      type='button'
      appearance={location ? 'text' : 'gray'}
      pressed={false}
      className={clsx(styles.addressButton, {
        [styles.emptyAddress]: !location,
      }, className)}
      onClick={handlerOpenLocationModal}
      onMouseDown={handlerOpenLocationModal}
      {...props}
    >
      {location && (
        <div className={styles.container}>
          <div className={styles.cityName}>{parsedAddress?.localities.length ? parsedAddress.localities : parsedAddress?.city}</div>
          <div className={styles.address}>
            <span className={styles.addressStreet}>
              {parsedAddress?.street.length ? `${parsedAddress?.street} ` : " "}
            </span>
            <span className={styles.addressHouse}> ,{parsedAddress?.house}</span>
            <ArrowIcon className={styles.arrowIcon} />
          </div>
        </div>
      )}
      {!location && (
        <>
          <LocationIcon className={styles.locationIcon} />
          <span className={styles.emptyText}>Укажите адрес доставки</span>
        </>
      )}
    </ButtonMobile>
  );
};
