import styles from "./Counter.module.css";
import CounterProps from "./Counter.props";
import MinusSvg from "~/assets/icons/minus.svg";
import PlusSvg from "~/assets/icons/plus.svg";
import clsx from "clsx";

export const Counter = ({
  className,
  value = 0,
  loading = false,
  onPlus = () => null,
  onMinus = () => null,
}: CounterProps): JSX.Element => {
  const incrementCount = async () => {
    try {
      await onPlus();
    } catch (e) {
      console.log("Ошибка увеличения счётчика", e);
    }
  };

  const decrementCount = async () => {
    try {
      await onMinus();
    } catch (e) {
      console.log("Ошибка уменьшения счётчика", e);
    }
  };

  return (
    <div className={clsx(styles.counter, className)}>
      <div className={styles.CounterBody}>
        <button
          disabled={loading}
          onClick={decrementCount}
          className={clsx(styles.minusBtn, styles.counterBtn)}
        >
          <MinusSvg className={styles.minusBtnSvg} />
        </button>
        <div className={styles.count}>
          <span className={styles.sht}>{value}</span>
        </div>
        <button
          disabled={loading}
          onClick={incrementCount}
          className={clsx(styles.PlusBtn, styles.counterBtn)}
        >
          <PlusSvg className={styles.minusBtnSvg} />
        </button>
      </div>
    </div>
  );
};
