import { useRouter } from 'next/router';
import Image from "next/legacy/image";
import clsx from "clsx";

import { TitleDesktop } from '../TitleDesktop';
import { AboutDeliveryPopup } from './AboutDeliveryPopup';
import { AboutPartnerPopup } from './AboutPartnerPopup';
import { PartnerRatingModal } from './PartnerRatingModal';
import styles from "./PartnerAboutDesktop.module.css";
import PartnerAboutProps from "./PartnerAboutDesktop.props";

import { usePartnerInfo } from '~/hooks/partner';

export const PartnerAboutDesktop = ({
  inline = true,
  className,
}: PartnerAboutProps): JSX.Element => {
  const router = useRouter();
  const partnerId = router.query.id as string;
  const partnerName = router.query.name as string;
  const { data: partner } = usePartnerInfo(partnerId);

  return (
    <div
      className={clsx({
        [styles.inlinePartnerAbout]: inline,
        [styles.partnerAbout]: !inline,
      }, className)}
    >
      {inline ? (
        <div className={styles.imageWrapper}>
          {partner?.logo ? (
            <Image
              priority
              width={96}
              height={96}
              unoptimized
              alt={String(partner?.name)}
              layout="fill"
              objectFit="contain"
              src={String(partner?.logo)}
            />
          ) : (
            <div className={styles.placeholderImage}></div>
          )}
        </div>
      ) : null}
      <div
        className={clsx(styles.info, {
          [styles.inlineInfo]: !inline,
        })}
      >
        <div className={styles.topBar}>
          <div className={styles.titleBlock}>
            <TitleDesktop
              headerType='h3'
              margin={false}
              title={partnerName}
            >
              {partnerName}
            </TitleDesktop>
            {/* <div className={styles.partnerShortName}>
              {partner?.shortDescription}
            </div> */}
          </div>

          {/* <div className={styles.favorite}>
            <PartnerAboutFavorite />
          </div> */}
        </div>

        <div className={styles.buttonsWrapper}>
          {partner?.feedbackCount ? (
            <PartnerRatingModal />
          ) : null}

          <AboutDeliveryPopup />

          <AboutPartnerPopup />
        </div>
      </div>
    </div>
  );
};
