import { useState, useEffect } from "react";

export default function useTimer(seconds: number, begin?: Date | string) {
  const [time, setTime] = useState(0);
  const [isStopTimer, setIsStopTimer] = useState(false);

  useEffect(() => {
    let timeLeft: any;
    if (begin) {
      const dateTime = typeof begin === "string" ? new Date(begin) : begin;
      const currenTime = new Date().getTime();
      const endTime = dateTime.setTime(dateTime.getTime() + seconds * 1000);

      if (currenTime > endTime) return setIsStopTimer(true);

      timeLeft = (endTime - currenTime) / 1000;
    } else {
      timeLeft = seconds;
    }

    const itervalId = setInterval(function () {
      if (!isStopTimer) {
        setTime((prevTime) => {
          if (prevTime + 1 < timeLeft) {
            return prevTime + 1;
          } else {
            clearInterval(itervalId);
            setIsStopTimer(true);
            return prevTime + 1;
          }
        });
      }
    }, 1000);

    return () => clearInterval(itervalId);
  }, [isStopTimer]);

  return { time, isStopTimer: isStopTimer };
}
