import clsx from "clsx";

import { BottomSheet, TitleMobile } from "~/components";
import BanknoteTypesBottomSheetProps from "./BanknoteTypesBottomSheet.props";
import styles from "./BanknoteTypesBottomSheet.module.css";

import { IBanknote } from '~/hooks/user/useSelectBanknotes';
import { useBasketContext } from "~/hooks/basket/useBasket.hook";

export const BanknoteTypesBottomSheet = ({
  isOpen,
  hideModal,
  onExit,
  className,
  ...props
}: BanknoteTypesBottomSheetProps): JSX.Element => {
  const { banknotes, changeFromBanknote, handleChangeBanknote } = useBasketContext();

  const handelChangePayment = (item: IBanknote) => {
    handleChangeBanknote(item);
    setTimeout(hideModal, 300);
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onExit={onExit}
      hideModal={hideModal}
    >
      <div className={clsx(styles.wrapper, className)} {...props}>
        <TitleMobile headerType='h4'>Сдача с купюры</TitleMobile>
        <div className={styles.paymentsList}>
          {banknotes.map((item) => {
            const { id, type } = item;

            return (
              <div
                key={id}
                className={clsx(styles.payment, {
                  [styles.checked]: id === changeFromBanknote?.id
                })}
                onClick={() => handelChangePayment(item)}
              >
                <span className={styles.paymentText}>
                  {type} ₽
                </span>
                <span className={styles.pseudoInput}></span>
              </div>
            );
          })}
        </div>
      </div>
    </BottomSheet>
  );
};
