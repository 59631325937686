import clsx from "clsx";

import { ButtonDesktop } from '~/components';
import AuthNickNameProps from "./AuthNickName.props";
import styles from "./AuthNickName.module.css";
import AlertIcon from "~/assets/icons/alert-colored.svg";

import useSaveUserName from '~/hooks/useSaveUserName';
import useEnterElement from '~/hooks/useEnterElement';

export const AuthNickName = ({
  onSuccessAuth = () => null,
  isOrderingScreen = false,
  ...props
}: AuthNickNameProps): JSX.Element => {
  const {
    setName,
    handleSaveName,
    name,
    isLoading,
    errors
  } = useSaveUserName(onSuccessAuth);

  useEnterElement({
    isRegisterListener: name.length >= 2,
    elementSelector: "#portal[data-portal]",
    callBack: handleSaveName
  });

  return (
    <div className={clsx({ [styles.authNickName]: isOrderingScreen })} {...props}>
      {!isOrderingScreen ? (
        <p className={styles.text}>
          Введите ваше имя, чтобы мы могли к вам правильно обращаться
        </p>
      ) : null}
      <div
        className={clsx(styles.inputWrapper, {
          [styles.inputWrapperOrdering]: isOrderingScreen,
          [styles.inputWrapperInvalid]: errors.length,
        })}
      >
        <AlertIcon className={styles.alertSvg} />
        <input
          className={clsx(styles.input, {
            [styles.inputOrdering]: isOrderingScreen,
          })}
          type="text"
          autoComplete="off"
          placeholder="Введите ваше имя"
          value={name}
          autoFocus
          onChange={(e) => setName(e.target.value)}
        />
        <div className={styles.alertPrompt}>
          {errors[0]}
        </div>
      </div>

      <ButtonDesktop
        type='button'
        onClick={handleSaveName}
        disabled={isLoading || name.length < 2}
        loading={isLoading}
        className={clsx(styles.confirmBtn, { [styles.confirmBtnOrdering]: isOrderingScreen })}
      >
        Сохранить
      </ButtonDesktop>
    </div>
  );
};
