import { useMemo, useState } from "react";
import { useRouter } from 'next/router';
import Image from "next/legacy/image";
import { isEqual } from 'lodash';
import clsx from "clsx";

import { ButtonMobile, CheckboxChoise, CounterMobile, MobileModal, TitleMobile } from "~/components";
import { RadioChoise } from "~/components/RadioChoise";

import { AdditiveCamelCase, Product } from "~/models/basket";
import AdditivesModalMobileeProps from "./AdditivesModalMobile.props";
import styles from "./AdditivesModalMobile.module.css";
import ClosePlateIcon from "~/assets/icons/shopping-store-signage.svg";
import DefaultImage from "~/assets/svg/product-placeholder.webp";

import { additiveCamelCaseToPascalCase, partnerIsNotOpenYet, partnerIsWorking } from "~/helpers";
import useConfirmBottomSheet from '~/hooks/useConfirmBottomSheet';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { usePartnerInfo } from '~/hooks/partner';

interface IAdditiveGroup {
	name: string;
	additives: AdditiveCamelCase[];
}

const getRequiredAdditivesMap = (
	additives: IAdditiveGroup[]
): Record<string, AdditiveCamelCase> => {
	return additives.reduce(
		(acc, a) => ({
			...acc,
			[a.name]: a.additives[0],
		}),
		{}
	);
};

export const AdditivesModalMobile = ({
	isOpen,
	hideModal,
	onExit,
	className,
	product,
	...props
}: AdditivesModalMobileeProps): JSX.Element => {
	const router = useRouter();
	const { addToBasket, basket } = useBasketContext();
	const { data: partner } = usePartnerInfo(router.query.id as string ?? "");
	const [requiredAdditives, setRequiredAdditives] = useState<
		Record<string, AdditiveCamelCase>
	>(getRequiredAdditivesMap(product.requiredAdditiveGroups));

	const [localAdditives, setLocalAdditives] = useState<AdditiveCamelCase[]>([]);

	const requiredAdditivesList = useMemo(
		() => Object.values(requiredAdditives),
		[requiredAdditives]
	);

	const additivesCost = useMemo(
		() =>
			requiredAdditivesList.reduce((sum, a) => sum && sum + a.cost, 0) +
			localAdditives.reduce((sum, a) => sum + a.cost, 0),
		[requiredAdditivesList, localAdditives]
	);

	const { requiredAdditiveGroups, additives } = product;

	const handleAdd = (additive: AdditiveCamelCase) => {
		setLocalAdditives((state) => [...state, additive]);
	};

	const handleRemove = (id: string) => {
		setLocalAdditives((state) => state.filter((a) => a.id !== id));
	};

	const handleAddRequired = (
		additive: AdditiveCamelCase,
		groupName: string
	) => {
		setRequiredAdditives({ ...requiredAdditives, [groupName]: additive });
	};

	const [loading, setLoading] = useState(false);

	const [quantity, setQuantity] = useState(1);
	// const [defQuantity, setDefQuantity] = useState(0);

	// const content = basket?.content;

	// useEffect(() => {
	// 	if (!content?.length) return;
	// 	const currentProductIndex = content.findIndex((item) => item.productId === product.id);
	// 	if (currentProductIndex >= 0) {
	// 		setDefQuantity(content[currentProductIndex].quantity);
	// 	}
	// }, [content, product.id]);

	const isHaveProductsWithBasket = useMemo(() => basket?.partnerId === partner?.id && basket?.content.length, [basket, partner]);

	const handleAddProduct = async () => {
		if (!partnerIsWorking(partner?.schedule) && !isHaveProductsWithBasket) {
			return showConfirmBottomSheet();
		}
		return await handleAddToBasket()
	};

	const handleAddToBasket = async () => {
		if (!partner) return
		try {
			// Has in basket like this
			setLoading(true);
			const productCart: Product = {
				productId: product?.id,
				name: product?.name,
				quantity: quantity,
				cost: product?.cost,
				photoUri: product?.image,
				additives: [...requiredAdditivesList, ...localAdditives].map((a) =>
					additiveCamelCaseToPascalCase(a)
				),
			};

			const allProuctsById =
				basket?.content.filter((p) => p.productId === productCart.productId) || [];

			const findedDish = allProuctsById.find((p) => {
				const productAdditivesIds = p.additives.map((a) => a.id);
				const productCartAdditivesIds = productCart.additives.map((a) => a.id);

				return isEqual(productAdditivesIds, productCartAdditivesIds);
			});

			const findedDishQuantity = (findedDish?.quantity ?? 0) + quantity;

			await addToBasket({
				partnerId: partner?.id,
				marketCategoryId: partner?.marketCategoryId,
				quantity: findedDishQuantity,
				product: productCart,
			});

			hideModal();
		} catch (e) {
			console.log("Ошибка добавления товара: ", e);
		} finally {
			setLoading(false);
		}
	};

	const beginTime = partner?.schedule.begin.slice(0, 5)
	const confirmMessage = partnerIsNotOpenYet(partner?.schedule) ?
		`сегодня с ${beginTime}` :
		`завтра c ${beginTime}`;

	const { showConfirmBottomSheet } = useConfirmBottomSheet({
		onConfirm: handleAddToBasket,
		onCancel: () => router.push(`/?categoryId=${partner?.marketCategoryId}`),
		title: "Доступен предзаказ",
		message: `Мы можем доставить только когда ${partner?.marketCategoryId == 1 ? "ресторан" : "магазин"} откроется - ${confirmMessage}`,
		cancelText: "Посмотреть другие места",
		confirmText: "Оформить предзаказ",
		icon: <ClosePlateIcon />,
		dependencies: [basket, partner, quantity]
	});

	return (
		<MobileModal fullScreen hiddenHeader isOpen={isOpen} hideModal={hideModal} onExit={onExit}>
			<div
				className={clsx(styles.wrapper, className)}
				{...props}
			>
				<div className={styles.header}>
					<Image
						className={styles.img}
						unoptimized
						layout="fill"
						objectFit="cover"
						alt={product.name}
						src={product.image.length ? product.image : DefaultImage}
					/>
				</div>

				<div className={styles.content}>
					<>
						<TitleMobile headerType='h3'>{product.name}</TitleMobile>

						<div className={styles.infoItem}>
							{product.portionSize ?? ""}
						</div>

						<div className={styles.infoItem}>
							{product.description ?? ""}
						</div>

						{requiredAdditiveGroups?.length
							? requiredAdditiveGroups.map((group) => (
								<div key={group.name}>
									{group.additives.length ? (
										<TitleMobile headerType='h4'>{group.name}*</TitleMobile>
									) : null}
									<div>
										{group.additives.map((additive) => (
											<RadioChoise
												isActive={
													requiredAdditives[group.name]?.id === additive.id
												}
												onSelect={() => handleAddRequired(additive, group.name)}
												key={additive.id}
												additive={additive}
											/>
										))}
									</div>
								</div>
							))
							: null}

						{additives?.length ? (
							<>
								<TitleMobile headerType='h4'>Не обязательные</TitleMobile>
								<div>
									{additives.map((additive) => (
										<CheckboxChoise
											onRemove={() => handleRemove(additive.id)}
											onSelect={() => handleAdd(additive)}
											key={additive.id}
											additive={additive}
											className={styles.checkboxItem}
										/>
									))}
								</div>
							</>
						) : null}
					</>

					<div className={styles.bottomBar}>
						<CounterMobile
							loading={loading}
							onMinus={() => setQuantity((q) => Math.max(q - 1, 1))}
							onPlus={() => setQuantity((q) => Math.min(q + 1, 99))}
							value={quantity}
						/>
						<div className={styles.totalWrp}>
							{quantity > 1 && additives ? (
								<span className={styles.forOnePrice}>
									{product.cost + additivesCost} ₽ за 1 шт.
								</span>
							) : null}
							<div className={styles.total}>
								Сумма:{" "}
								<span className={styles.totalPrice}>
									{product.cost * quantity} ₽
								</span>
							</div>
						</div>
						<ButtonMobile
							size='l'
							onClick={handleAddProduct}
							type="submit"
							className={styles.buttonAddToCart}
						>
							ДОБАВИТЬ
						</ButtonMobile>
					</div>
				</div>
			</div>
		</MobileModal>
	);
};
