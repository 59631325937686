import { PropsWithChildren, useEffect, useRef, useState, useCallback } from "react";
import DesktopBannerModalProps from "./DesktopBannerModal.props";
import clsx from "clsx";
import styles from "./DesktopBannerModal.module.css";

import CopyIcon from "~/assets/svg/promoCodeCopy.svg";
import { getPromotionInformation } from "~/plugins/api";
import { MobileModal } from '../_Components/MobileModal';
import { BottomSheet } from '../_Components/BottomSheet';

interface PromotionInfo {
    id: string;
    promoCode: string | null;
    promotionName: string;
    description: string | null;
}

export const DesktopBannerModal = ({
    isOpen,
    onExit,
    hideModal,
    promotionId,
    description,
    className,
    ...props
}: PropsWithChildren<DesktopBannerModalProps>): JSX.Element => {
    const [copiedText, setCopiedText] = useState<boolean>(false);
    const [promotion, setPromotion] = useState<PromotionInfo | undefined>(undefined);
    const { promoCode } = promotion || {};

    const fetchPromotionInfo = useCallback(async () => {
        const res = await getPromotionInformation({ promotionId });
        setPromotion(res.promotion);
    }, [promotionId]);

    useEffect(() => {
        if (!promotion) fetchPromotionInfo();
    }, [fetchPromotionInfo, promotion]);

    const copyToClipBoard = (text: string) => {
        navigator.clipboard.writeText(text.toUpperCase());
        setCopiedText(true);
        setTimeout(() => {
            setCopiedText(false);
            hideModal();
        }, 1000);
    };

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref?.current?.children && ref?.current?.children.length) {
            const input = ref?.current?.children[
                ref?.current?.children.length - 1
            ] as HTMLElement;

            if (input) input.focus();
        }
    }, []);

    return (
        <BottomSheet isOpen={isOpen} hideModal={hideModal} onExit={onExit}>
            <div
                className={clsx(styles.wrapper, className)}
                {...props}
            >
                <div ref={ref}>
                    {promoCode && (
                        <>
                            <div className={clsx(styles.mobileCopied, {
                                [styles.isCopied]: copiedText,
                            }
                            )}>
                                Промокод скопирован
                            </div>
                            <div className={styles.mobileBottom}>
                                <div className={styles.mobilePromoCode}>{promoCode}</div>
                                <div onClick={() => copyToClipBoard(promoCode)}
                                    className={styles.mobileCopyBtn}>
                                    <CopyIcon />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </BottomSheet>
    );
};
