import { PropsWithChildren } from "react";
import { useModal } from 'react-modal-hook';
import clsx from "clsx";

import { Modal, ButtonDesktop } from '~/components';
import { OrderFeedbackView } from '~/components/OrderFeedbackView';
import ButtonAddOrderFeedbackProps from "./ButtonAddOrderFeedback.props";
import MessageIcon from "~/assets/icons/message.svg";
import styles from "./ButtonAddOrderFeedback.module.css";
import { useGetOrder } from '~/hooks/order';

export const ButtonAddOrderFeedback = ({
  orderId,
  className,
  ...props
}: PropsWithChildren<ButtonAddOrderFeedbackProps>): JSX.Element => {
  const { data: order, refetch } = useGetOrder(orderId);

  const [showModal, hideModal] = useModal(({ in: open, onExited }) => {
    return (
      <Modal
        headline='Отзыв к заказу'
        width={600}
        isOpen={open}
        onExit={onExited}
        hideModal={hideModal}
      >
        {order ? (
          <OrderFeedbackView
            isModal
            handleSuccessFeedBack={() => {
              hideModal();
              refetch();
            }}
            data={order?.orderFeedback}
            orderId={order?.id}
          />
        ) : null}
      </Modal>
    );
  }, [order?.orderFeedback]);

  if (order?.statusId !== 4) return <></>;

  return (
    <ButtonDesktop
      size='m'
      type='button'
      appearance='gray'
      className={clsx(styles.button, className)}
      onClick={showModal}
      {...props}
    >
      <MessageIcon className={styles.buttonIcon} />
      {order.orderFeedback ? 'МОЙ ОТЗЫВ' : 'ОСТАВИТЬ ОТЗЫВ'}
    </ButtonDesktop>
  );
};
