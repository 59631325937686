import { useModal } from 'react-modal-hook';
import clsx from "clsx";

import { BanknoteTypesPopup, ButtonDesktop } from "~/components";
import SelectChangeBanknoteDesktopProps from "./SelectChangeBanknoteDesktop.props";
import styles from "./SelectChangeBanknoteDesktop.module.css";
import ArrowIcon from "~/assets/icons/arrow-down-2.svg";
import PanhandlerIcon from "~/assets/icons/panhandler-icon.svg";

import { useBasketContext } from '~/hooks/basket/useBasket.hook';

export const SelectChangeBanknoteDesktop = ({
  className,
  ...props
}: SelectChangeBanknoteDesktopProps): JSX.Element => {
  const { activePayment, changeFromBanknote } = useBasketContext();

  const [showPopup, hidePopup] = useModal(
    ({ in: open, onExited }) => {
      return <BanknoteTypesPopup isOpen={open} hidePopup={hidePopup} onExit={onExited} elementId={'payment'} />
    }
  );
  if (activePayment?.id !== 1) return <></>

  return (
    <ButtonDesktop
      id='payment'
      size="m"
      appearance='text'
      className={clsx(styles.buttonSelectPayment, className)}
      onClick={showPopup}
      {...props}
    >
      <span
        className={styles.paymentIcon}
      >
        <PanhandlerIcon />
      </span>
      <span className={styles.paymentText}>
        {changeFromBanknote ? changeFromBanknote?.type + " ₽" : "Сумма, с которой нужна сдача"}
      </span>

      <ArrowIcon className={styles.arrowIcon} />

    </ButtonDesktop>
  );
};
