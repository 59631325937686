import { useRouter } from 'next/router';
import Image from "next/legacy/image";
import clsx from "clsx";

import { AboutPartnerButton } from './AboutPartnerButton';
import { AboutDeliveryButton } from './AboutDeliveryButton';
import { PartnerRatingButton } from './PartnerRatingButton';
import PartnerAboutMobileProps from "./PartnerAboutMobile.props";
import styles from "./PartnerAboutMobile.module.css";

import { usePartnerInfo } from '~/hooks/partner';
import { TitleMobile } from '../TitleMobile';

export const PartnerAboutMobile = ({
  className,
}: PartnerAboutMobileProps): JSX.Element => {
  const router = useRouter();
  const partnerId = router.query.id as string;
  const { data: partner } = usePartnerInfo(partnerId ?? "");

  return (
    <div className={clsx(styles.partnerAbout, className)}>
      <div className={styles.topBar}>
        <div className={styles.imageWrapper}>
          <Image
            priority
            unoptimized
            alt={String(partner?.name)}
            layout="fill"
            objectFit="contain"
            src={String(partner?.logo)}
          />
        </div>
        <div className={styles.titleBlock}>
          <TitleMobile
            title={String(partner?.name)}
            headerType="h4"
            margin={false}
          >
            {partner?.name}
          </TitleMobile>
          <div className={styles.partnerShortName}>
            {partner?.shortDescription}
          </div>
        </div>

        {/* <div className={styles.favorite}>
          <PartnerAboutFavorite />
        </div> */}
      </div>

      <div className={styles.buttonsWrapper}>
        {partner?.feedbackCount ? (
          <PartnerRatingButton />
        ) : null}

        <AboutDeliveryButton />

        <AboutPartnerButton />
      </div>
    </div>
  );
};
