import { PropsWithChildren, useLayoutEffect, useState } from "react";
import clsx from "clsx";

import PopupProps from "./Popup.props";
import styles from "./Popup.module.css";
import useChangeVisiblePopup from '~/hooks/useChangeVisiblePopup';

interface ICoordinates { top?: string, bottom?: string, left?: string, right?: string }

export const Popup = ({
  isOpen,
  onExit,
  hidePopup,
  offset = 10,
  placement = 'left', //пока что не реализован функционал с позиционированием
  isHideWhenScrolling = true,
  elementId,
  children,
  className,
  ...props
}: PropsWithChildren<PopupProps>): JSX.Element => {
  useChangeVisiblePopup({ isOpen, hideModal: hidePopup, onExit, isHideWhenScrolling });

  const popupStyleAbove = isOpen ? [
    { transform: 'translateY(-8%)', opacity: 0 },
    { opacity: 0, offset: .5 },
    { transform: 'translateY(0)', opacity: 1 }
  ] : [
    { transform: 'translateY(0%)', opacity: 1 },
    { transform: 'translateY(-8%)', opacity: 0 }
  ];

  const popupStyleBelow = isOpen ? [
    { transform: 'translateY(8%)', opacity: 0 },
    { opacity: 0, offset: .5 },
    { transform: 'translateY(0)', opacity: 1 }
  ] : [
    { transform: 'translateY(0%)', opacity: 1 },
    { transform: 'translateY(8%)', opacity: 0 }
  ];

  const [showOneSelf, setShowOneSelf] = useState<"above" | "below">("below");

  const popupStyle = showOneSelf === "above" ? popupStyleAbove : popupStyleBelow;

  const getCoords = (): ICoordinates => {
    const body = document.body.getBoundingClientRect();
    const element = document.getElementById(elementId)?.getBoundingClientRect();
    const elementBottomPositionInView = (element && body?.height - element?.bottom) ?? 0;
    const elementTopPositionInView = element?.top ?? 0;
    const isLeftOrRight = ((element && body?.width - element?.left) ?? 0) > body?.width / 2;

    if (!element) return {}
    if (elementTopPositionInView > elementBottomPositionInView) {
      setShowOneSelf("above")
      return {
        bottom: `${body?.height - element?.y + offset}px`,
        left: isLeftOrRight ? `${element?.left}px` : "unset",
        right: !isLeftOrRight ? `${body?.width - element?.right}px` : "unset",
      };
    } else {
      setShowOneSelf("below")
      return {
        top: `${element?.bottom + offset}px`,
        left: isLeftOrRight ? `${element?.left}px` : "unset",
        right: !isLeftOrRight ? `${element && body?.width - element?.right}px` : "unset",
      };
    }
  };

  const [coordinates, setСoordinates] = useState<ICoordinates | null>(null);

  const setPopupCoordinates = () => setСoordinates(() => getCoords());

  useLayoutEffect(() => {
    const popupElement = document.getElementById(`${elementId}_popup`);
    const popup = new KeyframeEffect(
      popupElement,
      popupStyle,
      {
        duration: 300, iterations: 1, fill: 'forwards'
      }
    );
    const popupAnimation = new Animation(popup);
    popupAnimation.play();

    setPopupCoordinates();
  }, [isOpen, showOneSelf]);

  return (
    <div className={clsx(styles.wrapper, {
      [styles.open]: isOpen,
      [styles.close]: !isOpen,
    })}>
      <div className={clsx(styles.overlay, { [styles.backgroundOverlay]: !isHideWhenScrolling })} onClick={hidePopup} />

      <div
        id={`${elementId}_popup`}
        style={coordinates ? coordinates : {}}
        className={clsx(styles.window, className)}
        {...props}
      >
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};