import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { NIL as NIL_UUID, v4 as uuid } from "uuid";

import { confirm } from "~/components/modals/ConfirmHocModal";
import { confirmBottomSheet } from "~/components/modals/ConfirmHocBottomSheet";
import {
  deleteUserAddress,
  editUserAddress,
  getUserAddresses,
  setUserAddress,
} from "~/plugins/api";
import { customAuthFetch } from "~/plugins/services/fetch";
import { UserAddressToken, UserAddressWithList } from "~/models";
import { getCookie, removeCookie, setCookie } from "~/helpers/cookieUtilites";
import { getDeviceType } from '~/helpers/getDeviceType';

interface IContextProps {
  location: UserAddressToken | null;
  loadingAddress: boolean;
  userAddresses: UserAddressWithList[];
  handleGetUserAddresses: () => void;
  handleSetLocation: (location: UserAddressToken | null) => void;
  addAddress: (locationParams: UserAddressToken) => void;
  editAddress: (address: UserAddressWithList) => void;
  deleteAddress: (id: string) => void;
}

const AddressContext = createContext<IContextProps>({
  location: null,
  loadingAddress: false,
  userAddresses: [],
  handleGetUserAddresses: () => null,
  handleSetLocation: () => null,
  addAddress: () => null,
  editAddress: () => null,
  deleteAddress: () => null,
});

interface IState {
  location: UserAddressToken | null;
  userAddresses: UserAddressWithList[];
  loadingAddress: boolean;
}

const getToken = (): string | null => (getCookie("cron-token") as string) ?? null;

export function AddressProvider({ children }: { children: ReactNode }) {
  const [{ location, userAddresses, loadingAddress }, setState] = useState<IState>(() => {
    const locationToken = getCookie("cron-user-location");
    try {
      const parsedlocation = locationToken ? JSON.parse(locationToken) : null;
      return {
        location: parsedlocation,
        userAddresses: [],
        loadingAddress: false,
      };
    } catch (e) {
      console.warn("Ошибка парсинга адреса !");
      removeCookie("cron-user-location");
      return {
        location: null,
        userAddresses: [],
        loadingAddress: false,
      };
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const addressList: UserAddressWithList[] = getToken() ? await customAuthFetch<UserAddressWithList[]>(() =>
          getUserAddresses()
        ) : [];

        setState((prevState) => ({
          ...prevState,
          userAddresses: addressList,
        }));

      } catch (e) {
        console.warn("Ошибка загрузки адресов !", e);
      }
    })();
  }, []);

  const isNewAddress = (userAddresses: UserAddressWithList[], address: UserAddressToken | null) => {
    if (!address) return false;
    if (!userAddresses.length) return true;
    const isNewAddress = !userAddresses.find(
      (item) =>
        item.address === address.address &&
        item.cityName === address.cityName
    );
    return isNewAddress;
  };

  const handleGetUserAddresses = async () => {
    const addressList = await customAuthFetch<UserAddressWithList[]>(() =>
      getUserAddresses()
    );
    setState((prevState) => ({
      ...prevState,
      userAddresses: addressList,
    }));

    if (location?.id === NIL_UUID && isNewAddress(addressList, location)) {
      setState((prevState) => ({
        ...prevState,
        loadingAddress: true,
      }));

      const result = await customAuthFetch<UserAddressWithList>(() =>
        setUserAddress(location)
      );

      setCookie({ name: "cron-user-location", value: result });

      handleSetLocation(result);

      setState((prevState) => ({
        ...prevState,
        userAddresses: [result, ...addressList],
        loadingAddress: false,
      }));
    }
  };

  const handleSetLocation = (newLocation: UserAddressToken | null) => {
    if (newLocation) {
      setCookie({ name: "cron-user-location", value: newLocation });
      return setState((prevState) => ({
        ...prevState,
        location: newLocation,
      }));
    } else {
      removeCookie("cron-user-location");
      return setState((prevState) => ({
        ...prevState,
        location: newLocation,
      }));
    }
  };

  const addAddress = async (address: UserAddressToken) => {
    if (getToken()) {
      if (address?.id === NIL_UUID && isNewAddress(userAddresses, address)) {
        setState((prevState) => ({
          ...prevState,
          loadingAddress: true,
        }));

        const result = await customAuthFetch<UserAddressWithList>(() =>
          setUserAddress(address)
        );

        setCookie({ name: "cron-user-location", value: result });

        handleSetLocation(result);

        setState((prevState) => ({
          ...prevState,
          userAddresses: [result, ...userAddresses],
          loadingAddress: false,
        }));
      } else {
        const selectedAddress = userAddresses.find(
          (item) =>
            item.address === address.address &&
            item.cityName === address.cityName
        );

        if (selectedAddress) {
          setCookie({ name: "cron-user-location", value: selectedAddress });
          handleSetLocation(selectedAddress);
        }

        console.log("такой адрес уже существует !");
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        location: address,
      }));
      setCookie({ name: "cron-user-location", value: address });
    }
  };

  const isNewChangedAddress = (userAddresses: UserAddressWithList[], address: UserAddressToken | null) => {
    if (!address) return false;
    if (!userAddresses.length) return true;
    return !userAddresses.find(
      (item) =>
        item.address === address.address &&
        item.cityName === address.cityName &&
        item.floor === address.floor &&
        item.flat === address.flat &&
        item.entrance === address.entrance &&
        item.intercom === address.intercom &&
        item.comment?.trim().toLocaleLowerCase() === address.intercom?.trim().toLocaleLowerCase()
    );
  };

  const editAddress = async (address: UserAddressWithList) => {
    setState((prevState) => ({
      ...prevState,
      loadingAddress: true,
    }));

    if (isNewChangedAddress(userAddresses, address)) {
      const editItem = await customAuthFetch<UserAddressWithList>(() =>
        editUserAddress(address)
      );

      setState((prevState) => ({
        ...prevState,
        userAddresses: userAddresses.map((item) => (item.id === editItem.id ? editItem : item)),
        loadingAddress: false,
      }));
      if (editItem.id === location?.id) {
        setCookie({ name: "cron-user-location", value: editItem });

        handleSetLocation(editItem);
      }
    } else {
      console.log("такой адрес уже существует !");
    }
  };

  const deleteAddress = async (id: string) => {
    const confirmWindow = getDeviceType(window.navigator.userAgent).isDesktop ?
      confirm : confirmBottomSheet;
    await confirmWindow({
      confirmation: "Вы действительно хотите удалить сохранёный адрес ?",
    });
    setState((prevState) => ({
      ...prevState,
      loadingAddress: true,
    }));

    const deleteId = await customAuthFetch<string>(() => deleteUserAddress(id));
    const indx = userAddresses.findIndex(({ id }) => id === deleteId);

    setState((prevState) => ({
      ...prevState,
      userAddresses: [
        ...userAddresses.slice(0, indx),
        ...userAddresses.slice(indx + 1),
      ],
      loadingAddress: false,
    }));
    if (location?.id === id) {
      removeCookie("cron-user-location");
      handleSetLocation(null);
    }
  };

  return (
    <AddressContext.Provider
      value={{
        location,
        userAddresses,
        loadingAddress,
        handleGetUserAddresses,
        handleSetLocation,
        addAddress,
        editAddress,
        deleteAddress,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export function useAddressContext() {
  return useContext(AddressContext);
}