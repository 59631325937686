import CreditCardIcon from "~/assets/svg/credit-card.png";

const orderStatuses = {
  moderation: {
    id: 1,
    name: "Модерация",
  },
  isPrepared: {
    id: 2,
    name: "Готовится",
  },
  inWay: {
    id: 3,
    name: "В пути",
  },
  delivered: {
    id: 4,
    name: "Доставлен",
  },
  cancelled: {
    id: 5,
    name: "Отменен",
  },
};

const tokenCookie = "cron-token",
  locationCookie = "cron-user-location",
  basketCookie = "cron-user-basket",
  userCookie = "cron-user";

const ACCESS_LOGIN_PAGES = [
  "/myorders",
  "/myorders/details/[id]",
  "/myorders/details/feedback/[id]",
  "/myaddresses",
  "/ordercheckout",
  "/track",
  "/cart",
];

const partnerpath = [
  "/myorders",
  "/myorders/details/[id]",
  "/myorders/details/feedback/[id]",
  "/myaddresses",
  "/ordercheckout",
  "/track",
  "/cart",
];

enum CardsHeight {
  desktopProductCard = 352,
  desktopPartnerCard = 328,
  mobilePartnerCard = 284,
  mobileProductCard = 284,
}

type PaymentMethodsType = 1 | 2;

enum PaymentMethods {
  cash = 1,
  online = 2,
}

enum CardTypes {
  mir = 2,
  visa = 4,
  mastercard = 5,
}

enum LinkNewOnlineCard {
  key = "link_new_online_card",
}

export {
  orderStatuses,
  tokenCookie,
  locationCookie,
  basketCookie,
  userCookie,
  ACCESS_LOGIN_PAGES,
  CardsHeight,
  PaymentMethods,
  CardTypes,
  LinkNewOnlineCard,
};
export type { PaymentMethodsType };
