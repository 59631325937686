import { useMemo } from 'react';
import { useRouter } from 'next/router';
import clsx from "clsx";

import { ButtonMobile, CutleryCounterMobile, LoadingIndicator } from "~/components";
import OrderOptionsMobileProps from "./OrderOptionsMobile.props";
import styles from "./OrderOptionsMobile.module.css";
import HomeIcon from "~/assets/icons/green-earth.svg";
import EmptySvg from "~/assets/svg/empty-cart.svg";
import { CartItemMobile } from './CartItemMobile';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

export const Content = ({
  className,
  ...props
}: OrderOptionsMobileProps): JSX.Element => {
  const router = useRouter();
  const { basket } = useBasketContext();
  const isRestaurant = basket?.marketCategoryId == 1;
  const isEmptyBasket = !basket?.content.length;

  const productDiscount = useMemo(() => {
    if (!basket) return 0;
    return basket?.content.reduce((sum, item) => {
      if (item.oldCost) {
        return sum += (item.oldCost - item.cost) * item.quantity;
      }
      return sum;
    }, 0)
  }, [basket]);

  const amount = useMemo(() => {
    if (!basket) return 0;

    return Math.ceil(basket.amount + basket.deliveryCost - basket.discount);
  }, [basket]);

  if (!basket) return <LoadingIndicator isScreen />

  return (
    <div className={clsx(styles.content, { [styles.emptyContent]: isEmptyBasket }, className)} {...props}>
      {isEmptyBasket ? (
        <div className={styles.emptyBody}>
          <div className={styles.imageWrapper}>
            <EmptySvg />
            <p className={styles.emptyText}>
              Ваша корзина все еще пуста, исправьте это!
            </p>
          </div>
          <div className={styles.buttonsWrapper}>
            <ButtonMobile
              className={styles.actionButton}
              onClick={() => router.back()}
            >
              Посмотреть меню
            </ButtonMobile>
            <ButtonMobile
              appearance='gray'
              className={styles.actionButton}
              onClick={() => router.push(`/?categoryId=${basket.marketCategoryId}`)}
            >
              На главную
            </ButtonMobile>
          </div>
        </div>
      ) : (
        <div className={styles.cartBody}>
          {basket?.content.map((product) => {
            return (
              <CartItemMobile
                marketCategoryId={basket?.marketCategoryId}
                partnerId={basket?.partnerId}
                product={product}
                key={product.id}
              />
            );
          })}

          {isRestaurant && basket ? (
            <>
              <div className={styles.separateLine} />
              <CutleryCounterMobile basket={basket} />
              <p className={styles.greenText}>
                <span><HomeIcon className={styles.homeIcon} /></span>
                <span>
                  {basket?.cutleryCount > 0 ? `
                    Отказ от пластика поможет сберечь окружающую среду.
                    Берите приборы только если нет своих под рукой.
                    ` : `
                    Спасибо! Отказ от пластика поможет сберечь окружающую среду.
                    Берите приборы только если нет своих под рукой.
                  `}
                </span>
              </p>
            </>
          ) : null}

          <div className={styles.separateLine} />
          <div>
            <p className={styles.paymentItemInfo}>
              <span>Товары:</span>
              <span className={styles.itemSumm}>{basket && basket?.amount + productDiscount} ₽</span>
            </p>
            {basket?.discount ? <p className={styles.paymentItemInfo}>
              <span>Промокод:</span>
              <span className={styles.itemSumm}>{basket?.discount == 0 ? "0" : `-${basket?.discount}`} ₽</span>
            </p> : null}
            {productDiscount ? <p className={styles.paymentItemInfo}>
              <span>Скидка:</span>
              <span className={styles.itemSumm}>{productDiscount == 0 ? "0" : `-${productDiscount}`} ₽</span>
            </p> : null}
            <p className={styles.paymentItemInfo}>
              <span>Доставка:</span>
              <span className={styles.itemSumm}>
                {basket?.oldDeliveryCost !== basket?.deliveryCost && <span className={styles.underText}>{basket?.oldDeliveryCost}</span>}
                <span className={clsx({ [styles.redText]: basket?.oldDeliveryCost !== basket?.deliveryCost })}>
                  {basket?.deliveryCost} ₽
                </span>
              </span>
            </p>
            <p className={clsx(styles.paymentItemInfo, styles.totalSumm)}>
              <span>Итого:</span>
              <span className={styles.itemSumm}>{amount} ₽</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
