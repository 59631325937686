import { useCallback, useEffect, useState } from "react";
import clsx from 'clsx';

import { ButtonMobile, TitleMobile } from '~/components';
import MapProps from "./MapViewMobile.props";
import styles from "./MapViewMobile.module.css";
import useDebounce from '~/hooks/useDebounce';
import ArrowRightBlack from "~/assets/icons/arrow-right-black.svg";

declare const ymaps: any;

export const MapViewMobile = ({
  initAddress = null,
  handleSaveAddress,
  setActiveStage,
  getUserGeoData,
}: MapProps): JSX.Element => {
  const [selectedAddress, setSelectedAddress] = useState("");
  const [searchAddress, setSearchAddress] = useState(initAddress?.address);
  const [yandexMap, setYandexMap] = useState<any | null>(null);
  // const [geoCodeCoords, setGeoCodeCoords] = useState<number[] | string[] | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [mapActionCoords, setMapActionCoords] = useState<number[] | []>([]);

  const mapCenterCoords = useDebounce(mapActionCoords, 2000)

  useEffect(() => {
    if (mapCenterCoords.length) {
      getAddress(mapCenterCoords);
    }
  }, [mapCenterCoords[0], mapCenterCoords[1], initAddress])

  function init(latitude: number | null, longitude: number | null) {
    const defCoords = latitude && longitude ? [latitude, longitude] : [42.9747296, 47.5089114];
    const initCoords = initAddress?.latitude && initAddress.longitude ? [initAddress?.latitude, initAddress?.longitude] : defCoords;

    const map = new ymaps.Map(
      "map",
      {
        center: initCoords,
        zoom: 18,
      },
      {
        searchControlProvider: "yandex#search",
      }
    );

    map && setYandexMap(map);

    map.setCenter(initCoords, map.getZoom());

    map.controls.remove("geolocationControl"); // удаляем геолокацию
    map.controls.remove("searchControl"); // удаляем поиск
    map.controls.remove("trafficControl"); // удаляем контроль трафика
    map.controls.remove("typeSelector"); // удаляем тип
    map.controls.remove("fullscreenControl"); // удаляем кнопку перехода в полноэкранный режим
    map.controls.remove("zoomControl"); // удаляем контрол зуммирования
    map.controls.remove("rulerControl"); // удаляем контрол правил
    // map.behaviors.disable(['scrollZoom']); // отключаем скролл карты (опционально)

    const elem = document.getElementsByClassName(
      "ymaps-2-1-79-copyrights-pane"
    ) as HTMLCollectionOf<HTMLDivElement>;

    if (elem[0]) {
      elem[0].style.bottom = "-15px";
    }

    // const geolocationControl = new ymaps.control.GeolocationControl({
    //   options: {
    //     layout: ymaps.templateLayoutFactory.createClass(
    //       "<div class='geoConrol mobileGeoConrol' title='{{ data.title }}'>"
    //       + "{{ data.content }}" +
    //       "</div>"
    //     ),
    //     noPlacemark: true,
    //   }
    // });

    // geolocationControl.events.add("locationchange", function (event: any) {
    //   const position = event.get("position");
    //   // const locationPlacemark = new ymaps.Placemark(position);

    //   // map.geoObjects.options.set("preset", "islands#blueCircleIcon");
    //   // map.geoObjects.add(locationPlacemark);
    //   // Установим новый центр карты в текущее местоположение пользователя.
    //   // map.panTo(position);
    //   console.log(position)
    //   map.setCenter(position, 17);
    // });

    // map.controls.add(geolocationControl, {
    //   float: "none",
    //   position: { right: "16px", bottom: "180px" },
    //   floatIndex: 100,
    // });

    if (document.getElementById("suggestMobile")) {
      const suggestOptions = {
        results: 8,
        offset: [0, 10],
        boundedBy: initCoords,
        // strictBounds: true,
        // noSuggestPanel: false,
      };

      const suggestMobile = new ymaps.SuggestView('suggestMobile', {
        ...suggestOptions,
        provider: {
          suggest: (function (request: string, options: any) {
            return ymaps.suggest("Россия, Дагестан, " + request, suggestOptions).then(function (items: any) {
              let newItems: any[] = [];
              for (let i = 0; i < items.length; i++) {
                const item = {
                  ...items[i],
                  displayName: items[i].displayName.replace(/, Республика Дагестан, Россия/g, ''),
                  value: items[i].value.replace(/Россия, Республика Дагестан, /g, '')
                };
                newItems = [...newItems, item]
              }

              suggestMobile.events.fire('requestsuccess', {
                target: suggestMobile,
              });
              return newItems;
            });
          })
        }
      });

      suggestMobile.events.add('select', function (e: any) {
        const selectValue = e.get('item').value;
        setSelectedAddress(selectValue);
      });
    }

    map.events.add("actionbegin", function () {
      setIsSearching(true);
    })

    map.events.add("actionend", function () {
      const coords: number[] = map.getCenter();
      const fixedCoords = [+coords[0].toFixed(7), +coords[1].toFixed(7)];
      if (fixedCoords[0] !== mapActionCoords[0] || fixedCoords[1] !== mapActionCoords[1]) {
        setMapActionCoords(fixedCoords);
      }
    });
  }

  // const success = (position: GeolocationPosition) => {
  //   const { latitude, longitude } = position.coords;
  //   ymaps.ready(init(latitude, longitude));
  // };

  // const error = () => {
  //   console.warn('User denied access to Geolocation');
  //   ymaps.ready(init);
  // };

  useEffect(() => {
    // if ("geolocation" in navigator) {
    //   navigator.geolocation.getCurrentPosition(success, error);
    // } else {
    ymaps.ready(init);
    // }
  }, []);

  const getCityName = (address: string) => {
    return address.split(", ")[0].replace(/городской округ /g, '')
  };

  const getAddress = (data: string | number[] | string[], isNewMapCenter?: boolean) => {
    ymaps.geocode(data).then(function (res: any) {
      const geoObject = res.geoObjects.get(0);
      const coords = data instanceof Array ? data : geoObject.geometry._coordinates;
      const fixedCoords = [+coords[0].toFixed(7), +coords[1].toFixed(7)];
      const address = geoObject.getAddressLine().replace(/Россия, Республика Дагестан, /g, '');
      const isTownship = !!address.match(/посёлок/) || !!address.match(/поселок/);
      const isVillage = !!address.match(/село/);

      const city = isTownship ? getCityName(address) : geoObject.getLocalities()[0];
      const localities = geoObject.getLocalities().length > 1 ? geoObject.getLocalities()[1] : geoObject.getLocalities()[0];
      const street = geoObject.getThoroughfare() ?? "";
      const house = geoObject.getPremiseNumber() ?? "";

      if (fixedCoords && address.length && house.length) {
        setSearchAddress(address);
        getUserGeoData({
          fullAddress: address,
          address: isTownship ? `${localities}, ${street}, ${house}` : `${street}, ${house}`,
          cityName: city,
          addressObj: { localities, street, house },
          latitude: String(fixedCoords[0]),
          longitude: String(fixedCoords[1])
        })
        if (isNewMapCenter) yandexMap.setCenter(fixedCoords, yandexMap.getZoom());
      }
      setIsSearching(false);
    });
  };

  useEffect(() => {
    if (selectedAddress.length) getAddress(selectedAddress, true);
  }, [selectedAddress]);

  // useEffect(() => {
  //   if (geoCodeCoords) getAddress(geoCodeCoords, true);
  // }, [geoCodeCoords])

  // const getUsergeoData = (geoData: UserGeoData) => handlerSaveAddress(geoData);

  // useEffect(() => {
  //   userGeoData && getUsergeoData(userGeoData);
  // }, [userGeoData]);

  const handlePlus = useCallback(() => {
    yandexMap.setZoom(yandexMap.getZoom() + 1, { checkZoomRange: true, duration: 250 });
    setIsSearching(false);
  }, [yandexMap]);

  const handleMinus = useCallback(() => {
    yandexMap.setZoom(yandexMap.getZoom() - 1, { checkZoomRange: true, duration: 250 });
    setIsSearching(false);
  }, [yandexMap]);

  const handleSetActiveStage = () => setActiveStage(2);

  return (
    <div className={styles.wrapper}>
      <div className={styles.map} id="map"></div>
      <div className={styles.locationContainer}>
        <TitleMobile
          headerType='h2'
          align='center'
          className={clsx(styles.loactionPlate, {
            [styles.hiddenControl]: isSearching
          })}>
          {searchAddress}
        </TitleMobile>

        <div
          className={clsx(styles.infoPlate, {
            [styles.hiddenControl]: searchAddress && !isSearching
          })}
        >
          {isSearching ? "Ищем адрес..." : searchAddress ? null : "Выберите адрес с номером дома"}
        </div>


        <div
          id="marker"
          className={styles.markerContainer}
        >
          <div className={clsx(styles.marker, {
            [styles.markerSearching]: isSearching,
          })} />
          <div className={clsx(styles.markerShadow, {
            [styles.markerShadowSearching]: isSearching,
          })} />
        </div>

        <div
          className={clsx(styles.zoomControl, {
            [styles.hiddenControl]: isSearching
          })}
        >
          <div id="zoomIn" className={styles.zoomButton} onClick={handlePlus}>+</div>
          <div id="zoomOut" className={styles.zoomButton} onClick={handleMinus}>–</div>
        </div>

        <div className={clsx(styles.bottomBar, { [styles.hiddenControl]: isSearching || !searchAddress?.length })}>
          <ButtonMobile
            pressed={false}
            appearance='text'
            className={styles.detailsButton}
            onClick={handleSetActiveStage}
          >
            Уточнить
            <ArrowRightBlack
              className={styles.arrowRightIcon}
            />
          </ButtonMobile>
          <ButtonMobile
            pressed={false}
            className={styles.saveButton}
            onClick={handleSaveAddress}
          >
            Верно
          </ButtonMobile>
        </div>
      </div>
    </div>
  );
};

// function geoCode(address: string | number[]) {
//   ymaps.geocode(address).then(
//     function (res: any) {
//       const obj = res.geoObjects.get(0);
//       const house = obj.getPremiseNumber() ?? null;
//       console.log(obj.geometry._coordinates);
//       if (obj.geometry._coordinates.length && house)
//         yandexMap.setCenter(obj.geometry._coordinates, 16);
//     },
//     function (e: any) {
//       console.log(e);
//     }
//   );
// }