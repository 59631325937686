import { useRef, useState } from "react";
import clsx from "clsx";

import PopularPartnersDesktopProps from "./PopularPartnersDesktop.props";
import styles from "./PopularPartnersDesktop.module.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/css";

import Arrow from "~/assets/icons/nav-arrow-right.svg";

import { useRouter } from "next/router";
import Link from 'next/link';
import Image from 'next/legacy/image';
import { TitleDesktop } from '../TitleDesktop';
import { useResizeObserver } from '~/hooks/useResizeObserver';

export const PopularPartnersDesktop = ({ partners, categoryClass }: PopularPartnersDesktopProps): JSX.Element => {
    const router = useRouter();
    const path: boolean = router.pathname === '/';

    const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
    const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

    const prevSlides = (node: HTMLElement | null) => setPrevEl(node);
    const nextSlides = (node: HTMLElement | null) => setNextEl(node);

    const [slidesCount, setSlidesCount] = useState<number>(7);

    const ref = useRef<HTMLDivElement>(null);

    const resizeCallback = ({ width }: DOMRectReadOnly) => {
        if (ref && ref.current) {
            const count = width / (176 + 16);
            const count2 = width / (200 + 16);
            if (count < slidesCount) {
                setSlidesCount(() => Math.floor(count))
            }
            if (count2 > slidesCount) {
                setSlidesCount(() => Math.floor(count2))
            }
        }
    };

    useResizeObserver(ref, resizeCallback);

    const navigationOptions = { prevEl, nextEl };

    // useEffect(() => {
    //     if (notDesktop) {
    //         setSlideWidth(133);
    //     }
    // }, [notDesktop]);

    // useEffect(() => {
    //     if (!notDesktop) {
    //         const slides = document.querySelectorAll(`.fix-blink .swiper-slide`);
    //         slides.forEach((slide: any) => slide.style.height = `${slide.offsetWidth}px`);
    //     }
    // }, [notDesktop, width]);


    const getLink = (isStructure: boolean, id: string, name: string) => {
        if (isStructure) {
            return `/shop/${id}?name=${name}&category=Товары%20со%20скидкой&categoryId=promo`
        }
        return `/partner/${id}?name=${name}`
    };

    return (
        <div ref={ref} className={styles.wrapper}>
            <TitleDesktop headerType='h2'>Популярные</TitleDesktop>

            <div className={clsx(styles.content, categoryClass, "fix-blink", {
                [styles.marginTop]: !path,
                [styles.marginBottom]: partners.length <= slidesCount,
            })}
            >
                <button
                    className={clsx(styles.sliderBtnPrev, {
                        [styles.sliderBtnPrevPartner]: !path,
                        [styles.visibleSliderBtn]: partners.length > slidesCount
                    })}
                    ref={prevSlides}
                >
                    <Arrow />
                </button>
                <Swiper
                    spaceBetween={16}
                    slidesPerView={slidesCount}
                    slidesPerGroup={slidesCount}
                    navigation={navigationOptions}
                    modules={[Navigation]}
                    className={styles.sliderWrapper}
                >
                    {partners.map(({ id, name, isStructure, logo }) => {
                        return logo?.length ? (
                            <SwiperSlide
                                id={`slide-${id}`}
                                style={{ height: "152px" }}
                                key={id}
                                tabIndex={-1}
                                aria-hidden="true"
                            >
                                <Link as={getLink(isStructure, id, name)} href={getLink(isStructure, id, name)}>
                                    <div className={styles.partnerItem}>
                                        {logo && <div className={styles.backgroundImg}>
                                            <Image
                                                unoptimized
                                                alt={name}
                                                layout="fill"
                                                objectFit="cover"
                                                width={96}
                                                height={96}
                                                src={logo}
                                                sizes="96px"
                                            />
                                        </div>}
                                        <div className={clsx(styles.partnerName, "clamp-2")}>{name}</div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ) : null;
                    })}
                </Swiper>
                <button
                    className={clsx(styles.sliderBtnNext, {
                        [styles.sliderBtnNextPartner]: !path,
                        [styles.visibleSliderBtn]: partners.length > slidesCount
                    })}
                    ref={nextSlides}
                >
                    <Arrow />
                </button>
            </div>
        </div>
    );
};
