import { PropsWithChildren } from "react";

import { Modal } from "~/components";
import { AddressDetailsModalProps } from "./AddressDetailsModal.props";
import { AddressDetails } from './AddressDetails';

export const AddressDetailsModal = ({
  isOpen,
  onExit,
  hideModal,
}: PropsWithChildren<AddressDetailsModalProps>): JSX.Element => {

  return (
    <Modal
      width={500}
      headline='Уточните адрес доставки'
      align='left'
      isOpen={isOpen}
      hideModal={hideModal}
      onExit={onExit}
    >
      <AddressDetails hideModal={hideModal} onSuccesAddress={hideModal} />
    </Modal>
  );
};
