import { BottomSheet, ButtonMobile, TitleMobile } from "~/components";
import { PropsWithChildren } from "react";
import {
  confirmable,
  createConfirmation,
  ReactConfirmProps,
} from "react-confirm";

import styles from "./ConfirmModal.module.css";

export const ConfirmBottomSheetCallable = ({
  show,
  proceed,
  cancel,
  confirmation,
  dismiss,
}: PropsWithChildren<ReactConfirmProps>): JSX.Element => {
  return (
    <BottomSheet isOpen={show} hideModal={cancel} onExit={dismiss}>
      <>
        <TitleMobile align="center" headerType='h3'>{confirmation}</TitleMobile>
        <div className={styles.btnWrapper}>
          <ButtonMobile
            className={styles.button}
            appearance='gray'
            onClick={dismiss}
          >
            НЕТ
          </ButtonMobile>
          <ButtonMobile
            className={styles.button}
            onClick={() => {
              proceed("ok");
            }}
          >
            ДА
          </ButtonMobile>
        </div>
      </>
    </BottomSheet>
  );
};

const ConfirmHOC = confirmable(ConfirmBottomSheetCallable);

export const confirmBottomSheet = createConfirmation(ConfirmHOC);