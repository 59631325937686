import { Header } from './Header';
import { Content } from './Content';
import { CartBarLayout, ClientOnlyPortal, FooterSpacer } from '~/components';
import styles from "./OrderOptionsMobile.module.css";

export const OrderOptionsMobile = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <Header />
      <Content />
      <ClientOnlyPortal selector="#modal">
        <CartBarLayout />
      </ClientOnlyPortal>
      <FooterSpacer />
    </div>
  );
};
