import { TagLinkDesktop } from "~/components";

import CatalogFiltersPopupProps from "./CatalogFiltersPopup.props";
import styles from "./CatalogFiltersPopup.module.css";

export const CatalogFiltersPopup = ({
  tags,
  categoryId,
  handleSelectTag = () => null,
  ...props
}: CatalogFiltersPopupProps): JSX.Element => (
  <div className={styles.popupWrapper} {...props}>
    <ul className={styles.filtersList}>
      {tags.map((tag) => (
        <li key={tag.id} className={styles.itemList}>
          <div className={styles.itemListContent}>
            <TagLinkDesktop
              path={`/?categoryId=${categoryId}&filter=${tag?.name}`}
              className={'tag'}
              onClick={() => handleSelectTag(tag)}
              key={tag?.id}
            >
              {tag?.name}
            </TagLinkDesktop>
          </div>
        </li>
      ))}
    </ul>
  </div>
);
