import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from "next/link";
import Image from 'next/legacy/image';
import clsx from 'clsx';

import styles from './BannerDesktop.module.css';
import BannerDesktopProps from './BannerDesktop.props';
import { Partner } from "~/models/partners";
import { getPromotionInformation } from "~/plugins/api";

interface PromotionInfo {
    id: string;
    promoCode: string | null;
    promotionName: string;
    description: string | null;
}

export const BannerDesktop = ({ data, notResize = false }: BannerDesktopProps): JSX.Element => {
    const router = useRouter();
    const path: boolean = router.pathname === '/';

    const { image, promotionId, promoCode, partnerId, partnerName, partnerIsStructure } = data;
    const [promotion, setPromotion] = useState<PromotionInfo | undefined>(undefined);
    const [partners, setPartners] = useState<Partner[] | undefined>(undefined);
    const [copied, setCopied] = useState<boolean>(false);
    const { promotionName } = promotion || {};

    const link = () => {
        if (partnerId) {
            if (partnerIsStructure) {
                return `/shop/${partnerId}?name=${partnerName}&category=Товары со скидкой&categoryId=promo`;
            }
            return `/partner/${partnerId}?name=${partnerName}`
        }
        return `/promotions/${promotionId}`
    };


    useEffect(() => {
        let isIgnoreResponse = false;
        if (!promotionId) return;
        const fetchPromotionInfo = async () => {
            const res = await getPromotionInformation({ promotionId });
            if (!isIgnoreResponse) {
                setPromotion(res.promotion);
                setPartners(res.partners);
            }
        };

        fetchPromotionInfo();

        return () => {
            isIgnoreResponse = true;
        }
    }, [promotionId]);

    const copyToClipBoard = (text: string) => {
        navigator.clipboard.writeText(text.toUpperCase());
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    return (
        <>
            {path && (promotionId || partnerId) ? (
                <Link href={link()}>
                    <div className={clsx(styles.banner, {
                        [styles.notresize]: notResize
                    })}>
                        <div className={styles.front}>
                            <div className={styles.backgroundImg}>
                                <Image
                                    priority
                                    // unoptimized
                                    alt={image}
                                    layout="fill"
                                    objectFit="cover"
                                    src={image}
                                />
                            </div>
                            {partners && partners?.length === 1 ? (
                                partners?.map((partner: Partner) => {
                                    return (
                                        <div key={`logo${partner.id}`} className={styles.mobileLogo}>
                                            <Image
                                                key={partner.id}
                                                className={styles.mobileLogo}
                                                width={75} height={29}
                                                unoptimized
                                                alt={partner.name || ""}
                                                layout="fill"
                                                objectFit="cover"
                                                src={String(partner.logo)}
                                            />
                                        </div>
                                    );
                                })
                            ) : null}
                            {promotionName && <div className={styles.title}>{promotionName}</div>}
                            {promoCode && partners && partners?.length > 1 ? <div className={styles.promoCode}>{promoCode}</div> : null}
                        </div>
                    </div>

                </Link>
            ) : (
                <div className={clsx(styles.banner, {
                    [styles.notresize]: notResize
                })}>
                    <div className={styles.front}>
                        <div className={styles.backgroundImg}>
                            <Image
                                priority
                                // unoptimized
                                alt={image}
                                layout="fill"
                                objectFit="cover"
                                src={image}
                            />
                        </div>
                        {promotionName && <div className={styles.title}>{promotionName}</div>}
                        {promoCode && (
                            <>
                                <div
                                    onClick={() => copyToClipBoard(promoCode)}
                                    className={styles.promoCode}
                                >
                                    {promoCode}
                                </div>
                                <div className={clsx(styles.backTextCopied, {
                                    [styles.isCopied]: copied,
                                })}>Скопировано</div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
