import { PropsWithChildren } from "react";
import clsx from "clsx";

import { ButtonMobile, TitleMobile } from "~/components";
import ConfirmBottomSheetProps from "./ConfirmBottomSheet.props";
import styles from "./ConfirmModal.module.css";

const DEFAULT_CONFIRM_MESSAGE = "Сообщение окна подтверждения";

export const ConfirmBottomSheet = ({
  hideModal,
  onConfirm,
  onCancel,
  cancelText = "НЕТ",
  confirmText = "ДА",
  title = "Заголовок",
  message = DEFAULT_CONFIRM_MESSAGE,
  icon,
  className,
  ...props
}: PropsWithChildren<ConfirmBottomSheetProps>): JSX.Element => {
  const handleConfirm = () => {
    onConfirm();
    setTimeout(hideModal, 350);
  };

  return (
    <div
      className={clsx(styles.wrapper, className)}
      {...props}
    >
      {icon && (
        <div className={styles.iconContainer}>{icon}</div>
      )}
      <TitleMobile align="center" headerType='h3'>{title}</TitleMobile>
      <p className={styles.message}>{message}</p>
      <div className={styles.btnWrapper}>
        {cancelText.length ? (
          <ButtonMobile
            className={styles.button}
            appearance="gray"
            onClick={onCancel}
            isWrap
          >
            {cancelText}
          </ButtonMobile>
        ) : null}
        {confirmText.length ? (
          <ButtonMobile
            className={styles.button}
            onClick={handleConfirm}
            isWrap
          >
            {confirmText}
          </ButtonMobile>
        ) : null}
      </div>
    </div>
  );
};