import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import SwitcherProps from "./Switcher.props";
import styles from "./Switcher.module.css";

export const Switcher = ({
  label = "Label",
  ...props
}: SwitcherProps): JSX.Element => {


  return (
    <div className={clsx(styles.wrapper)} {...props}>
      <label htmlFor="switch" className={styles.switchContainer}>
        {label}
        <input className={styles.switcher} type="checkbox" role="switch" id="switch" />
      </label>
    </div>
  );
};
