import getTimeWithTimezone from "~/helpers/getTimeWithTimezone";
import { BasketWithProducts } from "~/models/basket";
import { httpv1 } from "../http";
interface IBasketRemoveProduct {
  basketId: string;
  id?: string;
}

interface IBasketCounterProps extends IBasketRemoveProduct {
  partnerId: string;
  marketCategoryId: number;
  productId: string;
  additives: string[] | [];
  quantity: number;
}

export const addProduct = async ({
  basketId,
  partnerId,
  marketCategoryId,
  id,
  productId,
  additives,
  quantity,
}: IBasketCounterProps) => {
  const response = await httpv1.post<BasketWithProducts>("Basket/inc_product", {
    basketId,
    partnerId,
    marketCategoryId,
    id,
    productId,
    additives,
    quantity,
  });

  return {
    ...response.data,
    content: response.data.content,
  };
};

export const decreaseProduct = async ({
  basketId,
  partnerId,
  marketCategoryId,
  id,
  productId,
  additives,
  quantity,
}: IBasketCounterProps) => {
  const response = await httpv1.post<BasketWithProducts>("Basket/dec_product", {
    basketId,
    partnerId,
    marketCategoryId,
    id,
    productId,
    additives,
    quantity,
  });

  return {
    ...response.data,
    content: response.data.content,
  };
};

export const removeProduct = async ({ basketId, id }: IBasketRemoveProduct) => {
  const response = await httpv1.post<BasketWithProducts>(
    "Basket/remove_product",
    {
      basketId,
      id,
    }
  );

  return {
    ...response.data,
    content: response.data.content,
  };
};

export const removeBasket = async (basketId: string) => {
  const response = await httpv1.post<BasketWithProducts>("Basket/clear", {
    basketId,
  });

  return {
    ...response.data,
    content: response.data.content,
  };
};

export const addCutlery = async (
  cutleryQuantity: number | string,
  basketId: string
) => {
  const response = await httpv1.post<BasketWithProducts>("Basket/cutlery", {
    cutleryQuantity,
    basketId,
  });

  return {
    ...response.data,
    content: response.data.content,
  };
};

export const addDeliverAtDatetime = async (
  basketId: string,
  deliverAtDatetime: Date | null
) => {
  const response = await httpv1.post<BasketWithProducts>(
    "Basket/deliver_at_datetime",
    {
      basketId,
      deliverAtDatetime: getTimeWithTimezone(deliverAtDatetime),
    }
  );

  return {
    ...response.data,
    content: response.data.content ? response.data.content : [],
  };
};

export const setPromocode = async (basketId: string, promocode: string) => {
  const response = await httpv1.post<BasketWithProducts>("Basket/promocode", {
    basketId,
    promocode,
  });

  return {
    ...response.data,
    content: response.data.content,
  };
};

export const removePromocode = async (basketId: string) => {
  const response = await httpv1.post<BasketWithProducts>(
    "Basket/remove_promocode",
    {
      basketId,
    }
  );

  return {
    ...response.data,
    content: response.data.content,
  };
};

export interface IAddressPartnerZone {
  basketId: string | undefined;
  latitude: string;
  longitude: string;
}

export const addressPartnerZone = async ({
  basketId,
  latitude,
  longitude,
}: IAddressPartnerZone) => {
  const response = await httpv1.get<BasketWithProducts>(
    "Basket/address_in_partner_zone",
    {
      params: {
        basketId,
        latitude,
        longitude,
      },
    }
  );

  return response.data;
};
