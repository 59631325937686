import clsx from "clsx";

import ArrowIcon from "~/assets/icons/arrow-left.svg";
import ButtonProps from "./ButtonBackMobile.props";
import styles from "./ButtonBackMobile.module.css";

export const ButtonBackMobile = ({
  className,
  appearance = "default",
  color,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <button className={clsx(styles.button, { [styles.light]: appearance === 'light' }, className)} {...props}>
      <span className={styles.arrow}>
        <ArrowIcon
          style={{
            stroke: color,
          }}
          className={styles.arrowIcon}
        />
      </span>
    </button>
  );
};
