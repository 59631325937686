import { DetailedHTMLProps, HTMLAttributes, useEffect } from 'react';
import { useRouter } from "next/router";
import clsx from "clsx";

import { ButtonMobile, ButtonBackMobile, TitleMobile } from "~/components";
import styles from "./OrderOptionsMobile.module.css";
import TrashIcon from "~/assets/icons/trash.svg";
import useConfirmBottomSheet from '~/hooks/useConfirmBottomSheet';
import { usePartnerInfo } from '~/hooks/partner';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

declare const ym: any;

interface HeaderOrderOptionsProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLHeadElement>, HTMLHeadElement> {

}

export const Header = ({
  className,
  ...props
}: HeaderOrderOptionsProps): JSX.Element => {
  const router = useRouter();
  const { basket, clearBasket, tokenForMetrics } = useBasketContext();
  const { data: partner } = usePartnerInfo(basket?.partnerId ?? "");
  const handleClearBasket = () => {
    hideConfirmBottomSheet();
    clearBasket();
    tokenForMetrics && ym ? ym(88121717, 'reachGoal', 'clear_basket') : null;
    return router.push(`/?categoryId=${basket?.marketCategoryId}`)
  };

  const handleHideConfirmModal = () => hideConfirmBottomSheet();
  const { showConfirmBottomSheet, hideConfirmBottomSheet } = useConfirmBottomSheet({
    onConfirm: handleClearBasket,
    onCancel: handleHideConfirmModal,
    title: "Вы хотите очистить корзину?",
    message: '',
    dependencies: [basket]
  });

  const handleBackPage = () => {
    if (!basket) return
    if (partner?.isStructure) {
      return router.push(
        `/retail/${partner?.id}?name=${partner?.name}&category=Товары по акции&categoryId=promo`
      );

    } return router.push(`/partner/${partner?.id}?name=${partner?.name}`);
  };

  useEffect(() => {
    if (!basket) {
      const handler = setTimeout(() => {
        router.push(`/?categoryId=1`);
      }, 4000);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [basket]);

  return (
    <header className={clsx(styles.header, className)} {...props}>
      <ButtonBackMobile onClick={handleBackPage} />
      <TitleMobile className={styles.title} headerType='h3' align='left' margin={false}>{basket?.partnerName}</TitleMobile>
      {basket?.content.length ? (
        <ButtonMobile pressed={false} appearance='text' onClick={showConfirmBottomSheet} className={styles.buttonClear}>
          <TrashIcon className={styles.clearIcon} />
        </ButtonMobile>
      ) : null}
    </header>
  );
};
