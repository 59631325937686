
import HeaderPartnerMobileProps from "./HeaderPartnerMobile.props";
import { Content } from './Content';
import { Wrapper } from './Wrapper';

export const HeaderPartnerMobile = ({
  className,
  ...props
}: HeaderPartnerMobileProps): JSX.Element => (
  <Wrapper className={className} {...props}>
    <Content />
  </Wrapper>
);
