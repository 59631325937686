import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";

import { IUser } from "./useUser.hook";
import { httpv1, httpv2 } from "~/plugins/api/http";
import { getUserData, Token } from "~/plugins/api";
import { getCookie, removeCookie, setCookie } from "~/helpers/cookieUtilites";

interface IUserState {
  token: Token | null;
  user: IUser | null;
  userName: string;
  loggedIn: boolean;
}

const setAuthorizationBearerToken = (accessToken: string) => {
  const bearerToken = `Bearer ${accessToken}`;
  httpv1.defaults.headers.common["Authorization"] = bearerToken;
  httpv2.defaults.headers.common["Authorization"] = bearerToken;
};

export default function useToken() {
  const [state, setState] = useState<IUserState>(() => {
    const cookieToken = getCookie("cron-token")
      ? JSON.parse(getCookie("cron-token") as string)
      : null;

    if (cookieToken) {
      const userName = jwtDecode<any>(cookieToken?.accessToken)["unique_name"];
      setAuthorizationBearerToken(cookieToken.accessToken);
      return {
        token: cookieToken,
        userName,
        user: null,
        loggedIn: true,
      };
    }
    return {
      token: null,
      userName: "",
      user: null,
      loggedIn: false,
    };
  });

  // useEffect(() => {
  //   const cookieToken = getCookie("cron-token")
  //     ? JSON.parse(getCookie("cron-token") as string)
  //     : undefined;

  //   if (cookieToken) {
  //     // try {
  //     const cookieToken = getCookie("cron-token")
  //       ? JSON.parse(getCookie("cron-token") as string)
  //       : undefined;

  //     http.defaults.headers.common[
  //       "Authorization"
  //     ] = `Bearer ${cookieToken?.accessToken}`;

  //     const userName = jwtDecode<any>(cookieToken?.accessToken)["unique_name"];

  //     setState({
  //       token: cookieToken,
  //       userName,
  //       user: null,
  //       loggedIn: false,
  //     });
  //   }
  // }, []);

  const { token, user, loggedIn, userName } = state;

  const login = async (token: Token, callback?: () => void) => {
    setCookie({ name: "cron-token", value: token });
    const userName = jwtDecode<any>(token?.accessToken)["unique_name"];
    setAuthorizationBearerToken(token.accessToken);
    setState({
      token,
      user: null,
      userName: userName,
      loggedIn: true,
    });
    handleGetUserData(token?.accessToken);
    if (callback) callback();
  };

  const logout = () => {
    setState({
      token: null,
      user: null,
      userName: "",
      loggedIn: false,
    });
    removeCookie("cron-token");
    setAuthorizationBearerToken("");
  };

  const handleGetUserData = async (accessToken: string) => {
    try {
      const user = await getUserData(accessToken);

      setState((prevState) => ({
        ...prevState,
        user,
        userName: user?.name ?? "",
        loggedIn: true,
      }));
    } catch (e) {
      console.log("Ошибка загрузки пользователя", e);
      setState((prevState) => ({
        ...prevState,
        loggedIn: false,
      }));
    }
  };

  useEffect(() => {
    if (token?.accessToken) {
      handleGetUserData(token?.accessToken);
    }
  }, []);

  const [isUpdateUserData, setIsUpdateUserData] = useState(false);

  useEffect(() => {
    if (token?.accessToken && isUpdateUserData) {
      handleGetUserData(token?.accessToken);
      setIsUpdateUserData(false);
    }
  }, [token?.accessToken, isUpdateUserData]);

  return {
    token,
    user,
    loggedIn,
    userName,
    setIsUpdateUserData,
    login,
    logout,
  };
}
