const deliverIsTommorow = (time: string | Date | null | undefined) => {
  if (!time) return false;
  const currentDay = new Date().getDate();
  const deliverAtDay = new Date(time).getDate();
  if (currentDay !== deliverAtDay) {
    return true;
  }
  return false;
};

const deliveryIsNow = (time: string | Date | null | undefined) => {
  if (!time) return false;
  const currentDay = new Date().getDate();
  const currentHours = new Date().getHours();
  const currentMinutes = new Date().getMinutes();
  const deliverAtDay = new Date(time).getDate();
  const deliveryAtHours = new Date(time).getHours();
  const deliveryAtMinutes = new Date(time).getMinutes();
  if (currentDay !== deliverAtDay) {
    return false;
  }
  if (
    currentHours !== deliveryAtHours ||
    currentMinutes !== deliveryAtMinutes
  ) {
    return false;
  }
  return true;
};

export { deliverIsTommorow, deliveryIsNow };
