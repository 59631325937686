import clsx from "clsx";
import styles from "./LoadingIndicator.module.css";
import LoadingIndicatorProps from "./LoadingIndicator.props";

export const LoadingIndicator = ({
  color = "primary",
  isScreen = false,
}: LoadingIndicatorProps): JSX.Element => {
  return (
    <div
      className={clsx({
        [styles.ldsDualRing]: !isScreen,
        [styles.screenLoading]: isScreen,
        [styles.colorWhite]: color === "white",
      })}
    ></div>
  );
};
