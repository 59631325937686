import { PropsWithChildren } from "react";
import clsx from "clsx";

import DeleteCircledIcon from "~/assets/icons/delete-circled-outline.svg";
import ButtonCancelOrderMobileProps from "./ButtonCancelOrderMobile.props";
import styles from "./ButtonCancelOrderMobile.module.css";

import { ButtonMobile } from '../ButtonMobile';
import { useCancelOrder } from '~/hooks/order';
import useTimer from '~/hooks/useTimer';
import useConfirmBottomSheet from '~/hooks/useConfirmBottomSheet';

export const ButtonCancelOrderMobile = ({
  orderId,
  dateTime,
  className,
  ...props
}: PropsWithChildren<ButtonCancelOrderMobileProps>): JSX.Element => {

  const { handleCanceledOrder } = useCancelOrder();

  const { isStopTimer } = useTimer(295, dateTime);

  const { showConfirmBottomSheet, hideConfirmBottomSheet } = useConfirmBottomSheet({
    onConfirm: () => handleCanceledOrder(orderId),
    onCancel: () => hideConfirmBottomSheet(),
    title: "Отменить заказ?",
    message: '',
    dependencies: [orderId]
  });

  if (isStopTimer) return <></>;

  return (
    <ButtonMobile
      size='s'
      type='button'
      appearance='gray'
      className={clsx(styles.button, className)}
      onClick={showConfirmBottomSheet}
      {...props}
    >
      <DeleteCircledIcon className={styles.buttonIcon} />
      ОТМЕНИТЬ ЗАКАЗ
    </ButtonMobile>
  );
};
