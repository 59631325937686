
import TagLinkDesktopProps from "./TagLinkDesktop.props";
import styles from "./TagLinkDesktop.module.css";
import clsx from "clsx";
import Link from 'next/link';

export const TagLinkDesktop = (({
  className,
  children,
  active = false,
  color = "gray",
  path,
  ...props
}: TagLinkDesktopProps): JSX.Element => {
  return (
    <Link
      href={path}
      passHref
    >
      <div
        className={clsx(styles.tag, {
          [styles.active]: active,
          [styles.colorBrown]: color === "brown",
          [styles.colorGray]: color === "gray",
          [styles.colorLight]: color === "light",
          [styles.colorGost]: color === "gost",
        }, className)}
        {...props}
      >
        {children}
      </div>
    </Link>
  );
});
