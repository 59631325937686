import { AxiosError } from "axios";
import { IPaymentType } from "~/hooks/user/useSelectPay";
import { httpv2 } from "../http";

interface UserCardList {
  cardId: number | string;
  pan: string;
  rebillId: string;
  cardType: number;
  expDate: string;
  status: string;
}

export const getUserCard = async (token?: string) => {
  let headers = {};

  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await httpv2.get<UserCardList[]>("Payment/card_list", {
    headers,
    params: {
      deviceId: 1,
    },
  });

  return response.data.map((it) => ({
    id: it.cardId,
    name: it.pan,
    key: it.rebillId,
    paymentMethodId: 2,
  })) as IPaymentType[];
};

export interface DeleteCardProps {
  cardId: number | string;
  rebillId: number | string;
}

export const deleteUserCard = async (props: DeleteCardProps) => {
  return await httpv2.post("Payment/remove_card", {
    ...props,
    deviceId: 1,
  });
};
