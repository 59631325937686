import { useQuery } from "react-query";
import { getUserCard } from "~/plugins/api/payment";

export function useGetUserCards(isAuthorized?: boolean) {
  return useQuery(["user_card_list"], () => getUserCard(), {
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000, // 1 hours
    enabled: isAuthorized,
    refetchOnWindowFocus: true
  });
}
