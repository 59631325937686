import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from 'react-dom';
import TooltipProps from "./Tooltip.props";
import styles from "./Tooltip.module.css";
import clsx from 'clsx';

export function Portal({
  children,
  selector,
}: {
  selector: string;
  children: ReactNode;
}) {
  const ref = useRef<Element>(document.querySelector(selector));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return ref.current ? createPortal(children, document.querySelector(selector)!) : null;
}

interface ICoordinates { top?: string | number, bottom?: string | number, left?: string | number }

export const Tooltip = ({ children, text, offset = 10 }: TooltipProps) => {
  const [positon, setPosition] = useState<ICoordinates | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [close, setClose] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!text.trim().length) return;
    const tooltipEl = tooltipRef.current;

    if (!anchorEl || !tooltipEl) {
      return;
    }

    const body = document.body.getBoundingClientRect();
    const anchorRect = anchorEl.getBoundingClientRect();
    const tooltipRect = tooltipEl.getBoundingClientRect();
    const headerHeight = 82;
    const anchorRectHeight = anchorRect.height < headerHeight ? headerHeight + anchorRect.height + offset : anchorRect.height * 2 + offset;

    if (anchorRect?.top > anchorRectHeight) {
      setPosition({
        bottom: `${body?.height - anchorRect?.y + offset}px`,
        left: anchorRect.left + anchorRect.width / 2 - tooltipRect.width / 2,
      });
    } else {
      setPosition({
        top: `${anchorRect?.bottom + offset}px`,
        left: anchorRect.left + anchorRect.width / 2 - tooltipRect.width / 2,
      });
    }

    return () => {
      setAnchorEl(null);
      window.removeEventListener("scroll", handleHiddenPopup, false);
    }
  }, [anchorEl, text]);

  const handleHiddenPopup = () => {
    setClose(true);
    setTimeout(() => {
      setAnchorEl(null);
    }, 200);
    window.removeEventListener("scroll", handleHiddenPopup, false);
  };

  const propsGetter = (baseProps: Record<string, unknown>) => {
    const { onMouseEnter, onMouseLeave } = baseProps;

    return {
      ...baseProps,
      onMouseLeave: (e: React.MouseEvent<HTMLElement>) => {
        if (typeof onMouseEnter === "function") {
          onMouseEnter(e);
        }
        setClose(true);
        setTimeout(() => setAnchorEl(null), 200);
      },
      onMouseEnter: (e: React.MouseEvent<HTMLElement>) => {
        if (typeof onMouseLeave === "function") {
          onMouseLeave(e);
        }
        setClose(false);
        setAnchorEl(e.currentTarget);
        window.addEventListener("scroll", handleHiddenPopup, false);
      }
    };
  };

  return (
    <>
      {anchorEl && text.trim().length ? (
        <Portal selector="#modal">
          <div className={styles.tooltipWrapper}>
            <div
              ref={tooltipRef}
              className={clsx(styles.tooltip, {
                [styles.open]: anchorEl,
                [styles.close]: close,
              })}
              style={positon ? positon : {}}
            >
              {text}
            </div>
          </div>

        </Portal>
      ) : null}
      {children(propsGetter as never)}
    </>
  );
};
