import { useModal } from 'react-modal-hook';

import { ButtonDesktop, Popup } from "~/components";
import AboutPartner from './AboutPartner';
import styles from "./AboutPartner.module.css";
import InfoIcon from "~/assets/icons/info.svg";

export const AboutPartnerPopup = (): JSX.Element => {
  const [showPopup, hidePopup] = useModal(
    ({ in: open, onExited }) => (
      <Popup
        isOpen={open}
        onExit={onExited}
        hidePopup={hidePopup}
        elementId="aboutPartner"
      >
        <AboutPartner />
      </Popup>
    )
  );

  return (
    <ButtonDesktop
      id="aboutPartner"
      appearance='text'
      className={styles.metaPlate}
      onClick={showPopup}
    >
      <div className={styles.metaPlateIcon}>
        <InfoIcon className={styles.infoSvg} />
      </div>

      <div className={styles.metaPlateInfo}>
        <div className={styles.metaSubText}>
          Информация о партнере
        </div>
      </div>
    </ButtonDesktop>
  )
};
