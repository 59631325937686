import clsx from "clsx";

import FreeShippingPlateProps from "./FreeShippingPlate.props";
import styles from "./FreeShippingPlate.module.css";
import FreeShippingIcon from "~/assets/icons/circle-wavy-courier.svg";
import useHtmlAttributes from '~/hooks/useHtmlAttributes';
import { useDeliveryFreeSumm, useLeftDeliveryFreeSumm } from '~/hooks/useDeliveryFreeSumm';

export const FreeShippingPlate = ({
  className,
  align = "left",
  size = "l",
  isShopPartner = false,
  ...props
}: FreeShippingPlateProps): JSX.Element => {
  const { devices } = useHtmlAttributes();
  const { freeDeliverysumm } = useDeliveryFreeSumm();
  if (!freeDeliverysumm) return <></>
  return (
    <div
      className={clsx(styles.plate, {
        [styles.plateSizeSmall]: size === "s",
        [styles.textCenter]: align === "center",
        [styles.plateMobile]: !devices?.isDesktop || isShopPartner
      }, className)}
      {...props}
    >
      <FreeShippingIcon className={styles.icon} />
      <div className={clsx(styles.text, "clamp-2")}>Бесплатная доставка от {freeDeliverysumm}₽</div>
    </div>
  );
};


export const FreeShippingLeftPlate = ({
  className,
  align = "left",
  size = "l",
  isShopPartner = false,
  ...props
}: FreeShippingPlateProps): JSX.Element => {
  const { devices } = useHtmlAttributes();
  const { freeDeliveryLeft } = useLeftDeliveryFreeSumm();
  if (!freeDeliveryLeft) return <></>
  return (
    <div
      className={clsx(styles.plate, {
        [styles.plateSizeSmall]: size === "s",
        [styles.textCenter]: align === "center",
        [styles.plateMobile]: !devices?.isDesktop || isShopPartner
      }, className)}
      {...props}
    >
      <FreeShippingIcon className={styles.icon} />
      <div className={clsx(styles.text, "clamp-2")}>До бесплатной доставки еще {freeDeliveryLeft}₽</div>
    </div >
  );
};
