import { useState } from "react";
import clsx from "clsx";

import { ButtonMobile } from "~/components";

import AddressListBottomSheetProps from "./AddressListBottomSheet.props";
import styles from "./AddressListBottomSheet.module.css";
import PlusIcon from "~/assets/icons/plus.svg";

import { UserAddressWithList } from '~/models';
import getAddressInfo from '~/helpers/getAddressInfo';
import useChangedAddresses from '~/hooks/address/useChangedAddresses';
import { useAddressContext } from '~/hooks/address/useAddress.hook';

export const AddressListBottomSheet = ({
  hidePopup = () => null,
  hidePopupWithOpenMapModal = () => null,
  onSuccessAddress = () => null,
  ...props
}: AddressListBottomSheetProps): JSX.Element => {
  const { location, userAddresses } = useAddressContext();

  const [changedAddress, setChangedAddress] = useState<UserAddressWithList | null>(null);

  useChangedAddresses({ address: changedAddress, successChanged: hidePopup, isMobile: true })

  if (!userAddresses.length) return <div></div>;

  return (
    <div {...props}>
      <div className={styles.addressList}>
        {userAddresses.map((item) => {
          const {
            id,
            cityName,
            address
          } = item;
          const parsedAddress = getAddressInfo(address, cityName ?? "")

          return (
            <div
              key={id}
              className={styles.addressItem}
              onClick={() => setChangedAddress(item)}
            >
              <span className={styles.address}>
                <span className={clsx(styles.circle, {
                  [styles.selectCircle]: address.toLowerCase() === location?.address.toLowerCase() && cityName.toLowerCase() === location?.cityName.toLowerCase()
                })}></span>
                <span className={styles.addressStreet}>
                  {!parsedAddress?.localities.length ? `${parsedAddress?.city}, ` : `${parsedAddress?.localities}, `}
                  {parsedAddress?.street.length ? `${parsedAddress?.street}, ` : " "}
                </span>
                <span className={styles.addressHouse}>{parsedAddress?.house}</span>
              </span>
            </div>
          )
        })}
      </div>
      <div className={styles.bottomBar}>
        <ButtonMobile pressed={false} className={styles.buttonAddAddress} onClick={hidePopupWithOpenMapModal} size="l" appearance='gray'>
          <PlusIcon />
          <span className={styles.text}>Добавить новый</span>
        </ButtonMobile>
      </div>
    </div>
  );
};
