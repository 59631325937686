import { BottomSheet } from "~/components";
import { Pay } from './Pay';
import PayBottomSheetProps from "./PayBottomSheet.props";

export const PayBottomSheet = ({
  isOpen,
  onExit = () => null,
  hideModal = () => null,
  className,
  ...props
}: PayBottomSheetProps): JSX.Element => (
  <BottomSheet isOpen={isOpen} onExit={onExit} hideModal={hideModal}>
    <Pay hideModal={hideModal} className={className} {...props} />
  </BottomSheet>
);
