import { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { NIL as NIL_UUID } from "uuid";

import { Input, Textarea, MapViewMobile, ButtonMobile } from "~/components";
import { UserGeoData } from "~/models/user";
import MobileMapModalProps from "./MobileMapModal.props";
import styles from "./MobileMapModal.module.css";
import CloseIcon from "~/assets/icons/close.svg";
import LocationIcon from "~/assets/icons/location-alt.svg";

import { useUserContext } from "~/hooks/user/useUser.hook";
import { updatePage } from "~/helpers/upadatePage";

import { getDeliveryCities } from "~/plugins/api";
import { City, UserAddressToken, UserAddressWithList } from '~/models';
import useConfirmBottomSheet from '~/hooks/useConfirmBottomSheet';
import { useAddressContext } from '~/hooks/address/useAddress.hook';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { usePartnerInfo } from '~/hooks/partner';

declare const ym: any;

export const MobileMapModal = ({
  isEditAddress = null,
  hideModal,
  onSuccesAddress = () => null,
  className,
  ...props
}: PropsWithChildren<MobileMapModalProps>): JSX.Element => {
  const router = useRouter();
  const { token } = useUserContext();
  const { location, addAddress, editAddress } = useAddressContext();
  const { basket, clearBasket, tokenForMetrics } = useBasketContext();

  const partnerid = basket?.content.length ? basket.partnerId : router.query.id as string;

  const { data: partner } = usePartnerInfo(partnerid);

  const field = useRef<HTMLInputElement>(null);
  const subDetailsField = useRef<HTMLInputElement>(null);

  const [activeStage, setActiveStage] = useState<1 | 2 | 0>(0);
  const [address, setAddress] = useState("");
  const [defaultAddress, setDefaultAddress] = useState<UserAddressWithList | null>(isEditAddress);
  const [userGeoData, setUserGeoData] = useState<UserGeoData | null>(null);
  const [isChangedDetails, setIsChangedDetails] = useState(false);
  const [orderInfo, setOrderInfo] = useState<Record<string, any>>({
    intercom: defaultAddress?.intercom ?? location?.intercom,
    entrance: defaultAddress?.entrance ?? location?.entrance,
    floor: defaultAddress?.floor ?? location?.floor,
    flat: defaultAddress?.flat ?? location?.flat,
    comment: defaultAddress?.comment ?? location?.comment,
  });

  const changeOrderInfo = (
    field: "intercom" | "entrance" | "floor" | "flat" | "comment",
    value: string
  ) => {
    setOrderInfo((info) => ({ ...info, [field]: value }));
    setIsChangedDetails(true);
  };

  useLayoutEffect(() => {
    if (token) {
      if (defaultAddress) {
        setAddress(defaultAddress.address);
        setActiveStage(2);
      }
    }
    if (!token) {
      if (location) {
        setAddress(location.fullAddress);
        setUserLocation(location);
        setActiveStage(1);
      }
    }
  }, [defaultAddress, token?.accessToken]);

  useEffect(() => {
    if (activeStage === 1) field?.current?.blur();
    if (activeStage === 2) setTimeout(() => field?.current?.focus(), 300);
  }, [activeStage]);

  const [cities, setCities] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const arr = await getDeliveryCities();
      return setCities(arr);
    })();
  }, []);

  const [userLocation, setUserLocation] = useState<UserAddressToken | null>(null);

  useEffect(() => {
    if (!userGeoData) return;
    setAddress(userGeoData.fullAddress);
    const geoStreet = userGeoData.fullAddress.split(", ").reverse()[1];
    const geoHouse = userGeoData.fullAddress.split(", ").reverse()[0];
    const locationStreet =
      (location && location.address.split(", ").reverse()[1]) ?? "";
    const locationHouse =
      (location && location.address.split(", ").reverse()[0]) ?? "";

    if (
      geoStreet.toLowerCase() !== locationStreet.toLowerCase() ||
      geoHouse.toLowerCase() !== locationHouse.toLowerCase()
    ) {
      setOrderInfo({
        intercom: "",
        entrance: "",
        floor: "",
        flat: "",
        comment: "",
      });
    }

    (async () => {
      if (!userGeoData?.addressObj || !userGeoData?.addressObj?.house) {
        setAddress(userGeoData.fullAddress);
        return field.current?.focus();
      }

      const addressCity = userGeoData?.cityName;
      const city = cities.find(
        (item) =>
          addressCity && item.name.toLowerCase() === addressCity?.toLowerCase()
      );

      if (!city) {
        setAddress("");
        setUserGeoData(null);
        return showNoneCityModal();
      }

      const locationParams = {
        id: NIL_UUID,
        cityId: city.id,
        cityName: city.name,
        address: userGeoData.address,
        fullAddress: userGeoData.fullAddress,
        addressObj: userGeoData?.addressObj,
        entrance: orderInfo.entrance,
        floor: orderInfo.floor,
        flat: orderInfo.flat,
        intercom: orderInfo.intercom,
        comment: orderInfo.comment,
        latitude: userGeoData.latitude,
        longitude: userGeoData.longitude,
      };
      setUserLocation(locationParams);
    })();
  }, [userGeoData?.fullAddress]);

  const handleConfirmButton = () => {
    hideConfirmBottomSheet();
    setTimeout(() => {
      hideModal();
      if (userLocation) {
        addAddress(userLocation);
      }

      if (basket?.content.length) {
        clearBasket();
        tokenForMetrics && ym ? ym(88121717, 'reachGoal', 'clear_basket') : null;
      }
      if (router.pathname === "/") return router.replace(router.asPath);
      return router.push(`/?categoryId=${partner?.marketCategoryId}`);
    }, 400);
  };

  const handleCancelButton = () => {
    hideConfirmBottomSheet();
    setTimeout(hideModal, 350);
  };

  const isHaveProducts = !!basket?.content.length;

  const { showConfirmBottomSheet, hideConfirmBottomSheet } = useConfirmBottomSheet({
    onConfirm: handleConfirmButton,
    onCancel: handleCancelButton,
    title: "Прочти пожалуйста!",
    message: `Выбранное заведение не доставляет по указанному адресу`,
    cancelText: "Оставить предыдущий",
    confirmText: `Изменить ${isHaveProducts ? "и удалить корзину" : ""}`,
    dependencies: [userLocation, isHaveProducts]
  });

  const handleSelectOtherAddress = () => {
    hideNoneCityModal();
    return field.current?.focus();
  };

  const {
    showConfirmBottomSheet: showNoneCityModal,
    hideConfirmBottomSheet: hideNoneCityModal
  } = useConfirmBottomSheet({
    onConfirm: () => null,
    onCancel: handleSelectOtherAddress,
    title: "Печалька :(",
    message: "К сожалению, мы пока не доставляем по этому адресу.",
    cancelText: "Указать другой адрес",
    confirmText: '',
  });

  const handleSaveAddress = async () => {
    if (!userGeoData) return;
    const params = {
      basketId: basket?.id as string,
      isEmptyBasket: !basket?.content.length,
      partnerId: partner?.id,
      categoryId: partner?.marketCategoryId,
      latitude: userGeoData?.latitude,
      longitude: userGeoData?.longitude,
    };

    updatePage({
      router,
      params,
      callbackFailure: showConfirmBottomSheet,
      callBackSuccess: () => handleSuccessAddress(userLocation),
    });
  };

  const handleSuccessAddress = (address: UserAddressToken | null) => {
    if (address) {
      const newAddress = {
        ...address,
        entrance: orderInfo.entrance ?? '',
        floor: orderInfo.floor ?? '',
        flat: orderInfo.flat ?? '',
        intercom: orderInfo.intercom ?? '',
        comment: orderInfo.comment ?? '',
      };
      addAddress(newAddress);
    }
    hideModal();
    setTimeout(onSuccesAddress, 300);
  };

  const handleEditAddress = async () => {
    if (defaultAddress) {
      const data = userGeoData ?? defaultAddress;

      let city: City | undefined = undefined;

      if (userGeoData?.cityName !== defaultAddress.cityName) {
        const addressCity = data?.cityName;
        const cities = await getDeliveryCities();
        city = cities.find(
          (item) =>
            addressCity && item.name.toLowerCase() === addressCity?.toLowerCase()
        );
      }

      const newAddress = {
        id: defaultAddress.id,
        cityId: city?.id ?? defaultAddress.cityId,
        cityName: city?.name ?? defaultAddress.cityName,
        fullAddress: userGeoData?.fullAddress ?? defaultAddress.fullAddress,
        address: data.address,
        entrance: orderInfo.entrance ?? defaultAddress.entrance,
        floor: orderInfo.floor ?? defaultAddress.floor,
        flat: orderInfo.flat ?? defaultAddress.flat,
        intercom: orderInfo.intercom ?? defaultAddress.intercom,
        comment: orderInfo.comment ?? defaultAddress.comment,
        latitude: data.latitude,
        longitude: data.longitude,
      };

      editAddress(newAddress);

      onSuccesAddress();

      // if (address.toLowerCase() === location?.address.toLowerCase()) {
      //   handleSetLocation({
      //     ...newAddress,
      //     fullAddress: userGeoData?.fullAddress ?? "",
      //     addressObj: userGeoData?.addressObj ?? location.addressObj
      //   })
      // }
      hideModal();
    }
  };

  const handleClear = () => {
    setUserLocation(null);
    setUserGeoData(null);
    setDefaultAddress(null);
    setAddress("");
    setOrderInfo({
      intercom: "",
      entrance: "",
      floor: "",
      flat: "",
      comment: "",
    });
  };

  return (
    <div className={clsx(styles.wrapper, className)} {...props}>

      <div
        className={clsx(styles.addressStage, {
          [styles.addressStageHidden]: activeStage === 2,
          [styles.addressStageVisible]: activeStage === 1,
        })}
      >
        <ButtonMobile pressed={false} appearance='text' onClick={hideModal} className={styles.closeButton}>
          <CloseIcon className={styles.closeIcon} />
        </ButtonMobile>
        <div className={styles.mapContainer}>
          <MapViewMobile
            initAddress={defaultAddress ?? location}
            handleSaveAddress={handleSaveAddress}
            getUserGeoData={setUserGeoData}
            setActiveStage={setActiveStage}
          />
        </div>
      </div>

      <div
        className={clsx(styles.addressStage, styles.detailsStage, {
          [styles.addressStage2Hidden]: activeStage === 1,
          [styles.addressStage2Visible]: activeStage === 2,
        })}
      >
        <div className={styles.safeAreaLine}></div>
        <div className={styles.stickyBlock}>
          <div className={styles.searchBar}>
            <Input
              id="suggestMobile"
              clear
              appearance='text'
              placeholder="Укажите адрес доставки"
              value={address ?? ""}
              onChange={({ currentTarget }) => {
                setAddress(currentTarget?.value);
              }}
              onClear={handleClear}
              innerRef={field}
              autocomplete="off"
            />
            <ButtonMobile pressed={false} appearance='text' onClick={hideModal} className={styles.closeTextButton}>
              Закрыть
            </ButtonMobile>
          </div>

          <div className={clsx(styles.addressDetails, {
            [styles.visible]: userLocation || defaultAddress,
          })}>
            <div className={styles.subDetails}>
              <Input
                type="number"
                onChange={({ currentTarget }) =>
                  changeOrderInfo("flat", currentTarget.value)
                }
                label="Квартира"
                value={orderInfo.flat}
                innerRef={subDetailsField}
                min={1}
                maxLength={4}
              />
              <Input
                onChange={({ currentTarget }) =>
                  changeOrderInfo("intercom", currentTarget.value)
                }
                label="Домофон"
                value={orderInfo.intercom}
                min={1}
                maxLength={12}
              />
              <Input
                type="number"
                onChange={({ currentTarget }) =>
                  changeOrderInfo("entrance", currentTarget.value)
                }
                label="Подъезд"
                value={orderInfo.entrance}
                min={1}
                maxLength={3}
              />
              <Input
                type="number"
                onChange={({ currentTarget }) =>
                  changeOrderInfo("floor", currentTarget.value)
                }
                label="Этаж"
                value={orderInfo.floor}
                min={1}
                maxLength={3}
              />
            </div>
            <Textarea
              onChange={({ currentTarget }) =>
                changeOrderInfo("comment", currentTarget.value)
              }
              placeholder="Комментарий к адресу"
              value={orderInfo.comment}
              className={styles.preference}
              maxLength={300}
            />
          </div>
        </div>

        <div className={styles.bottomBar}>
          <ButtonMobile
            pressed={false}
            appearance='text'
            className={clsx(styles.selectMapButton, {
              [styles.visible]: userLocation || defaultAddress,
            })}
            onClick={() => setActiveStage(1)}
          >
            <LocationIcon className={styles.locationIcon} />
            Указать на карте
          </ButtonMobile>

          <ButtonMobile
            pressed={false}
            className={clsx(styles.saveButton, {
              [styles.visible]: !token ? userLocation :
                defaultAddress ? isChangedDetails : userLocation,
            })}
            onClick={defaultAddress ? handleEditAddress : handleSaveAddress}
          >
            СОХРАНИТЬ
          </ButtonMobile>
        </div>

      </div>
    </div>
  );
};
