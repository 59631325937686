import styles from "./CounterMobile.module.css";
import CounterProps from "./CounterMobile.props";
import MinusSvg from "~/assets/icons/minus.svg";
import PlusSvg from "~/assets/icons/plus.svg";
import clsx from "clsx";
import { ButtonMobile } from '../ButtonMobile';

export const CounterMobile = ({
  className,
  value = 0,
  min = 0,
  max = 99,
  loading = false,
  onPlus = () => null,
  onMinus = () => null,
}: CounterProps): JSX.Element => {
  const incrementCount = async () => {
    try {
      await onPlus();
    } catch (e) {
      console.log("Ошибка увеличения счётчика", e);
    }
  };

  const decrementCount = async () => {
    try {
      await onMinus();
    } catch (e) {
      console.log("Ошибка уменьшения счётчика", e);
    }
  };

  return (
    <div className={clsx(styles.counter, className)}>
      <ButtonMobile
        size='l'
        pressed={false}
        appearance='gray'
        disabled={loading}
        onClick={decrementCount}
        className={styles.counterBtn}
      >
        <MinusSvg className={styles.btnSvg} />
      </ButtonMobile>
      <div className={styles.count}>
        <span className={styles.sht}>{value}</span>
      </div>
      <ButtonMobile
        size='l'
        pressed={false}
        appearance='gray'
        disabled={loading}
        onClick={incrementCount}
        className={styles.counterBtn}
      >
        <PlusSvg className={styles.btnSvg} />
      </ButtonMobile>
    </div>
  );
};
