import clsx from "clsx";

import { ButtonMobile, TitleMobile } from '~/components';
import AuthNickNameProps from "./AuthNickName.props";
import styles from "./AuthNickName.module.css";
import AlertIcon from "~/assets/icons/alert-colored.svg";

import useSaveUserName from '~/hooks/useSaveUserName';

export const AuthNickName = ({
  onSuccessAuth = () => null,
  isOrderingScreen = false,
  className,
  ...props
}: AuthNickNameProps): JSX.Element => {
  const {
    setName,
    handleSaveName,
    name,
    isLoading,
    errors
  } = useSaveUserName(onSuccessAuth);

  return (
    <>
      <TitleMobile headerType='h4' margin={false}>Авторизация</TitleMobile>

      <div className={styles.content} {...props}>
        <p className={styles.text}>
          Введите ваше имя, чтобы мы могли к вам правильно обращаться
        </p>
        <div
          className={clsx(styles.inputWrapper, {
            [styles.inputWrapperInvalid]: errors.length,
          })}
        >
          <AlertIcon className={styles.alertSvg} />
          <input
            className={styles.input}
            type="text"
            placeholder="Ваше имя"
            autoComplete="off"
            value={name}
            autoFocus
            onChange={(e) => setName(e.target.value)}
          />
          <div className={styles.alertPrompt}>
            {errors[0]}
          </div>
        </div>

        <ButtonMobile
          type='button'
          onClick={handleSaveName}
          disabled={isLoading || name.length < 2}
          className={styles.confirmBtn}
        >
          Сохранить
        </ButtonMobile>
      </div>
    </>
  );
};
