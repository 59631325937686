import { useRouter } from 'next/router';
import { useState, DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { ButtonMobile } from "~/components";

import { createOrder, CreateOrderArgs, CreateOrderProps, ResponseCreateOrder } from "~/plugins/api/order";
import { customAuthFetch } from '~/plugins/services/fetch';
import { setCookie } from '~/helpers/cookieUtilites';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { LinkNewOnlineCard } from '~/constants';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  orderData: CreateOrderArgs | null;
  disabled?: boolean;
  loading?: boolean;
}

declare const ym: any;

const TinkoffPayButton = ({
  orderData,
  disabled,
  loading,
  ...props
}: IProps): JSX.Element => {
  const { push } = useRouter();
  const { basket, handleSetBasket, tokenForMetrics } = useBasketContext();
  const { activePayment } = useBasketContext();

  const [isPaying, setIsPaying] = useState(false);

  const handleCreateOrder = async () => {
    setIsPaying(true);
    try {
      if (!orderData) return console.error("Заказ не был сохранён");
      const dataPay = activePayment?.key === LinkNewOnlineCard.key ? {
        linkNewCard: true,
        rebillId: null
      } : {
        linkNewCard: false,
        rebillId: activePayment?.key
      };

      const newOrderData: CreateOrderProps = {
        ...orderData,
        ...dataPay
      };

      const { orderId, paymentUrl } = await customAuthFetch<ResponseCreateOrder>(() => createOrder(newOrderData));

      setCookie({ name: "cron-user", value: "online" });
      console.log("Заказ создан !", orderId);
      setIsPaying(false);

      if (paymentUrl.length) {
        push(paymentUrl);
      }

      if (tokenForMetrics && basket && orderId.length) {
        ym ? ym(88121717, 'reachGoal', 'pay_online') : null;

        const products = basket.content.map((it) => {
          return {
            id: it.id,
            name: it.name,
            price: it.cost,
            brand: basket.partnerName,
            category: basket.marketCategoryId,
            variant: "",
            quantity: it.quantity
          };
        });

        dataLayer.push({
          ecommerce: {
            currencyCode: "RUB",
            purchase: {
              actionField: {
                id: orderId
              },
              products: products
            }
          }
        });

        handleSetBasket(null);

        push(`/myorders/details/${orderId}`);
      }
    } catch (e) {
      setIsPaying(false);
      console.log("Ошибка оформления заказа", e);
      alert("Ошибка оформления заказа. Свяжитесь с оператором.");
    }
  };

  return (
    <ButtonMobile
      pressed={false}
      size="h"
      disabled={disabled}
      loading={!orderData || loading || isPaying}
      onClick={handleCreateOrder}
      {...props}
    >
      ОПЛАТИТЬ
    </ButtonMobile>
  );
};


export const TinkoffPayButtonMobile = memo(TinkoffPayButton);