import { httpv1 } from "../http";

export const sendCode = async (phone: string) => {
  const response = await httpv1.post("Auth/send_code", {
    phoneNumber: phone,
  });

  return response.data;
};

interface ConfirmCodeArgs {
  phoneNumber: string;
  code: string;
  basketId?: string;
}

export const confirmCode = async ({
  phoneNumber,
  code,
  basketId,
}: ConfirmCodeArgs) => {
  const response = await httpv1.post("Auth/confirm_code", {
    phoneNumber,
    code,
    basketId,
  });

  return response.data;
};

export interface Token {
  accessToken: string;
  refreshToken: string;
}

export interface RefreshToken extends Token {
  headers: any;
}

export const getRefreshToken = async ({ accessToken, refreshToken }: Token) => {
  const response = await httpv1.post<RefreshToken>("Auth/refresh_token", {
    accessToken,
    refreshToken,
  });

  return response.data;
};
