import { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";

import OrderProgressDesktopProps from "./OrderProgressDesktop.props";
import WarningIcon from "~/assets/icons/circle-wavy-warning.svg";
import CheckIcon from "~/assets/icons/circle-wavy-check.svg";
import DeliveryManIcon from "~/assets/icons/delivery-bicycle.svg";
import CoffeeIcon from "~/assets/icons/coffee.svg";
import WatchIcon from "~/assets/icons/sand-watch-outline.svg";
import CartIcon from "~/assets/icons/cart-card.svg";
import PickUpIcon from "~/assets/icons/pick-up-outline.svg";
import styles from "./OrderProgressDesktop.module.css";
import { deliverIsTommorow, deliveryIsNow } from '~/helpers/deliveryDateTime';
import { TitleDesktop } from '../TitleDesktop';

interface IStages {
  id: number;
  name: string;
  icon: ReactNode,
}

const STAGES_PARTNER: IStages[] = [
  {
    id: 1,
    name: "Модерация",
    icon: <WarningIcon />
  },
  {
    id: 2,
    name: "Готовится",
    icon: <CoffeeIcon />
  },
  {
    id: 3,
    name: "В пути",
    icon: <DeliveryManIcon />
  },
  {
    id: 4,
    name: "Доставлен",
    icon: <CheckIcon />
  },
];

const STAGES_RETAIL: IStages[] = [
  {
    id: 1,
    name: "Модерация",
    icon: <WarningIcon />
  },
  {
    id: 2,
    name: "Собирается",
    icon: <CartIcon />
  },
  {
    id: 3,
    name: "В пути",
    icon: <DeliveryManIcon />
  },
  {
    id: 4,
    name: "Доставлен",
    icon: <CheckIcon />
  },
];

const STAGES_PICK_UP: IStages[] = [
  {
    id: 1,
    name: "Модерация",
    icon: <WarningIcon />
  },
  {
    id: 2,
    name: "Готовится",
    icon: <CoffeeIcon />
  },
  {
    id: 3,
    name: "К получению",
    icon: <PickUpIcon />
  },
];

export const OrderProgressDesktop = ({
  className,
  order,
  ...props
}: OrderProgressDesktopProps): JSX.Element => {
  const [activeStage, setActiveStage] = useState<number | null>(1);

  useEffect(() => {
    if (!order) return;
    if (!order?.deliverAtDatetime) return setActiveStage(order?.statusId);
    if (deliverIsTommorow(order?.deliverAtDatetime))
      return setActiveStage(null);
    if (!deliveryIsNow(order?.deliverAtDatetime))
      return setActiveStage(null);
  }, [order]);

  const stagesProgress = (
    <div className={styles.stagesContainer}>
      {!activeStage ? (
        <div
          className={clsx(styles.itemStage, styles.itemStagesActive)}
        >
          <div data-last-child={!activeStage} className={styles.itemIcon}><WatchIcon /></div>
          <div
            className={styles.stageName}
          >
            В ожидании
          </div>
        </div>
      ) : (
        STAGES_PARTNER.map(({ id, name, icon }) => (
          <div
            key={id}
            className={clsx(styles.itemStage, {
              [styles.itemStagesActive]: activeStage === id,
            })}
          >
            <div data-last-child={id === 4} className={styles.itemIcon}>{icon}</div>
            <div
              className={clsx(styles.stageName, {
                [styles.stageWay]: id === 3,
                [styles.stageDelivered]: id === 4,
              })}
            >
              {name}
            </div>
          </div>
        )))}
    </div>
  );

  const finalStages = (
    <div className={clsx(styles.text, {
      [styles.redText]: order?.statusId === 5,
      [styles.greenText]: order?.statusId === 4
    })}>
      {order?.statusId === 4 ? "доставлен" : "отменен"}
    </div>
  );

  const isFinalStages = order?.statusId === 4 || order?.statusId === 5;

  return (
    <div
      className={clsx(styles.progressContainer, {
        [styles.rowContainer]: isFinalStages
      }, className)}
      {...props}
    >
      {order?.paymentStatus?.toLocaleLowerCase() === "new" ? (
        <TitleDesktop headerType='h4' margin={false} className={styles.warningTitle}>
          Заказ Неоплачен!
        </TitleDesktop>
      ) : (
        <>
          <TitleDesktop headerType='h6' margin={false}>
            Заказ № {order?.orderNumber}
          </TitleDesktop>
          {isFinalStages ? finalStages : stagesProgress}
        </>
      )}

    </div>
  );
};
