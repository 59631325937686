import TagDesktopProps from "./TagDesktop.props";
import styles from "./TagDesktop.module.css";
import clsx from "clsx";

export const TagDesktop = ({
  className,
  children,
  active = false,
  disabled = false,
  innerRef,
  color = "gray",
  ...props
}: TagDesktopProps): JSX.Element => {
  return (
    <button
      className={clsx(styles.tag, {
        [styles.active]: active,
        [styles.colorBrown]: color === "brown",
        [styles.colorGray]: color === "gray",
        [styles.colorLight]: color === "light",
        [styles.colorGost]: color === "gost",
      }, className)}
      {...props}
      disabled={disabled}
      ref={innerRef}
    >
      {children}
    </button>
  );
};
