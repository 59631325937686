import clsx from "clsx";
import TextareaProps from "./Textarea.props";
import styles from "./Textarea.module.css";

export const Textarea = ({
	className,
	size,
	value = "",
	appearance = "outline",
	noResize = false,
	disabled = false,
	minLength = undefined,
	maxLength = undefined,
	...props
}: TextareaProps): JSX.Element => {
	return (
		<textarea
			disabled={disabled}
			value={value}
			minLength={minLength}
			maxLength={maxLength}
			className={clsx(
				styles.textarea,
				{
					[styles.fill]: appearance === "fill",
					[styles.outline]: appearance === "outline",
					[styles.text]: appearance === "text",
					[styles.noResize]: noResize,

					[styles.small]: size === "s",
					[styles.large]: size === "l",
				},
				className
			)}
			{...props}
		/>
	);
};
