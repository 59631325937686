import { PropsWithChildren } from "react";
import clsx from "clsx";

import ArrowIcon from "~/assets/icons/arrow-left.svg";
import ButtonProps from "./ButtonBackDesktop.props";
import styles from "./ButtonBackDesktop.module.css";

export const ButtonBackDesktop = ({
  children = "Назад",
  type = 'gost',
  className,
  color,
  ...props
}: PropsWithChildren<ButtonProps>): JSX.Element => {
  return (
    <button className={clsx(styles.button, { [styles.lite]: type === 'lite' }, className)} {...props}>
      <ArrowIcon
        style={{
          stroke: color,
        }}
        className={styles.arrowIcon}
      />
      <span
        style={{
          color,
        }}
      >
        {children}
      </span>
    </button>
  );
};
