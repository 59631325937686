import { ChangeEvent } from 'react';
import clsx from "clsx";
import InputProps from "./Input.props";
import styles from "./Input.module.css";
import AlertIcon from "~/assets/icons/alert-colored.svg";
import CheckIcon from "~/assets/icons/check.svg";
import DeleteCircledIcon from "~/assets/icons/delete-circled-outline.svg";
import { ButtonDesktop } from '../_DesktopUI/ButtonDesktop';

export const Input = ({
  className,
  clear = false,
  size,
  appearance = "outline",
  addoneAfter,
  feedBack,
  id,
  label = "",
  placeholder = "",
  validationType,
  innerRef,
  min = undefined,
  max = undefined,
  minLength = undefined,
  maxLength = undefined,
  type = "text",
  autocomplete = "off",
  value,
  onClick = () => null,
  onChange = () => null,
  onClear = () => null,
  ...props
}: InputProps): JSX.Element => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      if (maxLength) {
        if (event.currentTarget.value.length <= maxLength) {
          return onChange(event)
        }
        return null;
      }
      return onChange(event)
    } return onChange(event)
  };

  return (
    <div className={clsx(styles.wrapperInput, className)} onClick={onClick}>
      {/* {validationType === "success" ? <div className={styles.successOverlay}></div> : null} */}
      <input
        id={id}
        value={value}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        className={clsx(styles.Input, {
          [styles.fill]: appearance === "fill",
          [styles.outline]: appearance === "outline",
          [styles.text]: appearance === "text",
          [styles.clearInput]: clear,

          [styles.small]: size === "s",
          [styles.large]: size === "l",

          [styles.success]: validationType === "success",
          [styles.error]: validationType === "error",

          [styles.isPaddingRight]: addoneAfter,
          [styles.withLabelInput]:
            label.length && !placeholder && value?.length,
        })}
        type={type}
        autoComplete={autocomplete}
        ref={innerRef}
        onChange={handleChange}
        {...props}
      />

      {clear && !!value ? (
        <ButtonDesktop
          type='button'
          appearance='text'
          className={styles.buttonClear}
          onClick={onClear}
        >
          <DeleteCircledIcon className={styles.deleteIcon} />
        </ButtonDesktop>
      ) : null}
      {!placeholder && label ? (
        <label
          className={clsx(styles.label, {
            [styles.hasValue]: value?.length && value !== undefined,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      ) : null}
      {validationType === undefined && addoneAfter ? <div className={styles.addoneAfter}>{addoneAfter}</div> : null}
      {validationType === "error" ? <AlertIcon className={styles.addoneAfter} /> : null}
      {validationType === "success" ? <CheckIcon className={styles.addoneAfter} /> : null}

      {validationType !== undefined && feedBack ? (
        <div
          className={clsx(styles.feedBack, {
            [styles.successMessage]: validationType === "success",
            [styles.errorMessage]: validationType === "error",
          })}
        >
          {feedBack}
        </div>
      ) : null}
    </div>
  );
};
