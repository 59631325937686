import { useRouter } from 'next/router';
import { useModal } from 'react-modal-hook';

import { ButtonDesktop, Popup } from "~/components";
import AboutDelivery from './AboutDelivery';
import DeliveryIcon from "~/assets/icons/delivery.svg";
// import DeliveryPartnerIcon from "~/assets/icons/taxi.svg";
import styles from "./AboutDelivery.module.css";

import { usePartnerInfo } from '~/hooks/partner';

export const AboutDeliveryPopup = (): JSX.Element => {
  const router = useRouter();
  const partnerId = router.query.id as string;
  // const partnerName = router.query.name as string;
  const { data: partner } = usePartnerInfo(partnerId);

  // const isPartnerDelivery = partner?.deliveryFrames[0].deliveryTypeId === 1;

  const [showPopup, hidePopup] = useModal(
    ({ in: open, onExited }) => (
      <Popup
        onExit={onExited}
        isOpen={open}
        hidePopup={hidePopup}
        elementId="aboutDelivery"
      >
        <AboutDelivery />
      </Popup>
    )
  );

  return (
    <ButtonDesktop
      id="aboutDelivery"
      appearance='text'
      className={styles.metaPlate}
      onClick={showPopup}
    >
      <div className={styles.metaPlateIcon}>
        <DeliveryIcon className={styles.deliverySvg} />
      </div>

      <div className={styles.metaPlateInfo}>
        <div className={styles.metaSubTitle}>
          от {partner?.minAmountOrder}₽
        </div>
        <div className={styles.metaSubText}>О доставке</div>
      </div>
    </ButtonDesktop>
  )
};
