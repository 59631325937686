import { memo, useEffect, useState } from "react";
import { useRouter } from 'next/router';
import Link from "next/link";
import Image from "next/legacy/image";
import clsx from "clsx";

import MobileCategoriesCarouselProps from "./MobileCategoriesCarousel.props";
import { PartnerCategory } from "~/models/partners";
import styles from "./MobileCategoriesCarousel.module.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";

import useHtmlAttributes from "~/hooks/useHtmlAttributes";

const MobileCategoriesCarousel = ({
  className,
  categories,
  ...props
}: MobileCategoriesCarouselProps): JSX.Element => {
  const { devices } = useHtmlAttributes();
  const router = useRouter();
  const activeId = router.query.categoryId as string;
  const partnerId = router.query.id;
  const partnerName = router.query.name as string;

  const [categoriesSlice, setCategoriesSlice] = useState<
    PartnerCategory[][] | null
  >(null);

  useEffect(() => {
    if (categories.length && devices) {
      const promoCategories: PartnerCategory = {
        id: "promo",
        name: "Товары по акции",
        description: null,
        parentCategoryId: "00000000-0000-0000-0000-000000000000",
        partnerId: "00000000-0000-0000-0000-000000000000",
        photo: null,
        priority: 0,
      };
      const newCategories = [promoCategories, ...categories];

      const sliceItems = devices.isTablet ? 12 : 6;
      const sliceCount = Math.ceil(newCategories.length / sliceItems);
      const arr = [];
      for (let i = 1; i <= sliceCount; i++) {
        const prevIndex = (i - 1) * sliceItems;
        const nextIndex = i * sliceItems;
        const sliceArr = newCategories.slice(prevIndex, nextIndex);
        arr.push(sliceArr);
      }
      arr.length && setCategoriesSlice(arr);
    }
  }, [categories, devices]);

  if (!devices || !categoriesSlice) return <></>;

  return (
    <div className={clsx(styles.sliderCategories, className)} {...props}>
      <h4 className={clsx("h4", styles.title)}>Каталог</h4>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className={styles.sliderWrapper}
      >
        {categoriesSlice.length ? (
          categoriesSlice.map((slide, index) => {
            return (
              <SwiperSlide tabIndex={-1} aria-hidden="true" key={index}>
                <div
                  className={clsx(styles.sliderGrid, {
                    [styles.sliderGridTablet]: devices.isTablet,
                  })}
                >
                  {slide.map((category) => {

                    return (
                      <Link
                        href={`/retail/category/${partnerId}?name=${partnerName}&category=${category.name}&categoryId=${category.id}`}
                        passHref
                        key={category.id}
                      >
                        <div
                          className={clsx(styles.sliderItem, {
                            [styles.aciveItem]: activeId === category.id,
                          })}
                        >
                          <div className={styles.imageWrapper}>
                            {category.photo ? (
                              <Image
                                priority
                                unoptimized
                                alt={String(category.name)}
                                layout="fill"
                                objectFit="contain"
                                src={String(category.photo)}
                              />
                            ) : (
                              <div className={styles.percentImg}></div>
                            )}
                          </div>
                          <div
                            className={clsx(
                              styles.sliderItemTitle,
                              "clamp-2"
                            )}
                          >
                            {category.name}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </SwiperSlide>
            );
          })
        ) : (
          <SwiperSlide tabIndex={-1} aria-hidden="true">
            <div className={styles.sliderItem}>
              <div className={styles.imageWrapper}>
                <Image
                  priority
                  unoptimized
                  alt={String(categories[0].name)}
                  layout="fill"
                  objectFit="cover"
                  src={String(categories[0].photo)}
                />
              </div>
              <div>{categories[0].name}</div>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
};

export const MemoMobileCategoriesCarousel = memo(MobileCategoriesCarousel);
