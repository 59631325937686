import { DetailedHTMLProps, HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from "clsx";

import {
  AddressSelectButtonMobile,
  ButtonMobile,
  ButtonTimeSelectMobile,
  PromocodeButtonMobile,
  SelectChangeBanknoteMobile,
  SelectPaymentMethodMobile,
  Textarea,
  TinkoffPayButtonMobile,
} from "~/components";
import TimerIcon from "~/assets/icons/timer-field.svg";
import ArrowIcon from "~/assets/icons/arrow-right-black.svg";
import styles from "./PayBottomSheet.module.css";
import { useUserContext } from '~/hooks/user/useUser.hook';

import { customAuthFetch } from '~/plugins/services/fetch';
import { createOrder, CreateOrderArgs } from '~/plugins/api/order';
import { IOrderInfo } from '~/pages/ordercheckout';
import { setCookie } from '~/helpers/cookieUtilites';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { useAddressContext } from '~/hooks/address/useAddress.hook';
import { usePartnerInfo } from '~/hooks/partner';

interface PayBottomSheetProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  hideModal: () => void;
}

declare const ym: any;

export const Pay = ({
  hideModal = () => null,
  className,
  ...props
}: PayBottomSheetProps): JSX.Element => {
  const router = useRouter();
  const { user } = useUserContext();
  const { location } = useAddressContext();
  const { activePayment, payments, changeFromBanknote, basket, loadingBasket, tokenForMetrics, handleSetBasket } = useBasketContext();

  const { data: partner } = usePartnerInfo(basket?.partnerId ?? "");

  const availablePaymentMethods= partner?.paymentMethods;
  let onlyCash;
  let onlyCard;
  if (availablePaymentMethods) {
    onlyCash = (availablePaymentMethods.length === 1 && availablePaymentMethods[0].name === 'Наличные')
    onlyCard = (availablePaymentMethods.length === 1 && availablePaymentMethods[0].name === 'Онлайн')
  }

  const amount = useMemo(() => {
    if (!basket) return 0;

    return Math.ceil(basket.amount - basket.discount);
  }, [basket]);

  const oldAmount = useMemo(() => {
    if (!basket) return 0;
    if (basket.amount === amount) return 0;

    return basket.amount;
  }, [basket]);

  const [orderInfo, setOrderInfo] = useState<IOrderInfo>({
    intercom: "",
    entrance: "",
    floor: "",
    flat: "",
    addressComment: "",
    comment: "",
    longitude: "",
    latitude: "",
  });

  useEffect(() => {
    if (location?.longitude && location?.latitude) {
      setOrderInfo({
        intercom: location?.intercom,
        entrance: location?.entrance,
        floor: location?.floor,
        flat: location?.flat,
        addressComment: location?.comment,
        longitude: location.longitude,
        latitude: location.latitude,
      });
    }
  }, [location]);

  const [onlineOrderData, setOnlineOrderData] = useState<CreateOrderArgs | null>(null);

  useEffect(() => {
    if (basket && user && location && orderInfo) {
      setOnlineOrderData({
        basketId: basket?.id,
        phoneNumber: user?.phone,
        deliveryCityId: location?.cityId,
        address: location?.address,
        paymentMethodId: 2,
        changeSum: changeFromBanknote?.type ?? "",
        ...orderInfo,
      });
    }
  }, [basket, user, location, orderInfo, changeFromBanknote]);

  const [pending, setPending] = useState(false);

  const sendCreateOrder = async () => {
    try {
      setPending(true);

      if (basket && location && user?.phone) {
        const { orderId } = await customAuthFetch<any>(() => createOrder({
          basketId: basket.id,
          phoneNumber: user.phone,
          deliveryCityId: location?.cityId,
          address: location?.address,
          paymentMethodId: 1,
          changeSum: changeFromBanknote?.type ?? "",
          ...orderInfo,
        }));

        if (tokenForMetrics && orderId) {
          ym ? ym(88121717, 'reachGoal', 'pay_cash') : null;

          const products = basket.content.map((it) => {
            return {
              id: it.id,
              name: it.name,
              price: it.cost,
              brand: basket.partnerName,
              category: basket.marketCategoryId,
              variant: "",
              quantity: it.quantity
            };
          });

          dataLayer.push({
            ecommerce: {
              currencyCode: "RUB",
              purchase: {
                actionField: {
                  id: orderId
                },
                products: products
              }
            }
          });
        }

        setCookie({ name: "cron-user", value: "cash" });
        handleSetBasket(null);
        router.push(`/myorders/details/${orderId}`);
      } else {
        console.log(
          "Пользователь не авторизован/нет адреса/нет корзины",
          basket,
          location,
          user
        );
      }
    } catch (e) {
      console.log("Ошибка оформления заказа", e);
    } finally {
      setPending(false);
    }
  };

  return (
    <div className={clsx(styles.pay, className)} {...props}>
      <form className={styles.form}>
        <Textarea
          // onChange={({ currentTarget }) =>
          //   setOrderComment(currentTarget.value)
          // }
          onChange={({ currentTarget }) => {
            setOrderInfo((info) => ({ ...info, comment: currentTarget.value }));
          }}
          placeholder="Комментарий к заказу"
          value={orderInfo.comment ?? ""}
          noResize
          className={styles.preference}
        />
      </form>
      <div className={styles.block}>
        <AddressSelectButtonMobile />
        <div className={styles.separateLine} />
        <ButtonTimeSelectMobile addoneBefore={<TimerIcon />} addoneAfter={<ArrowIcon />} />
        <div className={styles.separateLine} />
        <SelectPaymentMethodMobile disabled={payments?.length === 1} onlyCash={onlyCash} onlyCard={onlyCard}/>
        <div className={styles.separateLine} />
        <SelectChangeBanknoteMobile />
        <PromocodeButtonMobile className={styles.promocodeButton} />
      </div>

      <div className={styles.buttonsWrapper}>
        <div className={styles.orderPay}>
          <p className={styles.totalSumm}>
            {oldAmount > 0 ?
              <span className={clsx(styles.supText, styles.underText)}>{oldAmount} ₽</span> :
              basket?.oldDeliveryCost !== basket?.deliveryCost ?
                <span className={styles.supText}>Доставка {basket?.deliveryCost}₽</span> :
                <span className={styles.supText}>К оплате</span>
            }
            <span className={styles.itemSumm}>
              {amount} ₽
            </span>
          </p>
          {activePayment?.id === 1 && (
            <ButtonMobile
              id="placeOrder buttonFinishOrder"
              size='l'
              loading={loadingBasket}
              pressed={false}
              disabled={!location?.address || !user || pending}
              className={styles.buttonPay}
              onClick={() => sendCreateOrder()}
            >
              ЗАКАЗАТЬ
            </ButtonMobile>
          )}
          {activePayment?.id !== 1 && (
            <TinkoffPayButtonMobile
              id="buttonFinishOrder"
              loading={loadingBasket}
              disabled={!location?.address || !user || pending}
              orderData={onlineOrderData}
              className={styles.buttonPay}
            />
          )}
        </div>
      </div>
    </div>
  );
};
