import { UserAddress, UserAddressWithList } from "~/models";
import { httpv1 } from "../http";

export const getUserData = async (token?: string) => {
  let headers = {};

  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await httpv1.get("User", {
    headers,
  });

  return response.data.user;
};

export const sendUserName = async (name: string) => {
  const response = await httpv1.post("User/name", {
    name,
  });

  return response.data;
};

export const getUserAddresses = async (token?: string) => {
  let headers = {};

  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await httpv1.get<{ addresses: UserAddressWithList[] }>(
    "User/addresses",
    {
      headers,
    }
  );

  return response.data.addresses;
};

interface IUserAddress extends UserAddress {
  returnModel?: boolean;
}

type IUserAddressProps = Omit<IUserAddress, "id">;

export const setUserAddress = async ({
  cityId,
  address,
  entrance,
  floor,
  flat,
  intercom,
  comment,
  latitude,
  longitude,
  returnModel = true,
}: IUserAddressProps) => {
  const response = await httpv1.post<UserAddressWithList>("User/address", {
    cityId,
    address,
    entrance,
    floor,
    flat,
    intercom,
    comment,
    latitude: String(latitude),
    longitude: String(longitude),
    returnModel,
  });

  return response.data;
};

export const editUserAddress = async ({
  id,
  cityId,
  address,
  entrance,
  floor,
  flat,
  intercom,
  comment,
  latitude,
  longitude,
  returnModel = true,
}: IUserAddress) => {
  const response = await httpv1.put<UserAddressWithList>("User/address", {
    id,
    cityId,
    address,
    entrance,
    floor,
    flat,
    intercom,
    comment,
    latitude,
    longitude,
    returnModel,
  });

  return response.data;
};

export const deleteUserAddress = async (addressId: string, token?: string) => {
  let headers = {};

  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await httpv1.delete<string>("User/address", {
    headers,
    data: {
      id: addressId,
    },
  });

  return response.data;
};
