import { useRouter } from 'next/router';
import clsx from 'clsx';

import { BannerMobile } from "~/components";
import PromotionsSliderMobileProps from "./PromotionsSliderMobile.props";
import { Promotion } from "~/models/promotions";
import styles from "./PromotionsSliderMobile.module.css";

export const PromotionsSliderMobile = ({ promotions }: PromotionsSliderMobileProps): JSX.Element => {
    const router = useRouter();
    const path: boolean = router.pathname === '/';

    if (!promotions) return <></>

    return (
        <div
            className={clsx(styles.promotions, {
                [styles.marginBottom]: !path
            })}
        >
            <div
                className={styles.gridPromotions}
            >
                {promotions.map((banner: Promotion) => {
                    return (
                        <div className={styles.slide} key={banner.id}>
                            <BannerMobile data={banner} />
                        </div>
                    );
                })}
            </div>
        </div>
    )
};
