import HeaderSearchMobileProps from "./HeaderSearchMobile.props";
import { Wrapper } from './Wrapper';
import { Content } from './Content';

export const HeaderSearchMobile = ({
  className,
  ...props
}: HeaderSearchMobileProps): JSX.Element => (
  <Wrapper className={className} {...props}>
    <Content />
  </Wrapper>
);
