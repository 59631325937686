import Link from 'next/link';
import { CategoryLinkDesktop } from './CategoryLinkDesktop';
import styles from "./MarketCategoriesTagsDesktop.module.css";
import MarketCategoriesTagsDesktopProps from "./MarketCategoriesTagsDesktop.props";

export const MarketCategoriesTagsDesktop = ({
  categories
}: MarketCategoriesTagsDesktopProps): JSX.Element => {
  return (
    <div
      className={styles.tags}
    >
      <div
        className={styles.gridTag}
      >
        {categories.map((category) => {
          const { image, name, id } = category;
          return (
            <Link
              href={`/?categoryId=${id}`}
              passHref
              key={id}
            >
              <CategoryLinkDesktop
                id={`${"buttonCategory" + id}`}
                className="buttonCategory"
                image={image}
              >
                {name}
              </CategoryLinkDesktop>
            </Link>
          );

        })}
      </div>
    </div>
  );
};
