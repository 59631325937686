// import { useInView } from 'react-intersection-observer';
import { useRouter } from 'next/router';
import clsx from "clsx";

import { PartnerCardDesktop } from '../_DesktopUI/PartnerCardDesktop';
import { RenderIfVisible } from '../RenderIfVisible';
import PartnerListDesktopProps from "./PartnerListDesktop.props";
import styles from "./PartnerListDesktop.module.css";
import { Partner } from '~/models/partners';

// import useInfiniteScrollingPartners from '~/hooks/useInfiniteScrollingPartners';

const cardHeight = 266;

export const PartnerListDesktop = ({
  partners: dataInit,
  tags,
  className,
  ...props
}: PartnerListDesktopProps): JSX.Element => {
  const router = useRouter();
  const categoryId = router.query.categoryId as string;
  // const filter = router.query.filter as string;
  // const filterId = tags.filter(({ name }) => name === filter)[0]?.id ?? undefined;

  // const { ref, inView } = useInView({
  //   initialInView: false,
  //   rootMargin: `${cardHeight * 3}px 0px ${cardHeight * 3}px 0px`
  // });

  // const {
  //   data,
  //   lastElemKey
  // } = useInfiniteScrollingPartners({ dataInit, categoryId, filter: filterId, limit: dataInit.partners.length, hasMore: inView });

  const Card = (item: Partner) => {
    // if (lastElemKey === item.id) {
    //   return (
    //     <div
    //       key={item.id}
    //       ref={ref}
    //     >
    //       <RenderIfVisible
    //         placeholderCardClass="partnerCard"
    //         defaultHeight={cardHeight}
    //         visibleOffset={cardHeight * 2}
    //       >
    //         <PartnerCardDesktop
    //           marketCategory={categoryId}
    //           partner={item}
    //         />
    //       </RenderIfVisible>
    //     </div>
    //   )
    // } else {
    return (
      <div
        key={item.id}
      >
        <RenderIfVisible
          placeholderCardClass="partnerCard"
          defaultHeight={cardHeight}
          visibleOffset={cardHeight * 2}
        >
          <PartnerCardDesktop
            marketCategory={categoryId}
            partner={item}
          />
        </RenderIfVisible>
      </div>
    )
    // }
  };

  return (
    <div
      className={clsx(styles.wrapper, className)}
      {...props}
    >
      <div className={styles.container}>
        {/* {lastElemKey && data.map((partner) => Card(partner))} */}
        {dataInit.partners.map((partner) => Card(partner))}
      </div>
    </div>
  );
};
