import { useModal } from 'react-modal-hook';
import styles from "./ProductCard.module.css";
import useMobileMapModal from '~/hooks/useMobileMapModal';
import dynamic from 'next/dynamic';
import useAdditivesModal from '~/hooks/useAdditivesModal';
import { namedComponent } from '~/helpers/dynamicComponent';
import { PartnerProduct } from '~/models/partners';
import { useAddressContext } from '~/hooks/address/useAddress.hook';

const MobileProductCardModal = dynamic(() =>
	namedComponent(import('../../modals/MobileProductCardModal'), 'MobileProductCardModal')
);

interface ICardCover {
	product: PartnerProduct;
}

export const CardCover = ({ product }: ICardCover): JSX.Element => {
	const { location } = useAddressContext();

	const { showModal: showAdditiviesModal } = useAdditivesModal(product, true);

	const [showCardModal, hideCardModal] = useModal(({ in: open, onExited }) => {
		return (
			<MobileProductCardModal
				onExit={onExited}
				isOpen={open}
				hideModal={hideCardModal}
				product={product}
			/>
		);
	}, [product]);

	const handlerOpenLocationModal = () => showMobileMapModal();

	const handleCardBackgroundClick = () => {
		if (!location) return handlerOpenLocationModal();

		if (!product.additives.length && !product.requiredAdditiveGroups.length) {
			return showCardModal();
		}
		return showAdditiviesModal();
	};

	const handleSuccessAddress = () => {
		if (!product.additives.length && !product.requiredAdditiveGroups.length) {
			return showCardModal();
		}
		return showAdditiviesModal();
	};

	const { showMobileMapModal } = useMobileMapModal({ succesCallBack: handleSuccessAddress });

	return (
		<div
			className={styles.cardCover}
			onClick={handleCardBackgroundClick}
		/>
	);
};
