import { useState, useEffect } from "react";

export default function useShowChildren() {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    setShowChildren(true);
  }, []);

  return showChildren;
}
