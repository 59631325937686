import { useRouter } from "next/router";
import { useEffect } from "react";
import getAddressInfo from "~/helpers/getAddressInfo";
import { updatePage } from "~/helpers/upadatePage";
import { UserAddressToken, UserAddressWithList } from "~/models";
import { useAddressContext } from "./useAddress.hook";
import { useBasketContext } from "../basket/useBasket.hook";
import { usePartnerInfo } from "../partner";
import useConfirmBottomSheet from "../useConfirmBottomSheet";
import useConfirmModal from "../useConfirmModal";

interface IPopup {
  isMobile?: boolean;
  address: UserAddressWithList | null;
  successChanged: () => void;
}

declare const ym: any;

export default function useChangedAddresses({
  isMobile = false,
  address,
  successChanged = () => null,
}: IPopup) {
  const router = useRouter();
  const { handleSetLocation } = useAddressContext();
  const { clearBasket, basket, tokenForMetrics } = useBasketContext();

  const partnerid = basket?.content.length
    ? basket.partnerId
    : (router.query.id as string);

  const { data: partner } = usePartnerInfo(partnerid);

  const handleConfirmButton = (item: UserAddressWithList | null) => {
    setTimeout(() => {
      if (item) {
        handelSetNewAddress(item);
      }

      if (basket?.content.length) {
        clearBasket();
        tokenForMetrics && ym
          ? ym(88121717, "reachGoal", "clear_basket")
          : null;
      }
      if (router.pathname === "/") return router.replace(router.asPath);
      return router.push(`/?categoryId=${partner?.marketCategoryId}`);
    }, 350);
  };

  const handleCancelButton = () => {
    isMobile ? hideConfirmBottomSheet() : hideConfirmModal();
    setTimeout(successChanged, 350);
  };

  const [ showConfirmModal, hideConfirmModal ] = useConfirmModal({
    onConfirm: () => handleConfirmButton(address),
    onCancel: handleCancelButton,
    width: 500,
    title: "Прочти пожалуйста!",
    message: `Выбранное заведение не доставляет по указанному адресу`,
    cancelText: "Оставить предыдущий",
    confirmText: `Изменить  ${
      basket?.content.length ? "и удалить корзину" : ""
    }`,
    dependencies: [address],
  });

  const { showConfirmBottomSheet, hideConfirmBottomSheet } =
    useConfirmBottomSheet({
      onConfirm: () => handleConfirmButton(address),
      onCancel: handleCancelButton,
      title: "Прочти пожалуйста!",
      message: `Выбранное заведение не доставляет по указанному адресу`,
      cancelText: "Оставить предыдущий",
      confirmText: `Изменить  ${
        basket?.content.length ? "и удалить корзину" : ""
      }`,
      dependencies: [address],
    });

  const handelSetNewAddress = (item: UserAddressWithList) => {
    const address = getAddressInfo(item.address, item.cityName ?? "");
    const NewLocation: UserAddressToken = {
      ...item,
      addressObj: {
        localities: address?.localities.length
          ? address?.localities
          : address?.city,
        street: address?.street,
        house: address?.house,
      },
    };
    handleSetLocation(NewLocation);
    successChanged();
  };

  const handleChangeAddress = (item: UserAddressWithList) => {
    const params = {
      basketId: basket?.id,
      isEmptyBasket: !basket?.content.length,
      partnerId: partner?.id,
      categoryId: partner?.marketCategoryId,
      latitude: item.latitude,
      longitude: item.longitude,
    };

    updatePage({
      router,
      params,
      callbackFailure: isMobile ? showConfirmBottomSheet : showConfirmModal,
      callBackSuccess: () => handelSetNewAddress(item),
    });
  };

  useEffect(() => {
    if (address) {
      handleChangeAddress(address);
    }
  }, [address]);

  return null;
}
