
import { useRouter } from "next/router";
import Link from "next/link";

import { UserAuthDesktop, ButtonBackDesktop } from "~/components";
import styles from "./HeaderAuthorizedDesktop.module.css";
import Logo from "~/assets/svg/logo.svg";

interface IPath {
  [key: string]: {
    pathName: string
  }
}

export const Content = (): JSX.Element => {
  const router = useRouter();
  const pathName = router.pathname;

  const path: IPath = {
    "/ordercheckout/success": {
      pathName: "/",
    },
    "/myaddresses": {
      pathName: "/",
    },
    "/myorders": {
      pathName: "/",
    },
    "/myorders/details/[id]": {
      pathName: "/myorders",
    },
    "/track": {
      pathName: `/myorders/details/${router.query.order as string}`,
    },
  };

  return (
    <div className={styles.headerContainer}>
      <ButtonBackDesktop
        onClick={() => {
          if (path[pathName]) return router.push(path[pathName].pathName);
          return router.back();
        }}
        className={styles.backBtn}
      >
        Назад
      </ButtonBackDesktop>

      <div className={styles.logoContainer}>
        <Link href="/">
          <Logo className={styles.logo} />
        </Link>
      </div>

      <UserAuthDesktop />
    </div>
  );
};
