
import { useRouter } from "next/router";
import { ButtonBackMobile, TitleMobile, UserAuthMobile } from "~/components";
import styles from "./HeaderAuthorizedMobile.module.css";
import Logo from "~/assets/svg/logo.svg";

interface IContent {
  headLine?: string
}

interface IPath {
  [key: string]: {
    pathName: string
  }
}

export const Content = ({ headLine = 'CronMarket' }: IContent): JSX.Element => {
  const router = useRouter();
  const pathName = router.pathname;

  const path: IPath = {
    "/myaddresses": {
      pathName: "/",
    },
    "/myorders": {
      pathName: "/",
    },
    "/myorders/details/[id]": {
      pathName: `/myorders`,
    },
    "/myorders/details/feedback/[id]": {
      pathName: `/myorders/details/${router.query.id as string}`,
    },
    "/track": {
      pathName: `/myorders/details/${router.query.order as string}`,
    },
  };

  return (
    <div className={styles.headerContainer}>
      <ButtonBackMobile
        appearance='light'
        onClick={() => {
          if (path[pathName]) return router.push(path[pathName].pathName);
          return router.back();
        }}
      />

      <TitleMobile headerType='h5' margin={false} align="center" >
        <div className={styles.logoTitle}>{headLine}</div>
        <Logo className={styles.logo} />
      </TitleMobile>

      <UserAuthMobile />
    </div>
  );
};
