import { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import clsx from "clsx";

import { ButtonMobile, TitleMobile } from '~/components';
import AuthPhoneProps from "./AuthPhone.props";
import styles from "./AuthPhone.module.css";
import AlertIcon from "~/assets/icons/alert-colored.svg";
import LoginIcon from "~/assets/icons/login-field.svg";
import {
  sendCode,
} from "~/plugins/api";
import { FieldState } from '~/models/account';

const AuthPhone = ({
  setActiveStage = (stage) => stage,
  getPhone = (phone) => phone,
  initPhone = "",
  isOrderingScreen = false,
  disabled = false,
}: AuthPhoneProps): JSX.Element => {
  const [phone, setPhone] = useState("");
  const [phoneRef, setPhoneRef] = useState<HTMLInputElement | null>(null);
  const [phoneState, setPhoneState] = useState<FieldState>({
    isLoading: false,
    errors: [],
  });

  useEffect(() => {
    if (initPhone.length) {
      setPhone(initPhone)
    }
  }, [initPhone]);

  useEffect(() => {
    setTimeout(() => phoneRef?.focus(), 500)
    setPhoneState({ isLoading: false, errors: [] });

    if (disabled) return
    if (!initPhone.length && isOrderingScreen) {
      setPhoneState({ isLoading: false, errors: ["Введите номер телефона"] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, disabled, isOrderingScreen, phoneRef]);

  const handleSendCode = async () => {
    const phoneRaw = phone.replace(/[^0-9]/g, "");
    try {
      setPhoneState({ ...phoneState, isLoading: true });
      await sendCode(phoneRaw);
      getPhone(phone)
      setActiveStage(1);
    } catch (e) {
      setPhoneState({ ...phoneState, errors: ["Ошибка отправки кода"] });
      console.log("Ошибка отправки кода");
      console.log(e);

      if (phoneRef) {
        phoneRef.focus();
      }
    } finally {
      setPhoneState((state) => {
        return {
          ...state,
          isLoading: false,
        };
      });
    }
  };

  return (
    <>
      <TitleMobile headerType='h4' margin={false}>Авторизация</TitleMobile>
      <div className={styles.content}>
        <p className={styles.text}>
          Введите номер телефона для авторизации
        </p>
        <div
          className={clsx(styles.inputWrapper, {
            [styles.inputDisabled]: disabled,
            [styles.inputWrapperInvalid]: phoneState.errors.length,
          })}
        >
          <AlertIcon className={styles.alertSvg} />

          <IMaskInput
            mask="+7 (000) 000-00-00"
            inputRef={setPhoneRef}
            onAccept={(value, mask) => {
              if (mask.unmaskedValue.startsWith("8") || !value) {
                setPhone("+7 (");
                isOrderingScreen && setPhoneState({ isLoading: false, errors: ["Введите номер телефона"] });
              } else {
                setPhone(value);
              }
            }}
            disabled={phoneState.isLoading}
            type="tel"
            inputMode="numeric"
            autoComplete="off"
            className={styles.input}
            value={phone}
            placeholder="+7 (___) ___-__-__"
          />

          <div className={styles.alertPrompt}>
            {phoneState.errors[0]}
          </div>
        </div>

        <ButtonMobile
          onClick={handleSendCode}
          disabled={phone.replace(/[^0-9]/g, "").length < 11}
          className={styles.confirmBtn}
        >

          <LoginIcon className={clsx(styles.icon)} />
          Войти
        </ButtonMobile>
      </div>

    </>
  );
};


export { AuthPhone };

// const { phoneState, isSuccessSend } = useSendCode(phone);

// useEffect(() => {
//   if (isSuccessSend) {
//     getPhone(phone);
//     setActiveStage(1);
//   }
// }, [isSuccessSend])

// useEffect(() => {
//   if (phoneState.errors.length) {
//     phoneRef?.focus();
//   }
// }, [phoneState])