import { AdditiveCamelCase } from "~/models/basket";
import { Partner, Schedule } from "~/models/partners";

export const additiveCamelCaseToPascalCase = (
  additive: AdditiveCamelCase
): AdditiveCamelCase => ({
  name: additive.name,
  cost: additive.cost,
  id: additive.id,
});

const parseTimeToNumber = (time: string) => {
  const hours = time.slice(0, 2);
  const minutes = time.slice(3, 5);

  return +`${hours}.${minutes}`;
};

export const isPartnerWorkingTime = ({ schedule }: Partner): boolean => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTime = parseTimeToNumber(
    new Date().toLocaleTimeString("ru-RU", { timeZone: timezone })
  );

  const openTime = parseTimeToNumber(schedule.begin);

  const closeTime = parseTimeToNumber(schedule.end);

  let isCloseAtNextDay = false;

  if (openTime >= closeTime) {
    isCloseAtNextDay = true;
  }

  if (openTime === closeTime) {
    return true;
  }

  if (isCloseAtNextDay) {
    return closeTime >= currentTime || currentTime >= openTime;
  } else {
    return openTime < currentTime && currentTime < closeTime;
  }
};

export const partnerIsWorking = (schedule: Schedule | null | undefined) => {
  if (!schedule) return false;
  const hours = new Date().getHours();
  const minute = new Date().getMinutes();
  const curentTime = `${hours < 10 ? "0" + hours : hours}:${
    minute < 10 ? "0" + minute : minute
  }`.split(":");
  const partnerBeginTime = schedule.begin.slice(0, 5).split(":")!;
  const partnerEndTime = schedule.end.slice(0, 5).split(":")!;

  if (
    schedule.begin.slice(0, 5) === "00:00" &&
    schedule.end.slice(0, 5) === "00:00"
  ) {
    return true;
  }

  if (
    +partnerBeginTime[0] <= +curentTime[0] &&
    +curentTime[0] < +partnerEndTime[0]
  )
    return true;
  if (
    +partnerBeginTime[0] <= +curentTime[0] &&
    +curentTime[0] === +partnerEndTime[0]
  ) {
    if (+curentTime[1] < +partnerEndTime[1]) return true;
    return false;
  }
};

export const partnerIsNotOpenYet = (schedule: Schedule | null | undefined) => {
  if (!schedule) return false;
  const hours = new Date().getHours();
  const minute = new Date().getMinutes();
  const curentTime = `${hours < 10 ? "0" + hours : hours}:${
    minute < 10 ? "0" + minute : minute
  }`.split(":");
  const partnerBeginTime = schedule.begin.slice(0, 5).split(":")!;

  if (+curentTime[0] < +partnerBeginTime[0]) return true;
  if (+curentTime[0] > +partnerBeginTime[0]) return false;
  if (+curentTime[0] == +partnerBeginTime[0]) {
    if (+curentTime[1] < +partnerBeginTime[1]) return true;
    return false;
  }
  return false;
};
