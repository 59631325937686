import { PropsWithChildren } from "react";
import clsx from "clsx";

import DrawerMobileProps from "./DrawerMobile.props";
import styles from "./DrawerMobile.module.css";
import useChangeVisibleMobileModal from '~/hooks/useChangeVisibleMobileModal';

export const DrawerMobile = ({
  onExit,
  hideModal,
  placement = "right",  //пока что не реализован функционал с позиционированием
  isOpen,
  className,
  children,
  ...props
}: PropsWithChildren<DrawerMobileProps>): JSX.Element => {
  useChangeVisibleMobileModal({ isOpen, onExit });

  return (
    <div
      id='portal'
      data-portal
      className={clsx(styles.wrapper, {
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
      })}
    >
      <div className={clsx(styles.overlay)} onTouchStart={hideModal} />
      <div className={clsx(styles.window, className)} {...props}>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};
