import { useModal } from "react-modal-hook";
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Link from "next/link";
import clsx from 'clsx';

import { ButtonMobile, MobileProfileModal, TitleMobile } from "~/components";
import UserAuthMobileProps from "./UserAuthMobile.props";
import styles from "./UserAuthMobile.module.css";

import UserCircleOutlineIcon from "~/assets/icons/user-circle-outline.svg";
import UserCircleSolidIcon from "~/assets/icons/user-circle-solid.svg";
import PhoneFieldIcon from "~/assets/icons/phone-field.svg";
import UserAuthLocation from "~/assets/icons/user-location.svg";
import UserAuthOrdersIcon from "~/assets/icons/user-orders.svg";
import ArrowRightBlack from "~/assets/icons/arrow-right-black.svg";
import UserAuthLogout from "~/assets/icons/user-logout.svg";
import VKontakte from "~/assets/icons/vk.svg";
import InstagramIcon from "~/assets/icons/instagram.svg";
import CloseIcon from "~/assets/svg/closeModal30x30.svg";
import WhatsAppIcon from "~/assets/icons/whatsapp.svg";

import { useUserContext } from "~/hooks/user/useUser.hook";
import useConfirmBottomSheet from '~/hooks/useConfirmBottomSheet';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { namedComponent } from '~/helpers/dynamicComponent';
import { ACCESS_LOGIN_PAGES } from '~/constants';
import { useGetSupportPhoneNumber } from '~/hooks/systemInfo';
import maskPhoneNumber from '~/helpers/maskPhoneNumber';
import { useQueryClient } from "react-query";

const Components = {
  AuthViewMobile: dynamic(() =>
    namedComponent(import('~/components/_MobileUI/AuthViewMobile'), 'AuthViewMobile')
  ),
  DrawerMobile: dynamic(() =>
    namedComponent(import('~/components/modals/_Components/DrawerMobile'), 'DrawerMobile')
  )
};

export const UserAuthMobile = ({ className, ...props }: UserAuthMobileProps): JSX.Element => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { loggedIn, userName, logout } = useUserContext();
  const { clearBasket } = useBasketContext();

  const handelLogoutUser = () => {
    if (ACCESS_LOGIN_PAGES.includes(router.pathname)) {
      router.push("/");
    }
    clearBasket();
    logout();
    queryClient.clear();
    handlerHideBottomSheet();
    setTimeout(hideCartDrawer, 300);
  };
  const handlerHideBottomSheet = () => hideConfirmBottomSheet();

  const { showConfirmBottomSheet, hideConfirmBottomSheet } = useConfirmBottomSheet({
    onConfirm: handelLogoutUser,
    onCancel: handlerHideBottomSheet,
    title: "Вы действительно хотите выйти из профиля?",
    message: "",
  });

  const [showProfileModal, hideProfileModal] = useModal(({ in: open, onExited }) => {
    return <MobileProfileModal isOpen={open} onExit={onExited} hideModal={hideProfileModal} />;
  });

  const handleShowProfile = () => {
    hideCartDrawer();
    setTimeout(showProfileModal, 300);
  };

  const { data: supportPhoneNumber } = useGetSupportPhoneNumber();

  const [showCartDrawer, hideCartDrawer] = useModal(
    ({ in: open, onExited }) => {
      return (
        <Components.DrawerMobile
          onExit={onExited}
          isOpen={open}
          hideModal={hideCartDrawer}
        >
          <div className={styles.mobileMenu}>
            <div className={styles.headerMenu}>
              <h2
                className={styles.mobileTitle}
              >
              </h2>
              <div className={styles.mobileClose} onClick={hideCartDrawer}>
                <CloseIcon />
              </div>
            </div>
            <div className={styles.bodyMenu}>
              {loggedIn && userName.length > 2 ? (
                <>
                  {/* {user?.name && ( */}
                  <h1 title={userName} className={styles.mobileName}>
                    {userName}
                  </h1>
                  {/* )} */}
                  <ul className={styles.mobileList}>
                    <li className={styles.mobileItem} onClick={handleShowProfile}>
                      <div>
                        <UserCircleSolidIcon />
                        <span className={styles.itemName}>Мои данные</span>
                        <ArrowRightBlack />
                      </div>
                    </li>
                    {/* <li className={styles.mobileItem}>
                      <Link href={`/`}>
                        <a>
                          <NotificationIcon />
                          <span>Уведомления</span>
                          <ArrowRightBlack />
                        </a>
                      </Link>
                    </li>
                    <li className={styles.mobileItem}>
                      <Link href={`/`}>
                        <a>
                          <UserAuthPopupHeart />
                          <span>Избранное</span>
                          <ArrowRightBlack />
                        </a>
                      </Link>
                    </li> */}
                    <li className={styles.mobileItem} onClick={hideCartDrawer}>
                      <Link href={`/myaddresses`}>
                        <UserAuthLocation />
                        <span className={styles.itemName}>Мои адреса</span>
                        <ArrowRightBlack />
                      </Link>
                    </li>
                    <li className={styles.mobileItem} onClick={hideCartDrawer}>
                      <Link href={`/myorders`}>
                        <UserAuthOrdersIcon />
                        <span className={styles.itemName}>История заказов</span>
                        <ArrowRightBlack />

                      </Link>
                    </li>
                    <li className={styles.mobileItem} onClick={showConfirmBottomSheet}>
                      <div>
                        <UserAuthLogout />
                        <span className={styles.itemName}>Выйти из аккаунта</span>
                      </div>
                    </li>
                  </ul>
                </>
              ) : (
                <div className={styles.mobileAuth}>
                  <Components.AuthViewMobile />
                </div>
              )}
              <div className={styles.socials}>
                <div className={styles.social}>
                  <VKontakte />
                  <Link href={`https://vk.com/cronmarket`}>
                    cronmarket
                  </Link>
                </div>
                <div className={styles.social}>
                  <InstagramIcon />
                  <Link href={`https://www.instagram.com/cronmarket.ru/?hl=ru`}>
                    @cronmarket.ru
                  </Link>
                </div>
                <div className={styles.social}>
                  <WhatsAppIcon />
                  <Link href={`https://wa.clck.bar/79894970664`}>
                    написать в whatsapp
                  </Link>
                </div>
              </div>
              {/* <div className={styles.metaData}>
                <ul className={styles.mobileList}>
                  <li>
                    <Link href={`/`}>
                    <a>Для партнеров</a>
                  </Link>
                  </li>
                  <li>
                    <Link href={`/`}>
                    <a>Стать курьером</a>
                  </Link>
                  </li>
                  <li>
                    <Link href={`/`}>
                    <a>FAQ</a>
                  </Link>
                  </li>
                  <li>
                      <Link href={`/`}>
                    <a>Политика обработки персональных данных</a>
                  </Link>
                    
                  </li>
                  <li>
                     <Link href={`/`}>
                    <a>Пользовательское соглашение</a>
                  </Link>
                    
                  </li>
                </ul>
              </div> */}
            </div>
            <div className={styles.mobileFooter}>
              <TitleMobile headerType='h4'>Поддержка</TitleMobile>
              <p className={styles.footerText}>
                В случае возникновения проблем обращайтесь в техподдержку
              </p>
              <div>
                <ButtonMobile
                  appearance="text"
                  className={styles.buttPhoneSupport}
                >
                  <PhoneFieldIcon />
                  <Link href={`tel:${supportPhoneNumber?.callCenterPhoneNumber}`} className={styles.phoneSupport}>
                    {maskPhoneNumber(supportPhoneNumber?.callCenterPhoneNumber)}
                  </Link>
                </ButtonMobile>
              </div>
            </div>
          </div>
        </Components.DrawerMobile>
      );
    }, [loggedIn, userName, supportPhoneNumber]
  );

  return (
    <div className={clsx(styles.userWrapper, className)} {...props}>
      <ButtonMobile
        pressed={false}
        onClick={showCartDrawer}
        className={styles.loginButton}
        appearance="text"
      >
        {loggedIn ? <UserCircleSolidIcon /> : <UserCircleOutlineIcon />}
      </ButtonMobile>
    </div>
  );
};
