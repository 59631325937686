import { useModal } from "react-modal-hook";
import { ReactNode } from 'react';
import { BottomSheet, ConfirmBottomSheet } from '~/components';

interface IConfirmModal {
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  message?: string;
  cancelText?: string;
  confirmText?: string;
  icon?: ReactNode;
  dependencies?: any[]
}

const DEFAULT_CONFIRM_MESSAGE = "Сообщение окна подтверждения";

export default function useConfirmBottomSheet({
  onConfirm,
  onCancel,
  cancelText = "НЕТ",
  confirmText = "ДА",
  title = "Заголовок",
  message = DEFAULT_CONFIRM_MESSAGE,
  icon,
  dependencies = []
}: IConfirmModal) {
  const [showConfirmBottomSheet, hideConfirmBottomSheet] = useModal(({ in: open, onExited }) => {
    return (
      <BottomSheet isOpen={open} hideModal={hideConfirmBottomSheet} onExit={onExited}>
        <ConfirmBottomSheet
          hideModal={hideConfirmBottomSheet}
          onConfirm={onConfirm}
          onCancel={onCancel}
          title={title}
          message={message}
          cancelText={cancelText}
          confirmText={confirmText}
          icon={icon}
        />
      </BottomSheet>
    );
  }, [...dependencies]);

  return {
    showConfirmBottomSheet,
    hideConfirmBottomSheet,
  };
}
