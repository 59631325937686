import clsx from "clsx";

import {
	ButtonMobile,
	PromocodeBottomSheet
} from '~/components';
import PromocodeButtonMobileProps from "./PromocodeButtonMobile.props";
import styles from "./PromocodeButtonMobile.module.css";
import { useModal } from 'react-modal-hook';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

export const PromocodeButtonMobile = ({
	className,
	...props
}: PromocodeButtonMobileProps): JSX.Element => {
	const { basket } = useBasketContext();

	const [showModal, hideModal] = useModal(
		({ in: open, onExited }) => <PromocodeBottomSheet isOpen={open} hideModal={hideModal} onExit={onExited} />
	);

	return (
		<ButtonMobile
			size='l'
			appearance='gray'
			type='button'
			pressed={false}
			onClick={showModal}
			className={clsx(styles.buttonAddPromocode, {
				[styles.success]: basket?.promocode
			}, className)}
			{...props}
		>
			<span className={styles.buttonText}>
				{basket?.promocode ? basket?.promocode : 'Применить промокод'}
			</span>
		</ButtonMobile>
	);
};