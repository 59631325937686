import BottomSheetProps from "./BottomSheet.props";
import styles from "./BottomSheet.module.css";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import useChangeVisibleMobileModal from '~/hooks/useChangeVisibleMobileModal';

export const BottomSheet = ({
  isOpen,
  onExit,
  hideModal,
  children,
  className,
  ...props
}: PropsWithChildren<BottomSheetProps>): JSX.Element => {
  useChangeVisibleMobileModal({ isOpen, onExit });
  return (
    <div
      id='portal'
      data-portal
      className={clsx(styles.wrapper, {
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
      })}
    >
      <div className={styles.overlay} onTouchStart={hideModal} />

      <div
        className={clsx(styles.window, className)}
        {...props}
      >
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};