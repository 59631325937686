import Image from "next/legacy/image";
import clsx from "clsx";
import CategoryLinkMobileProps from "./CategoryLinkMobile.props";
import styles from "./CategoryLinkMobile.module.css";

export const CategoryLinkMobile = (
  (
    {
      className,
      children,
      image,
      ...props
    }: CategoryLinkMobileProps,
  ): JSX.Element => {
    return (
      <div
        className={clsx(styles.tag, className)}
        {...props}
      >
        <div className={styles.imageWrapper}>
          <Image
            unoptimized
            alt={children?.toString()}
            width={54}
            height={54}
            src={image}
            objectFit="cover"
            layout="fill"
          />
        </div>

        <div className={styles.title}>{children}</div>
      </div>
    );
  }
);
