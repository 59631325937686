import { httpv1 } from "~/plugins/api/http";
import { Category, Tag } from "~/models/category";
import { Partner } from "~/models/partners";
import { Promotion } from "~/models/promotions";

export const cityIdDefault = "1edd9adf-8b76-44a9-84d1-942718a7c008";
export const coordinatesDefault = ["42.97638", "47.50236"];

interface IGetTagsParams {
  cityId?: string;
  marketCategoryId: string | number;
  latitude?: string;
  longitude?: string;
}

export const getTags = async ({
  marketCategoryId,
  latitude = coordinatesDefault[0],
  longitude = coordinatesDefault[1],
}: IGetTagsParams) => {
  const response = await httpv1.get<{ visibleTagsCount: number; tags: Tag[] }>(
    "Tag/bycitymarketcategory",
    {
      params: {
        MarketCategoryId: marketCategoryId,
        Latitude: latitude,
        Longitude: longitude,
      },
    }
  );

  return response.data;
};

export const getCategories = async (cityId = cityIdDefault) => {
  const response = await httpv1.get<Category[]>("MarketCategory/bycity", {
    params: {
      CityId: cityId,
    },
  });

  return response.data;
};

interface IGetPromotionsParams {
  cityId?: string;
  marketCategoryId: string | number | null;
  partnerId?: string | null;
  latitude?: string;
  longitude?: string;
}

export const getPromotions = async ({
  marketCategoryId = null,
  partnerId = null,
  latitude = coordinatesDefault[0],
  longitude = coordinatesDefault[1],
}: IGetPromotionsParams) => {
  const response = await httpv1.get<{ banners: Promotion[] }>(
    "Banners/bycitymarketcategory",
    {
      params: {
        MarketCategoryId: marketCategoryId,
        PartnerId: partnerId,
        Latitude: latitude,
        Longitude: longitude,
      },
    }
  );

  return response.data;
};

export interface IGetPromotionParams {
  promotionId: string;
  latitude?: string;
  longitude?: string;
}

interface IGetPromotionPromo {
  id: string;
  promoCode: string;
  promotionName: string;
  description: string;
}

interface IGetPromotionInformation {
  promotion: IGetPromotionPromo;
  partners: Partner[];
}

export const getPromotionInformation = async ({
  promotionId,
  latitude = coordinatesDefault[0],
  longitude = coordinatesDefault[1],
}: IGetPromotionParams) => {
  const response = await httpv1.get<IGetPromotionInformation>(
    "Promotion/promotion_information",
    {
      params: {
        PromotionId: promotionId,
        Latitude: latitude,
        Longitude: longitude,
      },
    }
  );

  return response.data;
};
