import clsx from 'clsx';
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from "react";
import styles from "./ResendCode.module.css";

interface IResendCodeProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isOrderingScreen?: boolean;
  sendCode?: () => void | Promise<unknown>;
}

const DEFAULT_SEND_CODE_TIMEOUT = 60;

export const ResendCode = ({ isOrderingScreen = false, sendCode = async () => null, className }: IResendCodeProps) => {
  const [seconds, setSeconds] = useState(DEFAULT_SEND_CODE_TIMEOUT);
  const [canSendCode, setCanSendCode] = useState(false);

  const handleSendCode = async () => {
    await sendCode();
    setCanSendCode(false);
  };

  useEffect(() => {
    const itervalId = setInterval(function () {
      if (!canSendCode) {
        setSeconds((sec) => {
          if (sec <= 0) {
            clearInterval(itervalId);
            setCanSendCode(true);
            return DEFAULT_SEND_CODE_TIMEOUT;
          } else {
            return sec - 1;
          }
        });
      }
    }, 1000);

    return () => clearInterval(itervalId);
  }, [canSendCode]);

  return canSendCode ? (
    <div className={clsx(styles.repeatCode, className, {
      [styles.repeatTextNormal]: isOrderingScreen,
      [styles.textUnderline]: isOrderingScreen
    })} onClick={handleSendCode}>
      Выслать код повторно
    </div>
  ) : (
    <div className={clsx(styles.repeatCode, styles.repeatCodeAfter, className, {
      [styles.repeatTextNormal]: isOrderingScreen
    })}>
      Отправить через {seconds} сек
    </div>
  );
};
