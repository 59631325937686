import RadioChoiseProps from "./RadioChoise.props";
import styles from "./RadioChoise.module.css";
import clsx from "clsx";
import { RadioButton } from "../RadioButton";

export const RadioChoise = ({
  className,
  additive,
  isActive = false,
  onSelect = () => null,
  onRemove = () => null,
  name,
  ...props
}: RadioChoiseProps): JSX.Element => {
  return (
    <div
      onClick={onSelect}
      className={clsx(styles.wrapper, className)}
      {...props}
    >
      <RadioButton
        name={name}
        value={additive.id}
        key={props.key}
        checked={isActive}
      />
      <div className={styles.title}>{additive.name}</div>
      <span className={styles.price}>{additive.cost} ₽</span>
    </div>
  );
};
