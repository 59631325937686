import { PropsWithChildren } from "react";
import clsx from "clsx";

import DrawerProps from "./Drawer.props";
import styles from "./Drawer.module.css";
import useChangeVisibleModal from '~/hooks/useChangeVisibleModal';

export const Drawer = ({
  onExit,
  hideModal,
  placement = "right",  //пока что не реализован функционал с позиционированием
  isOpen,
  className,
  children,
  ...props
}: PropsWithChildren<DrawerProps>): JSX.Element => {
  useChangeVisibleModal({ isOpen, hideModal, onExit });

  return (
    <div
      id='portal'
      data-portal
      className={clsx(styles.wrapper, {
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
      })}
    >
      <div className={clsx(styles.overlay)} onClick={hideModal} />
      <div className={clsx(styles.window, className)} {...props}>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};
