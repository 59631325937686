import { useRouter } from "next/router";
import Image from "next/legacy/image";
import clsx from "clsx";

import { ButtonDesktop, CartItem, TitleDesktop } from "~/components";
import { CutleryCounter } from "~/components/CutleryCounter";

import OrderOptionsDesktopProps from "./OrderOptionsDesktop.props";
import styles from "./OrderOptionsDesktop.module.css";
import { LoadingIndicator } from "../../LoadingIndicator";
import useConfirmModal from '~/hooks/useConfirmModal';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { usePartnerInfo } from '~/hooks/partner';

declare const ym: any;

export const OrderOptionsDesktop = ({
  className,
  ...props
}: OrderOptionsDesktopProps): JSX.Element => {
  const router = useRouter();
  const { basket, clearBasket, tokenForMetrics } = useBasketContext();
  const { data: partner } = usePartnerInfo(basket?.partnerId ?? "");
  const products = basket?.content;
  const isCartEmpty = !products?.length;
  const isRestaurant = basket?.marketCategoryId == 1;

  const handleClearBasket = () => {
    clearBasket();
    tokenForMetrics && ym ? ym(88121717, 'reachGoal', 'clear_basket') : null;
    return router.push(`/?categoryId=${basket?.marketCategoryId}`)
  };

  const handleHideConfirmModal = () => hideConfirmModal();

  const [ showConfirmModal, hideConfirmModal ] = useConfirmModal({
    onConfirm: handleClearBasket,
    onCancel: handleHideConfirmModal,
    width: 500,
    title: "Вы хотите очистить корзину?",
    message: '',
    dependencies: [basket]
  });

  return (
    <div
      className={clsx(styles.cart, className)}
      {...props}
    >
      <div className={styles.cartContainer}>
        <div className={styles.cartTop}>
          <TitleDesktop headerType='h3' margin={false} className={styles.cartTopTitle}>
            {partner?.logo && partner?.name ? (
              <div className={styles.image}>
                <Image
                  unoptimized
                  alt={partner?.name}
                  objectFit="cover"
                  width={48}
                  height={48}
                  src={partner?.logo}
                />
              </div>
            ) : null}
            {basket?.partnerName}
          </TitleDesktop>
          <ButtonDesktop
            appearance='text'
            className={styles.cleareBasket}
            onClick={showConfirmModal}
            disabled={!partner}
          >
            Очистить корзину
          </ButtonDesktop>
        </div>
        <div className={styles.separateLine} />
        {!isCartEmpty && partner !== null ? (
          <div
            className={styles.cartBody}
          >
            {isRestaurant && basket ? <CutleryCounter basket={basket} /> : null}
            {products.map((product) => {
              return (
                <CartItem
                  marketCategoryId={basket?.marketCategoryId}
                  partnerId={basket?.partnerId}
                  product={product}
                  key={product.id}
                />
              );
            })}
          </div>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </div>
  );
};
