import { PropsWithChildren } from "react";
import clsx from "clsx";

import { ButtonMobile } from '~/components';
import RepeatIcon from "~/assets/icons/repeat-arrow.svg";
import ButtonRepeatOrderMobileProps from "./ButtonRepeatOrderMobile.props";
import styles from "./ButtonRepeatOrderMobile.module.css";

import { useRepeatOrder } from '~/hooks/order';

export const ButtonRepeatOrderMobile = ({
  orderId,
  className,
  ...props
}: PropsWithChildren<ButtonRepeatOrderMobileProps>): JSX.Element => {
  const { isLoading, handleRepeatengOrder } = useRepeatOrder(true);

  return (
    <ButtonMobile
      size='s'
      loading={isLoading}
      spinnerColor="primary"
      appearance="gray"
      onClick={() => handleRepeatengOrder(orderId)}
      className={clsx(styles.button, className)}
      {...props}
    >
      <RepeatIcon
        className={styles.repeatIcon}
      />
      <span>
        Повторить
      </span>
    </ButtonMobile>
  );
};
