import { useEffect, useState, useMemo, useCallback } from "react";
import { useRouter } from 'next/router';
import { v4 as uuid } from "uuid";

import { Product } from "~/models/basket";
import AddToCartMobileProps from "./AddToCartMobile.props";
import styles from "./AddToCartMobile.module.css";

import MinusIcon from "~/assets/icons/minus.svg";
import PlusIcon from "~/assets/icons/plus.svg";
import CartIcon from "~/assets/icons/cart.svg";
import ClosePlateIcon from "~/assets/icons/shopping-store-signage.svg";

import { partnerIsNotOpenYet, partnerIsWorking } from '~/helpers';
import useMobileMapModal from '~/hooks/useMobileMapModal';
import useConfirmBottomSheet from '~/hooks/useConfirmBottomSheet';
import useAdditivesModal from '~/hooks/useAdditivesModal';
import { ButtonMobile } from '../ButtonMobile';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { useAddressContext } from '~/hooks/address/useAddress.hook';
import { usePartnerInfo } from '~/hooks/partner';

declare const ym: any;

export const AddToCartMobile = ({
	product,
	...props
}: AddToCartMobileProps): JSX.Element => {
	const router = useRouter();
	const { location } = useAddressContext();
	const { addToBasket, removeFromBasket, basket, tokenForMetrics } =
		useBasketContext();
	const { data: partner } = usePartnerInfo(router.query.id as string ?? "");

	const productCart: Product = {
		id: uuid(),
		productId: product.id,
		name: product.name,
		quantity: 1,
		cost: product.cost,
		photoUri: product.image,
		additives: [],
	};

	const isHaveProductsWithBasket = useMemo(() => basket?.partnerId === partner?.id && basket?.content.length, [basket, partner]);

	const [count, setCount] = useState(0);
	const products = useMemo(() => basket?.content, [basket?.content]);
	const inBasket = useCallback(
		() =>
			products?.reduce((acc, item) => {
				return (item.productId === product.id ? item.quantity : 0) + acc;
			}, 0) || 0,
		[product.id, products]
	);

	useEffect(() => {
		setCount(inBasket());
	}, [products]);

	const [loading, setLoading] = useState(false);

	const { showModal } = useAdditivesModal(product, true);

	const fetchAdd = async () => {
		if (!partner) return;
		await addToBasket({
			partnerId: partner.id,
			marketCategoryId: partner.marketCategoryId,
			product: productCart,
			quantity: 1,
		});
		count && setCount(1);
		tokenForMetrics && ym ? ym(88121717, "reachGoal", "add_to_cart") : null;
	};

	const succesFetch = () => {
		if (product.requiredAdditiveGroups?.length || product.additives?.length) {
			return showModal();
		}
		if (!partnerIsWorking(partner?.schedule) && !isHaveProductsWithBasket) return showConfirmBottomSheet();
		fetchAdd();
	};

	const { showMobileMapModal } = useMobileMapModal({ succesCallBack: succesFetch });

	const beginTime = partner?.schedule.begin.slice(0, 5)
	const confirmMessage = partnerIsNotOpenYet(partner?.schedule) ?
		`сегодня с ${beginTime}` :
		`завтра c ${beginTime}`;

	const { showConfirmBottomSheet } = useConfirmBottomSheet({
		onConfirm: fetchAdd,
		onCancel: () => router.push(`/?categoryId=${partner?.marketCategoryId}`),
		title: "Доступен предзаказ",
		message: `Мы можем доставить только когда ${partner?.marketCategoryId == 1 ? "ресторан" : "магазин"} откроется - ${confirmMessage}`,
		cancelText: "Посмотреть другие места",
		confirmText: "Оформить предзаказ",
		icon: <ClosePlateIcon />,
		dependencies: [basket, partner]
	})

	const handlerOpenLocationModal = () => showMobileMapModal();

	const handlePlus = async () => {
		if (!partner) return;

		if (!location) return handlerOpenLocationModal();

		if (product.requiredAdditiveGroups?.length || product.additives?.length) {
			return showModal();
		}

		if (!partnerIsWorking(partner?.schedule) && !isHaveProductsWithBasket) {
			return showConfirmBottomSheet();
		}

		if (partner.id !== basket?.partnerId) {
			return fetchAdd();
		}

		try {
			setLoading(true);
			await addToBasket({
				partnerId: partner.id,
				marketCategoryId: partner.marketCategoryId,
				product: productCart,
				quantity: count + 1,
			});
			count && setCount((count) => count + 1);
			tokenForMetrics && ym ? ym(88121717, "reachGoal", "add_to_cart") : null;
		} catch (e) {
			console.log("Ошибка добавления в корзину", e);
		} finally {
			setLoading(false);
		}
	};

	const handleMinus = async () => {
		if (!partner) return;
		try {
			setLoading(true);
			const currentProductList = products?.filter(
				(p) => p.productId === product.id
			);
			await removeFromBasket({
				partnerId: partner.id,
				marketCategoryId: partner.marketCategoryId,
				quantity: count,
				product: {
					...productCart,
					id:
						currentProductList && currentProductList[currentProductList?.length - 1].id,
				},
			});
			setCount((count) => count - 1);
			tokenForMetrics && ym ? ym(88121717, "reachGoal", "remove_from_cart") : null;
		} catch (e) {
			console.log("Ошибка удаления из корзины", e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			className={styles.counter}
		>
			{!count && (
				<ButtonMobile
					size='l'
					pressed={false}
					appearance="gray"
					className={styles.addFirstProduct}
					onClick={handlePlus}
					{...props}
				>
					<CartIcon
						className={styles.cartIcon}
					/>
					В корзину
				</ButtonMobile>
			)}
			{!!count && (
				<>
					<ButtonMobile
						size='l'
						pressed={false}
						appearance="gray"
						className={styles.button}
						disabled={loading}
						onClick={handleMinus}
					>
						<MinusIcon className={styles.counterIcon} />
					</ButtonMobile>
					<span className={styles.count}>{count}</span>
					<ButtonMobile
						size='l'
						pressed={false}
						appearance="gray"
						className={styles.button}
						disabled={loading}
						onClick={handlePlus}
					>
						<PlusIcon className={styles.counterIcon} />
					</ButtonMobile>
				</>
			)}
		</div>
	);
};
