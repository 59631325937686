import { useMemo } from "react";
import { useModal } from "react-modal-hook";
import { useRouter } from "next/router";
import dynamic from 'next/dynamic';
import clsx from "clsx";

import { ButtonDesktop } from "~/components";
import CartIcon from "~/assets/icons/cart.svg";
import CartButtonLinkProps from "./CartButtonLink.props";
import styles from "./CartButtonLink.module.css";

import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { usePartnerInfo } from '~/hooks/partner';
import { namedComponent } from '~/helpers/dynamicComponent';

const Components = {
  Drawer: dynamic(() =>
    namedComponent(import('~/components/modals/_Components/Drawer'), 'Drawer')
  ),
  AsideCart: dynamic(() =>
    namedComponent(import('~/components/AsideCart'), 'AsideCart')
  ),
  CartPopup: dynamic(() =>
    namedComponent(import('~/components/modals/CartPopup'), 'CartPopup')
  ),
}

export const CartButtonLink = ({
  className,
  ...props
}: CartButtonLinkProps): JSX.Element => {
  const router = useRouter();
  const pathname = router.pathname;
  const queryPartnerId = router.query.id;

  const { basket, loadingBasket } = useBasketContext();
  const { isLoading } = usePartnerInfo(basket?.partnerId ?? "");
  const amount = useMemo(() => {
    if (!basket) return 0;

    return Math.ceil(basket.amount + basket.deliveryCost - basket.discount);
  }, [basket]);

  const handleClickButton = () => {
    if (!basket?.partnerIsStructure) {
      return showCartPopup();
    } else {
      if (pathname === "/shop/[id]" && basket?.partnerId === queryPartnerId) {
        return showCartDrawer();
      }
      return showCartPopup();
    }
  };

  const [showCartDrawer, hideCartDrawer] = useModal(
    ({ in: open, onExited }) => {
      return (
        <Components.Drawer
          onExit={onExited}
          isOpen={open}
          hideModal={hideCartDrawer}
        >
          <Components.AsideCart className={styles.basket} />
        </Components.Drawer>
      );
    }
  );

  const [showCartPopup, hideCartPopup] = useModal(
    ({ in: open, onExited }) => {
      return (
        <Components.CartPopup
          onExit={onExited}
          isOpen={open}
          hidePopup={hideCartPopup}
          elementId="cartPopup"
        />
      )
    }
  );

  if (!basket?.amount || basket?.amount === 0) return <></>;

  return (
    <ButtonDesktop
      id='cartPopup'
      loading={isLoading || loadingBasket}
      onClick={handleClickButton} {...props}
    >
      <CartIcon className={styles.cartIcon} />
      <span className={styles.amount}>{amount} ₽</span>
    </ButtonDesktop>
  );
};
