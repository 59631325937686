import { City } from "~/models";
import { httpv1 } from "~/plugins/api/http";

export const getDeliveryCities = async () => {
  const response = await httpv1.get<City[]>("Address/delivery_cities");

  return response.data;
};

interface ISearchAddress {
  address: string;
  cityId?: string;
}

export const searchAddress = async ({ address, cityId }: ISearchAddress) => {
  const response = await httpv1.get("Address/suggest_address", {
    params: {
      CityId: cityId,
      Address: address,
    },
  });

  return response.data;
};

interface ISearchGeolocateAddress {
  latitude?: string;
  longitude?: string;
}

export const searchGeolocateAddress = async ({
  latitude,
  longitude,
}: ISearchGeolocateAddress) => {
  const response = await httpv1.get("Address/suggest_address_geolocate", {
    params: {
      Geolocate: {
        Latitude: latitude,
        Longitude: longitude,
      },
    },
  });

  return response.data;
};
