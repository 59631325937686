import { useModal } from 'react-modal-hook';
import clsx from "clsx";
import Image from 'next/legacy/image';

import { ButtonDesktop, PaymentMethodsPopup } from "~/components";
import SelectPaymentMethodDesktopProps from "./SelectPaymentMethodDesktop.props";
import styles from "./SelectPaymentMethodDesktop.module.css";
import ArrowIcon from "~/assets/icons/arrow-down-2.svg";
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

export const SelectPaymentMethodDesktop = ({
  className,
  onlyCash,
  onlyCard,
  ...props
}: SelectPaymentMethodDesktopProps): JSX.Element => {
  const { activePayment, payments } = useBasketContext();

  const tooltip = onlyCash ? 'наличными' : onlyCard ? 'онлайн оплату' : null;
  const onlyOnePaymentMethods = onlyCard || onlyCash;
  const [showPopup, hidePopup] = useModal(
    ({ in: open, onExited }) => {
      return <PaymentMethodsPopup isOpen={open} hidePopup={hidePopup} onExit={onExited} elementId={'payment'} />
    }
  );

  return (
    <ButtonDesktop
      id='payment'
      size="m"
      appearance='text'
      className={clsx(styles.buttonSelectPayment, className)}
      onClick={showPopup}
      {...props}
    >
      <span
        className={styles.paymentIcon}
      >
        <Image
          width={45}
          height={30}
          alt={activePayment?.key}
          src={activePayment?.icon ?? ""}
        />
      </span>
      <span className={styles.paymentText}>
        {activePayment?.name}
      </span>
      {onlyOnePaymentMethods &&
        <span className={styles.tooltip}>
          Партнер принимает
          только {tooltip}
        </span>
      }
      {
        payments && payments?.length > 1 &&
        <span>
          <ArrowIcon className={styles.arrowIcon} />
        </span>
      }
    </ButtonDesktop>
  );
};
