import { useEffect, useState } from "react";
import { useModal } from 'react-modal-hook';
import { useRouter } from 'next/router';
import Link from 'next/link';
import clsx from "clsx";

import { TagLinkMobile, BottomSheet, TagMobile, CatalogFiltersBottomSheet } from "~/components";
import styles from "./CatalogFiltersMobile.module.css";
import CatalogFiltersProps from "./CatalogFiltersMobile.props";
import { Tag as ITag } from "~/models/category";
import ArrowIcon from "~/assets/icons/arrow-down-2.svg";
// import SettingsIcon from "~/assets/icons/settings.svg";

export const CatalogFiltersMobile = ({
	className,
	tags,
	...props
}: CatalogFiltersProps): JSX.Element => {
	const tagsVisible = [
		{
			id: "0",
			name: "Все"
		},
		...tags.slice(0, 10)
	];

	const tagsHidden = tags.length > 9 ? [...tags.slice(10)] : [];

	const router = useRouter();
	const categoryId = router.query.categoryId as string;
	const filter = router.query.filter as string;
	const sort = router.query.sort as string ?? null;

	const [hiddenTag, setHiddenTag] = useState<ITag | null>(null);

	useEffect(() => {
		const arrTagsWidth: number[] = [];
		const tags = document.querySelectorAll('.tag')
		tags.forEach(element => {
			return arrTagsWidth.push(Math.ceil(element.getBoundingClientRect().width))
		});

		tags.forEach((element, index) => {
			if (element.textContent === filter) {
				const activeElement = element.getBoundingClientRect()
				const viewWidth = document.body.getBoundingClientRect().width;
				const scrollContainer = document.getElementsByClassName("filters")[0];
				if (activeElement.left < 0 || activeElement.right > viewWidth) {
					const scroll = arrTagsWidth.slice(0, index + 1).reduce((acc, num) => acc + num, 0);
					scrollContainer.scrollTo({ left: scroll - viewWidth / 2 - activeElement.width / 2 - 8, behavior: "smooth" });
				}

			}
		});
	}, [tags])

	const handleSelectTag = (item: ITag | null, isHidden?: boolean) => {
		if (isHidden) {
			setHiddenTag(item)
		} else {
			setHiddenTag(null);
		}
	};

	const [showFiltersPopup, hideFiltersPopup] = useModal(
		({ in: open, onExited }) => {
			return (
				<BottomSheet
					onExit={onExited}
					isOpen={open}
					hideModal={hideFiltersPopup}
				>
					<CatalogFiltersBottomSheet
						categoryId={categoryId}
						tags={tagsHidden}
						handleSelectTag={(item) => {
							handleSelectTag(item, true);
							hideFiltersPopup();
						}}
					/>
				</BottomSheet>
			);
		}, [tagsHidden]
	);

	// const [showSortPopup, hideSortPopup] = useModal(
	// 	({ in: open, onExited }) => {
	// 		return (
	// 			<BottomSheet
	// 				onExit={onExited}
	// 				isOpen={open}
	// 				hideModal={hideSortPopup}
	// 			>
	// 				<CatalogPageSortPopup
	// 					handleSelectTag={hideSortPopup}
	// 				/>
	// 			</BottomSheet>
	// 		);
	// 	}
	// );

	return (
		<div className={clsx("filters", styles.filters, className)} {...props}>
			<div
				className={styles.tags}
			>
				<div className={styles.tagsContainer}>
					{/* <div className={clsx('tag', styles.tagItem)}>
					<TagMobile
						className={clsx(styles.tag, styles.sortButton)}
						active={!!sort}
						onClick={showSortPopup}
						color="light"
					>
						<SettingsIcon className={clsx({ [styles.activeSortIcon]: !!sort })} />Сортировка
					</TagMobile>
				</div> */}
					{tagsVisible.map((tag) => {
						return (
							<div key={tag.id} className={clsx('tag', styles.tagItem)}>
								<Link
									href={`/?categoryId=${categoryId}&filter=${tag.id ? tag.name : "Все"}`}
									passHref
								>
									<TagLinkMobile
										className={styles.tag}
										active={filter === tag.name}
										onClick={() => handleSelectTag(tag)}
										color="light"
									>
										{tag.name}
									</TagLinkMobile>
								</Link>
							</div>
						);
					})}
					{tagsHidden.length ? (
						<div className={clsx('tag', styles.tagItem)}>
							<TagMobile
								className={styles.tag}
								active={!!hiddenTag}
								onClick={showFiltersPopup}
								color="light"
							>
								{hiddenTag ? hiddenTag.name : "Еще"}
								<ArrowIcon className={clsx(styles.arrowIcon, { [styles.activeIcon]: !!hiddenTag })} />
							</TagMobile>
						</div>
					) : null}
				</div>
			</div>
		</div >
	);
};
