import { useRouter } from "next/router";

import { ButtonBackMobile } from "~/components";
import { SearchMobile } from "../../SearchMobile";
import styles from "./HeaderSearchMobile.module.css";

export const Content = (): JSX.Element => {
  const router = useRouter();
  return (
    <div className={styles.headerContainer}>
      <ButtonBackMobile
        appearance='light'
        onClick={() => router.push("/")}
      />
      <SearchMobile className={styles.search} />
    </div>
  );
};
