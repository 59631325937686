import { useModal } from "react-modal-hook";
import clsx from "clsx";

import { ButtonMobile, TimePickerBottomSheet } from '~/components';
import ButtonTimeSelectMobileProps from "./ButtonTimeSelectMobile.props";
import styles from "./ButtonTimeSelectMobile.module.css";

import convertTime from "~/helpers/convertTime";
import { deliverIsTommorow } from '~/helpers/deliveryDateTime';
import useSetDeliveryAtDateTime from '~/hooks/useSetDeliveryAtDateTime';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { usePartnerInfo } from '~/hooks/partner';

export const ButtonTimeSelectMobile = ({
  addoneBefore,
  addoneAfter,
  className,
  ...props
}: ButtonTimeSelectMobileProps): JSX.Element => {
  const { basket } = useBasketContext();
  const { data: partner } = usePartnerInfo(basket?.partnerId ?? "");

  useSetDeliveryAtDateTime(partner)

  const [showDeliveryAtTime, hideDeliveryAtTime] = useModal(
    ({ in: open, onExited }) => {
      return partner ? (
        <TimePickerBottomSheet
          isOpen={open}
          onExit={onExited}
          hideModal={hideDeliveryAtTime}
          schedule={partner.schedule}
          handlerSaveTime={hideDeliveryAtTime}
          isRoundTheClock={partner.isRoundTheClock}
        />
      ) : <></>
    },
    [partner]
  );

  return (
    <ButtonMobile
      pressed={false}
      size="l"
      appearance='text'
      type='button'
      className={clsx(styles.buttonTimePicker, className)}
      onClick={showDeliveryAtTime}
      {...props}
    >
      {addoneBefore ? <span className={clsx(styles.addone, styles.addoneBefore)}>{addoneBefore}</span> : null}
      <span className={styles.time}>
        {basket?.deliverAtDatetime !== null ? (
          `
          ${deliverIsTommorow(
            basket?.deliverAtDatetime
          )
            ? "завтра "
            : "сегодня "}
          ${convertTime(basket?.deliverAtDatetime)}
          `
        ) : (
          `${partner?.avgDeliveryTime?.begin} ~ ${partner?.avgDeliveryTime?.end} мин.`
        )}
      </span>
      <span className={styles.lightText}>Доставить позже?</span>
      {addoneAfter ? <div className={clsx(styles.addone, styles.addoneAfter)}>{addoneAfter}</div> : null}
    </ButtonMobile>
  );
};
