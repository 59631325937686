import { useModal } from "react-modal-hook";
import { ReactNode } from 'react';
import { ConfirmModal, Modal } from '~/components';

interface IConfirmModal {
  onConfirm: () => void;
  onCancel: () => void;
  width: number;
  title?: string;
  message?: string;
  cancelText?: string;
  confirmText?: string;
  icon?: ReactNode;
  dependencies?: any[]
}

const DEFAULT_CONFIRM_MESSAGE = "Сообщение окна подтверждения";

export default function useConfirmModal({
  onConfirm,
  onCancel,
  width,
  cancelText = "НЕТ",
  confirmText = "ДА",
  title = "Заголовок",
  message = DEFAULT_CONFIRM_MESSAGE,
  icon,
  dependencies = []
}: IConfirmModal) {
  const [showConfirmModal, hideConfirmModal] = useModal(({ in: open, onExited }) => {
    return (
      <Modal width={width} headline={title} isOpen={open} hideModal={hideConfirmModal} onExit={onExited}>
        <ConfirmModal
          hideModal={hideConfirmModal}
          onConfirm={onConfirm}
          onCancel={onCancel}
          message={message}
          cancelText={cancelText}
          confirmText={confirmText}
          icon={icon}
        />
      </Modal>
    );
  }, [...dependencies]);

  return [
    showConfirmModal,
    hideConfirmModal,
  ];
}
