import clsx from "clsx";
import Image from 'next/legacy/image';

import { BottomSheet, TitleMobile } from "~/components";
import PaymentMethodBottomSheetProps from "./PaymentMethodBottomSheet.props";
import styles from "./PaymentMethodBottomSheet.module.css";

import { IPaymentType } from '~/hooks/user/useSelectPay';
import { PaymentMethods } from '~/constants';
import { useBasketContext } from "~/hooks/basket/useBasket.hook";

export const PaymentMethodBottomSheet = ({
  isOpen,
  hideModal,
  onExit,
  className,
  ...props
}: PaymentMethodBottomSheetProps): JSX.Element => {
  const { payments, activePayment, handleSetPaymentType, handleChangeBanknote } = useBasketContext();

  const handelChangePayment = (item: IPaymentType) => {
    handleSetPaymentType(item);
    if (item?.paymentMethodId !== PaymentMethods.cash) {
      handleChangeBanknote(null);
    }
    setTimeout(hideModal, 300)
  };

  return (
    <BottomSheet
      isOpen={isOpen}
      onExit={onExit}
      hideModal={hideModal}
    >
      <div className={clsx(styles.wrapper, className)} {...props}>
        <TitleMobile headerType='h4'>Способ оплаты</TitleMobile>
        <div className={styles.paymentsList}>
          {payments?.map((item) => {
            const {
              id,
              name,
              icon,
              key,
            } = item;

            return (
              <label
                key={id}
                className={clsx(styles.payment, {
                  [styles.checked]: id === activePayment?.id
                })}
                onClick={() => handelChangePayment(item)}
              >
                {icon && <span
                  className={styles.paymentIcon}
                >
                  <Image
                    width={45}
                    height={30}
                    alt={key}
                    src={icon}
                  />
                </span>}
                <span className={styles.paymentText}>
                  {name}
                </span>
                <span className={styles.pseudoInput}></span>
                <input className={styles.input} type="radio" name="paymentMethod" id={key} />
              </label>
            )
          })}
        </div>
      </div>
    </BottomSheet>
  );
};
