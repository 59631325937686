
import { useRouter } from "next/router";
import dynamic from 'next/dynamic';
import Link from "next/link";

import {
  UserAuthDesktop,
  CitySelectDesktop,
  ButtonBackDesktop,
  Search,
  SearchPartnerShopDesktop,
} from "~/components";
import styles from "./Header.module.css";

import { namedComponent } from '~/helpers/dynamicComponent';
import { usePartnerInfo } from '~/hooks/partner';

const CartButtonLink = dynamic(() =>
  namedComponent(import('~/components/_DesktopUI/CartButtonLink'), 'CartButtonLink')
);

export const Content = (): JSX.Element => {
  const { pathname } = useRouter();
  return (
    <div className={styles.headerContainer}>
      <BackElement />

      {pathname === "/shop/[id]" &&
        <div className={styles.search}>
          <SearchPartnerShopDesktop />
        </div>
      }
      {pathname === "/" && <div className={styles.search}> <Search /> </div>}
      <CitySelectDesktop className={styles.citySelect} />
      {pathname !== "/promotions/[id]" && <div className={styles.buttonCart}><CartButtonLink /></div>}
      <div className={styles.userAuth}><UserAuthDesktop /></div>
    </div>
  );
};


export const BackElement = () => {
  const { query, pathname, back } = useRouter();
  const partnerId = query.id as string;

  const { data } = usePartnerInfo(partnerId ?? "");

  return (
    <>
      {pathname === "/promotions/[id]" ? (
        <ButtonBackDesktop
          onClick={() => back()}
          className={styles.btnback}
        >
          Назад
        </ButtonBackDesktop>
      ) : (
        <div
          className={styles.logoWrapper}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Link href={`/?categoryId=${data?.mainWinImg}`} className={styles.logo}>
          </Link>
        </div>
      )}
    </>
  );
};
