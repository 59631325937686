import { useModal } from 'react-modal-hook';

import { BottomSheet, ButtonMobile } from "~/components";
import AboutPartner from './AboutPartner';
import styles from "./AboutPartner.module.css";
import InfoIcon from "~/assets/icons/info.svg";

export const AboutPartnerButton = (): JSX.Element => {
  const [showBottomSheet, hideBottomSheet] = useModal(
    ({ in: open, onExited }) => {
      return (
        <BottomSheet
          onExit={onExited}
          isOpen={open}
          hideModal={hideBottomSheet}
        >
          <AboutPartner />
        </BottomSheet>
      );
    }
  );

  return (
    <ButtonMobile
      appearance='gost'
      className={styles.buttonDelivery}
      onClick={showBottomSheet}
    >
      <InfoIcon className={styles.icon} />
      <div className={styles.buttonText}>
        Информация о партнере
      </div>
    </ButtonMobile>
  )
};
