import { NextRouter } from "next/router";
// import { IAddressPartnerZone, addressPartnerZone } from "~/plugins/api/basket";
import {
  checkPartnerDeliveryZone,
  // ICheckPartnerZone,
} from "~/plugins/api/partner";

interface IParams {
  partnerId?: string;
  categoryId?: number;
  basketId: string | undefined;
  latitude: string;
  longitude: string;
  isEmptyBasket: boolean;
}

interface IUpdatePage {
  router: NextRouter;
  params: IParams;
  callbackFailure?: (id: string) => void;
  callBackSuccess?: () => void;
}

export const updatePage = async ({
  router,
  params,
  callbackFailure = () => null,
  callBackSuccess = () => null,
}: IUpdatePage) => {
  const isPartnerPage =
    router.pathname === "/partner/[id]" ||
    router.pathname === "/shop/[id]" ||
    router.pathname === "/retail/[id]";

  // if (isPartnerPage) {
  let result = false;

  //Если корзина не пуста
  if (!params.isEmptyBasket) {
    // проверяем вхождение в зону при не пустой корзине
    if (!params.isEmptyBasket) {
      result = await checkPartnerDeliveryZone({
        partnerId: params.partnerId!,
        latitude: String(params.latitude),
        longitude: String(params.longitude),
      });
    }

    // Находимся на странице партнера
    if (isPartnerPage) {
      // Если по выбранному адресу партнер доставляет, то обновляем страницу и устанавливаем новый адрес
      if (result) {
        callBackSuccess();
        return router.replace(router.asPath);
      }

      // Если по выбранному адресу партнер не доставляет, то возвращаем "callbackFailure"
      if (!result) {
        return callbackFailure(params.basketId!);
      }
    } else {
      // Находимся не на странице партнера
      // Партнер входит в зону доставки и если корзина не пуста
      if (result && !params.isEmptyBasket) {
        callBackSuccess();
        return router.replace(router.asPath);
      }

      // Партнер не входит в зону доставки(если корзина не пуста)
      if (!result && !params.isEmptyBasket) {
        return callbackFailure(params.basketId!);
      }
    }
  }

  //Если корзина пуста
  if (params.isEmptyBasket) {
    // Находимся на странице партнера
    if (isPartnerPage) {
      // проверяем вхождение в зону при пустой корзине
      if (params.partnerId) {
        result = await checkPartnerDeliveryZone({
          partnerId: params.partnerId!,
          latitude: String(params.latitude),
          longitude: String(params.longitude),
        });
      }

      // Если по выбранному адресу партнер доставляет, то обновляем страницу и устанавливаем новый адрес
      if (result) {
        callBackSuccess();
        return router.replace(router.asPath);
      }

      // Если по выбранному адресу партнер не доставляет, то возвращаем "callbackFailure"
      if (!result) {
        return callbackFailure(params.basketId!);
      }
    }

    // Находимся не на странице партнера
    callBackSuccess();
    return router.replace(router.asPath);
  }
};

// const isPartnerPage =
//     router.pathname === "/partner/[id]" ||
//     router.pathname === "/shop/[id]" ||
//     router.pathname === "/retail/[id]";

//   let result = false;

//   if (!params.isEmptyBasket) {
//     result = await checkPartnerDeliveryZone({
//       partnerId: params.partnerId!,
//       latitude: String(params.latitude),
//       longitude: String(params.longitude),
//     });
//   }

//   if (params.isEmptyBasket) {
//     result = !!(await addressPartnerZone({
//       basketId: params.basketId,
//       latitude: String(params.latitude),
//       longitude: String(params.longitude),
//     }));
//   }
//   console.log(result);
//   if (isPartnerPage) {
//     if (result) {
//       callBackSuccess();
//       return router.replace(router.asPath);
//     }

//     if (params.isEmptyBasket && !result) callbackFailure(params.basketId!);

//     if (router.pathname === "/search") return callBackSuccess();

//     if (router.pathname === "/search") return callBackSuccess();

//     if (router.pathname === "/") {
//       callBackSuccess();
//       return router.replace(router.asPath);
//     }

//     return callbackFailure(params.basketId!);
//   } else {
//     if (router.pathname === "/myaddresses") {
//       callBackSuccess();
//       return router.replace(router.asPath);
//     }

//     // if (router.pathname === "/") {
//     callBackSuccess();
//     return router.replace(router.asPath);
//     // }
//     // callBackSuccess();
//     // return router.push("/");
//   }
