import { PropsWithChildren } from "react";

import {
	Modal,
	PromocodeViewDesktop
} from '~/components';
import PromocodeModalProps from "./PromocodeModal.props";

export const PromocodeModal = ({
	isOpen,
	hideModal,
	onExit,
	className,
	...props
}: PropsWithChildren<PromocodeModalProps>): JSX.Element => {

	return (
		<Modal width={420} headline="Промокод" align="left" isOpen={isOpen} hideModal={hideModal} onExit={onExit}>
			<PromocodeViewDesktop onSuccess={hideModal} className={className}{...props} />
		</Modal>
	);
};