import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useBasketContext } from "./basket/useBasket.hook";
import { usePartnerInfo } from "./partner";

export function useDeliveryFreeSumm() {
  const { query } = useRouter();
  const { data: partnerInfo = null } = usePartnerInfo(query.id as string);

  const [freeDeliverysumm, setFreeDeliverySumm] = useState<number>(0);

  useEffect(() => {
    if (partnerInfo) {
      const deliveryFrame = partnerInfo?.deliveryFrames[0].deliveryCosts;
      const isFree = deliveryFrame.find(
        ({ deliveryCost }) => deliveryCost === 0
      );
      const amountFrom = isFree?.amountFrom;
      amountFrom ? setFreeDeliverySumm(amountFrom) : setFreeDeliverySumm(0);
    }
  }, [partnerInfo]);

  return { freeDeliverysumm };
}

export function useLeftDeliveryFreeSumm() {
  const { basket } = useBasketContext();
  const { data: partnerInfo = null } = usePartnerInfo(basket?.partnerId ?? "");
  const getFreeSumm = (amountFree: number | null, amountBasket: number) => {
    if (!amountFree) return 0;
    if (amountFree > amountBasket) {
      if (Math.floor(amountFree / amountBasket - 1) > 0.4) return 0;
      return amountFree - amountBasket;
    }
    return 0;
  };

  const amount = useMemo(() => {
    if (!basket) return 0;

    return Math.ceil(basket.amount - basket.discount);
  }, [basket]);

  const [freeDeliveryLeft, setFreeDeliveryLeft] = useState(0);

  useEffect(() => {
    if (partnerInfo) {
      const deliveryFrame = partnerInfo?.deliveryFrames[0].deliveryCosts;
      const isFree = deliveryFrame.find(
        ({ deliveryCost }) => deliveryCost === 0
      );
      const freeDeliverysumm = isFree?.amountFrom ?? 0;
      const summ = getFreeSumm(freeDeliverysumm, amount);
      setFreeDeliveryLeft(summ);
    }
  }, [basket?.content]);

  return { freeDeliveryLeft };
}
