import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";

import SearchPartnerMobileProps from "./SearchPartnerMobile.props";
import styles from "./SearchPartnerMobile.module.css";
import SearchIcon from "~/assets/icons/search.svg";
import ClearIcon from "~/assets/icons/close.svg";

import useDebounce from "~/hooks/useDebounce";

export const SearchPartnerMobile = ({
  className,
  ...props
}: SearchPartnerMobileProps): JSX.Element => {
  const router = useRouter();
  const pathName = router.pathname;
  const partnerId = router.query.id;
  const partnerName = router.query.name as string;
  const category = router.query.category;
  const categoryId = router.query.categoryId;
  const searchQuery = router.query.search;

  const basePath =
    pathName === "/retail/search/[id]"
      ? `/retail/search/${partnerId}?name=${partnerName}&category=${category}&categoryId=${categoryId}&search=`
      : `/partner/search/${partnerId}?name=${partnerName}&search=`;

  const field = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    searchQuery && setSearch(searchQuery as string);
  }, [searchQuery]);

  const debouncedSearch = useDebounce(search, 340);

  useEffect(() => {
    if (!debouncedSearch) return;
    if (debouncedSearch.length >= 1) {
      router.push(
        `${basePath}${debouncedSearch}`, `${basePath}${debouncedSearch}`, { shallow: true }
      );
    }
    if (search?.length === 0) {
      router.push(`${basePath}`, `${basePath}`, { shallow: true });
    }
  }, [debouncedSearch, search]);

  useEffect(() => {
    field.current?.focus();
  }, []);

  const handleEnterDownEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && search) {
      router.push(
        `${basePath}${debouncedSearch}`,
        `${basePath}${debouncedSearch}`,
        { shallow: true }
      );
    }
  };

  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <button className={styles.button}>
        <SearchIcon className={styles.searchIcon} />
      </button>
      <input
        value={search ?? ""}
        placeholder="Введите текст для поиска"
        className={clsx("text", styles.field)}
        type="text"
        name="search"
        autoComplete="off"
        onKeyDown={handleEnterDownEvent}
        onChange={({ currentTarget }) => setSearch(currentTarget.value)}
        ref={field}
      />
      {search && (
        <button
          className={styles.clear}
          onClick={() => {
            setSearch("");
            router.push(`${basePath}`, `${basePath}`, {
              shallow: true,
            });
            return field.current?.focus();
          }}
        >
          <ClearIcon className={styles.clearIcon} />
        </button>
      )}
    </div>
  );
};
