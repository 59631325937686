import { RefObject, useEffect, useMemo } from "react";
import ResizeObserver from "resize-observer-polyfill";
export interface ResizeObserverEntry {
  target: HTMLElement;
  contentRect: DOMRectReadOnly;
}

export const useResizeObserver = (
  element: RefObject<HTMLElement>,
  callback: (entry: DOMRectReadOnly) => void,
  triggers: any[] = []
) => {
  const ref = element && element.current;

  const handleResize = useMemo(
    () => (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback, ...triggers]
  );

  useEffect(() => {
    if (!ref) return;
    const resizeObserverOrPolyfill = ResizeObserver;
    const observer = new resizeObserverOrPolyfill((entries: any[]) =>
      handleResize(entries)
    );
    observer.observe(ref);

    return () => {
      if (ref) {
        observer.unobserve(ref);
        observer.disconnect();
      }
    };
  }, [ref, ...triggers]);

  return {};
};
