import clsx from "clsx";
import Image from 'next/legacy/image';

import { Popup } from "~/components";
import PaymentMethodsPopupProps from "./PaymentMethodsPopup.props";
import styles from "./PaymentMethodsPopup.module.css";
import CheckIcon from "~/assets/icons/check.svg";

import { IPaymentType } from '~/hooks/user/useSelectPay';
import { PaymentMethods } from '~/constants';
import { useBasketContext } from "~/hooks/basket/useBasket.hook";

export const PaymentMethodsPopup = ({
  isOpen,
  hidePopup,
  onExit,
  elementId,
  className,
  ...props
}: PaymentMethodsPopupProps): JSX.Element => {
  const { payments, activePayment, handleSetPaymentType, handleChangeBanknote } = useBasketContext()

  const handelChangePayment = (item: IPaymentType) => {
    handleSetPaymentType(item);
    if (item?.paymentMethodId !== PaymentMethods.cash) {
      handleChangeBanknote(null);
    }
    hidePopup()
  };

  return (
    <Popup
      isOpen={isOpen}
      onExit={onExit}
      hidePopup={hidePopup}
      elementId={elementId}
    >
      <div className={clsx(styles.wrapper, className)} {...props}>
        <>
          {payments?.map((item) => {
            const {
              id,
              name,
              icon,
              key,
            } = item;

            return (
              <div
                key={id}
                className={clsx(styles.payment, {
                  [styles.active]: id === activePayment?.id
                })}
                onClick={() => handelChangePayment(item)}
              >
                {icon && <span
                  className={styles.paymentIcon}
                >
                  <Image
                    width={45}
                    height={30}
                    alt={key}
                    src={icon}
                  />
                </span>}
                <span className={styles.paymentText}>
                  {name}
                </span>
                <span>
                  <CheckIcon className={styles.checkIcon} />
                </span>
              </div>
            )
          })}
        </>
      </div>
    </Popup>
  );
};
