import { ModalMobileProps } from "./MobileModal.props";
import clsx from "clsx";

import styles from "./MobileModal.module.css";
import { TitleMobile } from '~/components/_MobileUI/TitleMobile';
import useChangeVisibleMobileModal from '~/hooks/useChangeVisibleMobileModal';

export const MobileModal = ({
  isOpen,
  hideModal,
  onExit,
  headline,
  control = "right",
  align = 'center',
  fullScreen = false,
  hiddenHeader = false,
  hiddenCloseControl = true,
  children,
  className,
  ...props
}: ModalMobileProps): JSX.Element => {
  useChangeVisibleMobileModal({ isOpen, onExit });
  return (
    <div
      id='portal'
      data-portal
      className={clsx(styles.wrapper, {
        [styles.open]: isOpen,
        [styles.close]: !isOpen,
      })}
    >
      <div className={styles.overlay} onTouchStart={hideModal} />

      <div
        className={clsx(styles.window, {
          [styles.fullScreen]: fullScreen
        }, className)}
        {...props}
      >

        {!hiddenHeader ? (
          <div className={styles.header}>
            {control === "left" ? (
              <div className={clsx(styles.controlContainer, styles.leftControlContainer)}>
                <button className={styles.closeButton} onTouchStart={hideModal} />
              </div>
            ) : null}

            <div className={clsx(styles.headerTitle, {
              [styles.mlTitle]: control === "right" && align != "left",
              [styles.mrTitle]: control === "left" && align != "right"
            })}>
              {headline ? (
                <TitleMobile align={align} headerType='h3' margin={false}>{headline}</TitleMobile>
              ) : null}
            </div>

            {control === "right" ? (
              <div className={clsx(styles.controlContainer, styles.rightControlContainer)}>
                <button className={styles.closeButton} onTouchStart={hideModal} />
              </div>
            ) : null}
          </div>
        ) : (
          hiddenCloseControl ? <button onTouchStart={hideModal} className={styles.hideWindowBtn} /> : null
        )}

        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};
