import { useModal } from "react-modal-hook";
import { UserAddressWithList } from '~/models';
import { MobileMapModal, MobileModal } from '~/components';

interface IMobileMapModal {
  isEditAddress?: UserAddressWithList | null;
  succesCallBack?: () => void;
  dependencies?: any[]
}

export default function useMobileMapModal({
  isEditAddress = null,
  succesCallBack = () => null,
  dependencies = []
}: IMobileMapModal = {}) {

  const [showMobileMapModal, hideMobileMapModal] = useModal(({ in: open, onExited }) => {
    return (
      <MobileModal fullScreen hiddenHeader hiddenCloseControl={false} isOpen={open} hideModal={hideMobileMapModal} onExit={onExited}>
        <MobileMapModal
          isEditAddress={isEditAddress}
          hideModal={hideMobileMapModal}
          onSuccesAddress={succesCallBack}
        />
      </MobileModal>
    );
  }, [...dependencies, isEditAddress]);

  return {
    showMobileMapModal,
    hideMobileMapModal,
  };
}
