import { DetailedHTMLProps, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import Image from 'next/legacy/image';

import {
  ButtonDesktop,
  Input,
  TitleDesktop,
} from "~/components";
import styles from "./DesktopProfileModal.module.css";
import TrashIcon from "~/assets/icons/trash.svg";

import useSaveUserName from '~/hooks/useSaveUserName';
import { useUserContext } from '~/hooks/user/useUser.hook';
import { useDeleteUserCard } from '~/hooks/user/useDeleteUserCard';
import useConfirmModal from '~/hooks/useConfirmModal';
import { DeleteCardProps } from '~/plugins/api/payment';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';

interface DesktopProfileModalProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  hideModal: () => void;
}

interface ErrorResponse {
  message: string;
  status: number;
  statusText: string;
}

// function isObject(obj: unknown): obj is object {
//   return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
// }

// const isErrorResponse = (error: unknown): error is ErrorResponse  => {
//   if (!isObject(error)) {
//     return false;
//   }

//   if (
//     'message' in error && typeof error.message === 'string' && 
//     'status' in error && typeof error.status === 'number' && 
//     'statusText' in error && typeof error.statusText === 'string'
//   ) {
//     return true
//   }

//   return false
// }

export const Profile = ({
  hideModal = () => null,
  ...props
}: DesktopProfileModalProps): JSX.Element => {
  const { userName } = useUserContext();
  const { cardsList } = useBasketContext();

  const {
    setName,
    handleSaveName,
    name,
  } = useSaveUserName(hideModal, userName);

  const field = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (field.current) field.current?.focus()
  }, []);

  const { mutate, isError, error, isLoading } = useDeleteUserCard();
  const [deleteId, setDeleteId] = useState<DeleteCardProps>({
    cardId: '',
    rebillId: ''
  })

  const handleDeleteCard = async (cardId: number, rebillId: string) => {
    setDeleteId({cardId, rebillId})
    showConfirmModal()
  };

  const [ showConfirmModal, hideConfirmModal ] = useConfirmModal({
		onConfirm: () => mutate(deleteId),
		onCancel: () => hideConfirmModal(),
		width: 500,
		message: "Вы уверены что хотите удалить карту",
		cancelText: "Нет",
		confirmText: "Да",
		dependencies: [deleteId]
	})

  const erroeResp = (error as ErrorResponse)?.message;

  const [ showAlertModal, hideAlertModal ] = useConfirmModal({
		onConfirm: () => null,
		onCancel: () => hideAlertModal(),
		width: 500,
    title: "Внимание !", 
		message: erroeResp || "Не удалось удалить карту из-за проблем на сервере.",
		cancelText: "Закрыть",
		confirmText: "",
		dependencies: [erroeResp]
	})

  useEffect(() => {
    if(isError && erroeResp) {
      showAlertModal();
    }
  }, [erroeResp, isError])

  return (
    <div {...props}>
      <div className={styles.setings}>
        <Input
          innerRef={field}
          placeholder="Ваше имя"
          value={!name.length ? userName : name}
          className={styles.name}
          onChange={({ currentTarget }) => setName(currentTarget?.value)}
        />

        <ButtonDesktop
          className={styles.buttonSaveName}
          disabled={userName.trim() == name.trim()}
          onClick={handleSaveName}
        >
          Внести изменения
        </ButtonDesktop>
      </div>

      {cardsList?.length ? <div className={styles.cards}>
        <TitleDesktop headerType='h4'>Привязанные карты</TitleDesktop>
        <ul className={styles.cardsList}>
          {cardsList.map(({ id, key, name, icon }) => (
            <li key={id} onClick={() => handleDeleteCard(id, key)} className={styles.cardItem}>
              {icon && <span
                className={styles.cardItemIcon}
              >
                <Image
                  width={45}
                  height={30}
                  alt={key}
                  src={icon}
                />
              </span>}
              <span className={styles.cardItemText}>
                {name}
              </span>
              <span>
                <TrashIcon className={styles.checkIcon} />
              </span>
            </li>
          ))}
        </ul>
      </div> : null}
      {/* <div className={styles.setings}>
          <TitleDesktop headerType='h5'>Настройки</TitleDesktop>
          <div
            onClick={handleChkecked}
            className={styles.checkbox}
            {...props}
          >
            <Checkbox value={'Уведомления'} checked={checked} />
            <div className={styles.title}>Уведомления</div>
          </div>
        </div> */}
    </div>
  );
};

