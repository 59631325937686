import Link from "next/link";
import clsx from "clsx";

import { ProductCardMobile, RenderIfVisible, Slider } from "~/components";
import ProductCardRowProps from "./ProductCardRow.props";
import styles from "./ProductCardRow.module.css";
import { CardsHeight } from '~/constants';
import "swiper/css";

export const ProductCardRowMobile = ({
  className,
  products,
  path = "",
  withLinkCard = true,
  ...props
}: ProductCardRowProps): JSX.Element => {
  return (
    <div className={clsx(className)} {...props}>
      <Slider
        slidesPerView={"auto"}
        sliderWidth={164}
        spaceBetween={16}
        colorHide="#f8f8f8"
      >
        {products.map((product) => {
          const defHeight = CardsHeight.mobileProductCard;
          return withLinkCard ? (
            <div key={product.id} className={styles.mobileCardLinkWrapper}>
              <RenderIfVisible
                key={product.id}
                defaultHeight={defHeight}
                placeholderCardClass="mobileCard"
              >
                <ProductCardMobile
                  key={product.id}
                  product={product}
                />
              </RenderIfVisible>
              <Link key={product.id} href={path} className={styles.mobileCardLink} target={"_self"}>
              </Link>
            </div>
          ) : (
            <RenderIfVisible
              key={product.id}
              defaultHeight={defHeight}
              placeholderCardClass="mobileCard"
            >
              <ProductCardMobile
                key={product.id}
                product={product}
              />
            </RenderIfVisible>
          );
        })}
      </Slider>
    </div>
  );
};
