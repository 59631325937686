import styles from "./ConfirmModal.module.css";
import { PropsWithChildren } from "react";
import {
  confirmable,
  createConfirmation,
  ReactConfirmProps,
} from "react-confirm";
import { ButtonDesktop, Modal } from "~/components";

export const ConfirmModalCallable = ({
  show,
  cancel,
  proceed,
  confirmation,
  dismiss,
}: PropsWithChildren<ReactConfirmProps>): JSX.Element => {
  return (
    <Modal width={500} headline={confirmation} isOpen={show} hideModal={cancel} onExit={dismiss}>
      <>
        <div className={styles.btnWrapper}>
          <ButtonDesktop
            appearance='gray'
            className={styles.button}
            onClick={dismiss}
          >
            НЕТ
          </ButtonDesktop>
          <ButtonDesktop
            className={styles.button}
            onClick={() => {
              proceed("ok");
            }}
          >
            ДА
          </ButtonDesktop>
        </div>
      </>
    </Modal>
  );
};

const ConfirmHOC = confirmable(ConfirmModalCallable);

export const confirm = createConfirmation(ConfirmHOC);
