import { isPartnerWorkingTime } from "~/helpers";
import {
  Pagination,
  Partner,
  PartnerCategory,
  PartnerProduct,
  PartnerRating,
} from "~/models/partners";
import { httpv1 } from "~/plugins/api/http";
import { coordinatesDefault } from "../main";

export const getPartnerInfo = async (partnerId: string) => {
  const response = await httpv1.get<Partner>("Partner/byid", {
    params: {
      PartnerId: partnerId,
    },
  });

  return response.data;
};

export interface IPartnersResponse {
  nextCursor: unknown;
  partners: Partner[];
  pagination: Pagination;
}

export interface IGetPartnersParams {
  cityId?: string;
  marketCategoryId: string | number;
  tagId?: string;
  latitude?: string;
  longitude?: string;
  offset?: number;
  limit?: number;
}

export const getPartners = async ({
  marketCategoryId,
  tagId,
  latitude = coordinatesDefault[0],
  longitude = coordinatesDefault[1],
  offset = 0,
  limit = 0,
}: IGetPartnersParams) => {
  const response = await httpv1.get<IPartnersResponse>("Partner/all", {
    params: {
      MarketCategoryId: marketCategoryId,
      TagId: tagId,
      Latitude: latitude,
      Longitude: longitude,
      Offset: offset,
      Limit: limit,
    },
  });

  return response.data;
};

interface IPopularPartners {
  longitude?: string;
  latitude?: string;
  offset?: number;
  limit?: number;
}

export const getPopularPartners = async ({
  latitude = coordinatesDefault[0],
  longitude = coordinatesDefault[1],
  offset = 0,
  limit = 0,
}: IPopularPartners) => {
  const response = await httpv1.get<Partner[]>("Partner/popularity_partners", {
    params: {
      Longitude: longitude,
      Latitude: latitude,
      Offset: offset,
      Limit: limit,
    },
  });

  return response.data;
};

export const isPartnerWorkingById = async (partnerId: string) => {
  const { data: partner } = await httpv1.get<Partner>("Partner/byid", {
    params: {
      PartnerId: partnerId,
    },
  });

  return isPartnerWorkingTime(partner);
};

interface IPartnerParentCategories {
  partnerId: string;
  categoryId?: string;
}

interface ICategoryResponse {
  validationErrors: Array<unknown>;
  categories: PartnerCategory[];
}

export const getPartnerParentCategories = async ({
  partnerId,
  categoryId,
}: IPartnerParentCategories) => {
  const response = await httpv1.get<ICategoryResponse>(
    "Partner/partner_products_categories",
    {
      params: {
        PartnerId: partnerId,
        CategoryId: categoryId,
      },
    }
  );

  return response.data.categories;
};

export interface IPartnerData {
  products: PartnerProduct[] | [];
  categories: PartnerCategory[];
  marketCategoryId: number;
  pagination: Pagination;
}

interface IPartnerProductsResponse {
  partnerId: string;
  productCategoryId?: string;
  hasDiscount?: boolean;
  offset?: number;
  limit?: number;
}

export const getPartnerProducts = async ({
  partnerId,
  productCategoryId,
  hasDiscount,
  offset = 0,
  limit = 0,
}: IPartnerProductsResponse) => {
  const response = await httpv1.get<IPartnerData>("Partner/products", {
    params: {
      PartnerId: partnerId,
      ProductCategoryId: productCategoryId,
      HasDiscount: hasDiscount,
      Offset: offset,
      Limit: limit,
    },
  });

  return response.data;
};

interface IPartnerRating {
  partnerId: string;
  offset?: number;
  limit?: number;
}

export const getPartnerRating = async ({
  partnerId,
  offset = 0,
  limit = 0,
}: IPartnerRating) => {
  const response = await httpv1.get<PartnerRating>("Partner/feedbacks", {
    params: {
      PartnerId: partnerId,
      Offset: offset,
      Limit: limit,
    },
  });

  return response.data;
};

export interface IPartnerBreadcrumbs {
  id: string;
  name: string;
  photo: string;
  description: string;
  priority: number;
  partnerId: string;
  parentCategoryId: string;
}

export const getPartnerBreadcrumbs = async (categoryId: string) => {
  const response = await httpv1.get<IPartnerBreadcrumbs[]>(
    "Partner/breadcrumbs",
    {
      params: {
        CategoryId: categoryId,
      },
    }
  );

  return response.data;
};

export interface ICheckPartnerZone {
  partnerId: string;
  longitude: string;
  latitude: string;
}

export const checkPartnerDeliveryZone = async ({
  partnerId,
  longitude,
  latitude,
}: ICheckPartnerZone) => {
  const response = await httpv1.get("Partner/check_partner_delivery_zone", {
    params: {
      partnerId: partnerId,
      Longitude: longitude,
      Latitude: latitude,
    },
  });

  return response.data;
};
