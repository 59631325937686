import { useState } from "react";
import clsx from "clsx";

import {
  ButtonDesktop
} from "~/components";

import AddressListPopupProps from "./AddressListPopup.props";
import styles from "./AddressListPopup.module.css";
import PlusIcon from "~/assets/icons/plus.svg";

import { UserAddressWithList } from '~/models';
import getAddressInfo from '~/helpers/getAddressInfo';
import useChangedAddresses from '~/hooks/address/useChangedAddresses';
import { useAddressContext } from '~/hooks/address/useAddress.hook';

declare const ym: any;

export const AddressListPopup = ({
  hidePopup = () => null,
  hidePopupWithOpenMapModal = () => null,
  onSuccessAddress = () => null,
  ...props
}: AddressListPopupProps): JSX.Element => {
  const { location, userAddresses } = useAddressContext();

  const [changedAddress, setChangedAddress] = useState<UserAddressWithList | null>(null);

  useChangedAddresses({ address: changedAddress, successChanged: hidePopup });

  if (!userAddresses.length) return <div></div>;

  return (
    <>
      <div className={styles.wrapper} {...props}>
        <div className={clsx(styles.addressList, {
          [styles.addressListScroll]: userAddresses.length > 4
        })}>
          {userAddresses.map((item) => {
            const {
              id,
              cityName,
              address
            } = item;
            const parsedAddress = getAddressInfo(address, cityName ?? "");

            return (
              <div
                key={id}
                className={styles.addressItem}
                onClick={() => setChangedAddress(item)}
              >
                <span className={styles.address}>
                  <span className={clsx(styles.circle, {
                    [styles.selectCircle]: address.toLowerCase() === location?.address.toLowerCase() && cityName.toLowerCase() === location?.cityName.toLowerCase()
                  })}></span>
                  <span className={styles.addressStreet}>
                    {!parsedAddress?.localities.length ? `${parsedAddress?.city}, ` : `${parsedAddress?.localities}, `}
                    {parsedAddress?.street.length ? `${parsedAddress?.street}, ` : " "}
                  </span>
                  <span className={styles.addressHouse}>{parsedAddress?.house}</span>
                </span>
              </div>
            );
          })}
        </div>
        <div className={styles.bottomBar}>
          <ButtonDesktop className={styles.buttonAddAddress} onClick={hidePopupWithOpenMapModal} appearance='gray'>
            <PlusIcon />
            <span className={styles.text}>Добавить новый</span>
          </ButtonDesktop>
        </div>
      </div>
    </>
  );
};
