import { PropsWithChildren, useMemo } from 'react';
import { useRouter } from "next/router";
import clsx from "clsx";

import {
  CartItem,
  ButtonTimeSelectDesktop,
  TitleDesktop,
  ButtonDesktop,
  FreeShippingLeftPlate,
} from "~/components";
import { CutleryCounter } from "~/components/CutleryCounter";

import { CartHTMLProps, CartProps } from "./AsideCart.props";
import styles from "./AsideCart.module.css";
import EditPencilIcon from "~/assets/icons/edit-pencil.svg";
import TrashIcon from "~/assets/icons/trash.svg";
import EmptySvg from "~/assets/svg/empty-cart.svg";
import CartIcon from "~/assets/icons/cart.svg";
import useConfirmModal from '~/hooks/useConfirmModal';
import { useBasketContext } from '~/hooks/basket/useBasket.hook';
import { usePartnerInfo } from '~/hooks/partner';

declare const ym: any;

export const AsideCart = ({
  isCartPopup = false,
  onHide = () => null,
  className,
  ...props
}: CartProps): JSX.Element => {
  return (
    <Wrapper className={className} {...props}>
      <>
        <CartHeader callBack={onHide} />

        <CartBody />

        <div className={styles.cartFooter}>
          <FreeShippingLeftPlate
            className={styles.shippengPlate}
            size='s'
            align='center'
          />

          <FooterMetaInfo isCartPopup={isCartPopup} />

          <FooterCheckout callBack={onHide} />
        </div>
      </>
    </Wrapper>
  );
};


const Wrapper = ({ children, className, ...props }: CartHTMLProps): JSX.Element => {
  const { basket } = useBasketContext();
  return (
    <div
      className={clsx(styles.cart, className)}
      {...props}
    >
      {basket?.content.length ? (
        children
      ) : (
        <div className={styles.empty}>
          <EmptySvg className={styles.svg} />
          <span className={styles.emptyText}>
            Ваша корзина все еще пуста, исправьте это!
          </span>
        </div>
      )}
    </div>
  );
};

const CartHeader = ({ callBack }: PropsWithChildren<{ callBack: () => void }>): JSX.Element => {
  const { basket, tokenForMetrics, clearBasket } = useBasketContext();

  const handleClearBasket = () => {
    clearBasket();
    callBack();
    tokenForMetrics && ym ? ym(88121717, 'reachGoal', 'clear_basket') : null;
  };

  const handleHideConfirmModal = () => hideConfirmModal();

  const [ showConfirmModal, hideConfirmModal ] = useConfirmModal({
    onConfirm: handleClearBasket,
    onCancel: handleHideConfirmModal,
    width: 500,
    title: "Вы хотите очистить корзину?",
    message: '',
    dependencies: [basket]
  });

  return (
    <div className={styles.cartHeader}>
      <TitleDesktop headerType='h4' margin={false}>{basket?.partnerName}</TitleDesktop>
      <ButtonDesktop appearance='text' onClick={showConfirmModal} className={styles.clearBasket}>
        <TrashIcon className={styles.cleareIcon} />
      </ButtonDesktop>
    </div>
  );
};

const CartBody = (): JSX.Element => {
  const { basket } = useBasketContext();
  const products = basket?.content;
  const isRestaurant = basket?.marketCategoryId == 1;

  return (
    <div className={styles.cartBody}>
      {products ? (
        <>
          {products.map((product) => {
            return (
              <CartItem
                marketCategoryId={basket?.marketCategoryId}
                partnerId={basket?.partnerId}
                product={product}
                key={product.id}
              />
            );
          })}
        </>
      ) : null}
      {isRestaurant ? <CutleryCounter basket={basket} /> : null}
    </div>
  );
};

const FooterMetaInfo = ({ isCartPopup }: PropsWithChildren<{ isCartPopup: boolean }>): JSX.Element => {
  const { basket } = useBasketContext();
  const { data: partner = null } = usePartnerInfo(basket?.partnerId ?? "");
  const deliveryType = partner?.deliveryFrames[0].deliveryTypeId;

  return (
    <div className={styles.metaInfo}>
      <div className={clsx(styles.metaItem, styles.metaDelivery)}>
        <div className={styles.text}>
          <span>
            Доставка
          </span>
          <span className={styles.deliverySumm}>
            {basket?.deliveryCost}₽
          </span>
        </div>
        <div className={styles.subText}>
          {deliveryType === 2
            ? "Kурьер CronMarket"
            : "Kурьер Партнера"}
        </div>
      </div>

      <div className={styles.metaItem}>
        <div>
          <div className={styles.subText}>
            Время доставки
          </div>
          <ButtonTimeSelectDesktop elementId={isCartPopup ? "cartPicker" : "picker"} className={styles.deliveryInfoButton} />
        </div>
        <EditPencilIcon className={styles.penEditIcon} />
      </div>
    </div>
  );
};

const FooterCheckout = ({ callBack }: PropsWithChildren<{ callBack: () => void }>): JSX.Element => {
  const { pathname, push } = useRouter();
  const { basket, loadingBasket, tokenForMetrics } = useBasketContext();
  const { data: partner = null, isLoading } = usePartnerInfo(basket?.partnerId ?? "");
  const partnerId = partner?.id;
  const minAmountDelivery =
    partner?.deliveryFrames[0]?.deliveryCosts[0]?.amountFrom ?? 0;

  const handleClickOrder = () => {
    tokenForMetrics && ym ? ym(88121717, 'reachGoal', 'place_order') : null;
    callBack();
    if (pathname === "/" || basket?.partnerId !== partnerId) {
      if (!basket?.partnerIsStructure) {
        return push(`/partner/${basket?.partnerId}?name=${basket?.partnerName}`);
      }
      return push(
        `/shop/${basket?.partnerId}?name=${basket?.partnerName}&category=Товары по акции&categoryId=promo`
      );
    }
    return push("/ordercheckout");
  };

  const amount = useMemo(() => {
    if (!basket) return 0;
    return Math.ceil(basket.amount - basket.discount);
  }, [basket]);

  return (
    <div className={styles.checkout}>
      {amount < minAmountDelivery && basket?.partnerId === partnerId ? (
        <ButtonDesktop
          disabled={true}
          className={styles.checkoutBtn}
        >
          <div className={styles.leftBlock}>
            <CartIcon className={styles.cartIcon} />
            {amount}₽
          </div>
          <div className={styles.rightBlock}>
            Заказ от {minAmountDelivery} ₽
          </div>
        </ButtonDesktop>
      ) : (
        <ButtonDesktop
          loading={loadingBasket || isLoading}
          className={styles.checkoutBtn}
          onClick={handleClickOrder}
        >
          {pathname === "/" || basket?.partnerId !== partnerId ?
            "Перейти к партнеру" :
            <>
              <div className={styles.leftBlock}>
                <CartIcon className={styles.cartIcon} />
                {amount}₽
              </div>
              <div className={styles.rightBlock}>
                Оформить заказ
              </div>
            </>
          }
        </ButtonDesktop>
      )}
    </div>
  );
};

