import { useState, useEffect } from "react";

const useCloseESCModal = () => {
  const [isClose, setIsClose] = useState(false);
  const onKeydown = ({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      return setIsClose(true);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => document.removeEventListener("keydown", onKeydown);
  });

  return isClose;
};

export default useCloseESCModal;
